import { FieldOption } from "activity/components/choosers/FieldCropChooser/FieldCropOptionBase";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const GroupHeading = styled.div`
  display: flex;
  font-size: ${Typography.sizes.sm};
  font-weight: ${Typography.weights.medium};
  justify-content: space-between;
  padding: ${Spacing.spacing12};
  text-transform: uppercase;
  a {
    color: ${UIColors.blue};
  }
`;

const FieldCropGroupOption = (props) => {
  const { onAddFieldCrops, onCreateFieldCrop, onRemoveFieldCrops, search, showSelectAll, value } = props;
  const { fields, group, totalFields } = props.option;
  const selectedCropIds = _.map(value, "id");

  const handleSelectAll = (event) => {
    event.preventDefault();
    onAddFieldCrops(fields?.filter(({ __typename }) => __typename === "Crop") ?? []);
  };

  const headingTitle = `${_.startCase(group.name)} \u00b7 ${totalFields} ${totalFields === 1 ? "field" : "fields"}`;

  return (
    <div>
      <GroupHeading>
        {headingTitle}
        {showSelectAll && (
          <Link to="/" onClick={handleSelectAll}>
            Select all
          </Link>
        )}
      </GroupHeading>

      <div>
        {_.map(fields, (fieldOrFieldCrop, i) => {
          const isCrop = fieldOrFieldCrop.__typename === "Crop";
          const isSelected = selectedCropIds.includes(fieldOrFieldCrop.id);

          const handleSelect = () => {
            if (isCrop) {
              if (isSelected) {
                onRemoveFieldCrops(fieldOrFieldCrop);
              } else {
                onAddFieldCrops(fieldOrFieldCrop);
              }
            } else {
              onCreateFieldCrop(fieldOrFieldCrop);
            }
          };

          return (
            <FieldOption
              key={`fieldOrFieldCrop-${fieldOrFieldCrop.id}`}
              isSelected={isSelected}
              onSelect={handleSelect}
              option={fieldOrFieldCrop}
              search={search}
            />
          );
        })}
      </div>
    </div>
  );
};

FieldCropGroupOption.propTypes = {
  onAddFieldCrops: PropTypes.func.isRequired,
  onCreateFieldCrop: PropTypes.func.isRequired,
  onRemoveFieldCrops: PropTypes.func.isRequired,
  option: PropTypes.shape({
    fields: PropTypes.array.isRequired,
    group: PropTypes.object.isRequired,
    totalFields: PropTypes.number.isRequired,
  }).isRequired,
  showSelectAll: PropTypes.bool,
};

FieldCropGroupOption.defaultProps = {
  showSelectAll: false,
};

export default FieldCropGroupOption;
