import { Section } from "demo/components";
import React from "react";
import styled from "styled-components";

import IconContainer from "components/fl-ui/Icons/IconContainer";
import IconManifest from "components/fl-ui/Icons/IconManifest";
import { Spacing } from "components/fl-ui/constants";

const Container = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  > * {
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px 5px;
    :hover {
      background-color: #efefef;
    }
  }
`;

const Text = styled.div`
  font-family: monospace;
  font-size: 11px;
`;

const IconDemo = () => {
  const handleClick = async (iconText) => {
    await navigator.permissions.query({ name: "clipboard-write" });
    navigator.clipboard.writeText(iconText);
  };

  return (
    <Section title="Icons">
      <Container>
        {IconManifest.map((icon, i) => (
          <div key={i}>
            <IconContainer
              icon={icon}
              size={Spacing.spacing32}
              title="Click to copy"
              onClick={() => handleClick(icon)}
            />
            <Text>{icon}</Text>
          </div>
        ))}
      </Container>
    </Section>
  );
};

export default IconDemo;
