import { StyleSheet } from "aphrodite/no-important";

import { ColorPalette, GreyColors, UIColors } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography, BorderRadius, Borders } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  changeButton: {
    alignSelf: "flex-end",
  },
  checkOutHeader: {
    alignItems: "center",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    marginBottom: Spacing.spacing16,
    marginTop: Spacing.spacing16,
    textAlign: "center",
  },
  // Plan Selection
  enterprise_bannerCol: {
    margin: `${Spacing.spacing16} 0`,
  },
  plan_CardContainer: {
    marginTop: Spacing.spacing48,
    "@media only screen and (min-width: 30rem)": {
      display: "none",
    },
  },
  plan_CardButton: {
    display: `inline-block !important`,
    maxHeight: "2.5rem",
    width: "100%",
    "@media only screen and (max-width: 30rem)": {
      display: "none",
    },
  },
  plan_CardButtonLabel: {
    display: "inline-block",
    verticalAlign: "top",
  },
  hide_OnMobile: {
    "@media only screen and (max-width: 30rem)": {
      display: "none",
    },
  },
  product_card: {
    height: "100%",
  },
  product_cardSelected: {
    borderColor: `${UIColors.primary} !important`,
    borderWidth: "1.5px",
    padding: `${Mixins.toRem(14)} !important`, // overrides 1rem/16px
    marginTop: `0 !important`,
    "@media only screen and (min-width: 62.5em)": {
      padding: `${Mixins.toRem(22)} !important`, // overrides 1.5rem/24px
    },
  },
  product_cardFooter: {
    alignItems: "center",
    borderTop: Borders.regular,
    display: "flex",
    justifyContent: "space-between",
    marginTop: Spacing.spacing16,
    paddingTop: Spacing.spacing16,
  },
  plan_CardName: {
    fontSize: Typography.sizes.xxl,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.spacing16,
    marginTop: Spacing.spacing16,
  },
  plan_CellName: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.spacing16,
    marginTop: Spacing.spacing16,
    "@media only screen and (min-width: 30rem)": {
      fontSize: Typography.sizes.lg,
    },
  },
  plan_CardText: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    marginBottom: Spacing.spacing16,
    marginTop: Spacing.spacing8,
    minHeight: Spacing.spacing14,
  },
  product_cardText: {
    fontSize: Typography.sizes.rg,
    lineHeight: 1.5,
    minHeight: "5rem",
  },
  product_cardButtonLink: {
    textAlign: "left",
    whiteSpace: "normal",
  },
  product_included: {
    color: ColorPalette["dark-blue"],
    fontWeight: Typography.weights.medium,
    marginTop: Spacing.spacing16,
    maxWidth: "65%",
    textTransform: "uppercase",
  },
  product_selectionContainer: {
    margin: `${Spacing.spacing8} 0 ${Spacing.spacing16}`,
  },
  product_title: {
    fontSize: Typography.sizes.xl,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.5,
    marginTop: 0,
    marginBottom: Spacing.spacing8,
    paddingTop: 0,
  },
  recommendedPlan_CardLabel: {
    background: "rgb(242, 230, 242)",
    border: Borders.regular,
    color: "rgb(117, 37, 111)",
    fontSize: Typography.sizes.sm,
    "@media only screen and (max-width: 30rem)": {
      display: "none",
    },
  },

  // PointOfSale
  pos_buttonContainer: {
    display: "flex",
    flexFlow: "column",
  },
  cancelButton: {
    margin: `${Spacing.spacing8} 0 ${Spacing.spacing16}`,
  },
  pos_submitButton: {
    margin: `${Spacing.spacing16} 0 ${Spacing.spacing8}`,
  },
  pos_cardDetail: {
    fontSize: Spacing.spacing14,
    margin: `0 ${Spacing.spacing12}`,
  },
  pos_miniMargins: {
    marginLeft: Spacing.spacing4,
    marginRight: Spacing.spacing4,
  },
  pos_formRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 0,
    "@media only screen and (max-width: 30em)": {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  pos_formGroupSplit: {
    width: "100%",
    marginLeft: `${Spacing.spacing12} !important`,
    marginRight: `${Spacing.spacing12} !important`,
    ":first-child": {
      marginLeft: `0 !important`,
    },
    ":last-child": {
      marginRight: `0 !important`,
    },
    "@media only screen and (max-width: 30em)": {
      marginLeft: `0 !important`,
      marginRight: `0 !important`,
    },
  },
  no_marginBottom: {
    marginBottom: 0,
  },
  pos_formInputError: {
    minHeight: Spacing.spacing14,
    fontSize: Typography.sizes.mn,
    fontWeight: Typography.weights.medium,
    color: UIColors.red,
    marginTop: Mixins.toRem(2),
    marginBottom: Mixins.toRem(8),
  },
  pos_stripeInput_Wrapper: {
    alignItems: "center",
    display: "inline-flex",
    border: `1px solid ${UIColors.lightest}`,
    borderRadius: BorderRadius.small,
    transition: "border-color 0.2s",
  },
  pos_stripeInput_Wrapper_focused: {
    borderColor: UIColors.primary,
  },
  pos_stripeInput_Wrapper_hasError: {
    borderColor: UIColors.danger,
    borderWidth: "2px",
  },
  pos_transactionError: {
    border: `1px solid ${UIColors.red}`,
    borderRadius: BorderRadius.small,
    color: UIColors.red,
    fontSize: Typography.sizes.md,
    fontWeight: Typography.weights.medium,
    marginBottom: Mixins.toRem(20),
    padding: Mixins.toRem(10),
  },
  pos_inputIcon: {
    width: Spacing.spacing20,
    height: Spacing.spacing20,
  },
  pos_stripeInput_tooltip: {
    marginRight: Spacing.spacing16,
    marginLeft: Spacing.spacing12,
  },
  pos_promo_codeText: {
    display: "block",
    marginBottom: Spacing.spacing8,
    marginTop: Spacing.spacing8,
  },
  pos_promo_code: {
    alignItems: "center",
    display: "flex",
    maxWidth: "70%",
  },
  pos_promo_code_button: {
    marginLeft: `${Spacing.spacing8} !important`,
  },
  pos_summary_text: {
    lineHeight: 1.5,
    textAlign: "left",
  },
  pos_summary_text_marginTop: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: Spacing.spacing16,
  },
  pos_agree_label: {
    display: "flex",
    fontSize: Typography.sizes.rg,
  },

  // Modal Shared Styles
  modal_text: {
    fontSize: Typography.sizes.lg,
    lineHeight: 1.5,
    marginBottom: 0,
  },

  cancel_modalText: {
    fontSize: Typography.sizes.lg,
    lineHeight: 1.5,
    marginBottom: Spacing.spacing16,
  },

  // SuccessModal
  success_modal: {
    borderRadius: `${BorderRadius.medium} !important`,
  },
  success_modalSection: {
    backgroundImage: "url(images/billing/support_team.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: Mixins.toRem(290),
    borderRadius: `${BorderRadius.medium} ${BorderRadius.medium} 0 0`,
  },
  success_modalBody: {
    borderRadius: `0 0 ${BorderRadius.medium} ${BorderRadius.medium}`,
  },
  success_closeX: {
    position: "absolute",
    top: Spacing.spacing24,
    right: Spacing.spacing24,
  },
  success_h2: {
    marginTop: 0,
    marginBottom: Spacing.spacing8,
  },
  platformLogo: {
    width: `${Spacing.spacing24} !important`,
    height: `${Spacing.spacing24} !important`,
    verticalAlign: "middle",
  },
  borderedContainer: {
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    marginBottom: Spacing.spacing24,
    padding: Spacing.spacing16,
  },
  couponContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: Spacing.spacing16,
  },
  couponErrorContainer: {
    background: "transparent",
    border: "none",
    margin: `0 -${Spacing.spacing16}`,
  },
  couponErrorValue: {
    background: ColorPalette["light-yellow"],
    margin: "0 -0.5px",
  },

  // utils
  bottom_line: {
    borderBottom: Borders.regular,
    paddingBottom: Spacing.spacing16,
    paddingTop: Spacing.spacing16,
    ":last-child": {
      borderBottom: "none",
      paddingBottom: 0,
    },
  },
  link_blue: {
    color: UIColors.blue,
    ":hover": {
      color: UIColors.blue,
    },
  },
  link_white: {
    color: UIColors.white,
    ":hover": {
      color: UIColors.white,
    },
  },
  logo: {
    color: UIColors.green,
    height: Spacing.spacing24,
    "@media only screen and (min-width: 30rem)": {
      height: Spacing.spacing32,
    },
  },
  right_margin: {
    marginRight: Spacing.spacing16,
  },
  centered: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  checkIcon: {
    color: UIColors.green,
    height: Spacing.spacing28,
    margin: "auto",
    width: Spacing.spacing28,
  },
  customizeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    ":first-of-type": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "@media only screen and (max-width: 48rem)": {
      alignItems: "center",
      flexFlow: "column-reverse",
      textAlign: "center",
    },
  },
  customizeText: {
    color: UIColors.primary,
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    marginTop: Spacing.spacing8,
    whiteSpace: "normal",
  },
  customizePill: {
    border: `3px solid ${GreyColors.smoke95}`,
    borderRadius: BorderRadius.medium,
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.medium,
    height: Spacing.spacing24,
    margin: Spacing.spacing8,
    padding: Spacing.spacing4,
    textTransform: "uppercase",
  },
  customizeLine: {
    background: GreyColors.smoke95,
    height: "3px",
    minWidth: "85%",
    "@media only screen and (max-width: 48rem)": {
      display: "none",
    },
  },
  customizeToggleButton: {
    borderRadius: "50%",
    color: UIColors.primary,
    height: Spacing.spacing48,
    width: Spacing.spacing48,
  },
  orderSummaryCopy: {
    lineHeight: 1.5,
    marginBottom: Spacing.spacing24,
  },
  tableCell: {
    border: Borders.regular,
    borderRight: "none",
    ":last-child": {
      borderRight: Borders.regular,
    },
    padding: `${Spacing.spacing12} ${Spacing.spacing16}`,
    textAlign: "center",
    "@media only screen and (max-width: 30rem)": {
      padding: `${Spacing.spacing16} ${Spacing.spacing4}`,
    },
  },
  selectedBackground: {
    background: ColorPalette["sky-blue"],
  },
  tableContainer: {
    marginTop: Spacing.spacing32,
    overflowX: "auto",
  },
  tableHead: {
    border: Borders.regular,
    borderRight: "none",
    ":last-child": {
      borderRight: Borders.regular,
    },
    padding: Spacing.spacing16,
    textAlign: "center",
    "@media only screen and (max-width: 30rem)": {
      padding: `${Spacing.spacing16} ${Spacing.spacing4}`,
    },
  },
  tableHeadFirst: {
    borderLeft: "none",
    borderTop: "none",
  },
  tableData: {
    borderTop: "none",
    fontSize: Typography.sizes.rg,
    ":first-child": {
      textAlign: "left",
    },
  },
  text_center: {
    textAlign: "center",
  },
});

const cardElementStyles = {
  // to do customize card element to look like mockup
  base: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.regular,
    lineHeight: "40px",
    padding: `${Spacing.spacing8} ${Spacing.spacing16}`,
    "::placeholder": {
      color: UIColors.lighter,
    },
  },
  invalid: {
    color: UIColors.danger,
  },
};

export { styles, cardElementStyles };
