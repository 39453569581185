import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const BlankDetailContent = styled.div`
  cursor: ${({ role }) => (["button", "link"].includes(role) ? "pointer" : "default !important")};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.spacing12};
  width: 100%;

  p {
    all: unset;
  }

  > span:last-child {
    color: ${UIColors.blue};
  }
`;

const BlankDetail = ({ onClick, ...props }) => {
  const role = onClick ? "button" : undefined;
  const clickAction = (event) => {
    if (typeof onClick === "function") {
      onClick(event);
    }
    return undefined;
  };

  return <BlankDetailContent {...props} className="blank-slate blank-slate-dotted" onClick={clickAction} role={role} />;
};

BlankDetail.propTypes = {
  onClick: PropTypes.func,
};

export default BlankDetail;
