import React from "react";
import useIntegrationConnectionLink from "modules/settings/components/Integrations/hooks/useIntegrationsConnectionLink";
import { Button } from "components/fl-ui";
const MachineDataErrorText = ({ integrationId, integrationStatus }) => {
    const { connect, disabled } = useIntegrationConnectionLink({ integrationSlug: integrationId });
    switch (integrationStatus) {
        case "UNAUTHENTICATED":
            return (React.createElement("span", null,
                `Your connection has been disrupted. Please `,
                React.createElement(Button, { link: true, disabled: disabled, onClick: connect }, "click here"),
                ` to reconnect.`));
        case "MISSING_PERMISSION":
            return (React.createElement("span", null,
                "A permission error occurred. Please ensure Bushel Farm is configured with the correct permissions in your provider account. Reference",
                " ",
                React.createElement("a", { href: "https://support.bushelfarm.com/hc/en-us/articles/25923593170708-Machine-Data-Partner-Permissions" }, "this support article"),
                " ",
                "for more information."));
        default:
            return null;
    }
};
export default MachineDataErrorText;
