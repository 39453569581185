import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { GenericStyledText } from "settings/GenericStyledText";
import DataIntegrationsModal from "settings/components/DataIntegrationsModal";
import styled from "styled-components";

import { UPLOAD_MACHINE_DATA } from "collection/graphql/yield/mutations";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";

import { YieldDataBlankSlateBanner } from "components/advertisements/banners/YieldDataBlankSlateBanner";
import PayGateCommonBlankSlateModal from "components/advertisements/modals/PayGateCommonBlankSlateModal";
import InlineFilePicker from "components/fl-ui/Attachments/InlineFilePicker";
import { Spacing } from "components/fl-ui/constants";

const List = styled.li`
  margin-bottom: ${Spacing.spacing16};
  padding-left: ${Spacing.spacing8};
`;

const OrderedList = styled.ol`
  list-style: decimal;
  margin: ${Spacing.spacing16};
`;

const YieldUpload = ({ onClose }) => {
  const [createMachineDataFile] = useMutation(UPLOAD_MACHINE_DATA);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const hasMachineData = useEnterpriseFeature("machine_data");

  if (!hasMachineData) {
    return <PayGateCommonBlankSlateModal banner={YieldDataBlankSlateBanner} onClose={onClose} width={960} />;
  }

  if (showSuccessModal) {
    return (
      <DataIntegrationsModal onClose={onClose} title="Your machine data import has started">
        <GenericStyledText>
          {`We're processing your files now. Machine data imports require conversion and typically take 30 minutes or more. You'll receive an alert once your import is complete.`}
        </GenericStyledText>
      </DataIntegrationsModal>
    );
  }

  return (
    <DataIntegrationsModal onClose={onClose} title="Upload Machine Data">
      <GenericStyledText>
        Exports of machine data from your equipment monitors or other agronomic software can be imported to your Bushel
        Farm by following the instructions below:
      </GenericStyledText>

      <OrderedList>
        <List>
          <GenericStyledText>
            <strong>Export your data</strong>
          </GenericStyledText>

          <GenericStyledText>
            {`Follow the manufacturer's instructions for exporting files containing `}
            <code>as-planted</code>, <code>as-applied</code> or <code>yield data</code>.
          </GenericStyledText>
        </List>

        <List>
          <GenericStyledText>
            <strong>Create a .zip of your files</strong>
          </GenericStyledText>

          <GenericStyledText>
            {"Follow "}
            <a href="https://www.wikihow.com/Make-a-Zip-File" rel="noopener noreferrer" target="_blank">
              these
            </a>
            {" instructions to create a zip file that you can upload on this page. "}
          </GenericStyledText>
        </List>

        <List>
          <GenericStyledText>
            <strong>Upload your zip file</strong>
          </GenericStyledText>

          <InlineFilePicker
            onError={(error) => {
              App.notify("An eoccurred, please try again.");
              throw error;
            }}
            onSuccess={({ filesUploaded }) => {
              if (filesUploaded?.length) {
                const { filename, url } = filesUploaded[0];
                const file = { name: filename, url };
                createMachineDataFile({ variables: { file } }).then(() => setShowSuccessModal(true));
              }
            }}
            pickerOptions={{ accept: ["application/zip", "application/x-zip-compressed"] }}
          />
        </List>
      </OrderedList>
    </DataIntegrationsModal>
  );
};

YieldUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default YieldUpload;
