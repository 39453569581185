import _ from "lodash";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import { getFeatureNameFromReportId } from "modules/reports/utils/getFeatureNameFromReportId";
import { ESSENTIALS, LITE, PREMIUM } from "model/Subscription/constants";
const isReportIdDisabled = (activeEnterpriseFeatures, reportId) => {
    return !_.includes(activeEnterpriseFeatures, getFeatureNameFromReportId(reportId));
};
const getPlanTypeFromReportId = (reportId) => {
    const businessReportIds = ["land_cost", "land_cost_crop_share", "land_cost_flex_rent"];
    const essentialsReportIds = [
        "activity_cost",
        "activity_summary",
        "fsa_acreage",
        "field_season",
        "field_summary",
        "marketing_position_summary",
        "spray_log",
    ];
    if (_.includes(businessReportIds, reportId)) {
        return PREMIUM;
    }
    else if (_.includes(essentialsReportIds, reportId)) {
        return ESSENTIALS;
    }
    else {
        return LITE;
    }
};
const getSubscriptionMessageFromReportId = (reportId) => {
    const planTypeForReportId = getPlanTypeFromReportId(reportId);
    if (planTypeForReportId == PREMIUM) {
        return "Included in Bushel Farm Business Plan";
    }
    else if (planTypeForReportId == ESSENTIALS) {
        return "Included in Bushel Farm Essentials Plan";
    }
    else {
        return "Included in Bushel Farm Lite Plan";
    }
};
const useReportRowViewModels = (reportDefinitions) => {
    var _a;
    const enterpriseFeatures = ((_a = useCurrentEnterprise().currentEnterprise) === null || _a === void 0 ? void 0 : _a.features) || [];
    const reportRowViewModels = reportDefinitions.map((reportDefinition) => {
        return {
            description: reportDefinition.description,
            reportId: reportDefinition.id,
            includedInPlanType: getPlanTypeFromReportId(reportDefinition.id),
            isDisabled: isReportIdDisabled(enterpriseFeatures, reportDefinition.id),
            title: reportDefinition.title,
            tooltipMessage: getSubscriptionMessageFromReportId(reportDefinition.id),
        };
    });
    const sortedViewModels = [
        ..._.filter(reportRowViewModels, { includedInPlanType: LITE }),
        ..._.filter(reportRowViewModels, { includedInPlanType: ESSENTIALS }),
        ..._.filter(reportRowViewModels, { includedInPlanType: PREMIUM }),
    ];
    return sortedViewModels;
};
export default useReportRowViewModels;
