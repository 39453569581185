import { number, object, string } from "yup";
const SeedProductSchema = object({
    brand: string().trim().nullable().label("Brand").default(""),
    commodityId: number()
        .label("Commodity")
        .typeError("${path}must be a number")
        .positive()
        .integer()
        .required(),
    id: number().optional().positive().integer().label("Product ID"),
    name: string().trim().required().label("Seed name"),
    notes: string().optional().default("").label("Notes"),
    relativeMaturity: number().optional().transform((value, originalValue) => originalValue === "" ? null : value)
        .nullable().label("Relative maturity").positive(),
    type: string().lowercase().oneOf(["seed"]).required().label("Type"),
});
export default SeedProductSchema;
