import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const ActivitiesBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Activities for free" : "Move to Essentials today",
        imagePath: "images/pay_gates/banners/activities.svg",
        title: "With Activities you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Easily log and review all your field work in one place to keep your records organized, secure, and accessible from any desktop or mobile device."),
                React.createElement(ValuePropListItem, null, "Log standard activities like planting, spraying, and harvesting to multiple fields at once or track custom activity types to get more specific about your operations."),
                React.createElement(ValuePropListItem, null, "Automatically analyze your cost of production by field, activity type, commodity, or across the entire farm without any additional effort.")))));
};
export { ActivitiesBlankSlateBanner };
