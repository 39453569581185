import { useMemo } from "react";
import { string as yupString } from "yup";
import useAsync from "hooks/useAsync";
import { browserOpenUrl, isNative } from "mobile/mobileManager";
import { fetch } from "lib/ajax";
const useIntegrationConnectionLink = ({ integrationSlug }) => {
    const { loading, value: url } = useAsync(async () => {
        const data = await fetch(`/v2.0/integrations/${integrationSlug}/url`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "GET",
        }).then((response) => response.json());
        return data.url;
    });
    return useMemo(() => {
        const disabled = loading || !yupString().url().required().isValidSync(url);
        return {
            connect: () => {
                if (isNative()) {
                    browserOpenUrl(url);
                }
                else {
                    window.location.href = url !== null && url !== void 0 ? url : "";
                }
            },
            disabled,
            url
        };
    }, [loading, url]);
};
export default useIntegrationConnectionLink;
