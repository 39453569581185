import { StyleSheet, css } from "aphrodite/no-important";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";
import withNotificationSettings from "modules/notifications/hoc/withNotificationSettings";
import { Button } from "components/fl-ui";
import { Form, Checkbox, Radio } from "components/fl-ui/Form";
import { Typography, Spacing, Borders } from "components/fl-ui/constants";
const AlertData = {
    rainfall: {
        category: "Rainfall",
        description: "Receive alerts when rainfall is detected on your fields.",
        emailAvailable: true,
        mobileAvailable: true,
    },
    "market-prices": {
        category: "Marketing",
        description: "Receive alerts about market price movements.",
        emailAvailable: false,
        mobileAvailable: true,
    },
    "work-orders": {
        category: "Activity Assignments",
        description: "Receive notifications when activities are assigned to you.",
        emailAvailable: true,
        mobileAvailable: false,
    },
    "elevate-plus-content-daily-flash": {
        category: "Cargill Elevate+",
        description: "Receive notifications when new **Daily Flash** commentary is posted.",
        emailAvailable: false,
        mobileAvailable: true,
    },
    "elevate-plus-content-market-update": {
        category: "Cargill Elevate+",
        description: "Receive notifications when **Market Update** podcasts are released.",
        emailAvailable: false,
        mobileAvailable: true,
    },
    "elevate-plus-content-weekly-reports": {
        category: "Cargill Elevate+",
        description: "Receive notifications when new **Weekly Reports** are posted.",
        emailAvailable: false,
        mobileAvailable: true,
    },
    "elevate-plus-content-market-reports": {
        category: "Cargill Elevate+",
        description: "Receive notifications when **USDA Market Updates** are posted.",
        emailAvailable: false,
        mobileAvailable: true,
    },
    "elevate-plus-content-other-commentary": {
        category: "Cargill Elevate+",
        description: "Receive notifications when all **Other** commentary is posted.",
        emailAvailable: false,
        mobileAvailable: true,
    },
    "elevate-plus-content-cash-recommendations": {
        category: "Cargill Elevate+",
        description: "Receive notifications when **Active Cash Recommendations** are updated.",
        emailAvailable: false,
        mobileAvailable: true,
    },
    "elevate-plus-crop-recommendations": {
        category: "Cargill Elevate+",
        description: "Receive notifications when **Crop Recommendations** are updated.",
        emailAvailable: false,
        mobileAvailable: true,
    },
};
const styles = StyleSheet.create({
    header: {
        fontSize: Typography.sizes.xl,
        lineHeight: 1.4,
        marginBottom: Spacing.spacing16,
    },
    alertName: {
        fontSize: Typography.sizes.lg,
        fontWeight: Typography.weights.medium,
        lineHeight: 1.75,
        borderTop: Borders.light,
        paddingTop: Spacing.spacing12,
    },
    alertDesc: {
        fontSize: Typography.sizes.rg,
        lineHeight: 2,
    },
    alertSection: {
        borderBottom: Borders.light,
        marginBottom: Spacing.spacing16,
        paddingBottom: Spacing.spacing16,
    },
    space: {
        display: "inline-block",
        width: Spacing.spacing24,
    },
    marginTop_16: {
        marginTop: Spacing.spacing16,
    },
    marginBottom_16: {
        marginBottom: Spacing.spacing24,
    },
    formActions: {
        display: "flex",
        justifyContent: "flex-end",
    },
});
const RainfallOptions = ({ options }) => {
    const yearRound = options.winter === true;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: css(styles.alertDesc, styles.marginTop_16) }, "Send rainfall alerts:"),
        React.createElement(Radio, { defaultChecked: yearRound, inline: true, label: "Year-round", name: "winter", value: "true" }),
        React.createElement("span", { className: css(styles.space) }),
        React.createElement(Radio, { defaultChecked: !yearRound, inline: true, label: "In-season only", name: "winter", value: "false" })));
};
const NotificationsSettings = ({ data, setUserSubscription }) => {
    const hasElevatePlus = useEnterpriseFeature("elevate_plus");
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const handleChange = (type, value) => {
        const changedSubs = filteredSubscriptions.map((subscription) => type === subscription.notificationType ? Object.assign(Object.assign({}, subscription), value) : subscription);
        setFilteredSubscriptions(changedSubs);
    };
    const handleFormChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === "winter") {
            handleChange("rainfall", {
                options: { winter: value === "true" },
            });
        }
        else {
            handleChange(name, {
                [value]: checked,
            });
        }
    };
    const handleSave = async () => {
        await Promise.all(filteredSubscriptions.map(setUserSubscription));
        App.notify("Changes saved.");
    };
    useEffect(() => {
        if (data.subscriptions) {
            setFilteredSubscriptions(data.subscriptions
                .filter(({ notificationType }) => _.keys(AlertData).includes(notificationType))
                .sort((a, b) => _.keys(AlertData).indexOf(a.notificationType) - _.keys(AlertData).indexOf(b.notificationType)));
        }
    }, [data.subscriptions]);
    return (React.createElement(Form, { onChange: handleFormChange, onSubmit: handleSave, layout: undefined, preventDefault: undefined },
        React.createElement("h2", { className: css(styles.header) }, "Alerts"),
        React.createElement("div", { className: css(styles.alertSection) }, filteredSubscriptions.map((x, index) => {
            const prevCategory = index > 0 ? AlertData[filteredSubscriptions[index - 1].notificationType].category : "";
            if (!hasElevatePlus && AlertData[x.notificationType].category === "Cargill Elevate+") {
                return null;
            }
            return (React.createElement("div", { key: x.notificationType },
                AlertData[x.notificationType].category !== prevCategory ? (React.createElement("h3", { className: css(styles.alertName) }, AlertData[x.notificationType].category)) : (React.createElement("div", { className: css(styles.marginBottom_16) })),
                React.createElement(ReactMarkdown, { className: css(styles.alertDesc) }, AlertData[x.notificationType].description),
                AlertData[x.notificationType].emailAvailable && (React.createElement(React.Fragment, null,
                    React.createElement(Checkbox, { defaultChecked: x.email, label: "Email", name: x.notificationType, value: "email" }),
                    React.createElement("span", { className: css(styles.space) }))),
                AlertData[x.notificationType].mobileAvailable && (React.createElement(Checkbox, { defaultChecked: x.push, label: "Mobile", name: x.notificationType, value: "push" })),
                x.notificationType === "rainfall" && React.createElement(RainfallOptions, { options: x.options || {} })));
        })),
        React.createElement(Button, { color: "primary", type: "submit" }, "Save Changes")));
};
const NotificationsSettingsPage = withNotificationSettings(NotificationsSettings);
export { NotificationsSettingsPage };
