import React from "react";
import styled from "styled-components";
import { MinimumAuthContentDesktopWidth } from "modules/sso/constants";
import { Logo } from "components/fl-ui/Icons";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, BorderRadius } from "components/fl-ui/constants";
const BaseSSOPageContainer = ({ className, children, title }) => {
    return (React.createElement("div", { className: className },
        React.createElement("div", { id: "sso-page-content-area" },
            React.createElement("div", { className: "top-area" },
                React.createElement(Logo, { className: "logo", size: "md" }),
                React.createElement("h2", null, title)),
            React.createElement("div", { className: "content" }, children))));
};
export default styled(BaseSSOPageContainer) `
  background-color: #f5f5f5;
  display: block;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 2rem;
  padding-top: 8rem;
  width: 100%;

  @media only screen and (max-width: 40em) {
    padding-top: 2rem;
  }

  .title {
    visibility: ${({ title }) => (title ? "visible" : "hidden")};
  }

  #sso-page-content-area {
    background-color: white;
    border-radius: ${BorderRadius.xxlarge};
    box-shadow: 1px 4px 4px 0px ${GreyColors.smoke80};
    height: auto;
    margin: 0 auto;
    padding: ${Spacing.spacing16};
    position: relative;
    width: ${MinimumAuthContentDesktopWidth};

    @media only screen and (max-width: 40em) {
      min-width: 0;
      width: 90%;
    }

    .top-area,
    .content {
      .logo {
        display: flex;
        margin-bottom: ${Spacing.spacing4};
        margin-top: ${Spacing.spacing20};
      }

      h2 {
        margin-bottom: 0;
        line-height: 1.4;  
        min-width: 350px;
        text-align: center;
      }

      align-items: center;
      display: flex;
      flex-direction: column;
      padding: ${Spacing.spacing12};

      @media only screen and (max-width: 40em) {
        min-width: 0;
        width: 100%;

        h2 {
          min-width: 0;
        }
      }
    }
  }
`;
