import moment from "moment";
import SSOAuthError, { NETWORK_ERROR } from "sso/workflows/SSOAuthError";
import { create } from "zustand";
import createUserMembership from "collection/graphql/auth/mutations/createUserMembership";
import getCurrentUser from "collection/graphql/auth/queries/getCurrentUser";
import currentEnterpriseVar from "collection/graphql/auth/vars/currentEnterpriseVar";
import { marketingClient } from "collection/graphql/client";
import { createEnterprise as createEnterpriseMutation, UPDATE_ENTERPRISE, } from "collection/graphql/enterprise/mutations";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import UpdateEnterpriseInputSchema from "collection/graphql/enterprise/schemas/UpdateEnterpriseInputSchema";
import updatePerson from "collection/graphql/users/mutations/updateUser";
export const createAuthWorkflowStore = (store, client) => {
    return create((set, get) => {
        store.subscribe(({ isAuthenticated }) => {
            set({ isAuthenticated });
        });
        return {
            authRequestRetryCount: 0,
            error: null,
            isAuthenticated: store.getState().isAuthenticated,
            selectedMembership: null,
            userMemberships: null,
            async createEnterprise(createEnterpriseInput) {
                await get().mutate(createEnterpriseMutation, {
                    variables: {
                        input: createEnterpriseInput,
                    },
                });
                await client.cache.reset();
            },
            async createUserMembership(userMembershipInput) {
                await get().mutate(createUserMembership, {
                    variables: {
                        input: userMembershipInput,
                    },
                });
                currentEnterpriseVar(null);
                await client.resetStore();
            },
            async getContractsReminderStatus() {
                var _a;
                const [error, currentEnterprise] = await get().query(GET_CURRENT_ENTERPRISE);
                return [error, !!((_a = currentEnterprise === null || currentEnterprise === void 0 ? void 0 : currentEnterprise.options) === null || _a === void 0 ? void 0 : _a.contractConnectionReminderDismissalDate)];
            },
            getCurrentEnterpriseUUID() {
                return currentEnterpriseVar();
            },
            incrementAuthRequestRetryCount() {
                set({
                    authRequestRetryCount: get().authRequestRetryCount + 1,
                });
            },
            async mutate(mutation, options = {}) {
                const { data } = await client.mutate(Object.assign({ mutation }, options));
                // @ts-ignore
                const keys = Object.keys(data);
                // @ts-ignore
                return keys.length === 1 ? data[keys[0]] : data;
            },
            async query(query, options = {}) {
                try {
                    const { data } = await client.query(Object.assign({ query }, options));
                    const keys = Object.keys(data);
                    const result = keys.length === 1 ? data[keys[0]] : data;
                    return [null, result];
                }
                catch (error) {
                    return [error];
                }
            },
            async saveReminderStatus() {
                const [error, currentEnterprise] = await get().query(GET_CURRENT_ENTERPRISE);
                if (error) {
                    const ssoError = new SSOAuthError("Unable to fetch current enterprise", NETWORK_ERROR);
                    get().setError(ssoError);
                    return Promise.reject(ssoError);
                }
                // @ts-ignore
                const updateEnterpriseInput = Object.assign({}, UpdateEnterpriseInputSchema.cast(currentEnterprise, {
                    stripUnknown: true,
                }));
                const options = Object.assign(Object.assign({}, updateEnterpriseInput.options), { contractConnectionReminderDismissalDate: moment().format("YYYY-MM-DD") });
                await get().mutate(UPDATE_ENTERPRISE, {
                    variables: {
                        enterprise: Object.assign(Object.assign({}, updateEnterpriseInput), { options }),
                    },
                });
                await client.clearStore();
            },
            setError: (error) => set(() => ({
                error,
            })),
            setSelectedMembership: (selectedMembership) => {
                var _a;
                const uuid = ((_a = selectedMembership === null || selectedMembership === void 0 ? void 0 : selectedMembership.enterprise) === null || _a === void 0 ? void 0 : _a.uuid) || null;
                currentEnterpriseVar(uuid);
                set({
                    selectedMembership,
                });
            },
            signInRedirect: async (email) => {
                return await store.getState().signInRedirect(email);
            },
            unsetCurrentEnterpriseUUID: () => {
                currentEnterpriseVar(null);
            },
            async updateEnterpriseName(enterprise) {
                await get().mutate(UPDATE_ENTERPRISE, {
                    variables: {
                        enterprise,
                    },
                });
                await client.clearStore();
            },
            async updateUserDetails(input) {
                await get().mutate(updatePerson, {
                    variables: {
                        input,
                    },
                });
                await client.clearStore();
                await marketingClient.query({
                    query: getCurrentUser,
                    fetchPolicy: "network-only",
                });
            },
        };
    });
};
