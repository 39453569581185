import { useQuery } from "@apollo/client";
import React from "react";
import styled from "styled-components";
import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import usePermissions from "hooks/usePermissions";
import { EmptyUsersArea } from "modules/settings/components/EmptyUsersArea";
import { IndividualUser } from "modules/settings/components/IndividualUser";
import { Spacing } from "components/fl-ui/constants";
const UserSettingsGroup = styled(({ noUsersText, className, description, title, users, role }) => {
    var _a, _b, _c, _d;
    const canWriteAccessControl = usePermissions().canWrite("access_control");
    const { data: userMembership } = useQuery(getCurrentUserMembership);
    const currentUserRole = (_b = (_a = userMembership === null || userMembership === void 0 ? void 0 : userMembership.currentMembership) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.name;
    const currentUserId = (_d = (_c = userMembership === null || userMembership === void 0 ? void 0 : userMembership.currentMembership) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.id;
    const canEditRole = canWriteAccessControl && (currentUserRole === "admin" || role !== "admin");
    return (React.createElement("div", { className: className },
        React.createElement("h2", null, title),
        React.createElement("p", null, description),
        users.length === 0 && React.createElement(EmptyUsersArea, { noUsersText: noUsersText }),
        users.map((user) => (React.createElement(IndividualUser, { canEditRole: canEditRole, isCurrentUser: user.id === currentUserId, key: user.id, user: user })))));
}) `
  h2 {
    padding-bottom: ${Spacing.spacing8};
    margin-top: ${Spacing.spacing48};
  }
`;
export { UserSettingsGroup };
