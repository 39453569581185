import { useMutation, useQuery } from "@apollo/client";
import { pick } from "lodash";
import LogExpenseRevenueModal from "profit_and_loss/components/LogExpenseRevenueModal";
import useFinancialAccounts from "profit_and_loss/hooks/useFinancialAccounts";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { GET_ALL_MARKETED_CROPS } from "collection/graphql/marketing";
import {
  CREATE_FINANCIAL_TRANSACTION,
  DELETE_FINANCIAL_TRANSACTIONS,
  UPDATE_FINANCIAL_TRANSACTION,
} from "collection/graphql/profit_and_loss/mutations";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import App from "layout/app";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

const requiredFields = ["account", "allocateType", "amount", "date"];

//TODO: This is not a container, it's a modal.
// It's literally just used in the one location and the single component that is exported is only used here.
// So all of this data should just live in the LogExpenseRevenueModal component.
const LogExpenseRevenueModalContainer = ({ data, onClose }) => {
  const cropYear = useCurrentCropYear()[0];

  const { accountTypes, loading, ...accountData } = useFinancialAccounts();
  const { crops: fieldCrops } = useYearFieldCrops(cropYear);
  const marketedCrops = useQuery(GET_ALL_MARKETED_CROPS, { variables: { year: cropYear } }).data?.marketedCrops;
  const [createFinancialAccountTransaction, { loading: createLoading }] = useMutation(CREATE_FINANCIAL_TRANSACTION, {
    refetchQueries: ["GetTransactions", "GetProfitAndLossForYear"],
  });
  const [deleteFinancialAccountTransactions, { loading: deleteLoading }] = useMutation(DELETE_FINANCIAL_TRANSACTIONS, {
    refetchQueries: ["GetTransactions", "GetProfitAndLossForYear"],
  });
  const [updateFinancialAccountTransaction, { loading: updateLoading }] = useMutation(UPDATE_FINANCIAL_TRANSACTION);
  const defaultValues = { ...data, crops: data.id ? [...data.allocateCommodityCrops, ...data.allocateFieldCrops] : [] };
  const [formData, setFormData] = useState(defaultValues);
  const { account, allocateType, amount, crops, date, disabled } = formData;
  const { accountType: type } = account;
  const activeAccounts = accountData[type].active;
  const accountType = (accountTypes?.find(({ value }) => value === type)?.display || type).toLowerCase();

  useEffect(() => {
    const isInvalid =
      !requiredFields.every((value) => formData[value]) ||
      !account?.id ||
      (allocateType !== "UNALLOCATED" && !crops?.length);

    if (isInvalid !== disabled) {
      setFormData({ ...formData, disabled: isInvalid });
    }
  }, [account, allocateType, amount, crops, date]);

  const updateFormData = (newData) => setFormData({ ...formData, ...newData });

  const handleDelete = async () => {
    try {
      await deleteFinancialAccountTransactions({ variables: { ids: [formData.id] } });
      onClose();
    } catch (err) {
      App.notify("An error occurred, please try again later");
      throw err;
    }
  };

  const confirmDelete = () =>
    App.confirm({
      confirm: `Delete ${accountType} item`,
      message:
        "This will delete this record and remove it from any accounts it was associated with. This action cannot be undone.",
      title: `Are you sure you want to delete this ${accountType} item`,
    }).then(handleDelete);

  const handleSave = async () => {
    const transaction = pick(formData, ["allocateType", "amount", "date", "id", "notes"]);
    const mutatator = transaction.id ? updateFinancialAccountTransaction : createFinancialAccountTransaction;
    const allocateMap = {
      COMMODITY_CROP: "allocateCommodityCrops",
      FIELD_CROP: "allocateFieldCrops",
    };

    transaction.cropYear = cropYear;
    transaction.accountId = formData.accountId || formData.account?.id;

    if (crops?.length) {
      transaction[allocateMap[allocateType]] = crops.map(({ crop, id }) => id || crop.id);
    }

    try {
      await mutatator({ variables: { transaction } });
      onClose();
    } catch (err) {
      App.notify("An error occurred, please try again later");
      throw err;
    }
  };

  return (
    <LogExpenseRevenueModal
      accounts={activeAccounts}
      accountType={accountType}
      cropYear={cropYear}
      data={formData}
      fieldCrops={fieldCrops}
      isSaving={createLoading || deleteLoading || updateLoading}
      loading={loading}
      marketedCrops={marketedCrops}
      onClose={onClose}
      onDelete={confirmDelete}
      onSave={handleSave}
      type={type}
      updateData={updateFormData}
    />
  );
};

LogExpenseRevenueModalContainer.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LogExpenseRevenueModalContainer;
