import { css } from "aphrodite";
import { FullPageOverlay } from "billing/layouts/FullPageOverlay";
import { SupportEmailLink, SalesPhoneLink } from "billing/lib";
import CarbonIneligible from "carbon_enrollment/components/CarbonIneligible";
import Confirmation from "carbon_enrollment/components/Confirmation";
import GenericFieldset from "carbon_enrollment/components/GenericFieldset";
import styles from "carbon_enrollment/styles";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { Button } from "components/fl-ui";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const EnrollmentForm = ({ clientData, disabled, enrollment, isEligible, isLoading }) => {
  const { confirmationCopy, headerCopy, headerTitle, LogoComponent, minimal, ...confirmationProps } = clientData;
  const { data, fields, isSaving, onCancel, onInputChange, onSelect, onSave, selectedFields } = enrollment;

  return (
    <FullPageOverlay onCancel={onCancel}>
      <LogoComponent />

      <Row className={css(styles.section)}>
        <Col xs={12}>
          <p className={css(styles.heading)}>{headerTitle}</p>
          <p className={css(styles.copy)}>{headerCopy}</p>
        </Col>
      </Row>

      {isLoading && <LoadingWrapper />}
      {!isLoading && !isEligible && <CarbonIneligible onCancel={onCancel} />}

      {!isLoading && isEligible && (
        <>
          {!minimal && (
            <GenericFieldset
              data={data}
              fields={fields}
              onInputChange={onInputChange}
              onSelect={onSelect}
              selectedFields={selectedFields}
            />
          )}

          <Confirmation
            copy={confirmationCopy}
            data={data}
            minimal={minimal}
            onInputChange={onInputChange}
            {...confirmationProps}
          />

          <Row>
            <Col xs={12} className={css(styles.buttonGroup)}>
              <Button
                className={css(styles.button)}
                color="primary"
                disabled={disabled}
                loading={isSaving}
                onClick={onSave}
                size="lg"
              >
                Confirm
              </Button>

              <Button className={css(styles.button)} color="primary" link onClick={onCancel} size="lg">
                Cancel
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className={css(styles.copy, styles.centerText)}>
                Have questions? You can reach us at <SalesPhoneLink /> or <SupportEmailLink />.
              </p>
            </Col>
          </Row>
        </>
      )}
    </FullPageOverlay>
  );
};

EnrollmentForm.propTypes = {
  clientData: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  enrollment: PropTypes.object.isRequired,
  isEligible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EnrollmentForm;
