import React from "react";
import styled from "styled-components";

import { Borders, Spacing } from "components/fl-ui/constants";

const MenuListFooter = styled.div`
  border-top: ${Borders.regular};
  padding: ${Spacing.spacing24} ${Spacing.spacing12};
  text-align: center;
  white-space: break-spaces;

  > :nth-child(2) {
    display: inline-block;
    margin-top: 10px;
  }
`;

export default MenuListFooter;
