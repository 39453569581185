import { SupportEmailLink } from "billing/lib";
import { MobileMaxWidth } from "constants/mobileBreakpointConstants";
import React from "react";
import styled from "styled-components";

import Icon from "components/fl-ui/Icons/Icon";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 60rem;
  text-align: center;

  @media only screen and (max-width: ${MobileMaxWidth}) {
    flex-flow: column;
  }
`;

const StyledIcon = styled(Icon)`
  background: ${GreyColors.smoke99};
  border: 1px solid ${GreyColors.smoke90};
  margin: auto;
  max-width: 25%;
  min-width: 5rem;
  padding: ${Spacing.spacing24};
  width: 5rem !important;

  @media only screen and (max-width: ${MobileMaxWidth}) {
    max-width: 100%;
  }
`;

const MobileHeader = styled.h3`
  display: none;
  margin: ${Spacing.spacing12} 0;

  @media only screen and (max-width: ${MobileMaxWidth}) {
    display: block;
    margin-bottom: ${Spacing.spacing24};
  }
`;

const Header = styled.h3`
  margin: ${Spacing.spacing12} 0;

  @media only screen and (max-width: ${MobileMaxWidth}) {
    display: none;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${Spacing.spacing16};

  @media only screen and (max-width: 62.5rem) {
    margin-bottom: ${Spacing.spacing8};
    margin-right: ${Spacing.spacing4};
  }
`;

const BlankSlate = () => (
  <Container>
    <MobileHeader>Coming soon</MobileHeader>
    <StyledIcon icon="camera" />
    <Content>
      <Header>Coming soon</Header>
      <p>
        {"Imagery is typically available within 24 hours of subscribing. Need help? Contact our support team at "}
        <SupportEmailLink />.
      </p>
    </Content>
  </Container>
);

export default BlankSlate;
