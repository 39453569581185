import { css } from "aphrodite";
import { round } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";
import FieldCardMenu from "modules/fields/components/FieldCardMenu";
import RainfallSummary from "modules/fields/components/RainfallSummary";

import FieldCropButton from "fields/FieldCropButton";
import styles from "fields/common/styles";

const FieldCompact = (props) => {
  const { crops, cropsLoading, field, showGroupName } = props;
  const groupName = showGroupName ? field.group?.name : null;
  const isMobile = useViewType() === "mobile";
  const hasMachineData = useEnterpriseFeature("machine_data");

  const hasWritePermissions = usePermissions().hasPermission("fields", ["write"]);

  const navigate = useNavigate();
  const redirectToDetail = (event) => {
    if (!event.defaultPrevented) {
      navigate(`/fields/${field.id}`);
    }
  };

  return (
    <Col className={css(styles.fieldCard_Col)} xs={12} onClick={redirectToDetail}>
      <div className={css(styles.fieldCard, styles.fieldCard_compact)}>
        <Row middle="xs">
          <Col xs={5} sm={3}>
            {groupName && <p className={css(styles.fieldCard_groupName)}>{groupName}</p>}
            <h2 className={css(styles.fieldCard_name)}>{field.name}</h2>
            {isMobile && (
              <p className={css(styles.fieldCard_p)}>
                {`${round(field.acreage)} ac · `}
                <FieldCropButton crops={crops} fieldId={field.id} loading={cropsLoading} />
              </p>
            )}
          </Col>

          <Col xs={1} sm={1}>
            {field.source === "LEAF" && hasMachineData && (
              <img width="15" height="20" src="/images/integrations/integration-arrow@24px.png" />
            )}
          </Col>

          {!isMobile && (
            <Col xs={0} sm={3}>
              <p className={css(styles.fieldCard_p)}>
                <FieldCropButton crops={crops} fieldId={field.id} loading={cropsLoading} />
              </p>
            </Col>
          )}

          <Col className={css(styles.hide_on_mobile)} xs={0} sm={1}>
            <p className={css(styles.fieldCard_p, styles.alignRight)}>{round(field.acreage)} ac</p>
          </Col>

          <Col xs={6} sm={3}>
            <RainfallSummary fieldId={field.id} />
          </Col>

          {hasWritePermissions && (
            <Col className={css(styles.hide_on_mobile)} xs={0} sm={1}>
              <FieldCardMenu field={field} />
            </Col>
          )}
        </Row>
      </div>
    </Col>
  );
};

export default FieldCompact;
