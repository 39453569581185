import MobileForcedAppUpdateConfigSchema from "mobile/MobileForcedAppUpdateConfigSchema";
import { appVersion, getPlatform, isNative } from "mobile/mobileManager";
import satisfies from "semver/functions/satisfies";
const platformSpecificDetails = (config) => {
    switch (getPlatform()) {
        case "android":
            return config.android;
        case "ios":
            return config.ios;
        default:
            return undefined;
    }
};
const getDeprecatedRange = (config) => {
    if (MobileForcedAppUpdateConfigSchema.isValidSync(config)) {
        const details = platformSpecificDetails(config);
        return details === null || details === void 0 ? void 0 : details.deprecatedRange;
    }
    return undefined;
};
const getExpiredRange = (config) => {
    if (MobileForcedAppUpdateConfigSchema.isValidSync(config)) {
        const details = platformSpecificDetails(config);
        return details === null || details === void 0 ? void 0 : details.expiredRange;
    }
};
/**
 * Takes in the LaunchDarkly config and determines the current state of the mobile app
 */
const getMobileForcedAppUpdateState = async (config) => {
    if (!isNative() || !config) {
        return undefined;
    }
    const currentAppVersion = await appVersion();
    const deprecatedRange = getDeprecatedRange(config);
    const expiredRange = getExpiredRange(config);
    if (!currentAppVersion) {
        return undefined;
    }
    const appIsExpired = !!expiredRange && satisfies(currentAppVersion, expiredRange);
    const appIsDeprecated = !appIsExpired && !!deprecatedRange && satisfies(currentAppVersion, deprecatedRange);
    const data = {
        appIsDeprecated,
        appIsExpired,
        deprecatedAlertCoolDownSeconds: config.deprecatedAlertCoolDownSeconds,
    };
    return data;
};
export default getMobileForcedAppUpdateState;
