import { StyleSheet, css } from "aphrodite/no-important";
import formatCurrency from "marketing/cards/utils/formatCurrency";
import Cell from "marketing/components/ui-elements/Cell";
import { number, normalize, percentage } from "marketing/utils/format";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import useViewType from "hooks/useViewType";
import CropEditModal from "modules/marketing/components/CropEditModal";
import CropProgressBarHeader from "modules/marketing/components/CropProgressBarHeader";
import { BaseProgressBar, IndependentProgressBar } from "modules/marketing/components/ui-elements/progress-bar";
import DetailCard from "modules/marketing/dashboards/DetailCard";
import DetailCropHeader from "modules/marketing/dashboards/DetailCropHeader";
import { DetailedCellRow, DetailedRow, DetailedRowBody } from "modules/marketing/dashboards/DetailedRow";

import { Button } from "components/fl-ui";
import { IconContainer } from "components/fl-ui/Icons";
import { ContentMain } from "components/fl-ui/Layout";
import Tooltip from "components/fl-ui/Tooltip";
import { ColorPalette, GreyColors, UIColors } from "components/fl-ui/colors";
import { getCropColors } from "components/fl-ui/colors/crops";
import { Typography, Borders, Mixins, Spacing } from "components/fl-ui/constants/index";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const gainLossLabel = (value) => (normalize(value) > 0 ? "gain" : "loss");

const styles = StyleSheet.create({
  marginTop: {
    marginTop: Mixins.toRem(24),
  },
  marginBottom: {
    marginBottom: Mixins.toRem(24),
  },
  heading: {
    padding: `${Mixins.toRem(10)} 0 ${Mixins.toRem(16)}`,
    borderBottom: Borders.regular,
    marginBottom: Mixins.toRem(16),
  },
  detailCard_lightText: {
    fontWeight: Typography.weights.regular,
    color: UIColors.lighter,
  },
  helpIcon_row: {
    borderTop: Borders.regular,
    margin: `${Mixins.toRem(16)} 0 0`,
    paddingTop: Mixins.toRem(16),
  },
  iconCell: {
    color: UIColors.blue,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
  },
  helpIcon: {
    height: Mixins.toRem(16),
    marginRight: Spacing.spacing4,
    width: Mixins.toRem(16),
  },
});

const TwoColumnResponsiveLayout = styled(({ children, className }) => {
  return <div className={[className, useViewType()].join(" ")}>{children}</div>;
})`
  display: flex;
  gap: ${Spacing.spacing20};

  .column {
    display: flex;
    flex-direction: column;
    gap: ${Spacing.spacing20};
  }
  &.desktop .column {
    width: 50%;
  }
  &.mobile {
    flex-direction: column;
  }
`;

const TooltipIcon = styled(({ className }) => (
  <IconContainer className={className} color={GreyColors.smoke30} icon="help" size={Spacing.spacing16} />
))`
  width: ${Spacing.spacing16};
  height: ${Spacing.spacing16};
  vertical-align: middle;
  margin-left: ${Spacing.spacing4};
`;

const ContractsLink = styled(({ className, id }) => {
  const search = new URLSearchParams([["marketedCrops", id]]);
  return (
    <Link className={className} to={`/contracts?${search}`}>
      View associated cash contracts
      <IconContainer icon="circleArrowRight" size={Spacing.spacing20} />
    </Link>
  );
})`
  align-items: center;
  color: ${ColorPalette.blue};
  display: flex;
  gap: 2px;
  justify-content: flex-end;
`;

const CropDetailsDashboard = ({ marketedCrop }) => {
  const [showCropModal, setShowCropModal] = useState(false);
  const {
    acresPlanted,
    averageYield,
    brokerageGainLoss,
    commodity,
    disableExchangePricing,
    fields = [],
    harvestBasis,
    harvestMonth,
    id,
    irrigated,
    productionCostPerUnit,
    productionStats,
    progress,
  } = marketedCrop;

  const isExchangeTraded = commodity.marketSymbol && !disableExchangePricing;
  const totalQuantity = productionStats.total.quantity;

  const perAcre = (value) => (
    <span>
      <strong>{formatCurrency(value, 0)}</strong> / ac
    </span>
  );
  const perUnit = (value) => (
    <CommodityYieldUnit
      amount={<strong>{formatCurrency(value, 2)}</strong>}
      commodity={commodity}
      per="unit"
      defaultUnit="bu"
    />
  );

  const percentOfTotal = ({ percentage, quantity }) => (
    <CommodityYieldUnit
      amount={[numeral(percentage / 100).format("0%"), number(quantity)].join(" \u00B7 ")}
      commodity={commodity}
      defaultUnit="bu"
    />
  );

  const editLink = () => {
    const onClick = (event) => {
      event.preventDefault();
      setShowCropModal(true);
    };

    return (
      <Button color="primary" link onClick={onClick}>
        Edit
      </Button>
    );
  };

  const list = (title, items) => {
    return items.length > 0 ? (
      <DetailedRow>
        {title}
        <DetailedRowBody>{_.map(items, "name").join(", ")}</DetailedRowBody>
      </DetailedRow>
    ) : (
      <DetailedCellRow>
        <Cell>{title}</Cell>
        <Cell right>-</Cell>
      </DetailedCellRow>
    );
  };

  const progressBarColors = getCropColors(marketedCrop?.commodity);

  return (
    <ContentMain fullWidth>
      <div className={css(styles.marginTop)}>
        <CropProgressBarHeader
          marketedPercentage={percentage(progress.marketed?.percentage)}
          unprotectedPercentage={percentage(progress.unprotected?.percentage)}
        />

        <BaseProgressBar baseColor={progressBarColors.unprotected} type="thick">
          <IndependentProgressBar
            color={progressBarColors.hedged}
            type="thick"
            width={progress.sold.percentage + progress.hedged.percentage}
          />
        </BaseProgressBar>

        <br />
        <br />

        <TwoColumnResponsiveLayout>
          <div className="column">
            {/* Sold */}
            <DetailCard
              subtitle={percentOfTotal(progress.sold)}
              title={<DetailCropHeader crop={commodity} type="priced" />}
            >
              <DetailedCellRow>
                <Cell>Total revenue booked</Cell>
                <Cell right>
                  <strong>{formatCurrency(progress.sold.value, 0)}</strong>
                </Cell>
              </DetailedCellRow>

              <DetailedCellRow>
                <Cell>Profit from sales</Cell>
                <Cell right>{perAcre(progress.sold.profitPerAcre)}</Cell>
              </DetailedCellRow>

              <DetailedCellRow>
                <Cell>Average price sold</Cell>
                <Cell right>{perUnit(progress.sold.valuePerUnit)}</Cell>
              </DetailedCellRow>
            </DetailCard>

            {/* Hedged */}
            {isExchangeTraded && (
              <DetailCard
                subtitle={
                  <div>
                    {percentOfTotal(progress.hedged)}
                    <Tooltip message="The amount of your crop with positions that limit downside. Other financial positions will impact gain/loss, but are not included here.">
                      <TooltipIcon />
                    </Tooltip>
                  </div>
                }
                title={<DetailCropHeader crop={commodity} type="hedged" />}
              >
                <DetailedCellRow>
                  <Cell>Average price floor</Cell>
                  <Cell right>{perUnit(progress.hedged.valuePerUnitMin)}</Cell>
                </DetailedCellRow>

                <DetailedCellRow>
                  <Cell>
                    {`Financial ${gainLossLabel(brokerageGainLoss)}`}
                    <Tooltip message="The total gain/loss for all your financial positions using their current market value.">
                      <TooltipIcon />
                    </Tooltip>
                  </Cell>
                  <Cell right>
                    <strong>{formatCurrency(brokerageGainLoss, 0)}</strong>
                  </Cell>
                </DetailedCellRow>
              </DetailCard>
            )}

            {/* Unprotected */}
            <DetailCard
              subtitle={percentOfTotal(progress.unprotected)}
              title={<DetailCropHeader crop={commodity} type="unprotected" />}
            >
              <DetailedCellRow>
                <Cell>Total cash value</Cell>
                <Cell right>
                  <strong>{formatCurrency(progress.unprotected.value, 0)}</strong>
                </Cell>
              </DetailedCellRow>

              {isExchangeTraded && (
                <>
                  <DetailedCellRow>
                    <Cell>
                      Potential profit
                      <Tooltip message="The profit that you could make off your unprotected crop by marketing it at the current estimated cash price.">
                        <TooltipIcon />
                      </Tooltip>
                    </Cell>
                    <Cell right>{perAcre(progress.unprotected.profitPerAcre)}</Cell>
                  </DetailedCellRow>

                  <DetailedCellRow>
                    <Cell>
                      Estimated cash price
                      <Tooltip
                        message={`${moment(harvestMonth.expirationDate).format(
                          "MMM YY",
                        )} futures price minus your estimated basis`}
                      >
                        <TooltipIcon />
                      </Tooltip>
                    </Cell>
                    <Cell right>{perUnit(progress.unprotected.valuePerUnit)}</Cell>
                  </DetailedCellRow>
                </>
              )}
            </DetailCard>

            {/* Total Production */}
            <DetailCard
              subtitle={<CommodityYieldUnit amount={<strong>{number(totalQuantity)}</strong>} commodity={commodity} />}
              title={<DetailCropHeader crop={commodity} type="total" />}
            >
              <DetailedCellRow>
                <Cell>
                  Crop value
                  <Tooltip message="The estimated price you could achieve for your entire crop if you sold the remainder today.">
                    <TooltipIcon />
                  </Tooltip>
                  &nbsp;
                  <span className={css(styles.detailCard_lightText)}>(sold + unsold)</span>
                </Cell>
                <Cell right>{perUnit(progress.total.valuePerUnit)}</Cell>
              </DetailedCellRow>

              {isExchangeTraded && (
                <>
                  <DetailedCellRow>
                    <Cell>Marketed profit</Cell>
                    <Cell right>{perAcre(progress.marketed.profitPerAcre)}</Cell>
                  </DetailedCellRow>

                  <DetailedCellRow>
                    <Cell>
                      Average marketed price
                      <Tooltip message="The current estimated value of your sold and hedged positions.">
                        <TooltipIcon />
                      </Tooltip>
                    </Cell>
                    <Cell right>{perUnit(progress.marketed.valuePerUnit)}</Cell>
                  </DetailedCellRow>
                </>
              )}
            </DetailCard>
          </div>

          <div className="column">
            {/* Production details */}
            <DetailCard subtitle={editLink()} title="Production details">
              <DetailedCellRow>
                <Cell>Acres</Cell>
                <Cell right>
                  <strong>{number(acresPlanted)}</strong> ac
                </Cell>
              </DetailedCellRow>

              <DetailedCellRow>
                <Cell>Average yield</Cell>
                <Cell right>
                  <CommodityYieldUnit
                    amount={<strong>{number(averageYield)}</strong>}
                    commodity={commodity}
                    per="acre"
                  />
                </Cell>
              </DetailedCellRow>

              <DetailedCellRow>
                <Cell>Estimated production</Cell>
                <Cell right>
                  <CommodityYieldUnit amount={<strong>{acresPlanted * averageYield}</strong>} commodity={commodity} />
                </Cell>
              </DetailedCellRow>

              <DetailedCellRow>
                <Cell>Irrigated</Cell>
                <Cell right>
                  <strong>{irrigated ? "Yes" : "No"}</strong>
                </Cell>
              </DetailedCellRow>

              <DetailedCellRow>
                <Cell>Cost of production</Cell>
                <Cell right>{perUnit(productionCostPerUnit)}</Cell>
              </DetailedCellRow>

              {isExchangeTraded && (
                <DetailedCellRow>
                  <Cell>Estimated basis</Cell>
                  <Cell right>{perUnit(harvestBasis)}</Cell>
                </DetailedCellRow>
              )}

              {list("Fields", fields)}
            </DetailCard>

            <ContractsLink id={id} />
          </div>
        </TwoColumnResponsiveLayout>

        {showCropModal && <CropEditModal marketedCrops={[marketedCrop]} onClose={() => setShowCropModal(false)} />}
      </div>
    </ContentMain>
  );
};

export default CropDetailsDashboard;
