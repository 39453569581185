import { findIndex, map, toLower } from "lodash";
import { useMemo } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import useWindow from "hooks/useWindow";
import { useTabBarPreferredFeatures } from "components/fl-ui/Layout/MainMenu/TabBar/useTabBarPreferredFeatures";
import { useMainMenuFeatures } from "components/fl-ui/Layout/MainMenu/useMainMenuFeatures";
const useTabBarMainMenuFeatures = () => {
    const { history } = useWindow();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const mainMenuFeatures = useMainMenuFeatures();
    const preferredFeatures = useTabBarPreferredFeatures();
    /*
     * Step 1: determine whether the back button should be shown
     */
    /*
     * Creates an array of each directory in .location.pathname. Directory names will be preceded with a /.
     * Examples:
     *    "/fields"       => ["/fields"]
     *    "/fields/1234"  => ["/fields", "/1234"]
     *    "/fields/1234/" => ["/fields", "/1234"]
     */
    const directoriesInPath = map([...pathname.matchAll(/(\/[^\/]+)/g)], 0);
    const topLevelPath = toLower(directoriesInPath[0]);
    const isBlacklisted = [useMatch("/prices/futures"), useMatch("/prices/local"), useMatch("/settings/*")].some((x) => x);
    const shouldAddBackButton = directoriesInPath.length > 1 && !isBlacklisted && history.length > 2;
    /*
     * Step 2: construct the responsive menu items, injecting the back button if necessary
     */
    const tabBarMainMenuItems = useMemo(() => {
        if (mainMenuFeatures.length == 0 || preferredFeatures.length == 0) {
            return [];
        }
        const prioritizedFeatures = mainMenuFeatures
            .filter(({ enabled, id }) => enabled && preferredFeatures.includes(id))
            .sort((a, b) => preferredFeatures.indexOf(a.id) - preferredFeatures.indexOf(b.id)); // Sort by index in preferredFeatures
        const returnedItems = prioritizedFeatures.slice(0, 4).concat({
            enabled: true,
            icon: "menu",
            id: "moreMenu",
            title: "Menu",
            to: "/",
        });
        if (shouldAddBackButton) {
            const indexOfMainMenuItemToReplace = findIndex(returnedItems, ({ to }) => (to === null || to === void 0 ? void 0 : to.toLowerCase()) === topLevelPath);
            const moreButtonIndex = returnedItems.length - 1;
            const backButtonIndex = indexOfMainMenuItemToReplace > -1 ? indexOfMainMenuItemToReplace : moreButtonIndex;
            returnedItems[backButtonIndex] = {
                enabled: true,
                icon: "chevronLeft",
                id: "back",
                onClick: (event) => {
                    event.preventDefault();
                    navigate(-1);
                },
                title: "Back",
                to: "/",
            };
        }
        return returnedItems;
    }, [mainMenuFeatures, pathname, preferredFeatures]);
    return tabBarMainMenuItems;
};
export { useTabBarMainMenuFeatures };
