import React from "react";
import InviteLayout from "layout/InviteLayout";
import LegacyAuthLayout from "layout/LegacyAuthLayout";
import Login from "layout/Login";
import PasswordReset from "layout/PasswordReset";
import PasswordResetToken from "layout/PasswordResetToken";
import SignIn from "modules/auth/pages/SignIn";
//This entire file should be able to be removed once the new auth flow is complete
export default {
    children: [
        {
            element: React.createElement(LegacyAuthLayout, null), // @ts-ignore
            children: [
                {
                    path: "/invite",
                    element: React.createElement(InviteLayout, null),
                },
                {
                    path: "/login",
                    element: React.createElement(Login, null),
                },
            ],
        },
        {
            path: "/password/reset",
            element: React.createElement(PasswordReset, null),
        },
        {
            path: "/password/reset/:token",
            element: React.createElement(PasswordResetToken, null),
        },
        {
            path: "/sign_in",
            element: React.createElement(SignIn, null),
        },
    ],
};
