import React from "react";
import styled from "styled-components";
import { Logo } from "components/fl-ui/Icons";
import { MainMenuSharedList } from "components/fl-ui/Layout/MainMenu/SharedList/MainMenuSharedList";
import { GreyColors } from "components/fl-ui/colors";
import { Borders, Mixins, Spacing } from "components/fl-ui/constants";
const LogoContainer = styled.div `
  border-bottom: ${Borders.regular};
  margin-bottom: ${Spacing.spacing8};
  padding: ${Spacing.spacing20}, 0, ${Spacing.spacing20}, ${Spacing.spacing12};
`;
const MainMenuDesktop = styled(({ className }) => (React.createElement("div", { className: className },
    React.createElement(LogoContainer, null,
        React.createElement(Logo, null)),
    React.createElement(MainMenuSharedList, null)))) `
  background-color: ${GreyColors.smoke98};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 0 ${Spacing.spacing16};
  position: fixed;
  width: ${Mixins.toRem(272)};
`;
export { MainMenuDesktop };
