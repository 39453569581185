import React from "react";
import useAnalyticsSetup from "hooks/useAnalyticsSetup";
import useMobileListeners from "hooks/useMobileListeners";
import useOIDCEvents from "hooks/useOIDCEvents";
import useSentrySetup from "hooks/useSentrySetup";
const AnalyticsProvider = ({ children }) => {
    useAnalyticsSetup();
    useSentrySetup();
    useMobileListeners();
    useOIDCEvents();
    return React.createElement(React.Fragment, { key: "AnalyticsProvider" }, children);
};
export default AnalyticsProvider;
