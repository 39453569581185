import React from "react";
import styled from "styled-components";
import Fullscreen from "components/fl-ui/util/Fullscreen";
const ContentContainer = styled.div `
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  justify-content: center;
  text-align: center;
`;
const AccountDeactivationConfirmation = ({ platform, role }) => {
    const isAdmin = role === "admin";
    const showMobileStoreLink = isAdmin && (platform === null || platform === void 0 ? void 0 : platform.isMobilePaymentProvider);
    return (React.createElement(Fullscreen, { id: "account-deactivation-confirmation" },
        React.createElement(ContentContainer, null,
            React.createElement("img", { height: "200px", src: "/images/app_icons/bushel-farm-icon-alt.svg", alt: "Bushel Farm Icon" }),
            React.createElement("h2", null,
                isAdmin &&
                    (platform === null || platform === void 0 ? void 0 : platform.isWebPaymentProvider) &&
                    `
            Your subscription has been canceled and all accounts have been deleted.
          `,
                isAdmin &&
                    (!platform || platform.isMobilePaymentProvider) &&
                    `
            All accounts have been deleted.
          `,
                !isAdmin &&
                    `
            Your account has been deleted.
          `),
            showMobileStoreLink && (React.createElement("div", null,
                React.createElement("p", null,
                    "In order to complete this process,",
                    " ",
                    React.createElement("a", { href: platform.storeUrl, target: "_blank", rel: "noopener noreferrer" }, "click here"),
                    " ",
                    "to go through ",
                    platform.storeName,
                    " and cancel your subscription."))))));
};
export default AccountDeactivationConfirmation;
