import { SecondaryMaxWidth } from "constants/mobileBreakpointConstants";
import React, { useState } from "react";
import styled from "styled-components";
import { ChangeUserAccessLevelModal } from "modules/settings/pages/Users/components/ChangeUserAccessLevelModal";
import { DeleteUserModal } from "modules/settings/pages/Users/components/DeleteUserModal";
import { Button } from "components/fl-ui";
import { Cluster } from "components/fl-ui/Layout";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const ButtonArea = styled(({ className, user }) => {
    const [showUserAccessLevelModal, setShowUserAccessLevelModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    return (React.createElement("div", { className: className },
        React.createElement(Button, { id: "change-access-level-button", color: "default", hollow: true, onClick: () => {
                setShowUserAccessLevelModal(true);
            } }, "Change access level"),
        React.createElement(Button, { id: "remove-user-button", color: "danger", hollow: true, onClick: () => setShowDeleteUserModal(true) }, "Remove"),
        showUserAccessLevelModal && (React.createElement(ChangeUserAccessLevelModal, { onClose: () => setShowUserAccessLevelModal(false), user: user })),
        showDeleteUserModal && React.createElement(DeleteUserModal, { onClose: () => setShowDeleteUserModal(false), user: user })));
}) `
  display: flex;
  gap: ${Spacing.spacing10};
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 0;

  @media (max-width: ${SecondaryMaxWidth}) {
    gap: ${Spacing.spacing8};
    width: 100%;
    padding: 0 ${Spacing.spacing8};
    justify-content: center;

    button {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    #change-access-level-button {
      width: 66%;
    }

    #remove-user-button {
      width: 27%;
    }
  }
`;
const IndividualUser = styled(({ canEditRole, className, isCurrentUser, user }) => (React.createElement(Cluster, { start: true, className: className, key: user.id, around: undefined, between: undefined, center: undefined, end: undefined, even: undefined },
    React.createElement("div", { className: "user-text-area" },
        React.createElement("div", { className: "user-name" }, user.name),
        React.createElement("div", { className: "user-email" }, user.email || "No email")),
    canEditRole && !isCurrentUser && React.createElement(ButtonArea, { user: user })))) `
  border: ${Spacing.spacing10} solid ${GreyColors.smoke96};
  padding: ${Spacing.spacing16};
  margin: ${Spacing.spacing10} 0;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing.spacing16};

  .user-text-area {
    flex-grow: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: ${Spacing.spacing24};

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${ColorPalette.black};
      font-size: ${Typography.sizes.lg};
      padding: ${Spacing.spacing2} 0;
    }

    .user-name {
      font-weight: bold;
    }
  }

  @media only screen and (max-width: ${SecondaryMaxWidth}) {
    flex-direction: column;
    align-items: stretch;

    .user-text-area {
      width: 100%;
      padding-left: ${Spacing.spacing16};
      text-align: left;
      white-space: normal;
    }
  }
`;
export { IndividualUser };
