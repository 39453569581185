import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import { capitalize, upperFirst } from "lodash";
import useFinancialAccounts from "profit_and_loss/hooks/useFinancialAccounts";
import { Label } from "profit_and_loss/styles";
import { getFilterCrops } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactSelect from "react-select";
import { Col, Row } from "react-styled-flexboxgrid";

import useCurrentCropYear from "hooks/useCurrentCropYear";

import FilterActionButtonGroup from "components/fl-ui/Filters/FilterActionButtonGroup";
import { Checkbox, ClearAllFormGroup } from "components/fl-ui/Form";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { DatePicker } from "components/fl-ui/common";

const ExpenseRevenueFilterModal = ({ filters, onClose, setFilters, transactions, type }) => {
  const cropYear = useCurrentCropYear()[0];
  const { accountTypes, loading, ...accountData } = useFinancialAccounts();
  const { fieldCrops: crops, marketedCrops } = getFilterCrops(transactions);
  const [filterData, setFilterData] = useState(filters);
  const { accounts, commodities, endDate, excludeOverhead, fieldCrops, startDate } = filterData;
  const updateFilterData = (newFilters) => setFilterData({ ...filterData, ...newFilters });
  const applyFilters = () => {
    setFilters(filterData);
    onClose();
  };

  const resetFilters = () => {
    setFilters({});
    onClose();
  };

  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>{`Filter ${capitalize(type)}`}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col xs={12}>
            <ClearAllFormGroup label="Account name" onClear={() => updateFilterData({ accounts: [] })}>
              <ReactSelect
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                isLoading={loading}
                isMulti
                onChange={(accounts) => updateFilterData({ accounts })}
                options={accountData[type].active}
                placeholder="Type to search..."
                styles={chooserStyles}
                value={accounts}
              />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <ClearAllFormGroup label="Start date" onClear={() => updateFilterData({ startDate: null })}>
              <DatePicker onChange={(startDate) => updateFilterData({ startDate })} value={startDate} />
            </ClearAllFormGroup>
          </Col>

          <Col xs={12} md={6}>
            <ClearAllFormGroup label="End date" onClear={() => updateFilterData({ endDate: null })}>
              <DatePicker onChange={(endDate) => updateFilterData({ endDate })} value={endDate} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <ClearAllFormGroup label="Field crops" onClear={() => updateFilterData({ fieldCrops: [] })}>
              <ReactSelect
                getOptionLabel={({ commodity, field }) => `${upperFirst(field.name)} · ${commodity.name} ${cropYear}`}
                getOptionValue={({ id }) => id}
                isMulti
                onChange={(fieldCrops) => updateFilterData({ fieldCrops })}
                options={crops}
                placeholder="Type to search..."
                styles={chooserStyles}
                value={fieldCrops}
              />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <ClearAllFormGroup label="Commodity crops" onClear={() => updateFilterData({ commodities: [] })}>
              <ReactSelect
                getOptionLabel={({ name }) => upperFirst(name)}
                getOptionValue={({ id }) => id}
                isMulti
                onChange={(commodities) => updateFilterData({ commodities })}
                options={marketedCrops}
                placeholder="Type to search..."
                styles={chooserStyles}
                value={commodities}
              />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Label htmlFor="excludeOverhead">
              <Checkbox
                checked={!!excludeOverhead}
                id="excludeOverhead"
                name="excludeOverhead"
                onChange={() => updateFilterData({ excludeOverhead: !excludeOverhead })}
              />
              Exclude expenses allocated to overhead
            </Label>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <FilterActionButtonGroup
          apply={{ action: applyFilters, buttonText: "Apply" }}
          cancel={{ action: onClose }}
          reset={{ action: resetFilters, buttonText: "Clear filters" }}
        />
      </ModalFooter>
    </Modal>
  );
};

ExpenseRevenueFilterModal.propTypes = {
  filters: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  setFilters: PropTypes.func,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
};

export default ExpenseRevenueFilterModal;
