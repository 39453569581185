import { css } from "aphrodite/no-important";
import d3 from "d3";
import _ from "lodash";
import RedGreenText from "marketing/components/format/RedGreenText";
import dollarFormat from "marketing/utils/dollarFormat";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "modules/prices/styles/futuresTabStyles";

import { Button } from "components/fl-ui";
import { IconContainer } from "components/fl-ui/Icons";
import { Col, Body, Header, HeaderCol, Row, Table } from "components/fl-ui/Table";
import Tooltip from "components/fl-ui/Tooltip";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const signedDollarFormat = d3.format("+$,.2f");

/* sortContracts() */
const sortContracts = (contracts, fieldId, sortAscending) => {
  let sortedContracts = fieldId === "name" ? _.sortBy(contracts, ["year", "month"]) : contracts;
  if (fieldId !== "name") {
    sortedContracts = contracts.slice(0).sort((a, b) => {
      return parseFloat(a[fieldId] || 0) < parseFloat(b[fieldId] || 0) ? -1 : 1;
    });
  }

  return sortAscending ? sortedContracts : sortedContracts.reverse();
};

const FuturesPricesTable = ({ commodity, contracts }) => {
  const [state, setState] = useState({
    sortAscending: true,
    sortColumn: "name",
  });
  const navigate = useNavigate();

  const { sortAscending, sortColumn } = state;

  const formatDailyChange = (value) => {
    value = parseFloat(value) === 0 ? 0 : parseFloat(value);

    return !value ? (
      <span style={{ color: "#999" }}>{signedDollarFormat(0)}</span>
    ) : (
      <RedGreenText value={value}>{signedDollarFormat(value)}</RedGreenText>
    );
  };

  const formatSpread = (value) => {
    return +value !== 0 ? formatDailyChange(value) : <span style={{ color: "#999" }}>--</span>;
  };

  const onSort = (sortColumn, e) => {
    e.preventDefault();
    setState({
      sortAscending: state.sortColumn === sortColumn ? !sortAscending : true,
      sortColumn,
    });
  };

  const SortableHeader = ({ children, column, numeric }) => (
    <HeaderCol
      className={css(numeric && styles.rightAligned, sortColumn === column && styles.isSorted)}
      onClick={_.partial(onSort, column)}
      sortAsc={sortColumn === column ? sortAscending : undefined}
    >
      {children}
    </HeaderCol>
  );

  const sortedContracts = sortContracts(contracts, sortColumn, sortAscending);
  const spreadTooltip = `
    Spread is the difference between the current futures price and the
    current price for a deferred delivery contract.
  `;

  return (
    <Table className={css(styles.table)}>
      <Header>
        <Row>
          <SortableHeader column="name">Delivery month / Contract</SortableHeader>

          <SortableHeader column="lastPrice" numeric>
            Last price
          </SortableHeader>

          <SortableHeader column="dailyChange" numeric>
            Daily change
          </SortableHeader>

          <SortableHeader column="spread" numeric>
            Spread
            <Tooltip message={spreadTooltip}>
              <IconContainer color={GreyColors.smoke30} icon="help" size={Spacing.spacing16} />
            </Tooltip>
          </SortableHeader>

          <HeaderCol className={css(styles.rightAligned)}> </HeaderCol>
        </Row>
      </Header>

      <Body>
        {sortedContracts.map((contract, i) => {
          const onDetailsClick = () => {
            navigate(`/prices/futures/contract/${contract.symbol}`);
          };

          return (
            <Row key={`futuresPricesRow-${i}`} onClick={onDetailsClick}>
              <Col>
                {contract.name}
                {" • "}
                {contract.symbol}
              </Col>

              <Col className={css(styles.rightAligned)}>
                <CommodityYieldUnit amount={dollarFormat(contract.lastPrice)} commodity={commodity} per="unit" />
              </Col>

              <Col className={css(styles.rightAligned)}>{formatDailyChange(contract.dailyChange)}</Col>

              <Col className={css(styles.rightAligned)}>{formatSpread(contract.spread)}</Col>

              <Col className={css(styles.centerAligned)}>
                <Button color="primary" link onClick={onDetailsClick}>
                  View details
                </Button>
              </Col>
            </Row>
          );
        })}
      </Body>
    </Table>
  );
};

export { sortContracts };
export default FuturesPricesTable;
