import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const Stat = styled(({ className, children }) => React.createElement("div", { className: className }, children)) `
  ${({ highlight, highlightColor }) => highlight &&
    `
    color: ${highlightColor};
  `}
  > big {
    font-size: ${Typography.sizes.xxl};
    font-weight: ${Typography.weights.regular};
  }
  > .label {
    color: ${UIColors.light};
    font-size: ${Typography.sizes.sm};
    margin-bottom: 1em;
    text-transform: uppercase;
  }
`;
export const RainfallStat = ({ children, highlight, label }) => (React.createElement(Stat, { highlight: highlight, highlightColor: UIColors.blue },
    children,
    React.createElement("div", { className: "label" }, label)));
export const HeatStat = ({ children, highlight, label }) => (React.createElement(Stat, { highlight: highlight, highlightColor: UIColors.red },
    children,
    React.createElement("div", { className: "label" }, label)));
const BottomButtonArea = styled(({ className, linkText }) => (React.createElement("div", { className: `col xs-12 ${className}` },
    React.createElement(Button, { color: "primary", lightWeight: true, link: true }, linkText)))) `
  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    margin-bottom: ${Spacing.spacing16};
  }
`;
const StatsContainer = styled.div `
  align-items: flex-end;
  color: ${UIColors.light};
  display: flex;
  font-weight: ${Typography.weights.medium};
  line-height: 1.3em;

  > :first-child {
    width: 60%;
  }
`;
const AspectSummary = ({ children, linkHref, linkText, title }) => (React.createElement(Link, { to: linkHref },
    React.createElement("div", { className: "row-fluid" },
        React.createElement("div", { className: "col xs-12" },
            React.createElement("h2", null, title))),
    React.createElement(StatsContainer, null, children),
    React.createElement("div", { className: "row-fluid" },
        React.createElement(BottomButtonArea, { linkText: linkText }))));
export default AspectSummary;
