import ProductInputChooser from "activity/components/choosers/ProductInputChooser";
import ProductUnitChooser from "activity/components/choosers/ProductUnitChooser";
import RateChooser from "activity/components/choosers/RateChooser";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import { useProductById } from "collection/graphql/products/hooks";
import { unitsByType } from "collection/graphql/units/unitsOfMeasure";
import useUnits from "hooks/useUnits";
import useViewType from "hooks/useViewType";

import { FormGroup, Input, NumericInput } from "components/fl-ui/Form";
import { UIColors } from "components/fl-ui/colors";
import Typography from "components/fl-ui/constants/typography";
import { ActivityInputSchema, IngredientSchema, PurchaseInputSchema } from "components/product/schemas";
import LandAreaUnit from "components/units/LandAreaUnit";
import { Spacing } from "components/fl-ui/constants";

export const Controls = styled.div`
  display: grid;
  grid-gap: 0 8px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
`;

const Fieldset = styled.fieldset`
  background: #fcfcfc;
  border: 1px solid #f0f0f0;
  padding: 10px;
  position: relative;
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  color: ${UIColors.danger};
  cursor: pointer;
  font-size: ${Typography.sizes.mn};
  font-weight: ${Typography.weights.bold};
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: ${Spacing.spacing2};
`;

const useFormFieldTouch = () => {
  const [touched, setTouched] = useState({});

  return {
    anyTouched: Object.keys(touched).length > 0,
    touch: (targetId) =>
      setTouched((touched) => {
        return targetId in touched ? touched : { ...touched, [targetId]: true };
      }),
    touched,
  };
};

const ProductChooser = ({ disableArea = false, input, inputType, onChange, onRemove }) => {
  const { getEnumAsOptions } = useUnits();
  const isMobile = useViewType() === "mobile";
  const applicationAreaTooltipCopy = disableArea && "Only available when logging an activity on a single field.";

  const handleChange = (fieldName, value) => {
    const updatedInput = { ...input };

    switch (fieldName) {
      // area
      case fieldNameMap.area:
        try {
          schema.validateSyncAt("area", { area: value.target.value });
          updatedInput[fieldNameMap.area] = value.target.value;
          updatedInput[fieldNameMap.areaUnit] = updatedInput[fieldNameMap.areaUnit] ?? "ACRE";
        } catch {
          updatedInput[fieldNameMap.area] = null;
          updatedInput[fieldNameMap.areaUnit] = null;
        }
        break;

      // product
      case fieldNameMap.product:
        updatedInput[fieldNameMap.product] = value?.id ?? null;
        updatedInput[fieldNameMap.amountUnit] = value?.commodity?.[defaultUnitKey] ?? "";
        break;

      default:
        updatedInput[fieldName] = value;
        break;
    }

    onChange(updatedInput);
  };

  // normalize field names
  const fieldNameMap = {
    amount: "amount",
    amountUnit: "amountUnit",
    product: "product",
    rate: "amountRate",
  };
  if (inputType === "INGREDIENT") {
    fieldNameMap.rate = "rateUnit";
  } else if (inputType === "PURCHASE") {
    fieldNameMap.cost = "cost";
    fieldNameMap.rate = "costRate";
  } else {
    fieldNameMap.area = "area";
    fieldNameMap.areaUnit = "areaUnit";
  }

  // rates
  const rateEnumName = inputType === "PURCHASE" ? "ProductPurchaseCostRate" : "ActivityInputAmountRate";
  const rateOptions = getEnumAsOptions(rateEnumName);

  // units
  const { product } = useProductById(input[fieldNameMap.product]);
  const defaultUnitKey = inputType === "PURCHASE" ? "defaultSeedPurchaseUnit" : "defaultSeedApplyUnit";
  const unitType = product?.commodity?.seedUnitType || product?.unitType;
  const unitOptions = unitsByType(unitType);

  // validation
  const validationInput = { ...input, unitType };
  const { touch, touched } = useFormFieldTouch();
  let schema = ActivityInputSchema;
  if (inputType === "INGREDIENT") {
    schema = IngredientSchema;
  } else if (inputType === "PURCHASE") {
    schema = PurchaseInputSchema;
  }

  const errorsByField = new Map();
  try {
    schema.validateSync(validationInput, { abortEarly: false });
  } catch ({ inner }) {
    inner.forEach(({ message, path }) => {
      if (path in touched) {
        errorsByField.set(path, message);
      }
    });
  }

  return (
    <Fieldset>
      <Controls columns={isMobile ? 1 : 2}>
        <FormGroup
          error={errorsByField.get(fieldNameMap.product)}
          label="Input"
          onBlur={() => touch(fieldNameMap.product)}
          hasError={errorsByField.has(fieldNameMap.product)}
          stretch
          style={{ position: "relative" }}
        >
          <ProductInputChooser
            hasError={errorsByField.has(fieldNameMap.product)}
            name={fieldNameMap.product}
            onChange={(value) => handleChange(fieldNameMap.product, value)}
            value={input[fieldNameMap.product] ?? ""}
          />

          {onRemove && <RemoveButton onClick={() => onRemove()}>Remove</RemoveButton>}
        </FormGroup>

        <FormGroup
          error={errorsByField.get(fieldNameMap.amount)}
          label="Amount"
          onBlur={() => touch(fieldNameMap.amount)}
          hasError={errorsByField.has(fieldNameMap.amount)}
        >
          <Input
            hasError={errorsByField.has(fieldNameMap.amount)}
            inputMode="decimal"
            name="amount"
            onBlur={() => touch(fieldNameMap.amount)}
            onChange={(event, { amount }) => handleChange("amount", amount === "" ? undefined : +amount)}
            step={1}
            type="number"
            value={input?.amount ?? ""}
          />
        </FormGroup>

        <FormGroup
          error={errorsByField.get(fieldNameMap.amountUnit)}
          label="Unit"
          hasError={errorsByField.has(fieldNameMap.amountUnit)}
        >
          <ProductUnitChooser
            hasError={errorsByField.has(fieldNameMap.amountUnit)}
            onChange={(unit) => handleChange(fieldNameMap.amountUnit, unit)}
            onMenuClose={() => touch(fieldNameMap.amountUnit)}
            options={unitOptions}
            productId={Number(input[fieldNameMap.product])}
            value={input[fieldNameMap.amountUnit] ?? ""}
          />
        </FormGroup>

        {inputType === "PURCHASE" && (
          <FormGroup
            error={errorsByField.get(fieldNameMap.cost)}
            label="Cost"
            onBlur={() => touch("cost")}
            hasError={errorsByField.has(fieldNameMap.cost)}
          >
            <Input
              hasError={errorsByField.has(fieldNameMap.cost)}
              inputMode="decimal"
              min={0}
              name="cost"
              prefix="$"
              onChange={(event, { cost }) => handleChange(fieldNameMap.cost, cost === "" ? undefined : +cost)}
              step={1}
              type="number"
              value={input[fieldNameMap.cost] ?? ""}
            />
          </FormGroup>
        )}

        <FormGroup
          error={errorsByField.get(fieldNameMap.rate)}
          label="Rate"
          hasError={errorsByField.has(fieldNameMap.rate)}
        >
          <RateChooser
            hasError={errorsByField.has(fieldNameMap.rate)}
            onChange={(rate) => handleChange(fieldNameMap.rate, rate)}
            onMenuClose={() => touch(fieldNameMap.rate)}
            options={rateOptions}
            value={input[fieldNameMap.rate] ?? ""}
          />
        </FormGroup>

        {inputType === "ACTIVITY" && (
          <FormGroup
            hasError={errorsByField.has(fieldNameMap.area)}
            label="Application area"
            optional
            tip={`${applicationAreaTooltipCopy}`}
          >
            <NumericInput
              disabled={disableArea}
              hasError={errorsByField.has(fieldNameMap.area)}
              maxPrecision={2}
              name={fieldNameMap.area}
              onChange={(value) => handleChange(fieldNameMap.area, value)}
              suffix={<LandAreaUnit value={input.areaUnit} />}
              type="float"
              value={input.area}
            />
          </FormGroup>
        )}
      </Controls>
    </Fieldset>
  );
};

ProductChooser.propTypes = {
  disableArea: PropTypes.bool,
  input: PropTypes.object.isRequired,
  inputType: PropTypes.oneOf(["ACTIVITY", "INGREDIENT", "PURCHASE"]).isRequired,
  onChange: PropTypes.func.isRequired,
  onFieldTouch: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ProductChooser;
