import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import getAllUsers from "collection/graphql/users/queries/getAllUsers";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";

const ActivityUserChooser = withSuspenseWrapper((props) => {
  const { onChange, value } = props;
  const allUsers = _.sortBy(useRestSuspenseQuery(getAllUsers).data.users, "name");
  const componentValue = value.map((id) => _.find(allUsers, { id }));

  return (
    <ReactSelect
      getOptionLabel={_.property("name")}
      getOptionValue={_.property("id")}
      isMulti
      onChange={(selectedOptions) => onChange(_.map(selectedOptions, "id"))}
      options={allUsers}
      styles={chooserStyles}
      value={componentValue}
    />
  );
});

ActivityUserChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ActivityUserChooser;
