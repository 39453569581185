import { StyleSheet } from "aphrodite";

import { UIColors, GreyColors } from "components/fl-ui/colors";
import { Borders, BorderRadius, Mixins, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  buttonGroup: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "start",
  },
  groupedButtonMargin: {
    borderRadius: 0,
    margin: `${Spacing.spacing4} -1px ${Spacing.spacing4} 0`,
    ":first-of-type": {
      borderRadius: `${BorderRadius.medium} 0 0 ${BorderRadius.medium}`,
    },
    ":last-of-type": {
      borderRadius: `0 ${BorderRadius.medium} ${BorderRadius.medium} 0`,
    },
  },
  groupedButtonContainer: {
    borderBottom: Borders.regular,
    ":last-child": {
      borderBottom: "none",
    },
  },
  groupedButtonHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  groupedButtonHeaderLeft: {
    color: Typography.colors.primary,
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.375,
    marginBottom: Spacing.spacing8,
  },
  green: {
    color: UIColors.green,
  },
  popoverIconButton: {
    display: "inline-block !important",
    width: Spacing.spacing32,
    height: Spacing.spacing32,
    padding: "0 !important",
    backgroundColor: UIColors.white,
    ":hover": {
      backgroundColor: GreyColors.smoke97,
      color: UIColors.primary,
    },
  },
  popoverIconButtonLarge: {
    height: Mixins.toRem(40),
    padding: "0",
    verticalAlign: "middle",
    width: Mixins.toRem(40),
  },
  popoverIconButtonPrimary: {
    color: UIColors.blue,
  },
  popoverOption: {
    display: "flex !important",
    fontSize: Typography.sizes.md,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.spacing16,
    textAlign: "left",
    width: "100%",
    ":last-child": {
      marginBottom: "0",
    },
  },
  regularTextColor: {
    color: UIColors.regular,
    fontWeight: Typography.weights.regular,
  },
});

export default styles;
