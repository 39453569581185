import { useMutation } from "@apollo/client";
import { css, StyleSheet } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { string } from "yup";

import { UPDATE_ENTERPRISE } from "collection/graphql/enterprise/mutations";
import UpdateEnterpriseInputSchema from "collection/graphql/enterprise/schemas/UpdateEnterpriseInputSchema";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import App from "layout/app";

import { Button } from "components/fl-ui";
import Input from "components/fl-ui/Form/Input";
import Icon from "components/fl-ui/Icons/Icon";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: UIColors.lighter,
    display: "inline-block",
    marginRight: Spacing.spacing12,
    marginLeft: Spacing.spacing8,
    width: Spacing.spacing24,
    height: Spacing.spacing24,
  },
  button: {
    marginLeft: `${Spacing.spacing8} !important`,
  },
});

const ZipCodeInput = ({ buttonText, defaultValue, icon, placeholder }) => {
  const { currentEnterprise } = useCurrentEnterprise();
  const [updateEnterprise] = useMutation(UPDATE_ENTERPRISE);
  const [postalCode, setPostalCode] = useState(defaultValue);

  const [loading, setLoading] = useState(false);
  const postalCodeSchema = string()
    .required()
    .matches(/^\d{1,5}$/);
  const buttonDisabled = loading || !postalCodeSchema.isValidSync(postalCode);

  const handleChange = (event) => {
    const { value } = event.target;
    if (value === "" || postalCodeSchema.isValidSync(value)) {
      setPostalCode(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      postalCodeSchema.validateSync(postalCode);
      const enterprise = UpdateEnterpriseInputSchema.validateSync(
        {
          ...currentEnterprise,
          postalCode,
        },
        { stripUnknown: true }
      );

      setLoading(true);
      await updateEnterprise({
        variables: { enterprise },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      App.notify("Update failed, please try again");
      setLoading(false);
    }
  };

  return (
    <form className={css(styles.flexRow)} onSubmit={handleSubmit}>
      <Input
        controlled
        name="zip-code"
        onChange={handleChange}
        maxLength={10}
        placeholder={placeholder}
        prefix={<Icon className={css(styles.icon)} icon={icon} />}
        size="large"
        value={postalCode}
      />
      <Button className={css(styles.button)} color="primary" disabled={buttonDisabled} type="submit">
        {buttonText}
      </Button>
    </form>
  );
};

ZipCodeInput.propTypes = {
  buttonText: PropTypes.string,
  defaultValue: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
};

ZipCodeInput.defaultProps = {
  buttonText: "Save",
  icon: "mapPin",
  placeholder: "Postal Code",
};

export default ZipCodeInput;
