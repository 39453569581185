import sortOptions from "products/sortOptions";
import PropTypes from "prop-types";
import React from "react";

import Filters from "components/fl-ui/Filters/Filters";

const ProductFilterBar = ({ filters, onChange, onFilterButtonClick }) => {
  const { search, sortCriteria, ...reducedFilters } = filters;

  const filtersProps = {
    filters: reducedFilters,
    search,
    setSearch: (search) =>
      onChange({
        ...filters,
        search,
      }),
    setShowFilterModal: () => onFilterButtonClick(),
    setSortCriteria: (sortCriteria) =>
      onChange({
        ...filters,
        sortCriteria,
      }),
    sortCriteria,
    sortOptions,
  };

  return <Filters {...filtersProps} />;
};

ProductFilterBar.propTypes = {
  filters: PropTypes.shape({
    search: PropTypes.string.isRequired,
    sortCriteria: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onFilterButtonClick: PropTypes.func.isRequired,
};

ProductFilterBar.defaultProps = {};

export default ProductFilterBar;
