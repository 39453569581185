import React from "react";
import { redirect } from "react-router-dom";
import ExpenseRevenueDashboardContainer from "modules/profit_and_loss/components/ExpenseRevenueDashboardContainer";
import ProfitAndLossDashboard from "modules/profit_and_loss/pages/ProfitAndLossDashboard";
export default [
    {
        path: "/other_expenses",
        loader: () => redirect("/other_expenses_and_revenue/expenses"),
    },
    {
        path: "/other_revenue",
        loader: () => redirect("/other_expenses_and_revenue/revenue"),
    },
    {
        path: "/other_expenses_and_revenue/*",
        element: React.createElement(ExpenseRevenueDashboardContainer, null),
    },
    {
        path: "/profit_and_loss",
        element: React.createElement(ProfitAndLossDashboard, null),
    },
];
