import styled from "styled-components";
import { Spacing, Typography } from "components/fl-ui/constants";
const GenericStyledText = styled.p `
  margin-bottom: ${Spacing.spacing4};
  line-height: 1.5;

  @media only screen and (max-width: 62.5rem) {
    font-size: ${Typography.sizes.sm};
  }
`;
export { GenericStyledText };
