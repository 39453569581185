import React from "react";
import styled from "styled-components";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
const ValuePropListItem = styled(({ children, className }) => React.createElement("li", { className: className }, children)) `
  color: ${UIColors.darker};
  line-height: 1.5;
  list-style: disc;
  margin-bottom: ${Spacing.spacing12};
  margin-left: ${Spacing.spacing24};
`;
export { ValuePropListItem };
