import SubscriptionSaveError from "billing/exceptions/SubscriptionSaveError";

import defaultCache from "collection/graphql/cache/defaultCache";
import { restClient } from "collection/graphql/client";
import { getCurrentSubscription } from "collection/graphql/subscription/index";
import updateSubscription from "collection/graphql/subscription/mutations/updateWebSubscription";
import subscriptionInputSchema from "collection/graphql/subscription/schemas/subscriptionInputSchema";
import { constructWebOrder } from "collection/graphql/subscription/utils";

/**
 * @typedef {{ payload: { source: "WEB" }}} WebSaveResponse
 */

/**
 * Saves a subscription order to the Bushel Farm backend.
 *
 * @param {OrderDescriptor} orderDescriptor
 * @return {Promise<WebSaveResponse>}
 * @throws {SubscriptionSaveError}
 */
const saveWebSubscription = async (orderDescriptor) => {
  const input = subscriptionInputSchema.cast(constructWebOrder(orderDescriptor), {
    stripUnknown: true,
  });

  try {
    await restClient.mutate({
      mutation: updateSubscription,
      variables: {
        input,
      },
      update: (defaultCache, { data }) => {
        defaultCache.writeQuery({
          data,
          query: getCurrentSubscription,
        });
      },
    });

    return {
      payload: defaultCache.readQuery({ query: getCurrentSubscription }).subscription,
      source: "WEB",
    };
  } catch (error) {
    const message = error.networkError.result.message ?? "Error saving web subscription";
    throw new SubscriptionSaveError(message, {
      cause: error,
      order: input,
      status: error.networkError.result.status,
    });
  }
};

export default saveWebSubscription;
