import useDocumentTitle from "hooks/useDocumentTitle";
import React from "react";
import { WalletCommonBlankSlateBanner } from "modules/wallet/components/WalletCommonBlankSlateBanner";
import { Button } from "components/fl-ui";
import { useNavigate } from "react-router-dom";
const WalletAvailableNotConnectedPage = () => {
    useDocumentTitle("Connect Your Wallet");
    const navigate = useNavigate();
    return (React.createElement(WalletCommonBlankSlateBanner, { title: "Connect Your Wallet Account" },
        React.createElement("h4", null, "Manage your farm and your finances"),
        React.createElement("h4", null, "Get Bushel Farm Lite for Free"),
        React.createElement("h4", null, "Easily relate your transactions to your Profit & Loss"),
        React.createElement("br", null),
        React.createElement(Button, { color: "primary", onClick: () => navigate("/wallet/sign-up") }, "Connect"),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("p", { className: "bottom-grey-text" }, "Only one Wallet account can be linked to a Bushel Farm account and all Admin users on your Bushel Farm account will have access to the linked Wallet.")));
};
export { WalletAvailableNotConnectedPage };
