import useDocumentTitle from "hooks/useDocumentTitle";
import React from "react";
import styled from "styled-components";
import { WalletSignUpFirstSection } from "modules/wallet/components/sign_up/WalletSignUpFirstSection";
import { WalletSignUpFourthSection } from "modules/wallet/components/sign_up/WalletSignUpFourthSection";
import { WalletSignUpSecondSection } from "modules/wallet/components/sign_up/WalletSignUpSecondSection";
import { WalletSignUpThirdSection } from "modules/wallet/components/sign_up/WalletSignUpThirdSection";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders } from "components/fl-ui/constants";
const WalletSignUpPage = styled(({ className }) => {
    useDocumentTitle("Wallet Sign Up");
    return (React.createElement("div", { className: className },
        React.createElement(WalletSignUpFirstSection, null),
        React.createElement(WalletSignUpSecondSection, null),
        React.createElement(WalletSignUpThirdSection, null),
        React.createElement(WalletSignUpFourthSection, null)));
}) `
  border: ${Borders.regular};
  border-radius: ${BorderRadius.medium};
  box-shadow: 0 4px 10px ${UIColors.shadowTwo};
  padding: 0;
`;
export { WalletSignUpPage };
