import { useMutation } from "@apollo/client";
import { css } from "aphrodite/no-important";
import cardStyles from "marketing/cards/cardStyles";
import { CardHeaderPopover } from "marketing/cards/components";
import Cell from "marketing/components/ui-elements/Cell";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import defaultCache from "collection/graphql/cache/defaultCache";
import { DELETE_MARKETED_CROP, GET_MARKETED_CROPS_SUMMARY } from "collection/graphql/marketing";
import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";
import CropBadge from "components/fl-ui/CropBadge";
import Input from "components/fl-ui/Form/Input";
import CloseX from "components/fl-ui/Icons/CloseX";
import Icon from "components/fl-ui/Icons/Icon";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";

export const CardHeader = ({ marketedCrop }) => {
  const [inputText, setInputText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { canDelete } = usePermissions();
  const [deleteMarketedCrop] = useMutation(DELETE_MARKETED_CROP, {
    onCompleted: () => {
      defaultCache.evict({ id: defaultCache.identify(marketedCrop) });
      defaultCache.gc();
    },

    refetchQueries: [GET_MARKETED_CROPS_SUMMARY],

    variables: {
      id: marketedCrop?.id,
    },
  });

  const handleDelete = async () => {
    if (canDelete("marketing")) {
      setIsDeleting(true);
      await deleteMarketedCrop();
      setIsDeleting(false);
      setShowModal(false);
    }
  };

  const viewDetails = () => {
    navigate(`/marketing/${marketedCrop?.id}`);
  };

  return (
    <div className={css(cardStyles.cardHeader, cardStyles.cardHeader_marketedCrop)}>
      <Cell>
        <div className={css(cardStyles.cropBadgeContainer)}>
          <CropBadge
            abbr={marketedCrop?.commodity?.abbr}
            color={marketedCrop?.commodity?.color}
            marginRight
          />

          {marketedCrop?.irrigated && (
            <div className={css(cardStyles.irrigationContainer)}>
              <Icon className={css(cardStyles.menuIcon, cardStyles.irrigationIcon)} icon="irrigation" />
              <h2 className={css(cardStyles.irrigationText)}>Irrigated</h2>
            </div>
          )}
        </div>

        <div>
          <h1 className={css(cardStyles.cardTitle)}>{marketedCrop?.name}</h1>
        </div>
      </Cell>

      {marketedCrop?.__typename === "MarketedCrop" && (
        <Cell className={css(cardStyles.dataRowCol_right)} right>
          <Button
            className={css(cardStyles.rightMargin)}
            color="primary"
            hollow
            onClick={viewDetails}
            size="md"
            type="button"
          >
            View details
          </Button>

          <CardHeaderPopover marketedCropId={marketedCrop?.id} onDeleteRequest={() => setShowModal(true)} />
        </Cell>
      )}

      {showModal && (
        <div onClick={(event) => event.preventDefault()}>
          <Modal>
            <ModalHeader>
              <ModalTitle>Are you sure you want to delete this crop?</ModalTitle>
              <CloseX onClick={() => setShowModal(false)} />
            </ModalHeader>

            <ModalBody>
              <span className={css(cardStyles.modalBodyText)}>
                <span className={css(cardStyles.underlinedText, cardStyles.dataPointLabel_large)}>
                  This action cannot be undone.
                </span>
                {" Deleting a marketed crop will remove the crop and all associated contracts and marketing data."}
              </span>

              <span className={css(cardStyles.modalBodyText, cardStyles.marginTop_16, cardStyles.dataPointLabel_large)}>
                {"Enter the word "}
                <span className={css(cardStyles.underlinedText)}>DELETE</span>
                {" to confirm this action"}
              </span>
              <Input
                display="block"
                onChange={({ target }) => setInputText(target.value)}
                size="large"
                value={inputText}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                className={css(cardStyles.rightMargin)}
                color="primary"
                hollow
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>

              <Button color="danger" disabled={isDeleting || inputText !== "DELETE"} hollow onClick={handleDelete}>
                Delete this crop and all associated data
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </div>
  );
};
