import useDocumentTitle from "hooks/useDocumentTitle";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import PasswordResetTokenForm from "layout/components/PasswordResetTokenForm";
import { getResetErrors, hasErrors } from "layout/utils";
import { UnauthorizedError } from "lib/errors";

const PasswordResetToken = () => {
  useDocumentTitle("Reset");
  const { token } = useParams();
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const defaultValues = {
    error: token ? "" : "No reset token detected. Please check your email and click on the link given.",
    loading: false,
    password: "",
    password_confirm: "",
  };

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(defaultValues);
  const { password } = formValues;

  const handleChange = ({ target: { name, value } }) => setFormValues({ ...formValues, [name]: value });
  const handleError = (errorMessage) => setFormValues({ ...formValues, error: errorMessage, loading: false });

  const handleReset = (event) => {
    event.preventDefault();
    const formErrors = getResetErrors(formValues);
    setFormErrors(formErrors);

    if (hasErrors(formErrors)) {
      return null;
    }

    setFormValues({ ...formValues, error: "", loading: true });

    return resetPassword({ token, password })
      .then(() => {
        navigate("/", { replace: true });
      })
      .catch((error) => {
        let errorMessage =
          "Sorry, we couldn't reset the password. Please try again, or contact Customer Experience if the problem persists.";
        if (error instanceof UnauthorizedError) {
          errorMessage =
            "The password reset link has expired or is invalid. Please try requesting a new password reset email.";
        }

        handleError(errorMessage);
      });
  };

  return (
    <PasswordResetTokenForm
      formErrors={formErrors}
      formValues={formValues}
      handleChange={handleChange}
      handleReset={handleReset}
    />
  );
};


export default PasswordResetToken;
