import React, { useState } from "react";
import styled from "styled-components";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import { MainMenuSharedListItem } from "components/fl-ui/Layout/MainMenu/SharedList/MainMenuSharedListItem";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing, Typography } from "components/fl-ui/constants";
const SectionHeader = styled.li `
  border-radius: ${BorderRadius.medium};
  font-weight: ${Typography.weights.regular};
  height: ${Spacing.spacing40};
  margin-bottom: ${Spacing.spacing8};
  width: 100%;

  a {
    align-items: center;
    color: ${GreyColors.smoke40};
    display: flex;
    gap: ${Spacing.spacing16};
    justify-content: space-between;
    padding: ${Spacing.spacing12} 0 ${Spacing.spacing12} ${Spacing.spacing14};
    text-decoration: none;
  }
  a:hover {
    font-weight: ${Typography.weights.medium};
  }
  a.active {
    color: ${UIColors.blue};
    font-weight: ${Typography.weights.bold};
  }

  span {
    flex-grow: 1;
  }

  .rotatable-icon {
    transition: transform 0.5s ease;
    transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;
const FeatureSectionAdditional = ({ category, mainMenuFeatures, onMenuFeatureClick, }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { "$isOpen": isOpen },
            React.createElement("a", { onClick: () => setIsOpen(!isOpen) },
                React.createElement(IconContainer, null,
                    React.createElement(Icon, { icon: "lock" })),
                React.createElement("span", null, category),
                React.createElement(IconContainer, null,
                    React.createElement(Icon, { className: "rotatable-icon", icon: "chevronDown" })))),
        isOpen && (React.createElement(React.Fragment, null, mainMenuFeatures.map((mainMenuFeature) => (React.createElement(MainMenuSharedListItem, Object.assign({}, mainMenuFeature, { key: `${category}${mainMenuFeature.id}`, onClick: onMenuFeatureClick }))))))));
};
export { FeatureSectionAdditional };
