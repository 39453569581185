import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const ImageryBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Start a free trial" : "Upgrade Plan",
        secondaryText: showActivateBusinessTrial
            ? "Satellite Imagery is included in the Essentials and Business plans and you can try it for free."
            : "Satellite Imagery requires a paid subscription. Included in the Essentials or Business plan.",
        imagePath: "images/pay_gates/banners/imagery.svg",
        title: "With Satellite Imagery you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Receive 10 meter resolution images delivered to your account approximately every week."),
                React.createElement(ValuePropListItem, null, "Toggle between true color and NDVI images."),
                React.createElement(ValuePropListItem, null, "Identify yield threats and focus scouting efforts on areas showing signs of stress.")))));
};
export { ImageryBlankSlateBanner };
