import { ThemeProvider, AccountLockedCard } from "@bushelpowered/wallet-components";
import useDocumentTitle from "hooks/useDocumentTitle";
import React from "react";
const WalletAccountLockedPage = () => {
    useDocumentTitle("Account Locked");
    return (React.createElement("div", null,
        React.createElement("h1", null, "Wallet Account Locked"),
        React.createElement(ThemeProvider, null,
            React.createElement(AccountLockedCard, null))));
};
export { WalletAccountLockedPage };
