import { pick } from "lodash";

/**
 * @param {OptionsContract} contract
 * @private
 * @return {OptionsContractInput}
 */
const buildOptionsContractInput = (contract) => {
  const optionsContractInput = pick(contract, [
    "contractDate",
    "contractStatus",
    "currentMarketPremium",
    "enabled",
    "expirationDate",
    "fees",
    "marketedCropId",
    "notes",
    "premium",
    "quantity",
    "quantityString",
    "revenueImpact",
    "strikePrice",
  ]);

  optionsContractInput.contractType = contract.contractSubtype;
  optionsContractInput.underlying = contract.underlying.symbol;

  return optionsContractInput;
};

export { buildOptionsContractInput };
