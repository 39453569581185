import React, { useState } from "react";
import AccountDeactivationConfirmation from "modules/settings/components/AccountDeletion/AccountDeactivationConfirmation";
import AccountDeactivationModal from "modules/settings/components/AccountDeletion/AccountDeactivationModal";
import { useAuth } from "collection/graphql/auth";
import useAccountDeactivation from "collection/graphql/users/useAccountDeactivation";
import useSubscriptionPlatform from "collection/graphql/subscription/useSubscriptionPlatform";
import { Button } from "components/fl-ui";
import LoadingDialog from "components/fl-ui/LoadingDialog";
import styled from "styled-components";
import App from "layout/app";
const DeactivateUserButton = styled(({ className }) => {
    const deactivateAccount = useAccountDeactivation();
    const platform = useSubscriptionPlatform();
    const { currentUser } = useAuth();
    const [activeModal, setActiveModal] = useState(null);
    const onConfirm = async () => {
        return await deactivateAccount().then((res) => {
            setActiveModal("COMPLETE");
        }).catch((err) => {
            setActiveModal(null);
            App.notify("Unable to deactivate enterprise. Please contact support@bushelfarm.com");
        });
    };
    const handleClose = () => setActiveModal(null);
    const handleConfirm = async () => {
        setActiveModal("LOAD");
        await onConfirm();
    };
    return (React.createElement("div", { className: className },
        React.createElement(Button, { color: "danger", hollow: true, onClick: () => setActiveModal("CONFIRM") }, "Deactivate Account"),
        activeModal === "CONFIRM" && (React.createElement(AccountDeactivationModal, { onClose: handleClose, onConfirm: handleConfirm, platform: platform, role: currentUser === null || currentUser === void 0 ? void 0 : currentUser.role })),
        activeModal === "LOAD" && React.createElement(LoadingDialog, { dialogMessage: "Processing request" }),
        activeModal === "COMPLETE" && React.createElement(AccountDeactivationConfirmation, { platform: platform, role: currentUser === null || currentUser === void 0 ? void 0 : currentUser.role })));
}) `
  float: left;
`;
export default DeactivateUserButton;
