import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useSubscriptionMutations from "collection/graphql/subscription/hooks/useSubscriptionMutations";
import useWindow from "hooks/useWindow";
import { trackSubscriptionBusinessTrialClose, trackSubscriptionBusinessTrialPresentation, trackSubscriptionBusinessTrialStart, } from "lib/metrics/events/trackEvents";
import OrderErrorDialog from "modules/billing/components/dialogs/OrderErrorDialog";
import { FullPageOverlay } from "modules/billing/layouts/FullPageOverlay";
import Button from "components/fl-ui/Button";
import { Logo } from "components/fl-ui/Icons";
import LoadingDialog from "components/fl-ui/LoadingDialog";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const CenteredWrapper = styled.div `
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.spacing24};
  justify-content: center;
  margin-top: ${Spacing.spacing48};
  padding: ${Spacing.spacing16};
`;
const TitleText = styled.h1 `
  color: ${GreyColors.smoke40};
  font-style: normal;
  font-size: 20px;
  font-weight: ${Typography.weights.medium};
  line-height: ${Spacing.spacing28};
  text-align: center;
`;
const MessageText = styled.p `
  color: ${GreyColors.smoke40};
  font-style: normal;
  font-size: 18px;
  font-weight: ${Typography.weights.regular};
  text-align: center;
`;
const DisclaimerText = styled.p `
  color: ${GreyColors.smoke40};
  font-style: italic;
  font-size: 13px;
  font-weight: ${Typography.weights.light};
  text-align: center;
`;
const ActivateBusinessTrial = () => {
    const [dialogId, setDialogId] = useState(null);
    const [saveError, setSaveError] = useState(null);
    const closeDialog = () => {
        setSaveError(null);
        setDialogId(null);
    };
    const win = useWindow();
    const navigate = useNavigate();
    const onCancel = () => {
        trackSubscriptionBusinessTrialClose();
        navigate("/fields", { replace: true });
    };
    const { createBusinessTrial } = useSubscriptionMutations();
    const handleOnClick = async () => {
        try {
            setDialogId("processing");
            await createBusinessTrial();
            onSuccess();
        }
        catch (error) {
            if (error instanceof Error) {
                setSaveError(error);
            }
            setDialogId("error");
        }
    };
    const onSuccess = () => {
        trackSubscriptionBusinessTrialStart();
        win.location = "/";
    };
    useEffect(() => {
        trackSubscriptionBusinessTrialPresentation();
    }, []);
    return (React.createElement(FullPageOverlay, { onCancel: onCancel },
        React.createElement(CenteredWrapper, null,
            React.createElement(Logo, null),
            React.createElement(TitleText, null, "Start your 30-day free trial of our Business Plan"),
            React.createElement(MessageText, null, "No credit card required."),
            React.createElement("img", { alt: "Keys", src: "images/trial/keys.png" }),
            React.createElement(Button, { color: "primary", onClick: handleOnClick, size: "md" }, "Activate free trial"),
            React.createElement(DisclaimerText, null, "Satellite Imagery and Machine Data features are available only with paid subscriptions.")),
        dialogId === "processing" && React.createElement(LoadingDialog, { dialogMessage: "Activating trial..." }),
        dialogId === "error" && (React.createElement(OrderErrorDialog, { actionType: "save", error: saveError !== null && saveError !== void 0 ? saveError : Error("Unknown Error"), onClose: closeDialog }))));
};
export default ActivateBusinessTrial;
