import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const LocalPricesBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Local Prices for free" : "Move to Essentials today",
        imagePath: "images/pay_gates/banners/local_prices.png",
        title: "With Local Prices you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Find where you can get the best price for your crop."),
                React.createElement(ValuePropListItem, null, "Know the optimal time to lock in the basis with your buyers."),
                React.createElement(ValuePropListItem, null, "Sort bids based on price and distance."),
                React.createElement(ValuePropListItem, null, "Click any location to see all posted bids.")))));
};
export { LocalPricesBlankSlateBanner };
