import { StyleSheet } from "aphrodite/no-important";

import { UIColors, GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const inputStyles = StyleSheet.create({
  blueText: {
    color: UIColors.blue,
  },
  input: {
    border: "none !important",
    margin: "0 !important",
    paddingLeft: `${Spacing.spacing16} !important`,
    paddingRight: `${Spacing.spacing16} !important`,
    width: "100%",
    outline: "none !important",
  },
  input_disabled: {
    backgroundColor: `${GreyColors.smoke96} !important`,
    color: `${UIColors.regular} !important`,
    opacity: 0.65,
  },
  suffixDropdownButton: {
    marginRight: "-0.625rem",
    minWidth: Spacing.spacing48,
    padding: Spacing.spacing4,
  },
  suffixDropdownList: {
    display: "flex",
    fontWeight: Typography.weights.regular,
    padding: `0 ${Spacing.spacing4} ${Spacing.spacing8}`,
  },
});

export default inputStyles;
