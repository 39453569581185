import FieldCropChooserFooter from "activity/components/choosers/FieldCropChooser/FieldCropChooserFooter";
import FieldCropGroupOption from "activity/components/choosers/FieldCropChooser/FieldCropGroupOption";
import { CropOption } from "activity/components/choosers/FieldCropChooser/FieldCropOptionBase";
import SearchInput from "activity/components/choosers/FieldCropChooser/SearchInput";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";

import useViewType from "hooks/useViewType";

import Tabs, { Tab } from "components/fl-ui/Tabs";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing, Typography } from "components/fl-ui/constants";

const Header = styled.div`
  border-bottom: 1px solid ${UIColors.lightest};
  box-shadow: 0 4px 10px ${UIColors.shadowOne};
  margin-bottom: 5px;
`;

const Menu = styled.div`
  border-radius: ${BorderRadius.medium};
  box-shadow:
    rgba(0, 0, 0, 0.1) 0 0 0 1px,
    rgba(0, 0, 0, 0.1) 0 4px 11px;
  margin-top: 8px;
`;

const MenuBody = styled.div`
  ${(props) => props.maxHeightInPx > 0 && `max-height: ${props.maxHeightInPx}px`}
  overflow-y: scroll;
`;

const MenuTabs = styled((props) => <Tabs {...props} />)`
  padding: 5px ${Spacing.spacing12};
  > .active {
    font-weight: ${Typography.weights.bold};
  }
  > .tab + .tab {
    margin-left: 10px;
  }
`;

const NoResults = styled.div`
  padding: ${Spacing.spacing12};
`;

const FieldCropDropdownMenu = (props) => {
  const {
    menuControl,
    onAddFieldCrops,
    onClose,
    onCreateFieldCrop,
    onRemoveFieldCrops,
    options,
    optionType,
    search,
    setOptionType,
    singleSelect,
    value,
  } = props;
  const hasOptions = _.size(options) > 0;

  // tabs
  const showGroups = optionType === "fields";
  const isMobile = useViewType() === "mobile";

  // menu height
  const heightBreakpoint = [570, 670, 812].find((breakpoint) => window.innerHeight <= breakpoint);
  const maxMenuHeight = useMemo(() => {
    /*
     * this adjustment amount is roughly the distance between the top of the control and
     * the top of the control and the top of the viewport on mobile screens.
     */
    const adjustment = isMobile ? 220 : 465;
    return heightBreakpoint > 0 ? heightBreakpoint - adjustment : window.innerHeight - adjustment;
  }, [heightBreakpoint]);

  return (
    <Menu ref={menuControl.menuContainerRef}>
      <Header>
        {!singleSelect && (
          <MenuTabs onChange={setOptionType} value={optionType}>
            <Tab value="fields">Fields</Tab>
            <Tab value="fieldCrops">Crops</Tab>
          </MenuTabs>
        )}

        {search && <SearchInput control={search} />}
      </Header>

      <MenuBody maxHeightInPx={maxMenuHeight}>
        {!hasOptions && <NoResults>No matching fields or crops were found.</NoResults>}

        {hasOptions &&
          showGroups &&
          _.map(options, (option, i) => {
            const showSelectAll = !singleSelect && option.containsCrops;

            return (
              <FieldCropGroupOption
                key={`groupOption-${i}`}
                option={option}
                onAddFieldCrops={onAddFieldCrops}
                onCreateFieldCrop={onCreateFieldCrop}
                onRemoveFieldCrops={onRemoveFieldCrops}
                search={search}
                showSelectAll={showSelectAll}
                value={value}
              />
            );
          })}

        {!showGroups &&
          _.map(options, ({ option }, i) => (
            <CropOption
              key={`commodity-${option.commodity.id}-${i}`}
              option={option}
              onSelect={onAddFieldCrops}
              search={search}
            />
          ))}
      </MenuBody>

      <FieldCropChooserFooter onCreateCrop={onCreateFieldCrop} onClose={onClose} />
    </Menu>
  );
};

FieldCropDropdownMenu.propTypes = {
  menuControl: PropTypes.object.isRequired,
  onAddFieldCrops: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateFieldCrop: PropTypes.func.isRequired,
  onRemoveFieldCrops: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionType: PropTypes.oneOf(["fields", "fieldCrops"]).isRequired,
  setOptionType: PropTypes.func.isRequired,
  singleSelect: PropTypes.bool,
  value: PropTypes.array.isRequired,
};

FieldCropDropdownMenu.defaultProps = {
  singleSelect: false,
};

export default FieldCropDropdownMenu;
