import { css } from "aphrodite/no-important";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-styled-flexboxgrid";
import { ONBOARDING_START_TRIAL } from "lib/metrics/events";
import OnboardingFooterHelp from "modules/fields/onboarding/OnboardingFooterHelp";
import styles from "modules/fields/onboarding/onboardingStyles";
import { Button } from "components/fl-ui";
const OnboardingDashboardMore = () => {
    const navigate = useNavigate();
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { className: css(styles.centeredContainer), xs: true },
                React.createElement("img", { alt: "Record keeping", src: "images/onboarding/record-keeping.png" }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: true },
                React.createElement("div", { className: css(styles.banner) },
                    React.createElement("h2", { className: css(styles.bannerHeading) }, "Stay organized with digital records"),
                    React.createElement("p", { className: css(styles.onboardingText) }, "Easily log all your field work and costs in one place to streamline reporting and analyze the profit and loss of your business"),
                    React.createElement("hr", { className: css(styles.bannerHr) })))),
        React.createElement(Row, { className: css(styles.rowPadding, styles.rowColumnReverseMobile) },
            React.createElement(Col, { xs: 12, sm: 12, md: 6 },
                React.createElement("div", { className: css(styles.sideBanner, styles.columnMobile) },
                    React.createElement("h2", { className: css(styles.bannerHeading) }, "Be confident in your marketing decisions"),
                    React.createElement("p", { className: css(styles.onboardingText, styles.sideBannerText) }, "Know the value of your unsold bushels as prices move and easily track crop sales, hedges, and overall marketing position against your goals and breakeven."))),
            React.createElement(Col, { className: css(styles.centeredContainer), xs: 12, sm: 12, md: 6 },
                React.createElement("img", { alt: "Preview Marketing", src: "images/onboarding/onboarding-preview-marketing-card.png" }))),
        React.createElement(Row, { className: css(styles.rowPadding, styles.columnMobile) },
            React.createElement(Col, { className: css(styles.centeredContainer), xs: 12, sm: 12, md: 6 },
                React.createElement("img", { alt: "Stay organized with activities", height: "510", src: "images/pay_gates/banners/activities.svg", width: "343" })),
            React.createElement(Col, { xs: 12, sm: 12, md: 6 },
                React.createElement("div", { className: css(styles.sideBanner, styles.columnMobile) },
                    React.createElement("h2", { className: css(styles.bannerHeading) }, "Stay Organized with Digital Records"),
                    React.createElement("p", { className: css(styles.onboardingText, styles.sideBannerText) }, "Easily log and review all your field work in one place to keep your records organized, secure, and accessible from any desktop or mobile device.")))),
        React.createElement(Row, { className: css(styles.rowPadding, styles.rowColumnReverseMobile) },
            React.createElement(Col, { xs: 12, sm: 12, md: 6 },
                React.createElement("div", { className: css(styles.sideBanner, styles.columnMobile) },
                    React.createElement("h2", { className: css(styles.bannerHeading) }, `Discover problems before it's too late`),
                    React.createElement("p", { className: css(styles.onboardingText, styles.sideBannerText) }, "Get in-season satellite imagery with NDVI, infrared, and true color layers to see crop conditions throughout the season."))),
            React.createElement(Col, { className: css(styles.centeredContainer), xs: 12, sm: 12, md: 6 },
                React.createElement("img", { alt: "Imagery", src: "images/pay_gates/banners/imagery.svg" }))),
        React.createElement("hr", { className: css(styles.bannerHr) }),
        React.createElement(Row, null,
            React.createElement(Col, { xs: true },
                React.createElement("div", { className: css(styles.banner) },
                    React.createElement("h3", { className: css(styles.bannerHeading) }, "Get started with Bushel Farm today"),
                    React.createElement("div", { className: css(styles.buttonGroupContainer) },
                        React.createElement(Button, { className: css(styles.button), color: "primary", display: "inline", onClick: () => {
                                ONBOARDING_START_TRIAL.track({});
                                navigate("/billing");
                            }, size: "lg" }, "Start a free trial now")),
                    React.createElement(OnboardingFooterHelp, null))))));
};
export default OnboardingDashboardMore;
