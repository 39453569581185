import styled from "styled-components";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const GroupHeaderListItem = styled.li `
  color: ${UIColors.darkBlue};
  font-size: ${Typography.sizes.md};
  font-weight: ${Typography.weights.bold};
  padding: ${Spacing.spacing16};
  text-transform: uppercase;
`;
export { GroupHeaderListItem };
