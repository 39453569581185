import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";

import defaultCache from "collection/graphql/cache/defaultCache";
import { trackingCallback } from "collection/graphql/client/common";
import { requiresFeatureDirective } from "collection/graphql/client/directives/requiresFeatureDirective";
import { requiresPermissionDirective } from "collection/graphql/client/directives/requiresPermissionDirective";
import { authLink } from "collection/graphql/client/links/authLink";
import errorLink from "collection/graphql/client/links/errorLink";
import { restLink } from "collection/graphql/client/links/restLink";

if (process.env.APOLLO_SHOW_ERRORS === "yes") {
  loadDevMessages();
  loadErrorMessages();
}

const headers = {};
headers["X-Client-Version"] = process.env.CLIENT_VERSION || "dev";

const additionalInfoLink = setContext(({ operationName, variables: { caller } }, { headers }) => ({
  headers: {
    ...headers,
    "X-Page-Hash": location.hash,
  },
}));

export default new ApolloClient({
  assumeImmutableResults: true,
  cache: defaultCache,
  documentTransform: requiresFeatureDirective.concat(requiresPermissionDirective),
  link: ApolloLink.from([
    additionalInfoLink,
    trackingCallback,
    authLink,
    errorLink,

    restLink,

    new HttpLink({
      uri: "/v2.0/grain-marketing/graphql",
      credentials: "same-origin", // allow auth cookies to pass through
      headers,
    }),
  ]),
});
