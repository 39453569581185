import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";

import { Borders, Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  formFooter_container: {
    width: "100%",
    borderTop: Borders.regular,
    padding: `${Spacing.spacing24} 0`,
    marginTop: Spacing.spacing24,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  formFooter_child: {
    marginRight: `${Spacing.spacing8} !important`,
    ":last-child": {
      marginRight: `0 !important`,
    },
  },
});

const FormFooter = ({ children, ...props }) => {
  return (
    <div className={css(styles.formFooter_container)}>
      {React.Children.map(children || null, (child, i) => {
        return <child.type {...child.props} key={i} className={css(styles.formFooter_child)} />;
      })}
    </div>
  );
};

export default FormFooter;
