import { css } from "aphrodite/no-important";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "collection/graphql/auth";
import useTrialStatus from "hooks/useTrialStatus";
import styles from "components/advertisements/styles";
import Button from "components/fl-ui/Button";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const PayGateAdBanner = ({ customActionText, customBodyText, customTitleText, featureName, isOverAcreLimit = false, planIncludedIn, }) => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const currentUser = useAuth().currentUser;
    const email = currentUser === null || currentUser === void 0 ? void 0 : currentUser.email;
    const firstName = currentUser === null || currentUser === void 0 ? void 0 : currentUser.firstName;
    const lastName = currentUser === null || currentUser === void 0 ? void 0 : currentUser.lastName;
    const phone = currentUser === null || currentUser === void 0 ? void 0 : currentUser.phone;
    const getTitleText = () => {
        if (customTitleText) {
            return customTitleText;
        }
        if (showActivateBusinessTrial) {
            return "Continue exploring Bushel Farm with a free trial";
        }
        return "Get Back to Smarter Farm Management";
    };
    const getBodyText = () => {
        if (customBodyText) {
            return customBodyText;
        }
        if (showActivateBusinessTrial) {
            return "You’ve added your first field, now unlock the power of Bushel Farm for your entire farm by starting your free trial. Select the Business plan to try all features.";
        }
        return "Bushel Farm helps you track, manage, and analyze your farm’s data—all in one place. Know your cost of production, monitor grain marketing positions, track input and equipment expenses, and keep detailed field records to make informed decisions. Whether you're managing land agreements or logging fieldwork, Bushel Farm gives you the tools to run a more profitable and efficient farm. Explore our plans to find the one that fits your farm’s needs.";
    };
    const getButtonText = () => {
        if (customActionText) {
            return customActionText;
        }
        if (showActivateBusinessTrial && featureName) {
            return `Try ${featureName} for free`;
        }
        if (showActivateBusinessTrial) {
            return "Start a free trial";
        }
        if (planIncludedIn) {
            return `Move to ${planIncludedIn} today`;
        }
        return "Upgrade Plan";
    };
    const navigate = useNavigate();
    const handleClick = (event) => {
        event.preventDefault();
        if (isOverAcreLimit) {
            window.open(`https://bushelfarm.as.me/schedule.php?firstName=${firstName}&lastName=${lastName}&phone=${phone}&email=${email}`, "_blank", "noopener,noreferrer");
        }
        else {
            navigate("/billing");
        }
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement("div", { className: css(styles.banner_container_fullbleed) },
            React.createElement("div", { className: css(styles.banner_content) },
                React.createElement("div", { className: css(styles.banner_textContainer, styles.banner_textContainer_sm) },
                    React.createElement("h1", { className: css(styles.promo_h2, styles.centered_on_mobile) }, getTitleText()),
                    React.createElement("p", { className: css(styles.banner_content_item, styles.centered_on_mobile) }, getBodyText()),
                    React.createElement(Button, { className: css(styles.promo_cta, styles.centered_on_mobile), color: "primary", onClick: handleClick }, getButtonText()))))));
};
export default PayGateAdBanner;
