import { StyleSheet } from "aphrodite";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Card } from "components/fl-ui/Card";
import { Checkbox } from "components/fl-ui/Form";
import { FormGroup, RadioGroup } from "components/fl-ui/Form";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";

export default StyleSheet.create({
  boldVerticalStack: {
    display: "flex",
    flexFlow: "column",
    fontWeight: Typography.weights.medium,
    marginLeft: "0",
    whiteSpace: "nowrap",
  },
  cancelIcon: {
    color: UIColors.danger,
    margin: `${Spacing.spacing4} ${Spacing.spacing4} ${Spacing.spacing4} 0`,
    paddingLeft: Spacing.spacing4,
    width: Spacing.spacing20,
  },
  centerAligned: {
    alignItems: "center",
    display: "flex",
  },
  cropSummaryCard: {
    background: UIColors.white,
    borderRadius: 0,
    borderTop: "none",
    padding: Spacing.spacing16,
    ":first-child": {
      borderBottom: Borders.regular,
      borderTopLeftRadius: BorderRadius.large,
      borderTopRightRadius: BorderRadius.large,
      borderTop: Borders.regular,
    },
    ":last-child": {
      borderBottom: Borders.regular,
      borderBottomLeftRadius: BorderRadius.large,
      borderBottomRightRadius: BorderRadius.large,
    },
    "@media only screen and (min-width: 62.5em)": {
      padding: Spacing.spacing12,
    },
  },
  cropSummaryContainer: {
    marginTop: Spacing.spacing12,
  },
  cropSummaryHeaderName: {
    fontSize: Typography.sizes.lg,
    marginTop: Spacing.spacing4,
  },
  cropSummaryHeaderNumber: {
    fontSize: Typography.sizes.rg,
    marginBottom: Spacing.spacing4,
  },
  cropSummaryHeaderSubtext: {
    color: UIColors.light,
    fontSize: Typography.sizes.sm,
    textTransform: "uppercase",
    ":first-child": {
      marginBottom: Spacing.spacing4,
    },
    ":last-child": {
      marginTop: Spacing.spacing4,
    },
  },
  editIcon: {
    color: UIColors.primary,
    marginLeft: Spacing.spacing8,
    width: Spacing.spacing20,
    "@media print": {
      display: "none",
    },
  },
  helpIcon: {
    marginLeft: Spacing.spacing4,
    width: Spacing.spacing16,
  },
  inlineEditContainer: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "flex-end",
  },
  inputIconContainer: {
    border: Borders.regular,
    borderRadius: BorderRadius.regular,
    display: "flex",
    marginLeft: Spacing.spacing8,
    marginRight: "-0.625rem",
    padding: Spacing.spacing4,
  },
  leftAlignedText: {
    textAlign: "left",
  },
  lightText: {
    color: UIColors.light,
  },
  lineHeight: {
    lineHeight: 1.3,
  },
  link: {
    color: UIColors.primary,
  },
  metricContainer: {
    borderRight: Borders.regular,
    marginRight: 0,
  },
  noResultsContainer: {
    lineHeight: 1.5,
    margin: "auto",
    maxWidth: 560,
    paddingTop: 100,
    textAlign: "center",
  },
  noResultsIcon: {
    color: UIColors.lightest,
    display: "inline-block",
    height: 68,
    textAlign: "center",
    width: 72,
  },
  normalFont: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
  },
  numericColumn: {
    maxWidth: "100px",
    textAlign: "right",
  },
  optionContainer: {
    borderTop: Borders.regular,
    ":first-child": {
      borderTop: "none",
    },
    ":last-of-type": {
      borderBottom: Borders.regular,
    },
  },
  optionSelect: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: Spacing.spacing16,
  },
  optionSelectBlankslate: {
    marginTop: Spacing.spacing16,
    textAlign: "center",
  },
  pointer: {
    ":hover": {
      cursor: "pointer",
      background: "rgb(249, 252, 255)",
    },
  },
  rightAlignedContainer: {
    margin: `0 ${Spacing.spacing16} 0 0`,
    textAlign: "right",
  },
  rightAlignedText: {
    textAlign: "right",
  },
  rightMargin: {
    marginRight: Spacing.spacing16,
  },
  saveIcon: {
    borderRight: Borders.regular,
    color: UIColors.primary,
    margin: `${Spacing.spacing4} 0 ${Spacing.spacing4} ${Spacing.spacing4}`,
    paddingRight: Spacing.spacing4,
    width: Spacing.spacing20,
  },
  smallMarginBottom: {
    marginBottom: Spacing.spacing4,
  },
  smallText: {
    fontSize: Typography.sizes.mn,
  },
  summaryTable: {
    borderCollapse: "collapse",
    marginBottom: "0",
  },
  tableData: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    padding: `${Spacing.spacing8} 0`,
    "@media only screen and (min-width: 62.5em)": {
      borderBottom: Borders.regular,
    },
  },
  tableHead: {
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.medium,
    padding: `${Spacing.spacing12} 0`,
    textTransform: "uppercase",
    "@media only screen and (min-width: 62.5em)": {
      borderBottom: Borders.regular,
    },
  },
  tableTitleRow: {
    display: "flex",
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    justifyContent: "space-between",
    padding: `${Spacing.spacing8} 0`,
    "@media only screen and (min-width: 62.5em)": {
      borderBottom: Borders.regular,
      paddingBottom: Spacing.spacing16,
    },
  },
  toggleButton: {
    marginLeft: 0,
    marginRight: "-0.75rem !important",
    padding: Spacing.spacing12,
  },
  toggleButtonIcon: {
    width: "auto !important",
  },
});

// Styled components
export const CardText = styled.p`
  color: ${UIColors.light};
  line-height: 1.3;
  margin-bottom: ${Spacing.spacing4}
  margin-top: ${Spacing.spacing4}
`;

export const CardHeading = styled(CardText)`
  font-size: ${Typography.sizes.sm};
  font-weight: ${Typography.weights.medium}
  text-transform: uppercase;
`;

export const Copy = styled.p`
  font-size: ${Typography.sizes.lg};
  line-height: 1.4;
  margin-bottom: ${Spacing.spacing24};

  @media only screen and (max-width: 62.5rem) {
    font-size: ${Typography.sizes.rg};
    line-height: 1.3;
  } ;
`;

export const Label = styled.label`
  align-items: center;
  display: flex;
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.medium}
  margin: ${Spacing.spacing8} 0;
`;

export const LeftButton = styled(Button)`
  &:not(:last-child) {
    margin-right: ${Spacing.spacing12};
  }
`;

export const NonPrintCheckBox = styled(Checkbox)`
  @media print {
    display: none;
  }
`;

export const Row = styled.tr`
  > td {
    ${(props) =>
      props.isSelected &&
      `
      background: rgb(249, 252, 255);
      border-color: rgb(123, 178, 234);
    `}
  }
`;

export const StyledCard = styled(Card)`
  box-shadow: 0 2px 4px 0 ${UIColors.shadowOne};
  margin-bottom: ${Spacing.spacing8};
`;

export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: ${Spacing.spacing24} !important;
`;

export const StyledLastFormGroup = styled(FormGroup)`
  margin-bottom: 0 !important;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  margin-bottom: ${Spacing.spacing24} !important;
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 0;

  td,
  th {
    padding: ${Spacing.spacing12};
    text-align: left;
    @media only screen and (min-width: 62.5em) {
      border-bottom: ${Borders.regular};
    }
    @media print {
      border-bottom: ${Borders.regular};
    }
  }
  td {
    font-size: ${Typography.sizes.rg};
    font-weight: ${Typography.weights.regular};
  }
  th {
    font-size: ${Typography.sizes.sm};
    font-weight: ${Typography.weights.medium};
    text-transform: uppercase;
  }
  th:last-child,
  td:last-child {
    text-align: right;
  }
`;

export const TableBody = styled.tbody`
  ${(props) => props.borderBottom && `border-bottom: ${Borders.regular}`};
  ${(props) => props.borderTop && `border-top: ${Borders.regular}`};
  ${(props) =>
    props.bordered &&
    `
    td, th {
      border-bottom: ${Borders.regular};
    };
  `}
`;
