import PropTypes from "prop-types";
import React from "react";
import BodyClassName from "react-body-classname";
import styled from "styled-components";

import PrimaryBannerMessage from "components/banners/PrimaryBannerMessage";
import usePrimaryBanner from "components/banners/usePrimaryBanner";
import CloseX from "components/fl-ui/Icons/CloseX";
import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import { ColorPalette } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const StyledBanner = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: ${ColorPalette["white"]};
  display: flex;
  justify-content: center;
  left: 0;
  padding: ${Spacing.spacing16};
  position: sticky;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 201;

  a {
    color: ${ColorPalette["white"]};
  }
  p {
    color: ${ColorPalette["white"]};
    font-size: ${Typography.sizes.mn};
    line-height: 1.5em;
    margin: 0;
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 14px;
  top: 14px;
`;

const PrimaryBanner = () => {
  const { backgroundColor, bannerProps, handleClose, showClose } = usePrimaryBanner();

  return !bannerProps ? null : (
    <ErrorBoundary fallback={() => {}}>
      <BodyClassName className="withPrimaryBanner">
        <StyledBanner className="primaryBanner" backgroundColor={backgroundColor}>
          <PrimaryBannerMessage {...bannerProps} />

          {showClose && (
            <CloseButtonContainer>
              <CloseX color={ColorPalette.white} onClick={handleClose} />
            </CloseButtonContainer>
          )}
        </StyledBanner>
      </BodyClassName>
    </ErrorBoundary>
  );
};

PrimaryBanner.propTypes = {
  onClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  showCloseButton: PropTypes.bool,
};

PrimaryBanner.defaultProps = {
  onClick: () => {},
  onCloseClick: () => {},
  showCloseButton: false,
};

export default PrimaryBanner;
