import React from "react";
import BinsCollection from "collection/bins";
import BuyersCollection from "collection/buyers";
import InventoryTransactionsCollection from "collection/inventory_transactions";
import useAsync from "hooks/useAsync";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const InventoryCollectionsLoader = ({ children }) => {
    const { loading } = useAsync(async () => {
        return await Promise.allSettled([
            BinsCollection.prime(),
            BuyersCollection.prime(),
            InventoryTransactionsCollection.prime(),
        ]);
    });
    return (React.createElement(LoadingWrapper, { isLoading: loading }, children));
};
export default InventoryCollectionsLoader;
