import { useMutation } from "@apollo/client";
import ProductInputChooser from "modules/activity/components/choosers/ProductInputChooser";
import React, { useState } from "react";
import { usePossibleMatches } from "modules/settings/pages/ProductAndMachineMatching/hooks/usePossibleMatches";
import styled from "styled-components";
import { SET_MACHINE_DATA_PRODUCT_MATCH } from "collection/graphql/integrations/mutations";
import App from "layout/app";
import { SETTINGS_PRODUCT_MATCH } from "lib/metrics/events";
import { Button } from "components/fl-ui";
import { Checkbox, FormGroup } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const ButtonContainer = styled.div `
  display: flex;
  gap: ${Spacing.spacing4};

  @media only screen and (min-width: 60rem) {
    justify-content: end;
    > :nth-child(1) {
      order: 3;
    }
    > :nth-child(2) {
      order: 2;
    }
    > :nth-child(3) {
      margin-right: auto;
      order: 1;
    }
  }
  @media only screen and (max-width: 60rem) {
    flex-direction: column;
  }
`;
const CheckboxLabel = styled.span `
  color: ${GreyColors.smoke30};
  font-size: ${Typography.sizes.mn};
`;
const ProductMatchModal = ({ onClose, onSave, productMatch }) => {
    var _a;
    const { id, matchedProduct, unitType } = productMatch;
    const [selection, setSelection] = useState(matchedProduct !== null && matchedProduct !== void 0 ? matchedProduct : null);
    const [rewriteHistory, setRewriteHistory] = useState(true);
    const [setProductMatch] = useMutation(SET_MACHINE_DATA_PRODUCT_MATCH);
    const possibleMatches = usePossibleMatches(id);
    const suggestedMatch = possibleMatches[0];
    const suggestedOptions = possibleMatches;
    const handleProductChange = (input) => setSelection(input);
    const handleSubmit = (event) => {
        event.preventDefault();
        save(selection !== null && selection !== void 0 ? selection : null);
    };
    const save = async (product) => {
        var _a;
        try {
            const payload = {
                matchId: id,
                productId: (_a = product === null || product === void 0 ? void 0 : product.id) !== null && _a !== void 0 ? _a : null,
                rewriteHistory,
            };
            await setProductMatch({ variables: payload });
            SETTINGS_PRODUCT_MATCH.track(payload);
            onSave();
        }
        catch (error) {
            console.error(error);
            App.notify("An error occurred, please try again later");
        }
    };
    return (React.createElement(Modal, { width: 500, id: undefined, hideModal: undefined },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Set Product Match"),
            React.createElement(CloseX, { onClick: onClose })),
        React.createElement(ModalBody, null,
            React.createElement("form", { id: "productMatchForm", onSubmit: handleSubmit },
                React.createElement(FormGroup, { label: "Unidentified Input", error: undefined, inputId: undefined, optional: undefined, tip: undefined },
                    React.createElement(Cluster, { between: true, around: undefined, center: undefined, end: undefined, even: undefined, start: undefined }, (_a = suggestedMatch === null || suggestedMatch === void 0 ? void 0 : suggestedMatch.name) !== null && _a !== void 0 ? _a : "--")),
                React.createElement(FormGroup, { label: "Select Matching Product", error: undefined, inputId: undefined, optional: undefined, tip: undefined },
                    React.createElement(ProductInputChooser, { onChange: handleProductChange, suggestedOptions: suggestedOptions, unitType: unitType, value: selection === null || selection === void 0 ? void 0 : selection.id, variant: "search" })),
                React.createElement(FormGroup, { error: undefined, inputId: undefined, label: undefined, optional: undefined, tip: undefined },
                    React.createElement(Checkbox, { checked: rewriteHistory, label: React.createElement(CheckboxLabel, null, "Rewrite previous instances with the match selected above."), name: "rewriteHistory", onChange: () => setRewriteHistory((prev) => !prev) })))),
        React.createElement(ModalFooter, null,
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { color: "primary", disabled: !selection, form: "productMatchForm", type: "submit" }, "Save Match"),
                React.createElement(Button, { onClick: onClose }, "Cancel"),
                matchedProduct && (React.createElement(Button, { color: "danger", hollow: true, onClick: () => save(null) }, "Delete Match"))))));
};
export { ProductMatchModal };
