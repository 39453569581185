import { StyleSheet } from "aphrodite";

import Variables from "components/fl-ui/constants/variables";

const beforeAfter = {
  content: '""',
  display: "block",
  position: "absolute",
  width: 0,
  height: 0,
};

const clearfix = {
  ":after": {
    content: '""',
    display: "block",
    clear: "both",
  },
};

const uiControl = {
  // UI CONTROL
  // an abstract mixin to style "things that are clicked"
  // specifically things that aren't text
  // useful for buttons, nav items, etc

  // prevent accidental text highlighting
  userSelect: "none",

  // make it look clickable
  cursor: "pointer",

  // don't show underlines on anchor tags
  textDecoration: "none",
  "&:hover": {
    textDecoration: "none",
  },

  // prevent browser focus outlines
  outline: "none",
  "&:focus": {
    outline: "none",
  },
};

const getOverrideStyles = (style) => {
  if (style) {
    return StyleSheet.create({ style }).style;
  }
  return "";
};

const toRem = (num) => {
  return `${num / Variables.baseRem}rem`;
};

//TODO: These should all just be refactored to Spacing.
// I don't understand why we are converting to rem all over the place.
// Doesn't actually fix a real problem.
const Mixins = {
  beforeAfter: beforeAfter,
  clearfix: clearfix,
  uiControl: uiControl,
  toRem: toRem,
  getOverrideStyles: getOverrideStyles,
};

export default Mixins;
