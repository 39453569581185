import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import React, { useState } from "react";
import styled from "styled-components";
import ButtonLink from "modules/fields/components/ButtonLink";
import AttachmentThumbnail from "components/field/AttachmentThumbnail";
import FilePopoverContainer from "components/field/FilePopoverContainer";
import { getSrcURL } from "components/field/utils";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";
const AttachmentDetailContainer = styled.div `
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  font-size: ${Typography.sizes.mn};
  justify-content: center;
  line-height: 1.3;
  margin-right: ${Spacing.spacing16};
  overflow: hidden;
`;
const AttachmentListItem = styled.div `
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: ${Spacing.spacing8} 0;
  width: 100%;

  > div {
    display: flex;
    overflow: hidden;
    max-width: 100%;
  }

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    border: ${Borders.regular};
    border-radius: ${BorderRadius.medium};
    margin: ${Spacing.spacing8} 0;
    padding: ${Spacing.spacing12};
  }
`;
const AttachmentItem = ({ file, isUpdating, selectedFile, selectFile, showPreview, updateAttachments, }) => {
    var _a;
    const { filepickerId, name } = file;
    const [hasPreview, setHasPreview] = useState(true);
    const handleError = ({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/images/app_icons/bushel-farm-icon-alt.svg";
        setHasPreview(false);
    };
    return (React.createElement(AttachmentListItem, null,
        React.createElement("div", null,
            React.createElement(AttachmentThumbnail, { isSelected: filepickerId === (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.filepickerId), onClick: selectFile, onError: handleError, src: getSrcURL(filepickerId) }),
            React.createElement(AttachmentDetailContainer, null,
                React.createElement(ButtonLink, { color: "primary", link: true, onClick: selectFile }, name),
                React.createElement("span", null, file.date),
                React.createElement("span", null, (_a = file.size) !== null && _a !== void 0 ? _a : "--"))),
        React.createElement(FilePopoverContainer, { file: file, handlePreview: () => showPreview(file), hasPreview: hasPreview, isUpdating: isUpdating, updateAttachments: updateAttachments })));
};
const AttachmentList = ({ fileList, isUpdating, selectedFile, setSelectedFile, showPreview, updateAttachments, }) => {
    const handlePreview = (file) => {
        setSelectedFile(file);
        showPreview();
    };
    return (React.createElement(React.Fragment, null, fileList.map((file) => (React.createElement(AttachmentItem, { file: file, isUpdating: isUpdating, key: file.filepickerId, selectedFile: selectedFile, selectFile: () => setSelectedFile(file), showPreview: handlePreview, updateAttachments: updateAttachments })))));
};
export default AttachmentList;
