import useDocumentTitle from "hooks/useDocumentTitle";
import React from "react";

import { useSSOContext } from "context/SSOContext";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import { SSOConfirmFarmNameForm } from "modules/sso/forms/SSOConfirmFarmNameForm.jsx";

const SSOConfirmFarmName = ({ onComplete }) => {
  const { selectedMembership } = useSSOContext();
  useDocumentTitle("Confirm Farm Name");

  return (
    <BaseSSOPageContainer title={`Welcome ${selectedMembership.user?.name}`}>
      <SSOConfirmFarmNameForm onComplete={onComplete} />
    </BaseSSOPageContainer>
  );
};

export { SSOConfirmFarmName };
