import Big from "big.js";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { number } from "yup";

import "lib/validation/yupPolyfill";

import Input from "components/fl-ui/Form/Input";

/**
 * A controlled input component for numeric values.
 */
const NumericInput = ({
  inputMode,
  max,
  maxPrecision,
  min,
  onBlur,
  onChange,
  onKeyDown,
  onPaste,
  render,
  step,
  type,
  ...props
}) => {
  if (type === "currency" && !("prefix" in props)) {
    props.prefix = "$";
  }

  const maxDecimals = useMemo(() => {
    /*
     * [MDN states](https://tinyurl.com/2xabjcyh) that the mantissa's precision should be about 15 to 17 decimal places.
     * Settling on 15.
     */
    const MAX_PRECISION = 15;
    const maxPrecisionSchema = number().required().integer().min(0).max(MAX_PRECISION);

    if (maxPrecisionSchema.isValidSync(maxPrecision)) {
      return +maxPrecision;
    } else if (type === "currency") {
      return 2;
    }

    return MAX_PRECISION;
  }, [maxPrecision, type]);

  /**
   * Hooks in to the beforeinput event to prevent certain changes.
   * @param {InputEvent} event
   */
  const handleBeforeInput = (event) => {
    /*
     * Uses the event.data attribute in concert with the current caret position (selection) to determine what the resulting
     * input value will be before this value is actually committed to the DOM.  Numeric inputs do not track selectionEnd or
     * selectionStart which is why we're now using an input of type="text" instead.
     */
    const { selectionEnd, selectionStart, value } = event.target;
    const characters = value.split("");
    characters.splice(selectionStart, selectionEnd - selectionStart, event.data);
    const resultingValue = characters.join("");

    // only allow numbers with the correct number of decimals and decimal places
    const decimalChunks = resultingValue.split(".");
    const allowedDecimals = type === "integer" ? 0 : 1;
    if (decimalChunks.length - 1 > allowedDecimals || (decimalChunks[1] || "").length > maxDecimals) {
      event.preventDefault();
    }

    // hyphen/minus sign normalization
    if (_.lastIndexOf(resultingValue.split(""), "-") > 0) {
      // prevent the change if any minus signs appear anywhere other than the first character
      event.preventDefault();
    } else if (min >= 0 && max >= 0 && resultingValue.split("-").length - 1 > 0) {
      // prevent the change if a negative numbers are not allowed
      event.preventDefault();
    }
  };

  /**
   * Normalizes the input value for certain special cases; namely when the value on blur is "-" or ends with a ".". Will fire
   * the onChange listener if either of these changes have been applied
   * @param {FocusEvent} event
   */
  const handleBlur = (event) => {
    const inputValue = event.target.value;
    const normalizedValue = inputValue === "" || Number.isNaN(+inputValue) ? "" : +inputValue + "";

    if (inputValue !== normalizedValue) {
      event.target.value = normalizedValue;
      onBlur(event);
      handleChange(event);
    } else {
      onBlur(event);
    }
  };

  /**
   * @param {FocusEvent|KeyboardEvent} event
   */
  const handleChange = (event) => {
    onChange(event);
  };

  /**
   * Prevents the change if certain key combinations are not detected; namely numeric keys and command keys.
   * @param {KeyboardEvent} event
   */
  const handleKeyDown = (event) => {
    if (["ArrowDown", "ArrowUp"].includes(event.key) && number().required().positive().isValidSync(step)) {
      event.preventDefault();
      const increment = event.key === "ArrowUp" ? step : step * -1;
      event.target.value = new Big(event.target.value || 0).add(increment).toNumber() + "";
      onKeyDown(event);
      handleChange(event);
    } else {
      onKeyDown(event);
    }
  };

  /**
   * @param {KeyboardEvent} event
   */
  const handleKeyPress = (event) => {
    const allowedKeys = Array.from(Array(10)).map((_, i) => i + "");
    allowedKeys.push("-");
    if (maxDecimals > 0 && type !== "integer") {
      allowedKeys.push(".");
    }
    const isAllowedKey = !event.metaKey && allowedKeys.includes(event.key);
    if (!isAllowedKey) {
      event.preventDefault();
    }
  };

  /**
   * @param {ClipboardEvent} event
   */
  const handlePaste = (event) => {
    const schema = (() => {
      switch (type) {
        case "currency":
          return number().currency().min(min).max(max);

        case "integer":
          return number().integer().min(min).max(max);

        default:
          return number().min(min).max(max);
      }
    })();

    const pastedValue = event.clipboardData.getData("text/plain");
    if (!schema.isValidSync(pastedValue)) {
      event.preventDefault();
    }
    onPaste(event);
  };

  return render({
    ...props,
    controlled: true,
    inputMode: inputMode ?? (props.type === "integer" ? "numeric" : "decimal"),
    onBeforeInput: handleBeforeInput,
    onBlur: handleBlur,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onKeyPress: handleKeyPress,
    onPaste: handlePaste,
    type: "text",
  });
};

NumericInput.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  id: PropTypes.string,
  inputMode: PropTypes.oneOf(["decimal", "numeric", "text"]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func,
  max: PropTypes.number,
  maxPrecision: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  render: PropTypes.func,
  step: PropTypes.number,
  style: PropTypes.object,
  suffix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.oneOf(["currency", "float", "integer"]),
  value: PropTypes.any,
};

NumericInput.defaultProps = {
  disabled: false,
  hasError: false,
  onBlur: () => {},
  onChange: () => {},
  onKeyDown: () => {},
  onPaste: () => {},
  render: Input,
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
  type: "float",
};

export default NumericInput;
