import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import { DeleteUserFormSchema } from "modules/settings/pages/Users/components/forms/DeleteUserFormSchema";
import { useCreateAndEditUser } from "modules/settings/pages/Users/hooks/useCreateAndEditUser";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form";
import Spacing from "components/fl-ui/constants/spacing";

const DeleteUserConfirmationForm = styled(({ className, onClose, user }) => {
  const methods = useForm({
    defaultValues: DeleteUserFormSchema.cast(
      {
        areYouSure: "",
      },
      { assert: false, stripUnknown: true },
    ),
    mode: "onTouched",
    resolver: yupResolver(DeleteUserFormSchema),
  });

  const { watch, formState } = methods;
  const confirmValue = watch("areYouSure");
  const { onSave } = useCreateAndEditUser();

  const removeUser = async () => {
    await onSave({
      id: user.id,
      enterpriseId: user.enterpriseId,
      role: "archive",
    });
  };

  return (
    <FormProvider {...methods} schema={DeleteUserFormSchema}>
      <div className={className}>
        <p>
          By removing a user from your account, previous records assigned to this person will no longer have a person
          assigned. To remove this user's access but still retain the records, change their access level to No Access
          instead of removing them.
        </p>

        <Form>
          <ControlledFormGroup
            name="areYouSure"
            render={({ field }) => <Input {...field} placeholder="Type REMOVE" />}
          />

          <div className="footer-buttons">
            <Button color="primary" link onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="remove-button"
              color="danger"
              disabled={confirmValue !== "REMOVE" || !_.isEmpty(formState.errors)}
              onClick={() => removeUser()}
            >
              Remove user
            </Button>
          </div>
        </Form>
      </div>
    </FormProvider>
  );
})`
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
    gap: ${Spacing.spacing20};
    margin-top: ${Spacing.spacing20};

    .remove-button {
      color: white;
      border: none;
      padding: ${Spacing.spacing8} ${Spacing.spacing16};
      cursor: pointer;
    }
  }
`;

export { DeleteUserConfirmationForm };
