import { useQuery } from "@apollo/client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "collection/graphql/auth";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import updateUser from "collection/graphql/users/mutations/updateUser";
import useDocumentTitle from "hooks/useDocumentTitle";
import useReleaseFeature from "hooks/useReleaseFeature";
import useRestMutation from "hooks/useRestMutation";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import { DeluxeSelector } from "components/fl-ui/DeluxeSelector";
const SSOWalletSurvey = () => {
    var _a, _b;
    useDocumentTitle("Wallet Survey");
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const hasWallet = useReleaseFeature("wallet-enabled");
    console.log({ currentUser, hasWallet });
    const [saveUser] = useRestMutation(updateUser, {
        refetchQueries: [GET_CURRENT_USER],
    });
    const { data: userMembership } = useQuery(getCurrentUserMembership);
    const role = (_b = (_a = userMembership === null || userMembership === void 0 ? void 0 : userMembership.currentMembership) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.name;
    const onClick = (route) => {
        saveUser({
            variables: {
                input: Object.assign(Object.assign({}, currentUser), { role, landingPage: route }),
            },
        }).then(() => {
            console.log("saved route... now navigating to SSO");
            navigate(`/${route}`);
        });
    };
    return (React.createElement(BaseSSOPageContainer, { title: "How can we help you get started with Bushel Farm?" },
        React.createElement(DeluxeSelector, { options: [
                {
                    key: "1",
                    icon: "wallet",
                    text: "I want to open an interest-bearing bank account and manage finances for my farm.",
                    onClick: () => onClick("wallet/dashboard/overview"),
                },
                {
                    key: "2",
                    icon: "equipment",
                    text: "I want to manage my on-farm activities and track rainfall.",
                    onClick: () => onClick("fields"),
                },
                {
                    key: "3",
                    icon: "landCosts",
                    text: "I want to manage both my farm and my finances.",
                    onClick: () => onClick("wallet/dashboard/overview"),
                },
            ] })));
};
export { SSOWalletSurvey };
