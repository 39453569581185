import { StyleSheet } from "aphrodite/no-important";

import { Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    backfaceVisibility: "hidden",
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    overflowY: "scroll",
    padding: Spacing.spacing32,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 4000,
  },
});

export default styles;
