import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const contractsStyles = StyleSheet.create({
  marginTopZero: {
    marginTop: 0,
  },
  centerAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formText: {
    textAlign: "center",
    marginBottom: 0,
  },
  helpText: {
    ...Typography.helpText,
    marginTop: Spacing.spacing8,
  },
  textArea: {
    fontWeight: Typography.weights.medium,
    fontSize: Typography.sizes.lg,
    lineHeight: 1.2,
    maxWidth: "100em",
  },
  detailsPanelEquationBar: {
    margin: `${Spacing.spacing8} 0px ${Spacing.spacing8} 50%`,
    background: UIColors.lightest,
  },
});

export default contractsStyles;
