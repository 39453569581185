import { PrimaryMaxWidth, SecondaryMaxWidth } from "constants/mobileBreakpointConstants";
import React from "react";
import styled from "styled-components";
import { SignUpDescriptionText } from "modules/wallet/components/sign_up/SignUpDescriptionText";
import { SignUpHeaderTitle } from "modules/wallet/components/sign_up/SignUpHeaderTitle";
import { WalletSignUpButton } from "modules/wallet/components/sign_up/WalletSignUpButton";
import { WalletSignUpSection } from "modules/wallet/components/sign_up/WalletSignUpSection";
import { CashYieldPercentageText } from "modules/wallet/constants";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const Subtitle = styled.h3 `
  font-size: ${Typography.medium};
  color: ${UIColors.darkGrey};
  margin-bottom: ${Spacing.spacing12};
`;
const WalletSignUpFirstSection = styled(({ className }) => (React.createElement(WalletSignUpSection, { backgroundColor: "white" },
    React.createElement("div", { className: className },
        React.createElement("div", { className: "left-half" },
            React.createElement("img", { className: "logo", src: "images/wallet/bushel-wallet-logo.svg", alt: "Bushel Wallet Logo" }),
            React.createElement(SignUpHeaderTitle, null, "Manage Your Farm and Finances for Improved Operations"),
            React.createElement(Subtitle, null, "Sign up for a Bushel Wallet account"),
            React.createElement(SignUpDescriptionText, null,
                "Streamline your farm management and finances with one seamless platform. Start earning",
                " ",
                CashYieldPercentageText,
                " on your cash balance today."),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(WalletSignUpButton, null),
            React.createElement("br", null)),
        React.createElement("div", { className: "right-half" },
            React.createElement("img", { className: "phone-image", src: "images/wallet/generic-wallet-phone-screen.png", alt: "Generic Phone Image" })))))) `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${Spacing.spacing20};
  max-width: ${SecondaryMaxWidth};
  margin: 0 auto;
  padding: ${Spacing.spacing24} 0;

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    flex-direction: column;
  }

  .left-half {
    flex: 0 0 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${Spacing.spacing24} ${Spacing.spacing48};

    @media only screen and (max-width: ${PrimaryMaxWidth}) {
      flex: none;
      width: 100%;
      padding: ${Spacing.spacing4} ${Spacing.spacing4};
    }
  }

  .right-half {
    flex: 0 0 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${Spacing.spacing24} ${Spacing.spacing48};

    @media only screen and (max-width: ${PrimaryMaxWidth}) {
      flex: none;
      width: 100%;
      padding: ${Spacing.spacing12} ${Spacing.spacing24};
    }
  }

  .logo {
    width: 260px;
    margin-bottom: ${Spacing.spacing12};
  }

  .phone-image {
    max-width: 100%;
    height: auto;
  }
`;
export { WalletSignUpFirstSection };
