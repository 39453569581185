import React from "react";
import styled from "styled-components";
import { border, color, compose, typography } from "styled-system";

import FileAttachmentCell from "components/fl-ui/common/FileAttachments/components/FileAttachmentCell";

const Container = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  text-decoration: none;
  }

  ${compose(border, color, typography)}
`;
Container.displayName = "AddFileCellContainer";

const CustomCell = ({ CustomContainer, ...props }) => (CustomContainer ? <CustomContainer {...props} /> : null);
const DefaultCell = ({ ...containerProps }) => (
  <FileAttachmentCell border="basic">
    <Container {...containerProps}>Attach files</Container>
  </FileAttachmentCell>
);

const AddFileCell = ({ accept, canAdd, children, setShowFilePicker }) => {
  const AttachmentCell = children ? CustomCell : DefaultCell;
  const customProps = {
    accept,
    CustomContainer: children,
    color: "blue",
    fontSize: "md",
    fontWeight: "bold",
    href: "#",
    onClick: (event) => {
      event.preventDefault();
      setShowFilePicker(true);
    },
  };

  return !canAdd ? null : <AttachmentCell {...customProps} />;
};

export default AddFileCell;
