import { useQuery } from "@apollo/client";
import { filter, maxBy } from "lodash";
import moment from "moment";

import { GET_FIELD, getPlantedCropsForField } from "collection/graphql/fields/queries";

const useWeatherQueryOptions = (fieldId, cropYear) => {
  const { data: fieldData, loading: centroidLoading } = useQuery(GET_FIELD, { variables: { fieldId } });
  const geometry = JSON.stringify(fieldData?.field?.centroid || {});

  const { data: crops, loading: cropLoading } = useQuery(getPlantedCropsForField, { variables: { cropYear, fieldId } });
  const relevantCrops = filter(crops?.plantedCrops, ({ plantingDate }) =>
    moment(plantingDate).isSameOrBefore(new Date(), "day"),
  );
  const lastPlantedCrop = maxBy(relevantCrops, "plantingDate");
  const startDate = lastPlantedCrop?.plantingDate ?? `${cropYear}-01-01`;

  return {
    geometry,
    lastPlantedCrop,
    loading: cropLoading || centroidLoading,
    endDate: `${cropYear}-12-31`,
    startDate: moment(startDate).format("YYYY-MM-DD"),
  };
};

export default useWeatherQueryOptions;
