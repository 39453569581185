import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/fl-ui";
import { BlankSlate } from "components/fl-ui/BlankSlate";
const ProductDetailsBlankSlate = ({ cropYear }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/products");
    };
    return (React.createElement(BlankSlate, { icon: "inputs" },
        React.createElement("p", { style: { margin: "20px auto" } },
            "No purchases or activities referencing this product were found in ",
            cropYear,
            "."),
        React.createElement(Button, { color: "primary", onClick: handleClick }, "View all purchases")));
};
export default ProductDetailsBlankSlate;
