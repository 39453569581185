import { some } from "lodash";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import useDocumentTitle from "hooks/useDocumentTitle";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useIntegrations from "hooks/useIntegrations";
import usePermissions from "hooks/usePermissions";
import { GenericStyledText } from "modules/settings/GenericStyledText";
import { BushelIntegration } from "modules/settings/components/Integrations/components/BushelIntegration";
import { CNHIndustrialIntegration } from "modules/settings/components/Integrations/components/CNHIndustrialIntegration";
import { ClimateFieldViewIntegration } from "modules/settings/components/Integrations/components/ClimateFieldViewIntegration";
import { JohnDeereIntegration } from "modules/settings/components/Integrations/components/JohnDeereIntegration";
import { ProductAndMachineMatching } from "modules/settings/pages/ProductAndMachineMatching/ProductAndMachineMatching";
import { ConnectionsBlankSlateBanner } from "components/advertisements/banners/ConnectionsBlankSlateBanner";
import { Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";
const Container = styled.div `
  align-items: center;
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.spacing16};
  padding-bottom: ${Spacing.spacing16};
  ${({ bordered }) => bordered &&
    `
    border: ${Borders.regular};
    border-radius: ${BorderRadius.medium};
    padding: ${Spacing.spacing16};
  `}

  @media only screen and (max-width: 62.5rem) {
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;
  }
`;
const ResponsiveHeading = styled.h2 `
  font-size: ${Typography.sizes.lg};
  text-transform: capitalize;
  @media only screen and (min-width: 62.5rem) {
    font-size: ${Typography.sizes.xl};
  }
`;
const ConnectionsSettingsPage = () => {
    var _a;
    useDocumentTitle("Connections");
    const hasThirdPartyIntegrationsFeature = useEnterpriseFeature("third_party_integrations");
    const canReadIntegrations = usePermissions().canRead("integrations");
    const knownIntegrations = new Map([
        ["bushel", BushelIntegration],
        ["climate", ClimateFieldViewIntegration],
        ["cnhi", CNHIndustrialIntegration],
        ["myjohndeere", JohnDeereIntegration],
    ]);
    const { integrations, loading } = useIntegrations();
    const hasConfiguredIntegrations = some(integrations, "enabled");
    if (!canReadIntegrations) {
        return React.createElement(RestrictedAccessBlankSlate, null);
    }
    return (React.createElement(Stack, { space: Spacing.spacing32 },
        React.createElement("div", null,
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement(Container, null,
                        React.createElement("div", null,
                            React.createElement(ResponsiveHeading, null, "Connections"),
                            React.createElement(GenericStyledText, null, "Connect your Bushel Farm account to other systems for ongoing data synchronization via API connection."))))),
            React.createElement(LoadingWrapper, { isLoading: loading }, (_a = integrations === null || integrations === void 0 ? void 0 : integrations.map((integration) => {
                if (!knownIntegrations.has(integration.id)) {
                    console.warn(`Unsupported integration: ${integration.id}`);
                    return null;
                }
                const IntegrationComponent = knownIntegrations.get(integration.id);
                if (!IntegrationComponent)
                    return null;
                return React.createElement(IntegrationComponent, { integration: integration, key: integration.id });
            })) !== null && _a !== void 0 ? _a : null)),
        hasConfiguredIntegrations && (React.createElement("div", null, hasThirdPartyIntegrationsFeature ? React.createElement(ProductAndMachineMatching, null) : React.createElement(ConnectionsBlankSlateBanner, null)))));
};
export { ConnectionsSettingsPage };
