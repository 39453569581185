import { isNative } from "mobile/mobileManager";
import { createDownloadLink } from "lib/download/helpers/createDownloadLink";
import { getExportFilename } from "lib/download/helpers/getExportFilename";
import { writeFileAndShare } from "lib/download/helpers/writeFileAndShare";
export async function downloadFile(apiResponse, filenameDefault) {
    const dataBlob = await apiResponse.blob();
    const filename = getExportFilename(apiResponse, filenameDefault);
    if (isNative()) {
        writeFileAndShare(dataBlob, filename);
    }
    else {
        createDownloadLink(dataBlob, filename).click();
    }
}
