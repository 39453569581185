import { Text } from "agreements/forms/components/MicroLabel";
import React from "react";
import styled from "styled-components";

import CloseX from "components/fl-ui/Icons/CloseX";
import Cluster from "components/fl-ui/Layout/Cluster";
import Stack from "components/fl-ui/Layout/Stack";
import { GreyColors } from "components/fl-ui/colors";
import { UIColors } from "components/fl-ui/colors";

const Section = styled((props) => <Stack {...props} />)`
  border: 1px solid ${GreyColors.smoke91};
  ${(props) => props.inError && `border-color: ${UIColors.red};`}
  border-radius: 4px;
  padding: 0.5rem;
`;
const Header = styled.header`
  border-bottom: 1px solid ${GreyColors.smoke91};
  margin-bottom: 0.25rem;
  padding-bottom: 0.5rem;
`;
const ErrorMessage = styled.div`
  color: ${UIColors.red};
  padding: 4px;
`;

const LineItemFieldset = (props) => {
  const { children, errorMessage, label, onRemove } = props;

  return (
    <Section inError={!!errorMessage}>
      <Header>
        <Cluster between>
          <Text>{label}</Text>
          {onRemove && <CloseX color={UIColors.red} onClick={onRemove} />}
        </Cluster>
      </Header>

      <Cluster style={{ flexWrap: "wrap", justifyItems: "start" }}>{children}</Cluster>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Section>
  );
};

export default LineItemFieldset;
