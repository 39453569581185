import styledPropTypes from "@styled-system/prop-types";
import PropTypes from "prop-types";
import React, { Children, useMemo } from "react";
import styled from "styled-components";
import { display, grid } from "styled-system";

import useViewType from "hooks/useViewType";

import { Spacing } from "components/fl-ui/constants";

const FormElement = styled.form`
  ${(props) =>
    ["flow-horizontal", "flow-vertical"].includes(props.layout) &&
    `
    > * {
      // this removes a hard-coded margin-bottom in FormGroup on an opt-in basis
      margin-bottom: 0;
    }
  `}

  ${display}
  ${grid}
`;

const useFormLayout = ({ children, layout }) => {
  const totalChildren = useMemo(() => Children.toArray(children).length, [children]);
  /*
   * The better way to do this is with a container query, but they
   * are not supported by versions of Safari older than 16. Once we
   * are sure the iOS app does not support these old versions of
   * Safari, we should refactor this to use container queries instead.
   */
  const isDesktop = useViewType() === "desktop";

  switch (layout) {
    case "columnar":
      return {
        display: "grid",
        gridTemplateColumns: "1fr",
      };

    case "flow-horizontal":
      return {
        display: "grid",
        gridGap: Spacing.spacing16,
        gridTemplateColumns: `repeat(${isDesktop ? 2 : 1}, 1fr)`,
      };

    case "flow-vertical":
      return {
        display: "grid",
        gridAutoFlow: isDesktop ? "column" : undefined,
        gridGap: Spacing.spacing16,
        gridTemplateRows: isDesktop ? `repeat(${Math.ceil(totalChildren / 2)}, auto)` : undefined,
      };

    default:
      return {};
  }
};

const Form = ({ children, layout, onChange, onSubmit, preventDefault, ...rest }) => {
  const layoutProps = useFormLayout({ children, layout });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    onChange(event, { [name]: value });
  };

  const handleFormSubmit = (event) => {
    if (preventDefault) {
      event.preventDefault();
    }

    onSubmit(event);
  };

  return (
    <FormElement {...layoutProps} {...rest} layout={layout} onChange={handleFormChange} onSubmit={handleFormSubmit}>
      {children}
    </FormElement>
  );
};

Form.propTypes = {
  layout: PropTypes.oneOf(["columnar", "flow-horizontal", "flow-vertical", null, undefined]),
  noValidate: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  preventDefault: PropTypes.bool,
  ...styledPropTypes.display,
  ...styledPropTypes.grid,
};

Form.defaultProps = {
  noValidate: true,
  onChange: () => {},
  onSubmit: () => {},
  preventDefault: true,
};

export default Form;
