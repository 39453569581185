import * as yup from "yup";
const firstName = yup
    .string()
    .label("First Name")
    .required()
    .max(100, "First Name cannot be longer than 100 characters");
const lastName = yup.string().label("Last Name").required().max(100, "Last Name cannot be longer than 100 characters");
const receiveNotificationEmails = yup.boolean().required();
const SSOUserInformationFormSchema = yup.object().shape({
    firstName,
    lastName,
    receiveNotificationEmails,
});
export { SSOUserInformationFormSchema };
