import { StyleSheet, css } from "aphrodite/no-important";
import classnames from "classnames";
import Color from "color";

import { UIColors, GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Typography, Mixins, Spacing } from "components/fl-ui/constants";

export const COLOR = {
  default: {
    background: GreyColors.smoke98,
    text: UIColors.regular,
    border: GreyColors.smoke80,
    link: UIColors.regular,
  },
  primary: {
    background: UIColors.primary,
    text: UIColors.white,
    border: UIColors.primary,
    link: UIColors.primary,
  },
  secondary: {
    background: UIColors.white,
    text: UIColors.primary,
    border: GreyColors.smoke80,
    link: UIColors.regular,
  },
  danger: {
    background: Color(UIColors.danger).desaturate(0.1).string(),
    text: UIColors.white,
    border: UIColors.danger,
    link: UIColors.danger,
  },
  white: {
    background: Color(UIColors.white).desaturate(0.1).string(),
    text: UIColors.regular,
    border: UIColors.white,
    link: UIColors.white,
  },
};

export const SIZE = {
  xl: { fontSize: Typography.sizes.xl },
  lg: { fontSize: Typography.sizes.lg },
  md: { fontSize: Typography.sizes.md },
  mn: { fontSize: Typography.sizes.mn },
  sm: { fontSize: Typography.sizes.sm },
  xs: { fontSize: Typography.sizes.xs },
};

export const getButtonColor = (color, link) => {
  const colorStyles = StyleSheet.create({
    button: {
      color: link ? COLOR[color].link : COLOR[color].text,
      background: link ? "none" : COLOR[color].background,
      border: link ? "none" : `1px solid ${COLOR[color].border}`,
      ":hover": {
        color: link ? Color(COLOR[color].link).darken(0.05).string() : COLOR[color].text,
        background: link ? "none" : Color(COLOR[color].background).lighten(0.05).string(),
      },
      ":active": {
        color: link ? Color(COLOR[color].link).darken(0.05).string() : COLOR[color].text,
        background: link ? "none" : Color(COLOR[color].background).darken(0.05).string(),
      },
    },
  });
  return colorStyles.button;
};

export const getButtonSize = (size) => {
  const sizeStyles = StyleSheet.create({
    size: {
      fontSize: SIZE[size].fontSize,
    },
  });
  return sizeStyles.size;
};

export const getButtonDisplay = (variant) => {
  const variantStyles = StyleSheet.create({
    inline: {
      display: "inline-flex",
      marginLeft: ".5em",
      marginRight: ".5em",
    },
    block: {
      display: "block",
      width: "100%",
      margin: "0 0 1em",
      padding: `${Mixins.toRem(12)} ${Mixins.toRem(16)}`,
      textAlign: "center",
      "&:last-child": {
        marginBottom: 0,
      },
    },
  });
  return variantStyles[variant];
};

export const getSpinnerColor = (color, link, hollow) => {
  if (link || hollow) {
    return COLOR[color].background;
  }
  return COLOR[color].text;
};

export const iconSizes = StyleSheet.create({
  xl: {
    width: Spacing.spacing28,
    height: Spacing.spacing28,
    marginRight: Spacing.spacing14,
  },
  lg: {
    width: Spacing.spacing24,
    height: Spacing.spacing24,
    marginRight: Spacing.spacing12,
  },
  md: {
    width: Spacing.spacing24,
    height: Spacing.spacing24,
    marginRight: Spacing.spacing8,
  },
  mn: {
    width: Spacing.spacing14,
    height: Spacing.spacing14,
    marginRight: Spacing.spacing8,
  },
  sm: {
    width: Spacing.spacing12,
    height: Spacing.spacing12,
    marginRight: Spacing.spacing4,
  },
  xs: {
    width: Spacing.spacing8,
    height: Spacing.spacing8,
    marginRight: Spacing.spacing4,
  },
});

const styles = StyleSheet.create({
  button: {
    ...Mixins.uiControl,
    alignItems: "center",
    backgroundColor: GreyColors.smoke98,
    border: "1px solid",
    borderColor: GreyColors.smoke80,
    borderRadius: BorderRadius.small,
    boxShadow: "none",
    color: UIColors.regular,
    display: "inline-block",
    fontWeight: Typography.weights.medium,
    lineHeight: Spacing.spacing24,
    padding: `${Mixins.toRem(7)} ${Mixins.toRem(16)}`,
    textDecoration: "none",
    whiteSpace: "nowrap",
    transition: "background-color 0.5s ease",
    ":hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  link: {
    backgroundColor: "none",
    border: "none",
    boxShadow: "none",
    cursor: "pointer",
    fontWeight: Typography.weights.medium,
    padding: 0,
    textDecoration: "none",
    textShadow: "none",
    ":hover": {
      textDecoration: "underline",
    },
  },
  raised: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.10)",
    ":hover": {
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.30)",
    },
  },
  disabled: {
    cursor: "default",
    pointerEvents: "none",
    opacity: 0.5,
  },
  hollow: {
    border: `1px solid ${GreyColors.smoke90}`,
    transition: "border 0.5s ease",
    ":hover": {
      border: "1px solid currentColor",
    },
  },
  buttonIcon: {
    display: "inline-block",
    height: Spacing.spacing24,
    marginRight: Spacing.spacing4,
    width: Spacing.spacing24,
  },
  buttonIcon_noChildren: {
    margin: "0 auto !important",
  },
  buttonIcon_right: {
    marginRight: 0,
    marginLeft: Spacing.spacing4,
  },
  buttonIcon_bold: {
    stroke: "currentColor",
    strokeWidth: "2px",
  },
  hasIcon: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
  },
  hasIcon_noChildren: {
    paddingRight: Mixins.toRem(12),
    paddingLeft: Mixins.toRem(12),
  },
  iconRight: {
    flexDirection: "row-reverse",
  },
  lightWeight: {
    fontWeight: Typography.weights.regular,
  },
  spinner: {
    marginRight: `${Spacing.spacing8} !important`,
    display: "inline-block",
  },
});

export const getBtnClasses = ({
  color = "default",
  display,
  size = "md",
  link,
  children,
  className,
  disabled,
  raised,
  style,
  hollow,
  icon,
  iconRight,
  lightWeight,
  loading,
}) =>
  classnames(
    css([
      styles.button,
      link && styles.link,
      display && getButtonDisplay(display),
      getButtonSize(size),
      getButtonColor(color, link || hollow),
      raised && styles.raised,
      disabled && styles.disabled,
      style && Mixins.getOverrideStyles(style),
      hollow && styles.hollow,
      icon && styles.hasIcon,
      icon && !children && styles.hasIcon_noChildren,
      iconRight && styles.iconRight,
      lightWeight && styles.lightWeight,
      loading && styles.hasIcon,
    ]),
    className
  );

export default styles;
