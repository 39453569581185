import React from "react";
import styled from "styled-components";
import { UIColors } from "components/fl-ui/colors";
import { Borders, BorderRadius, Spacing } from "components/fl-ui/constants";
const AttachmentThumbnail = styled(({ className, src, onClick, onError }) => (React.createElement("img", { className: className, src: src, alt: "AttachmentThumbnail", onClick: onClick, onError: onError }))) `
  border: ${(props) => props.isSelected ? `4px solid ${UIColors.primary}` : Borders.regular};
  border-radius: ${BorderRadius.medium};
  height: 4rem;
  margin-right: ${Spacing.spacing16};
  min-width: 4rem;
  object-fit: contain;
  width: 4rem;

  :hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  }
`;
export default AttachmentThumbnail;
