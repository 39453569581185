import { css, StyleSheet } from "aphrodite";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";
import { findDOMNode } from "react-dom";

import { GreyColors, UIColors } from "components/fl-ui/colors/index";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  textArea: {
    display: "inline-block",
    padding: Spacing.spacing8,
    margin: 0,
    marginBottom: Spacing.spacing16,

    border: Borders.dark,
    borderRadius: BorderRadius.medium,
    outline: "none",

    minHeight: "5em",
    maxWidth: "unset",

    color: UIColors.medium,
    backgroundColor: UIColors.white,

    fontWeight: Typography.weights.medium,
    fontSize: Typography.sizes.rg,
    lineHeight: 1.2,

    "&:focus": {
      outline: 0,
      borderColor: UIColors.light,
    },
    "&[disabled]": {
      cursor: "auto",
      background: GreyColors.smoke94,
      color: UIColors.light,
    },
  },
  xxl: {
    fontSize: Typography.sizes.xxl,
  },
  xl: {
    fontSize: Typography.sizes.xl,
  },
  l: {
    fontSize: Typography.sizes.l,
  },
  m: {
    fontSize: Typography.sizes.md,
  },
  s: {
    fontSize: Typography.sizes.sm,
  },
  xs: {
    fontSize: Typography.sizes.xs,
  },
  error: {
    outline: 0,
    borderColor: UIColors.danger,
    boxShadow: `0 0 0 3px ${UIColors.danger}`,
  },
  legacy: {
    maxWidth: "none",
    minHeight: "120px",
  },
});

const TextArea = createReactClass({
  displayName: "TextArea",

  PropTypes: {
    onChange: PropTypes.func,
  },

  componentDidMount() {
    const textarea = findDOMNode(this);
    if (this.props.autofocus) textarea.focus();
  },

  handleChange(e) {
    if (this.props.onChange) {
      const { name, value } = e.target;
      this.props.onChange(e, { [name]: value });
    }
  },

  render() {
    const { className, style, size, legacy, ...otherProps } = this.props;
    return (
      <textarea
        {...otherProps}
        onChange={this.handleChange}
        className={css([styles.textArea, legacy && styles.legacy])}
        style={style}
      >
        {this.props.children}
      </textarea>
    );
  },
});

export default TextArea;
