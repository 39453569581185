import createSiemLog from "collection/graphql/logging/mutations/createSiemLog";
import useRestMutation from "hooks/useRestMutation";

/*
IMPORTANT

SIEM logging to a publicly writable endpoint is a security risk. An attacker
could fabricate entries to mask their activity or otherwise create confusion.

Therefore, the useCreateSiemLog hook can only be used with whitelisted events.
If you are logging a new event, it must be registered with the backend.

Additionally, the attributes accepted for SIEM log entries are heavily
restricted. Most information is automatically compiled by the backend, instead
of trusting the client - for example, the user who performed the event.
Unsupported attributes are ignored.

These measures help ensure the integrity of SIEM logs.
*/

/**
 * Logs SIEM events. See specifications for more details on what should be logged:
 * https://bushel.atlassian.net/wiki/spaces/BT/pages/8015085583/SIEM+Logging+Spec
 */
const useCreateSiemLog = () => {
  const buildSiemLogInput = (logObj) => {
    return { variables: { input: logObj } };
  };

  const sendLog = useRestMutation(createSiemLog)[0];

  return {
    buildSiemLogInput,
    sendLog,
  };
};

export default useCreateSiemLog;
