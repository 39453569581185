import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { Icon, IconContainer as IconContainerFLUI } from "components/fl-ui/Icons";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
const BlankSlateContainer = styled(({ children, className }) => (React.createElement(Row, { center: "xs", middle: "xs", className: className },
    React.createElement(Col, { xs: 12, md: 8 }, children)))) `
  padding: ${Spacing.spacing48} 0 ${Spacing.spacing48};
  text-align: center;

  h2 {
    line-height: 3em;
  }
`;
const IconContainer = styled(IconContainerFLUI) `
  color: ${GreyColors.smoke91};
  text-align: center;
`;
const BlankSlate = ({ children, icon, title }) => (React.createElement(BlankSlateContainer, null,
    icon && (React.createElement(IconContainer, { size: Spacing.spacing48 },
        React.createElement(Icon, { icon: icon }))),
    title && React.createElement("h2", null, title),
    children));
export { BlankSlate };
