import { makeVar } from "@apollo/client";
//There is so much terrible code around this... why are we calling it an enterpriseVar? It's an enterprise UUID.
const currentEnterpriseVar = makeVar(localStorage.getItem("enterprise") || null);
const persist = (newValue) => {
    if (!newValue) {
        localStorage.removeItem("enterprise");
    }
    else {
        localStorage.setItem("enterprise", newValue);
    }
    currentEnterpriseVar.onNextChange(persist);
};
currentEnterpriseVar.onNextChange(persist);
window.addEventListener("storage", (event) => {
    if (event.key === "enterprise") {
        currentEnterpriseVar(event.newValue || null);
        if (window.navigator.onLine) {
            window.location.reload();
        }
    }
});
export default currentEnterpriseVar;
