import { gql } from "@apollo/client";

import subscriptionFragment from "collection/graphql/subscription/fragments/subscriptionFragment";

export default gql`
  query getCurrentSubscription {
    subscription: getCurrentSubscription @rest(type: "Subscription", path: "/subscriptions/subscription") {
      ${subscriptionFragment}
    }
  }
`;
