import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import { WeatherKitLogo } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    marginTop: Spacing.spacing16,
  },
  p: {
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: Spacing.spacing12,
  },
});

const WeatherAttributionFooter = () => (
  <Row>
    <Col xs className={css(styles.container)}>
      <hr />
      <WeatherKitLogo />
      <p className={css(styles.p)}>Forecasts provided by WeatherKit</p>
    </Col>
  </Row>
);

export default WeatherAttributionFooter;
