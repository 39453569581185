import React from "react";

import getAllUsers from "collection/graphql/users/queries/getAllUsers";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";

const withUserList = (WrappedComponent) => (props) => {
  const isWorkOrder = !!props.defaults?.isWorkOrder;

  const userList = useRestSuspenseQuery(getAllUsers).data.users.reduce((list, user) => {
    if (!isWorkOrder || user.role !== "no_access") {
      list.push({
        id: user.id,
        name: user.name,
      });
    }

    return list;
  }, []);

  return <WrappedComponent {...props} personList={userList} />;
};

export default withUserList;
