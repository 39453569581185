import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import React from "react";
import styled from "styled-components";
import { SignUpAccordionItem } from "modules/wallet/components/sign_up/SignUpAccordionItem";
import { SignUpHeaderTitle } from "modules/wallet/components/sign_up/SignUpHeaderTitle";
import { SignUpSecondaryTitle } from "modules/wallet/components/sign_up/SignUpSecondaryTitle";
import { WalletSignUpButton } from "modules/wallet/components/sign_up/WalletSignUpButton";
import { WalletSignUpSection } from "modules/wallet/components/sign_up/WalletSignUpSection";
const WalletSignUpFourthSection = styled(({ className }) => (React.createElement(WalletSignUpSection, { backgroundColor: "grey" },
    React.createElement("div", { className: className },
        React.createElement("div", { className: "accordion-area" },
            React.createElement("br", null),
            React.createElement(SignUpSecondaryTitle, null, "FAQs"),
            React.createElement("br", null),
            React.createElement(SignUpAccordionItem, { question: "How does Bushel Wallet protect my data?", answer: "Your data is encrypted and stored securely with industry-leading protection." }),
            React.createElement(SignUpAccordionItem, { question: "What happens if there\u2019s unauthorized activity on my account?", answer: "Bushel Wallet offers fraud protection and support to resolve any unauthorized activity." }),
            React.createElement(SignUpAccordionItem, { question: "Is my money insured?", answer: "Yes, funds are insured up to $1,000,000 through FDIC-insured institutions." }),
            React.createElement(SignUpAccordionItem, { question: "How quickly can I access my funds?", answer: "You can access your funds instantly using the Bushel Debit Card or linked accounts." }),
            React.createElement(SignUpAccordionItem, { question: "Can I link multiple financial accounts?", answer: "Yes, you can link multiple accounts to manage all your finances in one place." })),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("div", { className: "bottom-section" },
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(SignUpHeaderTitle, null, "Link Your Farm and Finances Today"),
            React.createElement("br", null),
            React.createElement(WalletSignUpButton, null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("br", null)))))) `
  .accordion-area {
    width: 60%;
    margin: 0 auto;

    @media only screen and (max-width: ${PrimaryMaxWidth}) {
      width: 100%;
    }
  }

  .bottom-section {
    width: 40%;
    margin: 0 auto;
    text-align: center;

    @media only screen and (max-width: ${PrimaryMaxWidth}) {
      width: 100%;
    }

    button {
      margin: 0 auto;
    }
  }
`;
export { WalletSignUpFourthSection };
