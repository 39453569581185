import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import { UPDATE_ENTERPRISE } from "collection/graphql/enterprise/mutations";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import App from "layout/app";
import countryList from "lib/countries";
import statesList from "lib/states";
import { filterEnterpriseFields, hasStates, validateAndUpdateEnterpriseState } from "modules/settings/utils";
import { Button } from "components/fl-ui";
import { Form, Input, Select } from "components/fl-ui/Form";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Spacing } from "components/fl-ui/constants";
const InlineInput = styled(Input) `
  margin-right: ${Spacing.spacing12};
`;
const getListOptions = (list) => [{ label: "-- Select One --", value: "" }].concat(list.map((m) => ({
    key: m.code,
    label: m.name,
    value: m.code,
})));
const countryOptions = getListOptions(countryList["Countries"]);
const FarmInput = ({ error, id, label, last = false, options, value }) => {
    const hasError = (error === null || error === void 0 ? void 0 : error.path) === id;
    return (React.createElement("div", { className: `control-group${last ? " last-sub-item" : ""}${hasError ? " error" : ""}` },
        React.createElement("label", { className: "control-label", htmlFor: id }, label),
        React.createElement("div", { className: "controls" },
            options ? (React.createElement(Select, { defaultValue: value, hasError: hasError, id: id, legacy: true, name: id, options: options })) : (React.createElement(InlineInput, { defaultValue: value, hasError: hasError, id: id, name: id, type: "text" })),
            hasError && React.createElement("span", { className: "help-inline" }, error.message))));
};
const FarmSettingsPage = () => {
    var _a, _b;
    const { currentEnterprise, loading } = useCurrentEnterprise();
    const [enterpriseData, setEnterpriseData] = useState(filterEnterpriseFields(currentEnterprise));
    const [error, setError] = useState(null);
    const [updateEnterprise] = useMutation(UPDATE_ENTERPRISE);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleFormChange = (_event, formData) => {
        if (error && formData[error.path]) {
            setError(null);
        }
        setEnterpriseData((prev) => (Object.assign(Object.assign({}, prev), formData)));
    };
    const handleSave = async () => {
        setButtonDisabled(true);
        try {
            const enterprise = await validateAndUpdateEnterpriseState(enterpriseData);
            await updateEnterprise({ variables: { enterprise } });
            App.notify("Changes saved.");
        }
        catch (error) {
            setError(error);
            App.notify("Update failed, please try again.");
        }
        finally {
            setButtonDisabled(false);
        }
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(Form, { className: "form-horizontal", onChange: handleFormChange, onSubmit: handleSave, layout: undefined, preventDefault: undefined },
            React.createElement("h2", { className: "heading-underline" }, "Farm Information"),
            React.createElement(FarmInput, { error: error, id: "name", label: "Farm name", value: enterpriseData.name }),
            React.createElement(FarmInput, { error: error, id: "phone", label: "Phone number", value: enterpriseData.phone }),
            React.createElement(FarmInput, { error: error, id: "acreage", label: "Harvested acres", last: true, value: enterpriseData.acreage }),
            React.createElement("h2", { className: "heading-underline" }, "Farm Address"),
            React.createElement(FarmInput, { error: error, id: "country", label: "Country", options: countryOptions, value: enterpriseData.country }),
            React.createElement(FarmInput, { error: error, id: "address", label: "Street", value: enterpriseData.address }),
            React.createElement(FarmInput, { error: error, id: "address2", label: "", value: enterpriseData.address2 }),
            React.createElement(FarmInput, { error: error, id: "city", label: "City", value: enterpriseData.city }),
            hasStates((_a = enterpriseData.country) !== null && _a !== void 0 ? _a : "") && (React.createElement(FarmInput, { error: error, id: "state", label: enterpriseData.country === "CA" ? "Province" : "State", options: getListOptions(statesList[(_b = enterpriseData.country) !== null && _b !== void 0 ? _b : ""]), value: enterpriseData.state })),
            React.createElement(FarmInput, { error: error, id: "postalCode", label: "Postal code", value: enterpriseData.postalCode }),
            React.createElement("div", { className: "form-actions" },
                React.createElement(Button, { color: "primary", disabled: buttonDisabled, type: "submit" }, "Save Changes")))));
};
export { FarmSettingsPage };
