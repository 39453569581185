import { directoryCache, fileWrite, share } from "mobile/mobileManager";
import { blobToBase64 } from "lib/download/helpers/blobToBase64";
export async function writeFileAndShare(blob, filename) {
    const fileAsBase64String = await blobToBase64(blob);
    const writeFileResult = await fileWrite({
        path: `com.farmlogs.app/${filename}`,
        data: fileAsBase64String,
        directory: directoryCache(),
        recursive: true,
    });
    await share({
        title: filename,
        url: writeFileResult.uri,
    }).catch((error) => {
        if (error.message === "Share canceled")
            return;
        throw new Error(error);
    });
}
