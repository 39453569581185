import React from "react";
import styled from "styled-components";
import ToolbarNotificationMenu from "components/fl-ui/Layout/Navigation/ToolbarNotificationMenu/ToolbarNotificationMenu";
import ToolbarUserMenu from "components/fl-ui/Layout/Navigation/ToolbarUserMenu";
const Toolbar = ({ className }) => (React.createElement("div", { className: className },
    React.createElement(ToolbarNotificationMenu, null),
    React.createElement(ToolbarUserMenu, null)));
export default styled(Toolbar) `
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: flex-end;
  position: relative;

  @media print {
    display: none;
  }
`;
