import useActivitiesProvider from "activity/hooks/useActivitiesProvider";
import React, { useMemo } from "react";
import styled from "styled-components";

import PageSummary, { ResponsiveFigure } from "components/fl-ui/PageSummary";
import { UIColors } from "components/fl-ui/colors";

const Green = styled.span`
  color: ${UIColors.green};
`;
const Red = styled.span`
  color: ${UIColors.red};
`;

const ActivitySummary = () => {
  const { filteredActivities, totalActivities, totalFilteredActivities } = useActivitiesProvider();

  const [totalDone, totalOverdue, totalPlanned] = useMemo(() => {
    const totals = [0, 0, 0];
    (filteredActivities || []).forEach((activity) => {
      if (activity.isDone) {
        ++totals[0];
      } else {
        ++totals[2]; // isPlanned
        if (activity.isOverdue) {
          ++totals[1];
        }
      }
    });

    return totals;
  }, [filteredActivities]);

  return (
    <PageSummary>
      <ResponsiveFigure
        label={totalFilteredActivities === 1 ? "Activity" : "Activities"}
        value={
          <span>
            {totalFilteredActivities} <small>of</small> {totalActivities}
          </span>
        }
      />

      <ResponsiveFigure label={<Red>Overdue</Red>} value={totalOverdue} />
      <ResponsiveFigure label="Planned" value={totalPlanned} />
      <ResponsiveFigure label={<Green>Done</Green>} value={totalDone} />
    </PageSummary>
  );
};

export default ActivitySummary;
