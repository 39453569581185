import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useMarketingAdvisor from "hooks/useMarketingAdvisor";
import usePermissions from "hooks/usePermissions";

const useFeatureAndPermissionAccess = ({ featureName, permissionName }) => {
  const { currentEnterprise, loading: loadingEnterprise } = useCurrentEnterprise();
  const hasFeature = currentEnterprise?.features?.includes(featureName);

  const { canRead, loading: loadingPermissions } = usePermissions();
  const hasReadPermission = canRead(permissionName);

  const { isMarketingAdvisor } = useMarketingAdvisor();
  const loadingAccess = loadingEnterprise || loadingPermissions;

  return {
    loadingAccess,
    showPayGate: !loadingAccess && !hasFeature && !isMarketingAdvisor,
    showRestricted: !loadingAccess && ((hasFeature && !hasReadPermission) || (!hasFeature && isMarketingAdvisor)),
    showContent: !loadingAccess && hasFeature && hasReadPermission,
  };
};

export { useFeatureAndPermissionAccess };
