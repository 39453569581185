import { appAddResumeListener, appState, getPlatform } from "mobile/mobileManager";
import { create } from "zustand";
import ssoUserVar from "collection/graphql/auth/vars/ssoUserVar";
/* AuthStatus */
export const AUTHENTICATED = "AUTHENTICATED";
export const INITIALIZED = "INITIALIZED";
export const UNINITIALIZED = "UNINITIALIZED";
const AUTH_STATUS = {
    AUTHENTICATED,
    /* `AuthStore` moves into the INITIALIZED state once the `.initialize()` method has been called. */
    INITIALIZED,
    /* default state */
    UNINITIALIZED,
};
/* AuthStrategy */
export const LEGACY = "LEGACY";
export const SSO = "SSO";
export const UNKNOWN = "UNKNOWN";
const AUTH_STRATEGIES = {
    LEGACY,
    SSO,
    UNKNOWN,
};
/*
 * This factory function exists only to make unit testing easier. `AuthStore` is intended as a singleton/hook.
 */
export const createAuthStore = () => {
    return create((set, get) => {
        let authInstance;
        return {
            isAuthenticated: false,
            status: UNINITIALIZED,
            strategy: null,
            user: null,
            // initializes authStore. idempotent.
            initialize: (auth) => set((state) => {
                var _a;
                if (authInstance) {
                    return state;
                }
                authInstance = auth;
                const update = {
                    isAuthenticated: auth.isAuthenticated,
                    status: auth.isAuthenticated ? AUTHENTICATED : INITIALIZED,
                    user: (_a = auth.user) !== null && _a !== void 0 ? _a : null,
                };
                /*
                 * TODO explore how to leverage the following properties on the  auth.user object:
                 *    * expired – (boolean?) undefined if auth is loading initially or the user has never been
                 *                authenticated. boolean if isAuthenticated has ever been true (including in ssoUserVar).
                 *    * expires_at – date in seconds of expiry
                 *    * expires_in – seconds until expiry. could be negative. auto refresh kicks in around 60s.
                 *   I've created a ticket for this: https://bushel.atlassian.net/browse/FARM-9807
                 */
                /*
                 * occurs when any of their access tokens refresh. followed by addAccessTokenExpiring callback
                 * if there was already a valid access token before refresh.
                 */
                auth.events.addUserLoaded((user) => {
                    ssoUserVar(user);
                    set(() => ({ user }));
                });
                /*
                 * Offline-aware silent renew logic
                 */
                const attemptSilentRenew = async () => {
                    if (navigator.onLine && ssoUserVar()) {
                        const user = await auth.signinSilent();
                        if (!user) {
                            const currentAppState = await appState();
                            if (getPlatform() === "android" && !currentAppState.isActive) {
                                // If Android fails in the background, do nothing
                            }
                            else {
                                void auth.signinRedirect();
                            }
                        }
                    }
                };
                // Immediately attempt silent renew, we may be coming from a cold app start
                void attemptSilentRenew();
                window.addEventListener("online", attemptSilentRenew);
                auth.events.addSilentRenewError(attemptSilentRenew);
                if (getPlatform() === "android") {
                    // When Android resumes, make an explicit renew attempt
                    appAddResumeListener(() => {
                        void attemptSilentRenew();
                    });
                }
                return update;
            }),
            setIsAuthenticated: (isAuthenticated) => set(({ status }) => {
                const hasEverBeenAuthenticated = ![UNINITIALIZED, INITIALIZED].includes(status);
                return {
                    isAuthenticated,
                    status: isAuthenticated && !hasEverBeenAuthenticated ? AUTHENTICATED : status,
                };
            }),
            setStrategy: (strategy) => set(() => ({ strategy })),
            signInRedirect: async (email) => {
                return await authInstance.signinRedirect({
                    login_hint: email,
                });
            },
        };
    });
};
/** @name AuthStore */
export const useAuthStore = createAuthStore();
