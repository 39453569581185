import * as yup from "yup";
const farmName = yup.string().label("Farm Name").required().max(100, "Farm Name cannot be longer than 100 characters");
const postalCode = yup
    .string()
    .label("Postal Code")
    .required()
    .max(10, "Postal Code cannot be longer than 10 characters");
const SSOCreateNewEnterpriseFormSchema = yup.object().shape({
    farmName,
    postalCode,
});
export { SSOCreateNewEnterpriseFormSchema };
