import React from "react";
import styled from "styled-components";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";
const CreateNewAccountRow = ({ className, onClick }) => {
    return (React.createElement("div", { className: className, onClick: onClick },
        React.createElement("div", { className: "icon-area" },
            React.createElement(IconContainer, { size: Spacing.spacing24 },
                React.createElement(Icon, { icon: "add" }))),
        React.createElement("div", { className: "text-area" },
            React.createElement("p", null, "Create new account"))));
};
export default styled(CreateNewAccountRow) `
  align-items: center;
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  color: ${UIColors.primary};
  margin: ${Spacing.spacing8} auto;
  width: 100%;
  display: inline-flex;

  &:hover {
    border: 1px solid ${UIColors.primary};
    cursor: pointer;
  }

  p {
    font-weight: ${Typography.weights.medium};
    text-align: center;
  }

  .icon-area {
    width: ${Spacing.spacing60};
    text-align: center;
  }

  .text-area {
    padding: ${Spacing.spacing16} 0;

    h4 {
      font-weight: ${Typography.weights.medium};
      margin: ${Spacing.spacing8} 0;
    }

    p {
      font-size: ${Typography.sizes.small};
      margin: ${Spacing.spacing2} 0;
      color: ${UIColors.primary};
      font-weight: ${Typography.weights.medium};
    }
  }
`;
