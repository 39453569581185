import useDocumentTitle from "hooks/useDocumentTitle";
import React from "react";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import { SSOSignUpForm } from "modules/sso/forms/SSOSignUpForm";
const SSOSignUp = ({ onComplete }) => {
    useDocumentTitle("Sign Up");
    return (React.createElement(BaseSSOPageContainer, { title: "Welcome to Bushel Farm" },
        React.createElement(SSOSignUpForm, { onComplete: onComplete })));
};
export { SSOSignUp };
