import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHref } from "react-router-dom";
import styled from "styled-components";

import withWorkOrderNotification from "collection/graphql/activities/withWorkOrderNotification";
import getUser from "collection/graphql/users/queries/getUser";
import useRestQuery from "hooks/useRestQuery";
import useWindow from "hooks/useWindow";

import { Button } from "components/fl-ui";
import { FormGroup, TextArea } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const Body = styled.div`
  line-height: 1.5em;
  a {
    color: ${UIColors.blue};
  }
  .control-group {
    margin: ${Spacing.spacing20} 0 0;
  }
  textarea {
    line-height: 1.5em;
  }
`;

const NotifyUserModal = ({ activityCount, onAbort, onSendSuccess, recipientId, sendWorkOrderNotification }) => {
  const href = useWindow().location.origin + "/" + useHref("/activity");
  const [message, setMessage] = useState("");
  const { user } =
    useRestQuery(getUser, {
      variables: {
        id: recipientId,
      },
    }).data ?? {};

  const sendMessage = async () => {
    const payload = {
      message,
      users: [recipientId],
    };

    await sendWorkOrderNotification(payload);
    onSendSuccess(payload);
  };

  return (
    <Modal width={635}>
      <ModalHeader>
        <ModalTitle>Notify assignee</ModalTitle>
        <CloseX onClick={() => onAbort()} />
      </ModalHeader>

      <ModalBody>
        <Body>
          <p>{`You've successfully assigned ${activityCount} ${activityCount === 1 ? "activity" : "activities"} to ${
            user?.fullName ?? ""
          }. Activities assigned to this user will automatically display at:`}</p>
          <a href={href}>{href}</a>
          <FormGroup label="Message for assigned user (optional)">
            <TextArea name="message" onChange={(event, { message }) => setMessage(message.trim())} rows={5} />
          </FormGroup>

          <p>This message will only be seen by the user assigned to the activity.</p>
        </Body>
      </ModalBody>

      <ModalFooter>
        <Cluster end>
          <Button onClick={() => onAbort()}>Cancel</Button>
          <Button color="primary" onClick={sendMessage}>
            Send notification
          </Button>
        </Cluster>
      </ModalFooter>
    </Modal>
  );
};

NotifyUserModal.propTypes = {
  activityCount: PropTypes.number.isRequired,
  onAbort: PropTypes.func.isRequired,
  onSendSuccess: PropTypes.func.isRequired,
  recipientId: PropTypes.number.isRequired,
  sendWorkOrderNotification: PropTypes.func.isRequired,
};

export default withWorkOrderNotification(NotifyUserModal);
