export const COLLECT_EMAIL = "COLLECT_EMAIL";
export const COLLECT_USER_DETAILS = "COLLECT_USER_DETAILS";
export const CONFIRM_FARM_NAME = "CONFIRM_FARM_NAME";
export const CONNECT_TO_CONTRACTS = "CONNECT_TO_CONTRACTS";
export const CREATE_ENTERPRISE = "CREATE_ENTERPRISE";
export const DONE = "DONE";
export const ERROR = "ERROR";
export const SELECT_MEMBERSHIP = "SELECT_MEMBERSHIP";
export const SIGN_UP = "SIGN_UP";
const SSOPossibleResults = {
    COLLECT_EMAIL,
    COLLECT_USER_DETAILS,
    CONFIRM_FARM_NAME,
    CONNECT_TO_CONTRACTS,
    CREATE_ENTERPRISE,
    DONE,
    ERROR,
    SELECT_MEMBERSHIP,
    SIGN_UP,
};
