import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const ProfitAndLossBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Profit & Loss for free" : "Move to Business today",
        imagePath: "images/pay_gates/banners/pandl.png",
        title: "With Profit & Loss you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Aggregate data from your Bushel Farm account to get a complete picture of how much money is being spent and made across every dimension of your farm."),
                React.createElement(ValuePropListItem, null, "Quickly determine which fields contributed to more profit or loss for your farm."),
                React.createElement(ValuePropListItem, null, "Evaluate where to invest or cut back."),
                React.createElement(ValuePropListItem, null, "Streamline conversations with your financial partners")))));
};
export { ProfitAndLossBlankSlateBanner };
