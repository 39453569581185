import MicroLabel from "agreements/forms/components/MicroLabel";
import styled from "styled-components";

import { FormGroup } from "components/fl-ui/Form";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

export const Container = styled.div`
  margin-bottom: ${Spacing.spacing16};
`;

export const DoubleMicroLabel = styled.div`
  border: 1px solid ${GreyColors.smoke91};
  borderradius: ${Spacing.spacing4};
  display: table;
  padding: ${Spacing.spacing16};
`;

export const LightText = styled.span`
  color: ${UIColors.light};
  font-weight: normal;
`;

export const StyledFormGroup = styled(FormGroup)`
  max-width: 18rem;
`;

export const StyledMicroLabel = styled(MicroLabel)`
  width: 7.5rem;
`;
