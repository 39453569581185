import { css } from "aphrodite/no-important";
// @ts-ignore
import { SalesEmailLink, SalesPhoneLink } from "billing/lib";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "components/advertisements/styles";
import { Button } from "components/fl-ui";
const PayGateCommonBlankSlateBanner = ({ actionButtonText, children, imagePath, secondaryText, size = "lg", title, }) => {
    const navigate = useNavigate();
    const onClick = () => navigate("/billing");
    return (React.createElement("div", { className: css(styles.banner_container) },
        React.createElement("div", { className: css(styles.banner_content) },
            React.createElement("div", { className: css(styles.banner_textContainer, size === "sm" && styles.banner_textContainer_sm) },
                React.createElement("h1", { className: css(styles.promo_h2, styles.centered_on_mobile) }, title),
                children,
                React.createElement(Button, { className: css(styles.promo_cta, styles.centered_on_mobile), color: "primary", onClick: onClick }, actionButtonText),
                secondaryText && React.createElement("p", { className: css(styles.secondaryText) }, secondaryText),
                size === "lg" && (React.createElement("p", { className: css(styles.promo_text, styles.centered_on_mobile) },
                    "Need help? Contact our support team at ",
                    React.createElement(SalesPhoneLink, null),
                    ", or email us at ",
                    React.createElement(SalesEmailLink, null),
                    "."))),
            React.createElement("div", { className: css(styles.banner_imgContainer, size === "sm" && styles.banner_imgContainer_sm) },
                React.createElement("img", { className: css(styles.banner_img), src: imagePath, alt: "Banner" })))));
};
export default PayGateCommonBlankSlateBanner;
