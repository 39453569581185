import useDocumentTitle from "hooks/useDocumentTitle";
import Backbone from "backbone";
import BinView from "inventory/bin";
import InventoryTransaction from "loads/components/InventoryTransaction";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CropView from "resources/crop";

import BinsCollection from "collection/bins";
import InventoryTransactionsCollection from "collection/inventory_transactions";
import useBackboneCollection from "hooks/useBackboneCollection";
import useBackboneModel from "hooks/useBackboneModel";
import useCommodities from "hooks/useCommodities";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import { BIN_DETAIL_MODAL_OPEN_LOAD_EDIT } from "lib/metrics/events";
import useFieldCrops from "modules/fields/hooks/useFieldCrops";

import { Button } from "components/fl-ui";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const BinDetailWrapper = () => {
  useDocumentTitle("Bin detail");
  const binRef = useRef();
  const binId = +useParams().id;
  const navigate = useNavigate();
  const [cropIdToEdit, setCropIdToEdit] = useState(null);
  const [load, setLoad] = useState(null);

  const { loading: commoditiesLoading } = useCommodities();
  const { getCropByInventoryNode, loading: cropsLoading } = useFieldCrops();
  const loading = commoditiesLoading || cropsLoading;

  useBackboneCollection(BinsCollection);
  useBackboneCollection(InventoryTransactionsCollection);
  /*
   * All right, look. None of us are happy about having to use Backbone, but we do need to accommodate it here and there
   * until we can tell it about the rabbit farm we'll have someday and....well, you get it.
   *
   * The rules of hooks say that you shouldn't render hooks conditionally so I can't just redirect here and feel great about
   * it.  So I'm creating an empty Backbone.Model as a null object until I can redirect a little later. Perfect solution? No.
   * Good enough until we kill Backbone? Yes? Yes.
   */
  const model = BinsCollection.get(binId);
  useBackboneModel(model || new Backbone.Model());

  const { group, inventory_node_id: inventoryNodeId, name } = model?.toJSON() || {};
  const handleAddLoad = () => {
    BIN_DETAIL_MODAL_OPEN_LOAD_EDIT.track();
    setLoad({
      source_id: inventoryNodeId,
      destination_id: inventoryNodeId,
      commodity_id: InventoryTransactionsCollection.getNodeTransactions(inventoryNodeId)[0]?.get("commodity_id"),
    });
  };

  useEffect(() => {
    if (!loading && model) {
      const redirectToIndex = () => navigate("/inventory", { replace: true });
      model.once("destroy", redirectToIndex);

      const binView = new BinView({
        el: binRef.current,
        getCropByInventoryNode,
        model,
      })
        .on("addLoad", handleAddLoad)
        .on("editCrop", (cropId) => setCropIdToEdit(cropId))
        .on("editLoad", (load) => setLoad(load))
        .on("viewBinDetails", (binId) => navigate(`/inventory/bin/${binId}`));
      binView.render();

      return () => {
        binView.undelegateEvents();
        binView.off();
        model.off("destroy", redirectToIndex);
      };
    }
  }, [getCropByInventoryNode, loading, model]);

  if (!useEnterpriseFeature("legacy_storage_and_loads")) {
    return <Navigate replace to="/fields" />;
  }

  return (
    <Container>
      <Header description={group} title={name}>
        <Button className="btn btn-smoke" onClick={handleAddLoad}>
          Log Load
        </Button>
      </Header>

      <div ref={binRef} style={{ margin: "0.5rem" }}>
        {loading && <LoadingWrapper />}
      </div>

      {load && <InventoryTransaction load={load} onClose={() => setLoad(null)} />}
      {cropIdToEdit && (
        <CropView
          id={cropIdToEdit}
          onFinish={() => {
            setCropIdToEdit(null);
          }}
        />
      )}
    </Container>
  );
};

export default BinDetailWrapper;
