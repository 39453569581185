import Dots from "contracts/components/Dots";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Icon, IconContainer } from "components/fl-ui/Icons";
import { Spacing } from "components/fl-ui/constants";

const CardHeader = styled(({ className, contract }) => (
  <div className={className}>
    <h2>{contract.contractNumber}</h2>
    <div className="content-right">
      {contract.dataSource === "PARTNER" && (
        <IconContainer className="bushel-logo" size={Spacing.spacing24}>
          <Icon icon="bushelLogo" />
        </IconContainer>
      )}
      <Dots className="dots" contract={contract} size={Spacing.spacing20} />
    </div>
  </div>
))`
  width: 100%;
  padding: ${Spacing.spacing8} 0 ${Spacing.spacing16} 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    margin: ${Spacing.spacing4} 0;
    width: 65%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .content-right {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    justify-content: center;

    .bushel-logo {
      margin-right: ${Spacing.spacing20};
    }
  }
`;

export default CardHeader;

CardHeader.propTypes = {
  contract: PropTypes.object,
};
