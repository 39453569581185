import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { Spacing } from "components/fl-ui/constants";
import { BorderRadius } from "components/fl-ui/constants";

const SortButton = styled(({ className, onClick, sortIsActive }) => (
  <Button className={className} color="primary" hollow={!sortIsActive} icon="doubleArrows" iconRight onClick={onClick}>
    Sort
  </Button>
))`
  padding: ${Spacing.spacing14} ${Spacing.spacing20};
  position: relative !important;
  border-radius: ${BorderRadius.xxxlarge} !important;

  svg {
    padding: ${Spacing.spacing2};
  }
`;

SortButton.propTypes = {
  onClick: PropTypes.func,
};

export default SortButton;
