import { StyleSheet } from "aphrodite/no-important";

import { UIColors, GreyColors } from "components/fl-ui/colors";
import { Mixins, BorderRadius, Typography } from "components/fl-ui/constants";

const offerStyles = StyleSheet.create({
  futuresPrice_subText: {
    lineHeight: Mixins.toRem(24),
    marginRight: Mixins.toRem(8),
  },
  offerType_container: {
    cursor: "pointer",
    position: "relative",
    ":before": {
      ...Mixins.beforeAfter,
      content: '"Select this option"',
      color: UIColors.white,
      textTransform: "uppercase",
      fontSize: Mixins.toRem(11),
      fontWeight: Typography.weights.medium,
      width: "auto",
      height: "auto",
      top: "50%",
      left: "50%",
      transform: "translateY(-50%) translateX(-50%)",
      zIndex: 200,
      display: "none",
    },
    ":after": {
      ...Mixins.beforeAfter,
      width: "100%",
      height: "100%",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: UIColors.primary,
      opacity: "0.9",
      zIndex: 100,
      display: "none",
      borderRadius: BorderRadius.large,
    },
    ":hover:after": {
      display: "block",
      opacity: "0.8",
    },
    ":hover:before": {
      display: "block",
    },
  },
  offerType_container_isSelected: {
    backgroundColor: `${UIColors.primary} !important`,
    color: `${UIColors.white} !important`,
    ":before": {
      content: '"Selected"',
    },
  },
  offerType_title: {
    marginTop: 0,
    color: UIColors.primary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  offerType_title_isSelected: {
    color: `${UIColors.white} !important`,
  },
  offerType_title_status: {
    textTransform: "uppercase",
    opacity: "0.75",
    fontSize: Mixins.toRem(11),
    fontWeight: Typography.weights.medium,
  },
  offerType_desc: {
    marginBottom: 0,
  },
  offerType_first: {
    marginTop: Mixins.toRem(24),
  },
  offerType_second: {
    borderRadius: `${BorderRadius.large} ${BorderRadius.large} 0 0 !important`,
    ":after": {
      borderRadius: `${BorderRadius.large} ${BorderRadius.large} 0 0 !important`,
    },
  },
  offerType_middle: {
    borderRadius: `0 0 0 0 !important`,
    ":after": {
      borderRadius: `0 0 0 0 !important`,
    },
  },
  offerType_last: {
    borderTop: "none !important",
    borderRadius: `0 0 ${BorderRadius.large} ${BorderRadius.large} !important`,
    ":after": {
      borderRadius: `0 0 ${BorderRadius.large} ${BorderRadius.large} !important`,
    },
  },
  offerType_divider: {
    position: "relative",
    marginTop: Mixins.toRem(34),
    marginBottom: Mixins.toRem(34),
    overflow: "visible",
    height: "2px",
    color: GreyColors.smoke90,
    ":before": {
      ...Mixins.beforeAfter,
      content: '"or"',
      width: Mixins.toRem(24),
      height: Mixins.toRem(19),
      top: "-8px",
      left: "50%",
      transform: "translateX(-50%)",
      fontSize: Mixins.toRem(12),
      fontWeight: Typography.weights.medium,
      lineHeight: 1.5833,
      textTransform: "uppercase",
      textAlign: "center",
      letterSpacing: "0.4px",
      color: UIColors.light,
      backgroundColor: UIColors.white,
      zIndex: 100,
    },
  },
  offers_genericHR: {
    marginTop: Mixins.toRem(24),
    marginBottom: Mixins.toRem(24),
  },
  offerForm_description: {
    fontSize: Mixins.toRem(13),
    lineHeight: 1.4615,
  },
  offerForm_lastUpdated: {
    color: Typography.colors.secondary,
  },
  offerForm_priceDetails: {
    fontSize: Typography.sizes.sm,
    lineHeight: 1.5,
  },
  offerForm_input: {
    width: "100% !important",
    minWidth: `${Mixins.toRem(120)} !important`,
    maxWidth: `${Mixins.toRem(300)} !important`,
  },
  errorText: {
    color: UIColors.danger,
  },
  offerForm_inputHelpText: {
    fontSize: Mixins.toRem(13),
    letterSpacing: `-0.2px`,
    lineHeight: 1.384,
    marginTop: Mixins.toRem(8),
  },
  offerForm_inputErrorText: {
    color: UIColors.red,
  },
});

export default offerStyles;
