import React from "react";
import SSOEntryPoint from "modules/sso/SSOEntryPoint";
import { SSOWalletSurvey } from "modules/sso/pages/SSOWalletSurvey";
export default [
    {
        path: "/sso",
        element: React.createElement(SSOEntryPoint, null),
    },
    {
        path: "/sso-wallet-survey",
        element: React.createElement(SSOWalletSurvey, null),
    },
];
