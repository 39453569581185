import { useQuery } from "@apollo/client";
import { BaseElement } from "components/fl-ui/common";
import AddPricingAndDeliveryButton from "contracts/components/AddPricingAndDeliveryButton";
import ContractDeliveryDateRange from "contracts/components/ContractDeliveryDateRange";
import ContractQuantity from "contracts/components/ContractQuantity";
import PriceWithSubtext from "contracts/components/PriceWithSubtext";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import PricingAndDeliveryModal from "contracts/modals/PricingAndDeliveryModal";
import React, { useState } from "react";
import styled from "styled-components";

import getPricelines from "collection/graphql/contracts/queries/getPricelines";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import formatCurrency from "modules/marketing/cards/utils/formatCurrency";

import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";

const PricelineDateRange = ({ contract, priceline: { deliveryEndDate, deliveryStartDate } }) => {
  if (contract.contractType === "SPOT" && !deliveryEndDate) {
    deliveryEndDate = contract?.contractDate;
  }

  return <ContractDeliveryDateRange endDate={deliveryEndDate} startDate={deliveryStartDate} />;
};

const PricingAndDeliveryHeader = styled(({ className, showEdit, contract }) => {
  const elevatePlusEnterpriseEnabled = useEnterpriseFeature("elevate_plus");
  return (
    <thead>
      <tr className={className}>
        <th className="pd-number">NUMBER</th>
        <th className="pd-delivery">DELIVERY</th>
        <th className="pd-location">LOCATION</th>
        <th className="pd-amount">AMOUNT</th>
        <th className="pd-futures-price">FUTURES PRICE</th>
        <th className="pd-basis">BASIS</th>
        <th className="pd-adjustment">ADJ</th>
        {elevatePlusEnterpriseEnabled && <th className="pd-additional-adj">ADDTL ADJ</th>}
        <th className="pd-cash-price">CASH PRICE</th>
        <th className="pd-futures-month">FUTURES MONTH</th>
        {showEdit && <th className="pd-edit" />}
      </tr>
    </thead>
  );
})`
  background-color: ${GreyColors.smoke97};
`;

const PricingAndDeliveryRow = styled(({ contract, className, priceline }) => {
  const [showPDModal, setShowPDModal] = useState(false);
  const { canWrite } = useContractPermissions();
  const elevatePlusEnterpriseEnabled = useEnterpriseFeature("elevate_plus");
  return (
    <tr className={className}>
      <td className="pd-number">{priceline.lineNumber ?? "--"}</td>
      <td className="pd-delivery">
        <PricelineDateRange contract={contract} priceline={priceline} />
      </td>
      <td className="pd-location">{priceline.deliveryLocation?.publicName ?? "--"}</td>
      <td className="pd-amount">
        <ContractQuantity contract={contract} quantity={priceline.quantityPriced} />
      </td>
      <td className="pd-futures-price">
        <PriceWithSubtext price={priceline.futuresPrice} status={priceline.futuresPriceStatus} />
      </td>
      <td className="pd-basis">
        <PriceWithSubtext price={priceline.basis} status={priceline.basisStatus} />
      </td>
      <td className="pd-adjustment">{formatCurrency(priceline.adjustments, 4)}</td>
      {elevatePlusEnterpriseEnabled && (
        <td className="pd-additional-adj">
          <PriceWithSubtext price={priceline.additionalAdjustments} status={priceline.additionalAdjustmentsStatus} />
        </td>
      )}

      <td className="pd-cash-price">
        <PriceWithSubtext price={priceline.cashPrice} status={priceline.cashPriceStatus} />
      </td>
      <td className="pd-futures-month">{priceline.underlying ?? "--"}</td>
      {canWrite() && (
        <td className="pd-edit">
          <span className="edit-text" onClick={() => setShowPDModal(true)}>
            Edit
          </span>
        </td>
      )}

      {showPDModal && (
        <PricingAndDeliveryModal contract={contract} onClose={() => setShowPDModal(false)} priceline={priceline} />
      )}
    </tr>
  );
})`
  font-weight: ${Typography.weights.medium};

  .edit-text {
    color: ${ColorPalette.blue};
    cursor: pointer;
  }
`;

const PricingAndDeliveryArea = styled(({ contract, className, expanded }) => {
  const { data } = useQuery(getPricelines, {
    skip: !expanded,
    variables: {
      id: contract.id,
    },
  });

  const { canWrite } = useContractPermissions();
  const pricelines = data?.getPricelines ?? [];

  return (
    <div className={className}>
      <h3>Pricing & Delivery</h3>
      <table className="card">
        <PricingAndDeliveryHeader showEdit={canWrite()} contract={contract} />
        <tbody>
          {pricelines.map((priceline) => (
            <PricingAndDeliveryRow contract={contract} key={priceline.id} priceline={priceline} />
          ))}
        </tbody>
      </table>
      {canWrite() && contract.dataSource !== "PARTNER" && (
        <BaseElement display="flex" justifyContent="flex-end">
          <AddPricingAndDeliveryButton contract={contract} />
        </BaseElement>
      )}
    </div>
  );
})`
  .card {
    margin: ${Spacing.spacing28};
    border: ${Borders.dark};
    border-radius: ${BorderRadius.medium};
    padding: 0;
  }

  table {
    margin: 0;
    width: calc(100% - 3.5rem);
    border-collapse: collapse;
  }

  table thead {
    border-bottom: ${Borders.dark};
  }

  th {
    padding: 8px;
    vertical-align: bottom;
    text-align: center;
  }

  @media only screen and (max-width: 1400px) {
    .card {
      font-size: 0.8rem;
    }
    .subtext {
      font-size: 0.6rem !important;
    }
  }

  .subtext {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 13px;
    white-space: nowrap;
  }

  .pd-amount,
  .pd-futures-price,
  .pd-basis,
  .pd-adjustment,
  .pd-additional-adj,
  .pd-cash-price,
  .pd-futures-month,
  .pd-edit {
    text-align: right;
    padding-right: ${Spacing.spacing4};
  }

  @media (max-width: 1150px) {
    .pd-number {
      display: none;
    }
  }

  @media (max-width: 1130px) {
    .pd-location {
      display: none;
    }
  }
`;

export default PricingAndDeliveryArea;
