import ContractDeliveryDateRange from "contracts/components/ContractDeliveryDateRange";
import ContractQuantity from "contracts/components/ContractQuantity";
import Dots from "contracts/components/Dots";
import ExpandedRowArea from "contracts/components/ExpandedRowArea";
import ExpansionCell from "contracts/components/ExpansionCell";
import Price from "contracts/components/Price";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import TableCell from "modules/contracts/components/TableCell";

import { Icon, IconContainer } from "components/fl-ui/Icons";
import ColorPalette from "components/fl-ui/colors/palette";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const CashContractRow = styled(({ className, contract }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={className}>
      <div className="main-row">
        <ExpansionCell expanded={expanded} toggleRow={() => setExpanded(!expanded)} />
        {expanded ? (
          <div className="expansion-header">
            <div>
              <h2 className="expansion-title">{contract.contractNumber}</h2>
              <IconContainer className="expansion-header-bushel-logo" size={Spacing.spacing24}>
                <Icon icon="bushelLogo" />
              </IconContainer>
            </div>
            <Dots className="expansion-header-dots" contract={contract} size={Spacing.spacing16} />
          </div>
        ) : (
          <>
            <TableCell columnClass="contract">{contract.contractNumber ?? "--"}</TableCell>
            <TableCell columnClass="buyer">{contract.buyer ?? "--"}</TableCell>
            <TableCell columnClass="buyer-icon">
              {contract.dataSource === "PARTNER" && (
                <IconContainer size={Spacing.spacing24}>
                  <Icon icon="bushelLogo" />
                </IconContainer>
              )}
            </TableCell>
            <TableCell columnClass="commodity">{contract.commodity?.name ?? "--"}</TableCell>
            <TableCell columnClass="delivery">
              <ContractDeliveryDateRange endDate={contract.deliveryEndDate} startDate={contract.deliveryStartDate} />
            </TableCell>
            <TableCell columnClass="qty-contracted">
              <ContractQuantity contract={contract} quantity={contract.quantity} />
            </TableCell>
            <TableCell columnClass="qty-priced">
              <ContractQuantity contract={contract} quantity={contract.quantityPriced} />
            </TableCell>
            <TableCell columnClass="pricing">
              <Price price={contract.netPrice} variant={contract.contractFinalStatus !== "Final" ? "YELLOW" : null} />
            </TableCell>
            <TableCell columnClass="dots">
              <Dots contract={contract} size={Spacing.spacing16} />
            </TableCell>
          </>
        )}
      </div>

      <ExpandedRowArea contract={contract} expanded={expanded} />
    </div>
  );
})`
  box-shadow: ${Borders.tableRow};
  border-radius: ${BorderRadius.xlarge};
  margin: ${Spacing.spacing8} ${Spacing.spacing2};
  padding: ${Spacing.spacing8} 0;
  color: ${ColorPalette["bushel-blue"]};

  .main-row {
    display: flex;
    height: 72px;
    width: 100%;
  }

  .expansion-header {
    width: 96%;
    display: flex;
    justify-content: space-between;
    > :first-child {
      align-items: center;
      display: flex;
    }
  }

  .expansion-title {
    color: ${ColorPalette["bushel-blue"]};
    display: inline-block;
  }

  .expansion-header-bushel-logo {
    margin-left: 20px;
    visibility: ${(props) => (props.contract.dataSource === "PARTNER" ? "visible" : "hidden")};
  }

  .expansion-header-dots {
    margin-top: 26px;
    margin-right: 38px;
  }
`;

export default CashContractRow;

CashContractRow.propTypes = {
  contract: PropTypes.object,
};
