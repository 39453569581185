import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { filterEnterpriseFields, validateAndUpdateEnterpriseState } from "settings/utils";
import styled from "styled-components";

import { useSSOContext } from "context/SSOContext";
import { SSOConfirmFarmNameFormSchema } from "modules/sso/forms/SSOConfirmFarmNameFormSchema";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form";
import Spacing from "components/fl-ui/constants/spacing";

const SSOConfirmFarmNameForm = styled(({ className, onComplete }) => {
  const { selectedMembership, updateEnterpriseName } = useSSOContext();

  const methods = useForm({
    defaultValues: SSOConfirmFarmNameFormSchema.cast(
      {
        farmName: "",
      },
      { assert: false, stripUnknown: true },
    ),
    mode: "onTouched",
    resolver: yupResolver(SSOConfirmFarmNameFormSchema),
  });

  const onSubmit = async (data) => {
    const filteredEnterpriseFields = filterEnterpriseFields(selectedMembership.enterprise);
    const enterprise = await validateAndUpdateEnterpriseState(filteredEnterpriseFields);
    await updateEnterpriseName({
      ...enterprise,
      name: data.farmName,
    });
    onComplete();
  };

  return (
    <FormProvider {...methods} schema={SSOConfirmFarmNameFormSchema}>
      <div className={className}>
        <p className="top-text">Please confirm your farm name</p>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup
            name="farmName"
            render={({ field }) => <Input {...field} placeholder="Example Farm" />}
          />

          <br />

          <p>
            To maintain security, users will no longer be able to share credentials. If you have other users who need to
            access this account, you can invite them to join your account in Settings under Manage Users.
          </p>

          <br />

          <Button className="save-button" color="primary" disabled={!_.isEmpty(methods.formState.errors)} type="submit">
            Next
          </Button>
        </Form>
      </div>
    </FormProvider>
  );
})`
  padding: ${Spacing.spacing20} 5% 0 5%;

  .checkbox-row {
    display: inline-block;

    input {
      display: inline-block;
      vertical-align: top;
      width: 5%;
    }

    p {
      display: inline-block;
      margin: 0;
      width: 90%;
    }
  }

  .save-button {
    width: 100%;
  }

  .top-text {
    text-align: left;
  }
`;

export { SSOConfirmFarmNameForm };
