import React from "react";
import styled from "styled-components";
import { useFarmAdvisorConfig } from "collection/graphql/advisor";
import styles from "modules/marketing/cards/cardStyles";
import { CargillCardBottomText } from "modules/marketing/cards/components/CargillCardBottomText";
import { ExchangeTradedCellRow } from "modules/marketing/cards/components/ExchangeTradedCellRow";
import formatAmount from "modules/marketing/cards/utils/formatAmount";
import formatCurrency from "modules/marketing/cards/utils/formatCurrency";
import CropCardProgressBarArea from "modules/marketing/components/CropCardProgressBarArea";
import Cell, { CellRow } from "modules/marketing/components/ui-elements/Cell";
import { IconContainer } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";
import { css } from "components/fl-ui/aphrodite";
import { ColorPalette } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";
const TitleHeading = styled.h3 `
  text-transform: uppercase;
`;
const CargillCropCardDetails = styled(({ advisorRecommendation, className, hasContracts, marketedCrop }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { data: { programName }, } = useFarmAdvisorConfig();
    const totalUnprotectedAmount = (_c = (_b = (_a = marketedCrop === null || marketedCrop === void 0 ? void 0 : marketedCrop.progress) === null || _a === void 0 ? void 0 : _a.total) === null || _b === void 0 ? void 0 : _b.quantity) !== null && _c !== void 0 ? _c : 0;
    const recommendedMarketedAmount = totalUnprotectedAmount * ((_d = advisorRecommendation === null || advisorRecommendation === void 0 ? void 0 : advisorRecommendation.recommendedPercentage) !== null && _d !== void 0 ? _d : 0);
    const recommendedAmountIncrease = recommendedMarketedAmount - ((_g = (_f = (_e = marketedCrop === null || marketedCrop === void 0 ? void 0 : marketedCrop.progress) === null || _e === void 0 ? void 0 : _e.marketed) === null || _f === void 0 ? void 0 : _f.quantity) !== null && _g !== void 0 ? _g : 0);
    const Unit = () => React.createElement(CommodityYieldUnit, { commodity: marketedCrop === null || marketedCrop === void 0 ? void 0 : marketedCrop.commodity, defaultUnit: "bu" });
    const progressBarMarketedValue = (React.createElement("span", null,
        formatAmount(recommendedMarketedAmount),
        " ",
        React.createElement(Unit, null)));
    const progressBarUnprotectedValue = (React.createElement("span", null,
        formatAmount(totalUnprotectedAmount),
        " ",
        React.createElement(Unit, null)));
    return (React.createElement("div", { className: className },
        advisorRecommendation ? (React.createElement(CropCardProgressBarArea, { marketedPercentage: advisorRecommendation.recommendedPercentage * 100, marketedText: progressBarMarketedValue, unprotectedText: progressBarUnprotectedValue, titleArea: React.createElement(TitleHeading, null,
                "Recommended by ",
                programName) })) : (React.createElement("div", { className: "no-recommendations-area" },
            React.createElement(TitleHeading, null,
                "Recommended by ",
                programName),
            React.createElement("p", null,
                programName,
                " has not released recommendations for this crop"))),
        React.createElement(CellRow, { className: css(styles.dataRow, styles.borderTop) },
            React.createElement(Cell, null, "Your Avg Sold Price"),
            React.createElement(Cell, { right: true },
                formatCurrency((_k = (_j = (_h = marketedCrop === null || marketedCrop === void 0 ? void 0 : marketedCrop.progress) === null || _h === void 0 ? void 0 : _h.sold) === null || _j === void 0 ? void 0 : _j.valuePerUnit) !== null && _k !== void 0 ? _k : 0),
                " ",
                React.createElement(Unit, null))),
        React.createElement(CellRow, { className: css(styles.dataRow) },
            React.createElement(Cell, null,
                programName,
                " Average Price",
                React.createElement(Tooltip, { message: "Futures reference price based on cash recommendations to date" },
                    React.createElement(IconContainer, { icon: "help", size: Spacing.spacing16 }))),
            React.createElement(Cell, { right: true },
                formatCurrency((_l = advisorRecommendation === null || advisorRecommendation === void 0 ? void 0 : advisorRecommendation.averageSoldPrice) !== null && _l !== void 0 ? _l : 0),
                " ",
                React.createElement(Unit, null))),
        marketedCrop.commodity.isExchangeTraded ? (React.createElement(ExchangeTradedCellRow, { marketedCrop: marketedCrop })) : (React.createElement(CellRow, { className: css(styles.dataRow) },
            React.createElement(Cell, null, "Budget Price"),
            React.createElement(Cell, { right: true },
                React.createElement("span", null,
                    formatCurrency(marketedCrop.harvestPrice),
                    " ",
                    React.createElement(Unit, null))))),
        marketedCrop.__typename === "MarketedCrop" && (React.createElement(CargillCardBottomText, { boldText: React.createElement(React.Fragment, null,
                formatAmount(recommendedAmountIncrease),
                " ",
                React.createElement(Unit, null)), hasContracts: hasContracts, recommendedAmountIncrease: recommendedAmountIncrease }))));
}) `
  .blue-text {
    color: ${ColorPalette.blue};
    padding-right: ${Spacing.spacing4};
    cursor: pointer;
  }

  .no-recommendations-area {
    p {
      padding: ${Spacing.spacing20};
      text-align: center;
    }
  }
`;
export { CargillCropCardDetails };
