import { css } from "aphrodite/no-important";
import React from "react";
import styles from "components/advertisements/styles";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody } from "components/fl-ui/Modal/Modal";
const PayGateCommonBlankSlateModal = ({ banner, onClose = () => { }, width = 1000, }) => (React.createElement(Modal, { width: width, hideModal: undefined, id: undefined },
    React.createElement(ModalBody, { className: css(styles.modal_body) },
        React.createElement(CloseX, { className: css(styles.modal_closeX), onClick: onClose }),
        banner)));
export default PayGateCommonBlankSlateModal;
