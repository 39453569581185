import React from "react";
import { Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import { ComparePlansTable } from "modules/billing/components/ComparePlansTable";
import Button from "components/fl-ui/Button";
import { Modal } from "components/fl-ui/Modal/Modal";
import { Spacing } from "components/fl-ui/constants";
const PaddedSection = styled.div `
  padding: ${Spacing.spacing16};
`;
const ComparePlansModal = ({ onClose }) => (React.createElement(Modal, { hideModal: undefined, width: 600, id: "compare-plans-modal" },
    React.createElement(PaddedSection, null,
        React.createElement(Row, { reverse: true },
            React.createElement(Button, { "data-cy": "closeX", icon: "close", link: true, onClick: onClose }))),
    React.createElement(ComparePlansTable, null)));
export { ComparePlansModal };
