import { StyleSheet, css } from "aphrodite/no-important";
import { SupportEmailLink, SalesPhoneLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";

import { ColorSpinner } from "components/fl-ui/Icons";
import { Modal, ModalBody } from "components/fl-ui/Modal/Modal";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  modalBody: {
    fontSize: Typography.sizes.lg,
    lineHeight: 1.375,
    color: Typography.colors.secondary,
    borderBottom: 0,
    minHeight: Spacing.spacing48,
    textAlign: "center",
    borderRadius: BorderRadius.small,
  },
  h1: {
    fontSize: Typography.sizes.xl,
    marginTop: Spacing.spacing24,
  },
});

//TODO: This component needs to be refactored to a function component
class LoadingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.escape = this.escape.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.escape);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.escape);
  }

  escape = (e) => {
    if (e.key === "Escape") {
      this.props.onClose();
    }
  };

  render() {
    const { dialogMessage } = this.props;
    return (
      <Modal width={450} className={css(styles.modal)}>
        <ModalBody className={css(styles.modalBody)}>
          <ColorSpinner color={UIColors.green} />
          <h1 className={css(styles.h1)}>{dialogMessage}</h1>
          <p>
            Need help? Contact our support team at <SalesPhoneLink />, or email us at: <SupportEmailLink />.
          </p>
        </ModalBody>
      </Modal>
    );
  }
}

LoadingDialog.propTypes = {
  dialogMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LoadingDialog;
