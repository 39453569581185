import { css } from "aphrodite/no-important";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import styles from "components/fl-ui/Layout/styles/headerStyles";
const BackLink = ({ backLink }) => typeof backLink === "string" ? (React.createElement(Link, { className: classNames(css(styles.backLink), "heading-back-link"), "data-qa-id": "back-arrow", to: backLink })) : (React.createElement("a", { onClick: (e) => {
        e.preventDefault();
        backLink();
    }, className: classNames(css(styles.backLink), "heading-back-link"), "data-qa-id": "back-arrow", href: "#" }));
export default BackLink;
