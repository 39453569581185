import { useSuspenseQuery } from "@apollo/client";
import useDocumentTitle from "hooks/useDocumentTitle";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { getCustomProducts } from "collection/graphql/products/queries";
import useBoolean from "hooks/useBoolean";
import { InputGroup } from "modules/settings/components/InputGroup";
import ProductRecipeList from "modules/settings/components/ProductRecipeList";
import InputItem from "modules/settings/components/input_item";
import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";
import ProductInputModal from "components/product/crud";
const InputsSettingsPage = withSuspenseWrapper(() => {
    useDocumentTitle("Input Settings");
    const { data: { products }, refetch, } = useSuspenseQuery(getCustomProducts);
    const { ChemicalProduct: chemicals, FertilizerProduct: fertilizers, SeedProduct: seeds, } = useMemo(() => {
        const editableProducts = _.reject(products, "public");
        const sorted = _.sortBy(editableProducts, "name");
        return _.groupBy(sorted, "__typename");
    }, [products]);
    const [showModal, { off, on }] = useBoolean(false);
    const [productBeingEdited, setProductBeingEdited] = useState(null);
    const edit = (product) => {
        setProductBeingEdited(product);
        on();
    };
    return (React.createElement("div", { className: "settings-inputs-wrapper margin-top-10" },
        React.createElement(InputGroup, { title: "Custom Fertilizers", onAdd: () => {
                setProductBeingEdited({ type: "FERTILIZER" });
                on();
            } }, _.map(fertilizers, (fertilizer) => (React.createElement(InputItem, { key: fertilizer.id, onEdit: () => edit(fertilizer), sub: fertilizer.shortDescription, title: fertilizer.name })))),
        React.createElement(InputGroup, { title: "Custom Chemicals", onAdd: () => {
                setProductBeingEdited({ type: "CHEMICAL" });
                on();
            } }, _.map(chemicals, (chemical) => (React.createElement(InputItem, { key: chemical.id, onEdit: () => edit(chemical), sub: chemical.shortDescription, title: chemical.name })))),
        React.createElement(InputGroup, { title: "Custom Seeds", onAdd: () => {
                setProductBeingEdited({ unitType: "COUNT", type: "SEED" });
                on();
            } }, _.map(seeds, (seed) => (React.createElement(InputItem, { key: seed.id, onEdit: () => edit(seed), sub: seed.shortDescription, title: seed.name })))),
        showModal && (React.createElement(ProductInputModal, { onClose: () => {
                setProductBeingEdited(null);
                off();
            }, onFinish: async (newProduct) => {
                setProductBeingEdited(null);
                if (newProduct) {
                    await refetch();
                }
                off();
            }, product: productBeingEdited })),
        React.createElement(ProductRecipeList, null)));
});
export { InputsSettingsPage };
