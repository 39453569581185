/* eslint-disable react/display-name */
import React from "react";
import usePermissions from "hooks/usePermissions";
import { PER_ACRE } from "modules/agreements/constants";
import FieldDetailsTableRow from "modules/fields/components/FieldDetailsTableRow";
import { currency } from "modules/marketing/utils/format";
import { Button } from "components/fl-ui";
const EstimatedLandValue = ({ field, onAddLandValue }) => {
    const landValueMap = {
        PER_ACRE: field.landValuePerAcre,
        TOTAL: field.landValueTotal,
    };
    if (field.landValueRate && +field.landValuePerAcre) {
        const rateLabel = field.landValueRate === PER_ACRE ? "/ ac" : "";
        return (React.createElement(FieldDetailsTableRow, { label: "Estimated land value", value: `${currency(landValueMap[field.landValueRate])} ${rateLabel}` }));
    }
    else {
        return (React.createElement(FieldDetailsTableRow, { label: "Estimated land value", value: React.createElement(Button, { color: "primary", lightWeight: true, link: true, onClick: () => onAddLandValue() }, "Set") }));
    }
};
const withPermissionProtection = (WrappedComponent) => (props) => {
    const { canRead } = usePermissions();
    return canRead("agreements") ? React.createElement(WrappedComponent, Object.assign({}, props)) : null;
};
export default withPermissionProtection(EstimatedLandValue);
