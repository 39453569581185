import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import styled from "styled-components";

import { AddUserFormSchema } from "modules/settings/pages/Users/components/forms/AddUserFormSchema";
import { useCreateAndEditUser } from "modules/settings/pages/Users/hooks/useCreateAndEditUser";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form";
import Spacing from "components/fl-ui/constants/spacing";

const AddUserForm = styled(({ className, close }) => {
  const methods = useForm({
    defaultValues: AddUserFormSchema.cast(
      {
        email: "",
        accessLevel: "",
        firstName: "",
        lastName: "",
      },
      { assert: false, stripUnknown: true },
    ),
    mode: "onTouched",
    resolver: yupResolver(AddUserFormSchema),
  });

  const accessLevelOptions = [
    { id: "admin", label: "Admin", value: "admin", name: "Admin" },
    { id: "manager", label: "Manager", value: "manager", name: "Manager" },
    { id: "contributor", label: "Contributor", value: "contributor", name: "Contributor" },
    { id: "no_access", label: "No access", value: "no_access", name: "No access" },
  ];

  const { watch } = methods;
  const accessLevel = watch("accessLevel");

  const { onSave } = useCreateAndEditUser();

  const onSubmit = async (data) => {
    console.log("Submitted Data:");
    console.log({ data });

    await onSave({
      email: data.email,
      role: data.accessLevel,
      firstName: data?.firstName,
      lastName: data?.lastName,
    });

    close();
  };

  return (
    <FormProvider {...methods} schema={AddUserFormSchema}>
      <div className={className}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <p>The new user will receive an email to sign up to access your farm.</p>
          <br />

          <ControlledFormGroup name="email" render={({ field }) => <Input {...field} placeholder="Email" />} />

          <ControlledFormGroup
            name="accessLevel"
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={accessLevelOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                value={accessLevelOptions.find((opt) => opt.value === field.value) || null}
                onChange={(selectedOption) => field.onChange(selectedOption.value)}
              />
            )}
          />

          {accessLevel === "no_access" && (
            <>
              <ControlledFormGroup
                name="firstName"
                render={({ field }) => <Input {...field} placeholder="First Name" />}
              />
              <ControlledFormGroup
                name="lastName"
                render={({ field }) => <Input {...field} placeholder="Last Name" />}
              />
            </>
          )}

          <br />

          <div className="footer-buttons">
            <Button color="primary" link onClick={() => close()}>
              Cancel
            </Button>
            <Button
              className="save-button"
              color="primary"
              disabled={!_.isEmpty(methods.formState.errors)}
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </FormProvider>
  );
})`
  padding: ${Spacing.spacing20} 5% 0 5%;

  .top-text {
    text-align: left;
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
    gap: ${Spacing.spacing12};
    margin-top: ${Spacing.spacing20};

    button {
      margin-right: 0;
    }
  }
`;

export { AddUserForm };
