import React from "react";
import styled from "styled-components";
const TableCell = styled(({ className, columnClass, children }) => (React.createElement("div", { className: `${className} ${columnClass}` },
    React.createElement("span", null, children)))) `
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & > span {
    max-height: 100%;
    width: 100%;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;
export default TableCell;
