import React from "react";
import getName from "components/fl-ui/Layout/Navigation/utils/getName";
import { useAuth } from "collection/graphql/auth";
import styled from "styled-components";
import { Spacing, Typography } from "components/fl-ui/constants";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
const UserProfileIcon = ({ className, onClick }) => {
    const user = useAuth().currentUser;
    if (!user) {
        return null;
    }
    const { firstInitial, lastInitial } = getName(user);
    return (React.createElement("button", { className: className, onClick: onClick },
        React.createElement("span", { className: "initials" },
            React.createElement("span", { className: "first-initial" }, firstInitial),
            React.createElement("span", { className: "last-initial" }, lastInitial))));
};
export default styled(UserProfileIcon) `
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    border-radius: 100%;
    background-color: ${styleVars.header_color};
    font-size: ${Typography.sizes.sm};
    font-weight: ${Typography.weights.medium};
    color: ${styleVars.header_backgroundColor};
    text-align: center;
    border: 3px solid ${styleVars.header_color};
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
    
    .initials {
        .first-initial {
            padding: ${Spacing.spacing4} ${Spacing.spacing2} 0 0;
        }

        .last-initial {
            padding: ${Spacing.spacing4} 0 0 0;
        }
    }
   
`;
