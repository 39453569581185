import usePlan from "billing/components/PlanLists/usePlan";
import { useBillingProducts } from "billing/providers/BillingProductsProvider";
import cx from "classnames";
import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import numeral from "numeral";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";

const SecondaryPlan = styled(({ className, plan }) => {
  const { description, interval, price, selected, setSelected } = usePlan(plan);

  return (
    <li
      className={cx(className, selected && "selected")}
      data-cy="plan"
      data-cy-plan={plan.id}
      data-cy-plan-display="secondary"
    >
      <div className="price">
        <h2>{plan.name}</h2>
        <div>
          {numeral(price).format("$0,0.00")} / {interval}
        </div>
      </div>

      <p className="description">{description}</p>

      <div>
        <Button color={selected ? "primary" : "secondary"} onClick={setSelected}>
          {selected ? "Selected" : "Select plan"}
        </Button>
      </div>
    </li>
  );
})`
  align-items: center;
  background: #fafafb;
  border: 3px solid transparent;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  justify-content: space-around;
  padding: 16px 32px;
  &.selected {
    border-color: #1e7ddc;
  }

  .price {
    text-align: center;
    h2 {
      margin: 0 0 5px;
    }
  }

  .description {
    flex-grow: 2;
    line-height: 1.4;
    margin: 0;
    max-width: 380px;
  }

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    flex-direction: column;
    gap: 10px;
    .price {
    }
  }
`;

const SecondaryPlanList = () => {
  const { secondaryPlans } = useBillingProducts();

  return secondaryPlans.length === 0 ? null : (
    <ul>
      {secondaryPlans.map((plan) => (
        <SecondaryPlan key={plan.id} plan={plan} />
      ))}
    </ul>
  );
};

export default SecondaryPlanList;
