import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { cacheConfig } from "collection/graphql/cache/defaultCache";
import { authLink } from "collection/graphql/client/links/authLink";
import errorLink from "collection/graphql/client/links/errorLink";
import { restLink } from "collection/graphql/client/links/restLink";
export const createSSOAuthWorkflowClient = () => {
    return new ApolloClient({
        assumeImmutableResults: true,
        // @ts-ignore
        cache: new InMemoryCache(cacheConfig),
        link: ApolloLink.from([
            authLink,
            errorLink,
            restLink,
            new HttpLink({
                uri: "/v2.0/grain-marketing/graphql",
                credentials: "same-origin", // allow auth cookies to pass through
            }),
        ]),
    });
};
const ssoAuthWorkflowClient = createSSOAuthWorkflowClient();
export default ssoAuthWorkflowClient;
