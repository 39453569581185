import React from "react";
import { FileAttachmentsBlankSlateBanner } from "components/advertisements/banners/FileAttachmentsBlankSlateBanner";
import PayGateCommonBlankSlatePage from "components/advertisements/pages/PayGateCommonBlankSlatePage";
const FileAttachmentsBlankSlatePage = () => {
    const steps = [
        "Click on any of your fields to view the field detail page",
        'Scroll down to find "Attachments". Select "Add attachment" and upload file',
        "Once files have been uploaded, you can view all attached files related to a specific field across all years as well as preview, rename, download, or delete attached files",
    ];
    return React.createElement(PayGateCommonBlankSlatePage, { banner: FileAttachmentsBlankSlateBanner, steps: steps });
};
export default FileAttachmentsBlankSlatePage;
