import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";
import { PRICE_SNAPSHOT_FRAGMENT } from "collection/graphql/marketing/fragments/PriceSnapshotFragment";

export default gql`
  query getCommodityQuotes($startDate: Date!, $endDate: Date!, $skipContracts: Boolean!) {
    commodityQuotes: getCommodityQuotes(startDate: $startDate, endDate: $endDate) {
      commodity {
        ...Commodity
      }
      contracts @skip(if: $skipContracts) {
        dailyChange
        lastPrice
        month
        name
        spread
        symbol
        year
      }
      priceSnapshot {
        ...PriceSnapshotFragment
        currentOverMovingAverage20Day
        movingAverage20Day
      }
    }
  }
  ${COMMODITY}
  ${PRICE_SNAPSHOT_FRAGMENT}
`;
