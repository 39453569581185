import React from "react";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
const ConnectionsBlankSlateBanner = () => {
    const bannerProps = {
        actionButtonText: "Upgrade Plan",
        imagePath: "images/pay_gates/banners/connections.svg",
        secondaryText: "Machine Data Connections requires a paid subscription.",
        title: "Connections",
    };
    return (React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps, { size: "lg" }),
        React.createElement("ul", null,
            React.createElement(ValuePropListItem, null, "With Connections you can link your accounts with other data providers to Bushel Farm, automatically and manually synchronize data, and get the full power of Bushel Farm with less data entry."))));
};
export { ConnectionsBlankSlateBanner };
