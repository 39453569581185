const EnterpriseTypePolicy = {
    fields: {
        options: {
            read(cachedValue) {
                return cachedValue || {};
            },
        },
    },
};
export default EnterpriseTypePolicy;
