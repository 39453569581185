import { css } from "aphrodite/no-important";
import classNames from "classnames";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import BackLink from "components/fl-ui/Layout/BackLink";
import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import styles from "components/fl-ui/Layout/styles/headerStyles";
const Header = ({ title, description, backLink = "", noBorder = false, children = null, style, className = "", alignChildrenLeft = false, mobile = false, fullWidth = false, }) => (React.createElement(Row, { className: css(styles.headerContainer, mobile && styles.noPaddingBottom) },
    React.createElement(Col, { xs: true },
        React.createElement("div", { className: css(styles.headerBorder, noBorder && styles.noBorder, mobile && styles.noPaddingBottom) },
            React.createElement("div", { className: css(styles.headerContent, mobile && styles.inlineChildren) },
                title && (React.createElement("h1", { className: classNames(css(styles.h1, backLink && styles.h1_wBackLink), className), style: style },
                    backLink && React.createElement(BackLink, { backLink: backLink }),
                    title && React.createElement("span", { className: css(styles.h1_fieldName) }, title),
                    description && (React.createElement("div", { className: `info ${mobile ? "heading-info-new-line" : ""}` }, Array.isArray(description) ? (React.createElement("span", null,
                        React.createElement("span", { className: "heading-info-first-section" }, description[0]),
                        description[1] && React.createElement("span", null,
                            " - ",
                            description[1]))) : (description))))),
                React.createElement("div", { className: css(styles.headerChildren, alignChildrenLeft && styles.headerChildren_alignLeft, fullWidth && styles.headerChildren_fullWidth) }, children)),
            React.createElement("div", { className: css(styles.toolbarContainer, (!children || alignChildrenLeft) && styles.toolbarContainer_noChildren) },
                React.createElement(Toolbar, null))))));
export default Header;
