import { cloneDeep } from "lodash";

import deleteRecipe from "collection/graphql/recipes/hooks/lib/deleteRecipe";
import saveRecipe from "collection/graphql/recipes/hooks/lib/saveRecipe";
import { GET_PRODUCT_RECIPES } from "collection/graphql/recipes/queries";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useRestQuery from "hooks/useRestQuery";

/**
 * Returns an array of functions for (in order) creating, updating, and deleting a {@link ProductRecipe}.
 */
const useProductRecipeMutations = () => {
  const { currentEnterprise } = useCurrentEnterprise();
  const { refetch } = useRestQuery(GET_PRODUCT_RECIPES);

  /**
   * Creates a {@link ProductRecipe}.
   * @param {ProductRecipe} productRecipe
   * @return {Promise<ProductRecipe>}
   */
  const create = async (productRecipe) => {
    productRecipe = { ...cloneDeep(productRecipe), enterpriseId: currentEnterprise.id };
    const savedRecipe = await saveRecipe(productRecipe);
    await refetch();

    return savedRecipe;
  };

  /**
   * Updates a {@link ProductRecipe}.
   * @param productRecipe
   * @return {Promise<ProductRecipe>}
   */
  const update = async (productRecipe) => {
    const savedRecipe = await saveRecipe(productRecipe);
    await refetch();

    return savedRecipe;
  };

  /**
   * Deletes a {@link ProductRecipe}.
   * @param {ProductRecipe|ID} recipe
   * @return {Promise<void>}
   */
  const destroy = async (recipe) => {
    const recipeId = recipe?.id ?? recipe;
    if (recipeId > 0) {
      await deleteRecipe(recipeId);
      await refetch();
    } else {
      return Promise.reject(new Error(`Unable to identify recipe id: ${recipeId}`));
    }
  };

  return [create, update, destroy];
};

export default useProductRecipeMutations;
