import { makeVar } from "@apollo/client";
import { User } from "oidc-client-ts";
const storageKey = `oidc.user:${process.env.KEYCLOAK_AUTH_ISSUER}:${process.env.KEYCLOAK_CLIENT_ID}`;
const getInitialValue = () => {
    const oidcStorage = localStorage.getItem(storageKey);
    return oidcStorage ? User.fromStorageString(oidcStorage) : null;
};
const ssoUserVar = makeVar(getInitialValue());
const persist = (user) => {
    if (!user) {
        localStorage.removeItem(storageKey);
    }
    else {
        localStorage.setItem(storageKey, JSON.stringify(user));
    }
    ssoUserVar.onNextChange(persist);
};
ssoUserVar.onNextChange(persist);
/*
 * When other tabs
 */
window.addEventListener("storage", async (event) => {
    if (event.key === storageKey) {
        const { newValue } = event;
        ssoUserVar(newValue ? User.fromStorageString(newValue) : null);
    }
});
export default ssoUserVar;
