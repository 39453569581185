import React from "react";
import styled from "styled-components";
import { GreyColors } from "components/fl-ui/colors";
import { BorderRadius } from "components/fl-ui/constants";
import { Spacing } from "components/fl-ui/constants";
const EmptyUsersArea = styled(({ className, noUsersText }) => (React.createElement("div", { className: className },
    React.createElement("div", { className: "empty-text" }, noUsersText)))) `
  border-radius: ${BorderRadius.small};
  background: ${GreyColors.smoke94};
  margin-bottom: ${Spacing.spacing16};
  padding: ${Spacing.spacing8};
  border: 1px solid ${GreyColors.smoke90};

  .empty-text {
    padding: ${Spacing.spacing40};
    text-align: center;
  }
`;
export { EmptyUsersArea };
