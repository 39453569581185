import React from "react";
import { Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import Button from "components/fl-ui/Button";
import Fullscreen from "components/fl-ui/util/Fullscreen";
const OverlayContent = styled.div `
  margin: 0 auto;
  max-width: 1000px;
`;
const FullPageOverlay = ({ children, onCancel }) => (React.createElement(Fullscreen, null,
    React.createElement(OverlayContent, null,
        onCancel && (React.createElement(Row, { reverse: true },
            React.createElement(Button, { "data-cy": "closeX", icon: "close", link: true, onClick: onCancel }))),
        React.createElement(React.Fragment, null, children))));
export { FullPageOverlay };
