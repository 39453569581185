import { find, includes } from "lodash";
import { array, object, string } from "yup";

import getCurrentUser from "collection/graphql/auth/queries/getCurrentUser";
import defaultCache from "collection/graphql/cache/defaultCache";

/**
 * Available feature names sourced from the {@link UserPermissionsPolicy} on the [current user]{@link CurrentUser}. These
 * need to be kept in sync with the available features coming from the backend as well as with the {@link UserFeature} typedef.
 * @type {UserFeature[]}
 */
const userFeatures = [
  "access_control",
  "activities",
  "activity_types",
  "activity_type_costs",
  "agreements",
  "commentary",
  "commodities",
  "contracts",
  "equipment",
  "farm_info",
  "fields",
  "imagery",
  "import_export",
  "inputs",
  "integrations",
  "login",
  "market_prices",
  "marketing",
  "planner",
  "profit_and_loss",
  "recommendations",
  "reports",
  "scale_tickets",
  "storage",
  "subscription",
  "work_order_admin",
  "work_order_assignee",
];

const schema = object({
  actions: array()
    .of(string().oneOf(["delete", "read", "write"]))
    .required()
    .min(1)
    .max(3),
  feature: string()
    .oneOf(userFeatures, ({ value, values }) => {
      return `Invalid feature: "${value}. Must be one of the following: ${values}"`;
    })
    .required(),
});

/**
 * Returns a boolean indicating whether a user has the requested feature permissions.
 *
 * @param {UserFeature} feature
 * @param {UserAccessType[]} actions
 * @return {boolean}
 * @example
 * // returns true if the user has both read and write permissions for the activities feature.
 * hasPolicyPermissions("activities", ["read", "write"]);
 */
export const hasPolicyPermissions = (feature, actions) => {
  schema.validateSync({ actions, feature }, { abortEarly: false });

  /** @type {UserFeaturePolicy[]} */
  const policies = defaultCache.readQuery({ query: getCurrentUser })?.currentUser?.policy?.permissions;
  const featureActions = find(policies, { feature })?.actions;

  return actions.every((action) => includes(featureActions, action));
};
