import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Col } from "react-styled-flexboxgrid";

import { Borders, Mixins, Spacing } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  sidebar: {
    borderLeft: Borders.regular,
    position: "relative",
    "@media only screen and (min-width: 57.142em)": {
      minHeight: Mixins.toRem(600),
    },
    "@media only screen and (max-width: 57.142em)": {
      borderLeft: "none",
    },
    "@media print": {
      display: "block",
      width: "100% !important",
      borderLeft: "none",
      borderBottom: Borders.regular,
      minHeight: "unset",
      marginBottom: Spacing.spacing24,
    },
  },
  sidebarContent: {
    width: "100%",
    height: "100%",
    borderBottom: "none",
    "@media only screen and (max-width: 57.142em)": {
      borderBottom: Borders.regular,
      paddingBottom: Spacing.spacing24,
    },
    "@media print": {
      marginBottom: Spacing.spacing24,
    },
  },
});

const ContentSidebar = ({ children }) => (
  <Col xs={12} md={3} lg={3} className={css(styles.sidebar)}>
    <div className={css(styles.sidebarContent)}>{children}</div>
  </Col>
);

export default ContentSidebar;
