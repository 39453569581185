import { SectionHeader } from "activity/common/styles";
import EquipmentImage from "equipment/components/EquipmentImage";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { space } from "styled-system";

import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

export const DefinitionList = styled.dl`
  align-items: center;
  display: grid;
  font-size: ${Typography.sizes.mn};
  grid-column-gap: ${Spacing.spacing4};
  grid-row-gap: ${Spacing.spacing16};
  grid-template-columns: repeat(2, 1fr);

  dt {
    color: ${UIColors.light};
    text-transform: uppercase;
  }
  dd {
    font-weight: ${Typography.weights.bold};
    margin: 0;
    text-align: right;
  }

  ${space}
`;

DefinitionList.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Definition = ({ children, term }) => {
  return children ? (
    <>
      <dt>{term}</dt>
      <dd>{children}</dd>
    </>
  ) : null;
};

Definition.propTypes = {
  children: PropTypes.node.isRequired,
  term: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

const ImplementDefinitions = (props) => {
  const { isRented, manufactureYear, notes } = props.implement;

  return (
    <DefinitionList>
      <Definition term="Manufacture Year">{manufactureYear}</Definition>
      <Definition term="Ownership">{isRented ? "Rented" : "Owned"}</Definition>
      <Definition term="Notes">{notes}</Definition>
    </DefinitionList>
  );
};

const TractorDefinitions = (props) => {
  const { fuelCapacity, horsepower, make, model, notes, isRented, year } = props.tractor;

  return (
    <DefinitionList mt={Spacing.spacing32}>
      <Definition term="Year">{year}</Definition>
      <Definition term="Make">{make}</Definition>
      <Definition term="Model">{model}</Definition>
      <Definition term="Horsepower">
        {horsepower} <abbr title="horsepower">hp</abbr>
      </Definition>
      <Definition term="Fuel capacity">
        {fuelCapacity} <abbr title="gallons">gal</abbr>
      </Definition>
      {isRented !== null && <Definition term="Ownership">{isRented ? "Rented" : "Owned"}</Definition>}
      <Definition term="Notes">{notes}</Definition>
    </DefinitionList>
  );
};

const EquipmentDetailSummary = (props) => {
  const { equipment, onEdit, type } = props;
  const isTractor = type === "tractor";
  const label = isTractor ? "Tractor details" : "Implement details";

  return (
    <div>
      <SectionHeader label={label} borderless />
      <EquipmentImage icon={equipment.icon} name={equipment.name} type={type} />

      {isTractor && <TractorDefinitions tractor={equipment} />}
      {!isTractor && <ImplementDefinitions implement={equipment} />}

      <Button onClick={onEdit}>{isTractor ? "Edit tractor" : "Edit implement"}</Button>
    </div>
  );
};

EquipmentDetailSummary.propTypes = {
  equipment: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["implement", "tractor"]).isRequired,
};

EquipmentDetailSummary.defaultProps = {};

export default EquipmentDetailSummary;
