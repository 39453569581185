import React from "react";
import styled from "styled-components";

import { CommentaryExpansionHeight } from "modules/marketing/cards/constants/commentaryExpansionHeight";

import { Spacing } from "components/fl-ui/constants";

const CommentaryBodyArea = styled(({ className, children, expandable, expanded }) => (
  <div className={`${className} ${expanded ? "expanded" : ""} ${expandable ? "expandable" : ""}`}>{children}</div>
))`
  padding-bottom: ${Spacing.spacing20};
  margin-top: ${Spacing.spacing20};
  max-height: ${CommentaryExpansionHeight}px;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  &.expandable {
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
  }

  &.expanded {
    transition: max-height 0.5s ease-in-out;
    max-height: 4000px;
    -webkit-mask-image: none;
  }
`;

export { CommentaryBodyArea };
