import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import React, { Suspense } from "react";
import styled from "styled-components";
import useViewType from "hooks/useViewType";
import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import LoadingSpinner from "components/ui/loading";
const Loading = () => (React.createElement("div", { className: "loading-placeholder" },
    React.createElement("h2", null, "Loading..."),
    React.createElement(LoadingSpinner, null)));
const Main = styled(({ className, children }) => (React.createElement("div", { id: "main", className: `new-navigation ${className}` },
    React.createElement("div", null,
        React.createElement(Suspense, { fallback: React.createElement(Loading, null) }, children || React.createElement(Loading, null)))))) `
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: ${(props) => props.displaySideNavPadding ? `0 0 0 ${styleVars.mainMenuWidth_desktop}` : "0 0 0 0"} !important;

  @media print {
    padding: 0 !important;
  }

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    padding: 0 0 4.25rem 0 !important;
  }

  & > div {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;
const ContentContainer = ({ children }) => {
    const isDesktop = useViewType() === "desktop";
    return (React.createElement(ErrorBoundary, null,
        React.createElement(Main, { displaySideNavPadding: isDesktop }, children)));
};
export default ContentContainer;
