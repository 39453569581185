import React from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import styled from "styled-components";
import useReleaseFeature from "hooks/useReleaseFeature";
import { FinishWalletSetupPage } from "modules/wallet/pages/FinishWalletSetupPage";
import { WalletAccountLockedPage } from "modules/wallet/pages/WalletAccountLockedPage";
import { WalletAvailableNotConnectedPage } from "modules/wallet/pages/WalletAvailableNotConnectedPage";
import { WalletDashboard } from "modules/wallet/pages/WalletDashboard";
import { WalletSignUpPage } from "modules/wallet/pages/WalletSignUpPage";
import Button from "components/fl-ui/Button";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import { Spacing } from "components/fl-ui/constants";
const TransferAndPayButton = styled(({ className }) => (React.createElement("div", { className: className },
    React.createElement(Button, { color: "primary", onClick: () => console.log("Transfer & Pay") },
        React.createElement("span", { className: "transfer-and-pay" }, "Transfer & Pay"),
        React.createElement(IconContainer, { size: Spacing.spacing20 },
            React.createElement(Icon, { icon: "dollar" })))))) `
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const BaseWalletPage = () => {
    const location = useLocation();
    const hasWallet = useReleaseFeature("wallet-enabled");
    if (!hasWallet) {
        return React.createElement(Navigate, { to: "/sso" });
    }
    return (React.createElement(Container, null,
        React.createElement(Header, { title: "Wallet" }, location.pathname.startsWith("/wallet/dashboard/") && React.createElement(TransferAndPayButton, null)),
        React.createElement("div", { className: "base-wallet-page-content" },
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/finish-setup", element: React.createElement(FinishWalletSetupPage, null) }),
                React.createElement(Route, { path: "/not-connected", element: React.createElement(WalletAvailableNotConnectedPage, null) }),
                React.createElement(Route, { path: "/account-locked", element: React.createElement(WalletAccountLockedPage, null) }),
                React.createElement(Route, { path: "/sign-up", element: React.createElement(WalletSignUpPage, null) }),
                React.createElement(Route, { path: "/dashboard/*", element: React.createElement(WalletDashboard, null) })))));
};
export { BaseWalletPage };
