import { css } from "aphrodite";
import styles from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";

import withViewType from "hoc/withViewType";

import { BlankSlate } from "components/fl-ui/BlankSlate";

// The intention is to harmonize this with 'components/fl-ui/BlankSlate' so we have a more useful BlankSlate Component
// https://farmlogs.atlassian.net/browse/CORE-4596

const ResponsiveBlankSlate = ({ children, copy, header, viewType }) => {
  const Header = viewType === "mobile" ? "h3" : "h2";

  return (
    <BlankSlate>
      <Header className={css(styles.lineHeight)}>{header}</Header>
      <p className={css(styles.lineHeight)}>{copy}</p>
      {children}
    </BlankSlate>
  );
};

ResponsiveBlankSlate.propTypes = {
  copy: PropTypes.any,
  header: PropTypes.string,
  viewType: PropTypes.string,
};

export default withViewType(ResponsiveBlankSlate);
