import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import styled from "styled-components";

import { ChangeUserAccessLevelFormSchema } from "modules/settings/pages/Users/components/forms/ChangeUserAccessLevelFormSchema";
import { useCreateAndEditUser } from "modules/settings/pages/Users/hooks/useCreateAndEditUser";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form } from "components/fl-ui/Form";
import Spacing from "components/fl-ui/constants/spacing";

const ChangeUserAccessLevelForm = styled(({ className, onClose, user }) => {
  const methods = useForm({
    defaultValues: ChangeUserAccessLevelFormSchema.cast(
      {
        accessLevel: user.role || "",
      },
      { assert: false, stripUnknown: true },
    ),
    mode: "onTouched",
    resolver: yupResolver(ChangeUserAccessLevelFormSchema),
  });

  const accessLevelOptions = [
    { id: "admin", label: "Admin", value: "admin", name: "Admin" },
    { id: "manager", label: "Manager", value: "manager", name: "Manager" },
    { id: "contributor", label: "Contributor", value: "contributor", name: "Contributor" },
    { id: "no_access", label: "No access", value: "no_access", name: "No access" },
  ];

  const { onSave } = useCreateAndEditUser();

  const onSubmit = async (data) => {
    const payload = {
      id: user.id,
      role: data.accessLevel,
      enterpriseId: user.enterpriseId,
    };

    await onSave(payload);
  };

  return (
    <FormProvider {...methods} schema={ChangeUserAccessLevelFormSchema}>
      <div className={className}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup
            name="accessLevel"
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={accessLevelOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                value={accessLevelOptions.find((opt) => opt.value === field.value) || null}
                onChange={(selectedOption) => field.onChange(selectedOption.value)}
              />
            )}
          />

          <br />

          <div className="footer-buttons">
            <Button color="primary" link onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              className="save-button"
              color="primary"
              disabled={!_.isEmpty(methods.formState.errors)}
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </FormProvider>
  );
})`
  padding: ${Spacing.spacing20} 5% 0 5%;

  .top-text {
    text-align: left;
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
    gap: ${Spacing.spacing12};
    margin-top: ${Spacing.spacing20};

    button {
      margin-right: 0;
    }
  }
`;

export { ChangeUserAccessLevelForm };
