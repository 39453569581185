import { useWindowResize } from "beautiful-react-hooks";
import { useState } from "react";
const useViewType = () => {
    /* 1001px is a magic number added to prevent a crash at 1000px. */
    const desktopBreakpoint = "1001px";
    const getViewType = () => (matchMedia(`(min-width: ${desktopBreakpoint})`).matches ? "desktop" : "mobile");
    const [viewType, setViewType] = useState(getViewType());
    useWindowResize(() => setViewType(getViewType()));
    return viewType;
};
export default useViewType;
