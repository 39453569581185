import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { UIColors } from "components/fl-ui/colors/";
import { Mixins, Spacing } from "components/fl-ui/constants/";

const DEFAULT_SIZE = Spacing.spacing48;
const DEFAULT_COLOR = UIColors.regular;

const ColorSpinner = ({ size, color, className }) => {
  const styles = StyleSheet.create({
    spinner_container: {
      width: size,
      height: size,
      margin: "0 auto",
    },
    spinner: {
      ":before": {
        ...Mixins.beforeAfter,
        width: size,
        height: size,
        margin: 0,
        borderRadius: "100px",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: `${color} transparent ${color} ${color}`,
        animation: "spinner 2.5s",
        animationTimingFunction: "ease",
        animationIterationCount: "infinite",
      },
    },
  });

  return (
    <div className={classNames(css(styles.spinner_container), className)}>
      <span className={css(styles.spinner)} />
    </div>
  );
};

ColorSpinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

ColorSpinner.defaultProps = {
  size: DEFAULT_SIZE,
  color: DEFAULT_COLOR,
};

export default ColorSpinner;
