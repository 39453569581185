import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";
import { UNDERLYING_FRAGMENT } from "collection/graphql/marketing/fragments/UnderlyingFragment";

export default gql`
  fragment EditableBaseContract on BaseContract {
    commodity {
      ...Commodity
    }
    contractDate
    contractSource
    contractStatus
    contractSubtype @client
    enabled
    id
    marketedCrop {
      allowsSpeculativeContracts @client
      id
      name
      commodity {
        ...Commodity
      }
      underlyings {
        ...UnderlyingFragment
      }
      year
    }
    notes
    quantityUnit
    underlying {
      ...UnderlyingFragment
    }
  }

  ${COMMODITY}
  ${UNDERLYING_FRAGMENT}
`;
