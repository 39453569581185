import SummaryItem from "./SummaryItem";
import TabularReport from "./TabularReport";
import { css } from "aphrodite/no-important";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

import reportStyleSheet from "modules/reports/components/reportStyleSheet";

import CropBadge from "components/fl-ui/CropBadge";

const CropPositionReport = ({ data }) => {
  const { commodity, contracts, headers, production_summary, year } = data;
  const title = `${year} ${commodity.name}`;

  return (
    <Row className={css(reportStyleSheet.cropPosition)}>
      <Col xs>
        <div className={css(reportStyleSheet.header_wCropBadge)}>
          <CropBadge marginRight color={commodity.color} abbr={commodity.abbr} />
          <h2>{title}</h2>
        </div>

        <div className={css(reportStyleSheet.summaryContainer)}>
          {headers.map((h, index) => (
            <SummaryItem key={h.title} title={h.title} value={h.value} />
          ))}
        </div>

        <TabularReport
          title="Production & Revenue Summary"
          rows={production_summary.rows}
          columns={production_summary.columns}
          total={production_summary.total}
          showResultCount={false}
          className={css(reportStyleSheet.reportTable)}
        />

        {contracts.rows.length ? (
          <TabularReport
            title="Contracts"
            rows={contracts.rows}
            columns={contracts.columns}
            total={contracts.total}
            showResultCount={false}
            className={css(reportStyleSheet.reportTable)}
          />
        ) : (
          <p>There is no contract data for {title}.</p>
        )}
      </Col>
    </Row>
  );
};

export default CropPositionReport;
