import { PRICELINE_STATUSES } from "contracts/constants/pricelineStatuses";
import formatCurrency from "marketing/cards/utils/formatCurrency";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { CropsColorPalette } from "components/fl-ui/colors/palette";
import { BorderRadius, Spacing } from "components/fl-ui/constants";

const PriceWithSubtext = styled(({ className, price, status }) => (
  <div className={className}>
    {price ? <span className="price">{formatCurrency(price, 4)}</span> : "--"}
    {status && <div className="subtext">{status}</div>}
  </div>
))`
  display: inline-block;
  font-size: 0.9em;
  height: fit-content;
  text-align: right;

  .price {
    background-color: ${(props) => {
      if (!props.status) {
        return "inherit";
      }
      return props.status === PRICELINE_STATUSES.final
        ? CropsColorPalette["seafoam-green"]
        : CropsColorPalette["light-yellow"];
    }};
    border-radius: ${BorderRadius.xsmall};
    color: ${(props) => {
      if (!props.status) {
        return "inherit";
      }
      return props.status === PRICELINE_STATUSES.final ? CropsColorPalette["dark-green"] : CropsColorPalette["brown"];
    }};
    padding: 1px ${Spacing.spacing4};
  }
`;

export default PriceWithSubtext;

PriceWithSubtext.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
};
