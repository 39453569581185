import * as yup from "yup";
const firstName = yup
    .string()
    .label("First Name")
    .required()
    .max(100, "First Name cannot be longer than 100 characters");
const lastName = yup.string().label("Last Name").required().max(100, "Last Name cannot be longer than 100 characters");
const farmName = yup.string().label("Farm Name").required().max(100, "Farm Name cannot be longer than 100 characters");
const postalCode = yup
    .string()
    .label("Postal Code")
    .required()
    .max(10, "Postal Code cannot be longer than 10 characters");
const howDidYouHearAboutUs = yup.object().label("How did you hear about Bushel Farm?").required();
const receiveNotificationEmails = yup.boolean().required();
const SSOSignUpFormSchema = yup.object().shape({
    firstName,
    lastName,
    farmName,
    postalCode,
    howDidYouHearAboutUs,
    receiveNotificationEmails,
});
export { SSOSignUpFormSchema };
