import { css } from "aphrodite/no-important";
import FuturesPricesTable from "prices/components/FuturesPricesTable";
import styles from "prices/styles/futuresTabStyles";
import React from "react";

import CropBadge from "components/fl-ui/CropBadge";

const ContractsByCommodity = ({ commodity, contracts }) => {
  return (
    <div>
      <div className={css(styles.listingHeader)}>
        <div className={css(styles.listingTitle)}>
          <CropBadge marginRight color={commodity.color} abbr={commodity.abbr} />

          <h1>{commodity.name} futures</h1>
        </div>
      </div>

      <FuturesPricesTable commodity={commodity} contracts={contracts} />
    </div>
  );
};

export default ContractsByCommodity;
