import { gql } from "@apollo/client";

export default gql`
  mutation createUser($input: CreatePersonInput!) {
    person: createPerson(input: $input) @rest(type: "LegacyPerson", path: "/api/people", method: "POST") {
      id
      created
      email
      enterpriseId
      firstName
      icon
      isArchived
      landingPage
      lastLogin
      lastName
      name
      notes
      phone
      role
      updated
    }
  }
`;
