import cx from "classnames";
import React from "react";
const Tab = ({ children, className, active, onClick, size, disabled }) => (React.createElement("div", { className: cx("tab", { active, [size || ""]: size, disabled }, className), onClick: disabled ? undefined : onClick, onKeyDown: () => null, role: "button", tabIndex: disabled ? -1 : 0, "aria-disabled": disabled }, children));
const Tabs = ({ className, onChange, children, value, size }) => (React.createElement("div", { className: cx("tabs", "horizontal-tabs", className) }, React.Children.map(children, (child) => {
    var _a;
    if (!child)
        return null;
    if (React.isValidElement(child)) {
        return React.cloneElement(child, Object.assign(Object.assign({}, child.props), { size, active: "active" in child.props ? child.props.active : value === ((_a = child.props) === null || _a === void 0 ? void 0 : _a.value), onClick: () => { var _a; return onChange((_a = child.props) === null || _a === void 0 ? void 0 : _a.value); } }));
    }
    return null;
})));
export { Tab };
export default Tabs;
