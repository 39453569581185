import { getAppRedirectUrl } from "sso/getAppRedirectUrl";
import React from "react";
import useWindow from "hooks/useWindow";
const RedirectionProvider = ({ children }) => {
    const location = useWindow().location;
    const urlString = location.toString();
    const appRedirectUrl = getAppRedirectUrl(urlString);
    if (appRedirectUrl) {
        location.replace(appRedirectUrl);
        return null;
    }
    return React.createElement(React.Fragment, { key: "RedirectionProvider" }, children);
};
export default RedirectionProvider;
