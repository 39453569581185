import { bool, number, object, string } from "yup";
import { getUnitTypes } from "lib/units";
import _ from "lodash";
const ChemicalProductSchema = object().shape({
    brand: string().trim().nullable().label("Brand").default(""),
    chemicalType: string()
        .label("Chemical type")
        .lowercase()
        .required()
        .oneOf(["adjuvant", "fungicide", "herbicide", "insecticide"]),
    density: number().min(0).nullable().label("Density"),
    epaNumber: string().default("").label("EPA number"),
    id: number().optional().positive().integer().label("Product ID"),
    name: string().trim().required().label("Chemical name"),
    notes: string().optional().default("").label("Notes"),
    restrictedUse: bool().nullable().default(null).label("Restricted"),
    type: string().lowercase().oneOf(["chemical"]).required().label("Type"),
    unitType: string()
        .lowercase()
        .oneOf(getUnitTypes().map(_.toLower), "Invalid ${path}")
        .required()
        .label("Unit type"),
});
export default ChemicalProductSchema;
