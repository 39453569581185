import { gql } from "@apollo/client";

export default gql`
  query getAdvisorRecommendations {
    getAdvisorRecommendations {
      averageSoldPrice
      commodity {
        commodityId
        name
      }
      cropYear
      updated
      id
      recommendedPercentage
    }
  }
`;
