import React, { createContext, useContext } from "react";
export const SSOContext = createContext(undefined);
const SSOContextProvider = ({ children }) => {
    //This is still very confusing, the Zustand store is being created here by some magic
    // and overriding all the context here.
    // I'd like to delete it completely in the future, but this was already a massive derailment.
    return (
    // @ts-ignore
    React.createElement(SSOContext.Provider, { value: null }, children));
};
const useSSOContext = () => {
    const context = useContext(SSOContext);
    if (!context) {
        throw new Error("useSSOContext must be used within a SSOContextProvider");
    }
    return context;
};
export { SSOContextProvider, useSSOContext };
