import { css, StyleSheet } from "aphrodite/no-important";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { getCropStylesFromColor } from "components/fl-ui/colors/crops";
import { BorderRadius, Spacing, Typography } from "components/fl-ui/constants";

const getCropBadgeStyles = ({ color, fontSize, marginRight, maxWidth, size, style }) => {
  return StyleSheet.create({
    cropColorStripe: {
      height: "2.5rem",
      marginRight: Spacing.spacing16,
      minWidth: Spacing.spacing8,
    },
    styles: {
      borderRadius: BorderRadius.medium,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: size,
      maxWidth: maxWidth || size,
      height: size,
      padding: 0,
      textAlign: "center",
      fontSize: fontSize ? Typography.sizes[fontSize] : Typography.sizes.rg,
      fontWeight: Typography.weights.medium,
      marginRight: marginRight ? Spacing.spacing16 : 0,
      ...getCropStylesFromColor(color),
      ...style,
    },
  });
};

const CropBadge = (props) => {
  if (props.color) {
    const { cropColorStripe, styles } = getCropBadgeStyles(props);
    return <div className={cx(css(styles, props.stripe && cropColorStripe), props.className)}>{props.abbr || ""}</div>;
  }

  return <span />;
};

CropBadge.propTypes = {
  abbr: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  marginRight: PropTypes.bool,
  maxWidth: PropTypes.number,
  size: PropTypes.number,
  stripe: PropTypes.bool,
  style: PropTypes.object,
};

CropBadge.defaultProps = {
  size: 34,
  style: {},
};

export default CropBadge;
