import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import styled from "styled-components";
import { Spacing } from "components/fl-ui/constants";
export const WidgetHeader = styled.div `
  display: flex;
  justify-content: space-between;
  margin: ${Spacing.spacing16} 0 ${Spacing.spacing8};

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    margin-top: ${Spacing.spacing16};
  }
`;
