import { MarketingCropCardButtons } from "marketing/cards/components";
import React from "react";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const MarketingCropCardWrap = styled(({ className, children, marketedCrop }) => {
  const showOverlay = marketedCrop && marketedCrop.__typename !== "MarketedCrop";
  return (
    <div className={className}>
      <div className="body">{children}</div>
      {showOverlay && (
        <div className="overlay">
          <MarketingCropCardButtons marketedCrop={marketedCrop} />
        </div>
      )}
    </div>
  );
})`
  border-radius: ${BorderRadius.large};
  border: ${Borders.regular};
  background: ${UIColors.white};
  padding: ${Spacing.spacing16};
  @media only screen and (min-width: 62.5em) : {
    padding: ${Spacing.spacing24};
  }
  position: relative;

  .body {
    background-color: transparent;
    height: 100%;
    position: relative;

    &:last-child {
      border-bottom: none;
    }
  }

  .overlay {
    background-color: ${UIColors.overlayLight};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export default MarketingCropCardWrap;
