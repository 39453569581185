import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";

import { NOTIFICATION_DISMISS } from "lib/metrics/events";

import { CloseX } from "components/fl-ui/Icons";
import { ColorPalette } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography, BorderRadius } from "components/fl-ui/constants";

const ToastContainer = styled.div`
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${BorderRadius.small};
  box-shadow: 0 2px 2px hsla(0, 0%, 0%, 0.05);
  color: ${Typography.colors.primary};
  display: flex;
  font-size: ${Typography.sizes.lg};
  font-weight: ${Typography.weights.medium};
  gap: ${Spacing.spacing16};
  justify-content: space-between;
  left: 50%;
  max-width: ${Mixins.toRem(480)};
  min-width: ${Mixins.toRem(100)};
  padding: ${Spacing.spacing12};
  position: fixed;
  top: ${Mixins.toRem(95)};
  transform: translateX(-50%);
  width: auto;
  z-index: 9999;

  a {
    text-decoration: underline;
  }
  > :last-child {
    color: ${Typography.colors.primary};
    min-width: ${Mixins.toRem(24)};
  }
`;

const Toast = ({ onDismiss, message, status }) => {
  const styleProps = useMemo(() => {
    switch (status) {
      case "error":
        return {
          backgroundColor: ColorPalette["medium-red"],
        };

      case "success":
        return {
          backgroundColor: ColorPalette["seafoam-green"],
        };

      case "warning":
        return {
          backgroundColor: ColorPalette["light-yellow"],
        };

      default:
        throw new Error(`Invalid status "${status}"`);
    }
  }, [status]);

  const handleDismiss = () => {
    NOTIFICATION_DISMISS.track({ message });
    onDismiss();
  };

  return (
    <ToastContainer {...styleProps}>
      <span>{message}</span>
      <CloseX onClick={handleDismiss} />
    </ToastContainer>
  );
};

Toast.propTypes = {
  backgroundColor: PropTypes.string,
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  status: PropTypes.oneOf(["error", "warning", "success"]).isRequired,
};

export default Toast;
