import { openStore } from "mobile/mobileAppUpdate";
import React, { useEffect } from "react";
import styled from "styled-components";
import { trackMobileForcedAppUpdatePresentation } from "lib/metrics/events/trackEvents";
import Button from "components/fl-ui/Button";
import { Logo } from "components/fl-ui/Icons";
import { Spacing, Typography } from "components/fl-ui/constants";
const CenteredWrapper = styled.div `
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${Spacing.spacing48};
  padding: ${Spacing.spacing16};
`;
const TitleText = styled.h1 `
  font-size: ${Typography.sizes.xxl};
`;
const MessageText = styled.p `
  display: flex;
  flex-flow: column;
  font-size: ${Typography.sizes.md};
  margin-bottom: ${Spacing.spacing32};
  text-align: center;
`;
const MobileForcedAppUpdateRequired = () => {
    useEffect(() => {
        trackMobileForcedAppUpdatePresentation("EXPIRED");
    }, []);
    return (React.createElement(CenteredWrapper, null,
        React.createElement(Logo, null),
        React.createElement(TitleText, null, "App Update Required"),
        React.createElement(MessageText, null, "This version of the app is no longer supported. Please update to the latest version."),
        React.createElement(Button, { color: "primary", onClick: openStore, size: "lg" }, "Update")));
};
export default MobileForcedAppUpdateRequired;
