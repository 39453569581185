import { find } from "lodash";
import React from "react";
import { useAuth } from "collection/graphql/auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import usePermissions from "hooks/usePermissions";
import SettingsNav from "modules/settings/components/SettingsNav";
import { useSettingsPageContext, withSettingsPageContext } from "modules/settings/context/settingsPageContext";
import { ActivitiesSettingsPage } from "modules/settings/pages/ActivitySettingsPage";
import { BillingSettingsPage } from "modules/settings/pages/BillingSettingsPage";
import { CommoditiesSettingsPage } from "modules/settings/pages/Commodities/pages/CommoditiesSettingsPage";
import { ConnectionsSettingsPage } from "modules/settings/pages/Connections/ConnectionsSettingsPage";
import { DataSettingsPage } from "modules/settings/pages/DataSettingsPage";
import { ExpenseAndRevenueSettingsPage } from "modules/settings/pages/ExpenseAndRevenueSettingsPage";
import { FarmSettingsPage } from "modules/settings/pages/FarmSettingsPage";
import { InputsSettingsPage } from "modules/settings/pages/InputsSettingsPage";
import { NotificationsSettingsPage } from "modules/settings/pages/NotificationsSettingsPage";
import { UserSettingsPage } from "modules/settings/pages/Users/UserSettingsPage";
import { WalletSettingsPage } from "modules/settings/pages/Wallet/WalletSettingsPage";
import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";
import LoadingSpinner from "components/ui/loading";
const settingsPageInformation = [
    { component: ActivitiesSettingsPage, tabId: "activities", title: "Activities" },
    { component: NotificationsSettingsPage, tabId: "alerts", title: "Alert Settings" },
    { component: BillingSettingsPage, tabId: "billing", title: "Billing Settings" },
    { component: CommoditiesSettingsPage, tabId: "commodities", title: "Commodities" },
    { component: ConnectionsSettingsPage, tabId: "connections", title: "Connections" },
    { component: DataSettingsPage, tabId: "data", title: "Data Settings" },
    { component: ExpenseAndRevenueSettingsPage, tabId: "expense_and_revenue", title: "Expense & Revenue Accounts" },
    { component: FarmSettingsPage, tabId: "farm", title: "Farm Settings" },
    { component: InputsSettingsPage, tabId: "inputs", title: "Input Settings" },
    { component: UserSettingsPage, tabId: "users", title: "Users Settings" },
    { component: WalletSettingsPage, tabId: "wallet", title: "Wallet Settings" },
];
const tabIdToPermission = (tabId) => {
    const map = {
        activities: ["activity_types", "write"],
        billing: ["subscription", "read"],
        data: ["import_export", "read"],
        farm: ["farm_info", "read"],
        inputs: ["inputs", "read"],
        users: ["access_control", "read"],
    };
    return map[tabId];
};
const SettingsPage = withSettingsPageContext(({ activeRoute, subRoute }) => {
    const { isLoggingOut } = useAuth();
    const [permission, checkType] = tabIdToPermission(activeRoute) || [];
    const { canRead, canWrite } = usePermissions();
    let canAccess = true;
    if (permission) {
        if (checkType === "read") {
            canAccess = canRead(permission);
        }
        else if (checkType === "write") {
            canAccess = canWrite(permission);
        }
    }
    const { mainNavIsVisible } = useSettingsPageContext();
    const activeSettingsPage = find(settingsPageInformation, { tabId: activeRoute });
    const PageComponent = activeSettingsPage === null || activeSettingsPage === void 0 ? void 0 : activeSettingsPage.component;
    useDocumentTitle(activeSettingsPage === null || activeSettingsPage === void 0 ? void 0 : activeSettingsPage.title);
    return (React.createElement("div", { className: "analytics-page container-fluid" },
        React.createElement("div", { className: "headline row-fluid" },
            React.createElement("div", { className: "col xs-12" },
                React.createElement("div", { className: "with-toolbar no-border" },
                    React.createElement("h1", null, "Settings"),
                    React.createElement("div", { id: "toolbar" },
                        React.createElement(Toolbar, null))))),
        React.createElement("div", { className: "row-fluid" },
            mainNavIsVisible && (React.createElement("div", { className: "col xs-12" },
                React.createElement(SettingsNav, { activeTab: activeRoute }))),
            React.createElement("div", { className: "col xs-12 margin-top-10 settings-content" },
                React.createElement("div", { className: "js-tab-content tab-content" }, isLoggingOut ? (React.createElement(LoadingSpinner, null)) : (React.createElement(React.Fragment, null,
                    canAccess && PageComponent && React.createElement(PageComponent, { subRoute: subRoute }),
                    !canAccess && React.createElement(RestrictedAccessBlankSlate, null))))))));
});
export { SettingsPage };
