import { MobileMaxWidth } from "constants/mobileBreakpointConstants";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { DisconnectFromWalletModal } from "modules/settings/pages/Wallet/DisconnectFromWalletModal";
import { useWalletSettingsInfo } from "modules/settings/pages/Wallet/useWalletSettingsInfo";
import { Button } from "components/fl-ui";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";
const Card = styled.div `
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  padding: ${Spacing.spacing24};
  background-color: white;
  width: 100%;
  max-width: 600px;
`;
const CardTitle = styled.h3 `
  ${Typography.h3};
  margin-bottom: ${Spacing.spacing12};
`;
const CardRow = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing.spacing8} 0;
  border-bottom: ${Borders.regular};

  &:last-child {
    border-bottom: none;
  }
`;
const Label = styled.span ``;
const Value = styled.span `
  color: ${GreyColors.smoke40};
`;
const BottomButtons = styled(({ className }) => {
    const [showModal, setShowModal] = useState(false);
    const viewAdditionalWalletSettings = () => {
        //TODO: Set this up once we are connected with wallet
        console.log("View additional Wallet settings");
    };
    return (React.createElement("div", { className: className },
        React.createElement(Button, { className: "disconnect-button", color: "secondary", display: "flex", size: "sm", onClick: () => setShowModal(true) }, "Disconnect Wallet"),
        React.createElement(Button, { color: "secondary", display: "flex", size: "sm", onClick: viewAdditionalWalletSettings }, "View additional Wallet settings"),
        showModal && React.createElement(DisconnectFromWalletModal, { onClose: () => setShowModal(false) })));
}) `
  max-width: 600px;
  display: flex;
  justify-content: flex-end;
  margin-top: ${Spacing.spacing16};
  gap: ${Spacing.spacing12};

  .disconnect-button {
    color: ${ColorPalette["dark-red"]};
  }

  @media (max-width: ${MobileMaxWidth}) {
    flex-direction: column;
    align-items: center;
    gap: ${Spacing.spacing8};

    button {
      width: 100%;
    }
  }
`;
const WalletSettingsPage = () => {
    const hasWalletAccountLinkedToFarmAccount = true;
    const loading = false;
    const { businessName, dba, handle, address } = useWalletSettingsInfo();
    if (!hasWalletAccountLinkedToFarmAccount) {
        return React.createElement(Navigate, { to: "/wallet/finish-setup" });
    }
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement("h2", null, "Wallet Information"),
        React.createElement(Card, null,
            React.createElement(CardTitle, null, "Business Info"),
            React.createElement(CardRow, null,
                React.createElement(Label, null, "Business Name"),
                React.createElement(Value, null, businessName)),
            React.createElement(CardRow, null,
                React.createElement(Label, null, "DBA"),
                React.createElement(Value, null, dba)),
            React.createElement(CardRow, null,
                React.createElement(Label, null, "Handle"),
                React.createElement(Value, null, handle)),
            React.createElement(CardRow, null,
                React.createElement(Label, null, "Address"),
                React.createElement(Value, null, address))),
        React.createElement(BottomButtons, null)));
};
export { WalletSettingsPage };
