import { css } from "aphrodite/no-important";
import color from "color";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Manager, Reference, Popper } from "react-popper";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { popoverStyles } from "components/fl-ui/Popover/popoverStyles";
import { COLOR } from "components/fl-ui/buttonStyles";
import { Borders, Spacing } from "components/fl-ui/constants";

const DropdownOption = styled.div`
  border-bottom: ${Borders.regular};
  padding: ${Spacing.spacing8} 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  position: relative;
  width: fit-content;

  > button:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 1px solid
      ${(props) =>
        color(props.color ? COLOR[props.color]?.border : "rgba(30, 125, 220)")
          .darken(0.25)
          .string()};
  }
  > .trigger > button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 1px solid
      ${(props) =>
        color(props.color ? COLOR[props.color]?.border : "rgba(30, 125, 220)")
          .lighten(0.25)
          .string()};
  }
`;

const DropdownOptions = ({ containerRef, onSelect, options, optionsRef, style }) => (
  <div
    className={css(popoverStyles.popover)}
    ref={optionsRef}
    style={{
      ...style,
      minWidth: containerRef.current.getBoundingClientRect().width,
    }}
  >
    {options.map((option, i) => (
      <DropdownOption key={i} onClick={() => onSelect(option)}>
        {option.text || option.label}
      </DropdownOption>
    ))}
  </div>
);

const DropdownButton = ({
  button,
  buttonText,
  closeOnSelect,
  color,
  disabled,
  onClick,
  onSelect,
  options,
  placement,
}) => {
  const [shown, setShown] = useState(false);
  const containerRef = useRef();

  const mainButton = button || (
    <Button color={color} disabled={disabled} onClick={onClick}>
      {buttonText}
    </Button>
  );

  const dropdownTrigger = (
    <Button
      className="trigger"
      color={color}
      disabled={disabled}
      icon="chevronDown"
      onClick={(event) => {
        event.preventDefault();
        setShown((shown) => !shown);
      }}
    />
  );

  const handleSelect = (option) => {
    if (closeOnSelect) {
      setShown(false);
    }
    onSelect(option);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      setShown((shown) => (!event.defaultPrevented && shown ? false : shown));
    };

    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <ButtonGroup color={color} ref={containerRef}>
      {mainButton}

      <Manager>
        <Reference>
          {({ ref }) => (
            <span className="trigger" ref={ref}>
              {dropdownTrigger}
            </span>
          )}
        </Reference>

        {shown && (
          <Popper placement={placement}>
            {({ ref, ...rest }) => (
              <DropdownOptions
                {...rest}
                containerRef={containerRef}
                onSelect={handleSelect}
                options={options}
                optionsRef={ref}
              />
            )}
          </Popper>
        )}
      </Manager>
    </ButtonGroup>
  );
};

DropdownButton.propTypes = {
  button: PropTypes.elementType,
  buttonText: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  placement: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
    "right",
    "right-start",
    "right-end",
  ]),
  options: PropTypes.array.isRequired,
};

DropdownButton.defaultProps = {
  closeOnSelect: true,
  color: "primary",
  disabled: false,
  onClick: () => {},
  onSelect: () => {},
  placement: "bottom-end",
};

export default DropdownButton;
