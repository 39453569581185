//All new icons must be added to this array
//Problem is... nothing actually constrains this.
// There are tons of them not added in here, curious if there is a better way of handling this (might delete it entirely).
const IconManifest = [
  "aar",
  "aar2",
  "activities",
  "add",
  "aerial",
  "alertActive",
  "alertInactive",
  "approved",
  "arrowDown",
  "arrowLeft",
  "arrowRight",
  "arrowUp",
  "assignment",
  "boost",
  "bushelLogo",
  "buttonChevronDown",
  "calendar",
  "camera",
  "candlestick",
  "cardsLayout",
  "check",
  "chevronDown",
  "chevronLeft",
  "chevronRight",
  "chevronUp",
  "circleAdd",
  "circleArrowRight",
  "circleCheck",
  "circleClose",
  "circleRemove",
  "clearMap",
  "close",
  "collapse",
  "contracts",
  "deliveryLoads",
  "dismissAll",
  "dollar",
  "doubleArrows",
  "drawCircle",
  "drawFreeform",
  "drawSquare",
  "edit",
  "ellipses",
  "enrollment",
  "equipment",
  "error",
  "expand",
  "expense",
  "fee",
  "fields",
  "flag",
  "harvesting",
  "help",
  "imagery",
  "implements",
  "info",
  "inputs",
  "integrations",
  "irrigation",
  "landCosts",
  "layers",
  "lineChart",
  "listLayout",
  "loads",
  "lock",
  "lockBasis",
  "map",
  "mapPin",
  "marketing",
  "marketingPrices",
  "menu",
  "newWindow",
  "news",
  "noriLogo",
  "notes",
  "notifications",
  "overflow",
  "overflowHorizontal",
  "overflowVertical",
  "people",
  "personWarning",
  "plans",
  "play",
  "pricingWindow",
  "print",
  "profitAndLoss",
  "rainfall",
  "remove",
  "reports",
  "revenue",
  "roll",
  "sbbLogo",
  "scouting",
  "searchMap",
  "settings",
  "signOut",
  "soil",
  "storage",
  "tune",
  "typeToSearch",
  "wallet",
  "warning",
  "warningOutline",
  "wrench",
];

export default IconManifest;
