import { css } from "aphrodite";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import styles from "modules/activity/common/styles";

import { Button } from "components/fl-ui";
import { GroupedButtons } from "components/fl-ui/Buttons";
import Input from "components/fl-ui/Form/Input";
import Popover from "components/fl-ui/Popover/Popover";
import { Spacing } from "components/fl-ui/constants";

const ActivityQuantityInput = (props) => {
  const { amount, disabled, hasError, onChange, placeholder, rates, selectedRate, selectedUnit, units } = props;
  const renderInputSuffix = () => {
    return (
      <Popover
        content={
          <Fragment>
            <GroupedButtons
              hasDescription
              onClick={(selectedUnit) => onChange({ selectedUnit })}
              options={units}
              selected={selectedUnit}
              style={{ padding: `${Spacing.spacing16} 0` }}
              title="Unit"
            />
            <GroupedButtons
              hasDescription
              onClick={(selectedRate) => onChange({ selectedRate })}
              options={rates}
              selected={selectedRate}
              style={{ padding: `${Spacing.spacing16} 0` }}
              title="Rate"
            />
          </Fragment>
        }
        persist
      >
        <Button className={css(styles.dropDownButton)} disabled={disabled} icon="buttonChevronDown" iconRight>
          {`${(selectedUnit && selectedUnit.abbr) || "--"} ${(selectedRate && selectedRate.abbr) || "--"}`}
        </Button>
      </Popover>
    );
  };

  return (
    <Input
      display="block"
      hasError={hasError}
      name="amount"
      onChange={({ target }) => {
        const amount = target.value === "" ? target.value : +target.value;
        onChange({ amount });
      }}
      placeholder={placeholder}
      size="large"
      style={{ marginBottom: Spacing.spacing12 }}
      suffix={renderInputSuffix()}
      type="number"
      value={amount}
    />
  );
};

ActivityQuantityInput.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rates: PropTypes.array,
  selectedRate: PropTypes.object,
  selectedUnit: PropTypes.object,
  units: PropTypes.array,
};

export default ActivityQuantityInput;
