import { Definition, DefinitionList } from "modules/equipment/components/EquipmentDetailSummary";
import React from "react";
import styled from "styled-components";
import { Button } from "components/fl-ui";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";
const ProductMatchCardBase = styled.div `
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ProductMatchCardBody = styled.div `
  padding: ${Spacing.spacing16};
`;
const ProductMatchCardHeader = styled.header `
  background-color: ${GreyColors.smoke90};
  padding: 0 ${Spacing.spacing16};
`;
const ProductMatchDefinitionList = styled(DefinitionList) `
  > dt {
    font-size: 11px;
  }
`;
const ProductMatchCard = ({ match, onEdit }) => {
    const { expectedProduct, matchedProduct, search, unitType } = match;
    const hasExpectedMatch = !!expectedProduct;
    const hasSavedMatch = !!matchedProduct;
    const hasNoMatch = !hasExpectedMatch && !hasSavedMatch;
    const buttonProps = {
        children: hasSavedMatch ? "Edit Bushel Farm Match" : "Set Match",
        color: hasSavedMatch ? "primary" : "danger",
        hollow: true,
        onClick: () => onEdit(match),
        style: { width: "100%" },
    };
    const termTextColor = hasNoMatch ? ColorPalette.white : undefined;
    if (!hasNoMatch) {
        buttonProps.children = "Edit Match";
        buttonProps.color = "primary";
    }
    return (React.createElement(ProductMatchCardBase, null,
        React.createElement(ProductMatchCardHeader, null,
            React.createElement(ProductMatchDefinitionList, null,
                React.createElement(Definition, { term: React.createElement("span", { style: { color: termTextColor } }, "Source Name") }, search || "--"))),
        React.createElement(ProductMatchCardBody, null,
            React.createElement(ProductMatchDefinitionList, null,
                React.createElement(Definition, { term: "Set Match" }, (matchedProduct === null || matchedProduct === void 0 ? void 0 : matchedProduct.name) || "--"),
                React.createElement(Definition, { term: "Auto Match" }, (expectedProduct === null || expectedProduct === void 0 ? void 0 : expectedProduct.name) || "--"),
                React.createElement(Definition, { term: "Product Type" }, (matchedProduct === null || matchedProduct === void 0 ? void 0 : matchedProduct.type) || "--"),
                React.createElement(Definition, { term: "Measurement Type" }, unitType || "--")),
            React.createElement(Button, Object.assign({}, buttonProps)))));
};
export { ProductMatchCard };
