import { css } from "aphrodite/no-important";
import styles from "modules/fields/onboarding/onboardingStyles";
import { SalesPhoneLink, SalesEmailLink } from "modules/billing/lib";
import React from "react";
const OnboardingFooterHelp = () => (React.createElement("p", { className: css(styles.footerText) },
    "Have questions? You can reach us at ",
    React.createElement(SalesPhoneLink, null),
    " or ",
    React.createElement(SalesEmailLink, null),
    "."));
export default OnboardingFooterHelp;
