var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation } from "@apollo/client";
import { pick } from "lodash";
import useFinancialAccounts from "modules/profit_and_loss/hooks/useFinancialAccounts";
import React, { useState } from "react";
import ExpenseAndRevenueList from "modules/settings/components/ExpenseAndRevenueList";
import FinancialAccountAddEditModal from "modules/settings/components/FinancialAccountAddEditModal";
import { Link } from "modules/settings/styles";
import { marketingClient } from "collection/graphql/client";
import { CREATE_FINANCIAL_ACCOUNT, UPDATE_FINANCIAL_ACCOUNT, } from "collection/graphql/profit_and_loss/mutations";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import App from "layout/app";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";
const copyMap = {
    EXPENSE: (React.createElement("span", null,
        "Use expense accounts to track costs not captured by ",
        React.createElement(Link, { to: "/activity" }, "activities"),
        ", ",
        React.createElement(Link, { to: "/land_costs" }, "land costs"),
        ", and",
        " ",
        React.createElement(Link, { to: "/products" }, "input purchases"))),
    REVENUE: "Use revenue accounts to track revenue not associated with your crops",
};
const ExpenseAndRevenueSettingsPage = () => {
    const _a = useFinancialAccounts(), { accountTypes, loading } = _a, data = __rest(_a, ["accountTypes", "loading"]);
    const [accountData, setAccountData] = useState(null);
    const { canRead } = usePermissions();
    const hasAccess = useEnterpriseFeature("profit_and_loss") && canRead("profit_and_loss");
    const [createFinancialAccount, { loading: createLoading }] = useMutation(CREATE_FINANCIAL_ACCOUNT, {
        client: marketingClient,
        refetchQueries: ["GetFinancialAccountsData"],
    });
    const [updateFinancialAccount, { loading: updateLoading }] = useMutation(UPDATE_FINANCIAL_ACCOUNT, {
        client: marketingClient,
    });
    const handleDataMutation = async (accountData) => {
        var _a;
        const account = pick(accountData, ["accountType", "id", "isArchived", "name"]);
        account.name = (_a = account.name) === null || _a === void 0 ? void 0 : _a.trim();
        const mutator = account.id ? updateFinancialAccount : createFinancialAccount;
        try {
            await mutator({ variables: { account } });
            setAccountData(null);
            App.notify("Account saved successfully");
        }
        catch (err) {
            console.error(err);
            App.notify("An error occurred, please try again later");
        }
    };
    return hasAccess ? (React.createElement(LoadingWrapper, { isLoading: loading }, accountTypes === null || accountTypes === void 0 ? void 0 :
        accountTypes.map(({ display, value }) => (React.createElement(ExpenseAndRevenueList, { key: value, copy: copyMap[value], data: data[value], handleCreateData: () => setAccountData({ accountType: value, display }), handleEditData: (data) => setAccountData(Object.assign(Object.assign({}, data), { display })), type: display.toLowerCase() }))),
        accountData && (React.createElement(FinancialAccountAddEditModal, { accountData: accountData, disabled: createLoading || updateLoading, onArchive: () => handleDataMutation(Object.assign(Object.assign({}, accountData), { isArchived: !accountData.isArchived })), onClose: () => setAccountData(null), onInputChange: (_e, newData) => setAccountData(Object.assign(Object.assign({}, accountData), newData)), onSave: () => handleDataMutation(accountData) })))) : (React.createElement(RestrictedAccessBlankSlate, null));
};
export { ExpenseAndRevenueSettingsPage };
