import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import React from "react";
import styled from "styled-components";
import { SignUpDescriptionText } from "modules/wallet/components/sign_up/SignUpDescriptionText";
import { SignUpSecondaryTitle } from "modules/wallet/components/sign_up/SignUpSecondaryTitle";
import { WalletSignUpButton } from "modules/wallet/components/sign_up/WalletSignUpButton";
import { WalletSignUpSection } from "modules/wallet/components/sign_up/WalletSignUpSection";
import { CashYieldPercentageText } from "modules/wallet/constants";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing } from "components/fl-ui/constants";
const WalletSignUpSecondSection = styled(({ className }) => (React.createElement(WalletSignUpSection, { backgroundColor: "grey" },
    React.createElement("div", { className: className },
        React.createElement("br", null),
        React.createElement(SignUpSecondaryTitle, null,
            "Earn ",
            CashYieldPercentageText,
            " on your cash balance."),
        React.createElement("br", null),
        React.createElement(SignUpDescriptionText, null, "Grow your farm\u2019s finances effortlessly with industry-leading returns while keeping your funds secure and accessible. Your funds are insured up to $1,000,000 through FDIC-insured institutions and no minimum balance."),
        React.createElement("br", null),
        React.createElement("img", { className: "savings-graph", src: "images/wallet/savings-graph.png", alt: "Savings Graph" }),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(SignUpSecondaryTitle, null, "Seamlessly connect your financial and farm management accounts."),
        React.createElement("br", null),
        React.createElement(SignUpDescriptionText, null, "Unlock powerful tools and insights by integrating your accounts in one place. It\u2019s easy, secure, and ensures all your critical operations are at your fingertips."),
        React.createElement("br", null),
        React.createElement("div", { className: "phone-images" },
            React.createElement("img", { className: "left-phone", src: "images/wallet/generic-wallet-phone-screen.png", alt: "Generic Phone Image" }),
            React.createElement("img", { className: "right-phone", src: "images/wallet/generic-wallet-phone-screen.png", alt: "Generic Phone Image" })),
        React.createElement("br", null),
        React.createElement("div", { className: "bottom-button-area" },
            React.createElement(WalletSignUpButton, null)))))) `
  margin: 0 auto;
  width: 60%;

  .savings-graph {
    border-radius: ${BorderRadius.xlarge};
    box-shadow: 0 2px 5px ${UIColors.shadowOne};
  }

  .phone-images {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: ${Spacing.spacing60};
    margin: 0 auto;
    padding: ${Spacing.spacing24} 0;

    @media only screen and (max-width: ${PrimaryMaxWidth}) {
      gap: ${Spacing.spacing24};
    }

    img {
      width: 30%;

      @media only screen and (max-width: ${PrimaryMaxWidth}) {
        width: 40%;
      }
    }
  }

  .bottom-button-area {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    width: 100%;
  }
`;
export { WalletSignUpSecondSection };
