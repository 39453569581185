import { SalesEmailLink, SalesPhoneLink } from "billing/lib";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useIntegrations from "hooks/useIntegrations";
import useRestQuery from "hooks/useRestQuery";
import { FIELD_LIST_TRIGGER } from "lib/metrics/events";

import CloseX from "components/fl-ui/Icons/CloseX";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Modal, ModalBody } from "components/fl-ui/Modal/Modal";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
import useFieldData from "fields/hooks/useFieldData";

const StyledWrapText = styled.p`
  overflow-wrap: break-word;
`;

const StyledLogoContainer = styled.div`
  background: ${UIColors.bushelBlue};
  border-radius: 100%;
  height: ${Spacing.spacing48} !important;
  padding: ${Spacing.spacing8};
  width: ${Spacing.spacing48} !important;
`;

const OverLimitContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  line-height: 1.5;
  padding: ${Spacing.spacing16};
  text-align: center;
`;

const trackAdd = () => FIELD_LIST_TRIGGER.track();

const ChooseMethodModal = ({ onClose }) => {
  const { data: subscriptionData } = useRestQuery(getCurrentSubscription);
  const { totalAcreage } = useFieldData();
  const { acreLimit, enforceMaxAcres } = subscriptionData?.subscription || {};
  const isOverLimit = enforceMaxAcres && totalAcreage > acreLimit;
  const { integrations, loading } = useIntegrations();
  const fieldsProviders = _.filter(integrations, _.negate(_.matchesProperty("id", "bushel")));

  return (
    <Modal width={isOverLimit ? 500 : 850}>
      <ModalBody>
        <CloseX className="close-x" onClick={onClose} />

        {isOverLimit ? (
          <OverLimitContainer>
            <StyledLogoContainer>
              <img alt="Bushel Farm" height="" src="/images/app_icons/bushel-farm-icon-white.svg" width="" />
            </StyledLogoContainer>
            <h2>Upgrade required</h2>
            <p>
              Your account has exceeded the {acreLimit} acreage limit for your plan. Please contact our sales team at{" "}
              <SalesPhoneLink /> or email us at <SalesEmailLink /> to upgrade your subscription.
            </p>
          </OverLimitContainer>
        ) : (
          <div className="text-centered">
            <div className="row" style={{ padding: "0 4rem" }}>
              <h2>How would you like to add your fields?</h2>

              <div className="col md-4" style={{ padding: "2rem 0.5rem" }}>
                <img height="75" src="/images/fields/machine-data-integration-icon.png" width="70" />
                <StyledWrapText>Import field boundaries from your connections</StyledWrapText>
                <LoadingWrapper isLoading={loading}>
                  {fieldsProviders?.map((integrationStatus) => {
                    return (
                      <Link
                        className="btn btn-block btn-primary"
                        key={integrationStatus.id}
                        onClick={trackAdd}
                        to="/settings/connections"
                      >
                        {integrationStatus.name}
                      </Link>
                    );
                  })}
                </LoadingWrapper>
              </div>

              <div className="col md-4" style={{ padding: "2rem 0.5rem" }}>
                <img height="75" src="/images/fields/manual-icon2.png" width="65" />
                <StyledWrapText>Use the map to select shapes or draw your own</StyledWrapText>
                <Link
                  className="btn btn-block btn-primary"
                  data-cy="add-field-manually"
                  onClick={trackAdd}
                  style={{ display: "block" }}
                  to="/fields/add"
                >
                  Add Manually
                </Link>
              </div>
              <div className="col md-4" style={{ padding: "2rem 0.5rem" }}>
                <img height="75" src="/images/fields/shapefile-icon2.png" width="70" />
                <StyledWrapText>Add multiple fields at a time by uploading shapefiles</StyledWrapText>
                <Link className="btn btn-block btn-primary" onClick={trackAdd} to="/settings/data/fieldBoundaries">
                  Upload Shapefiles
                </Link>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ChooseMethodModal;
