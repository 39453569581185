import useDocumentTitle from "hooks/useDocumentTitle";
import { useElevateConsent } from "modules/elevate_plus/hooks/useElevateConsent";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFarmAdvisorConfig } from "collection/graphql/advisor";
import App from "layout/app";
import { Button } from "components/fl-ui";
import { Radio, RadioGroup } from "components/fl-ui/Form";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const ConsentPage = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token") || "";
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [consentDecision, setConsentDecision] = useState(false);
    const { handleElevateConsentSubmit } = useElevateConsent();
    const { data: { programName }, } = useFarmAdvisorConfig(true);
    const answeredConsent = (consentAnswer) => {
        setSubmitButtonEnabled(true);
        setConsentDecision(consentAnswer);
    };
    const handleSubmit = async (event) => {
        var _a;
        event.preventDefault();
        setLoading(true);
        try {
            await handleElevateConsentSubmit({
                token,
                consentDecision,
            });
            setHasSubmitted(true);
        }
        catch (error) {
            App.notify((_a = error.message) !== null && _a !== void 0 ? _a : "Failed to submit. Please try again later.");
        }
        finally {
            setLoading(false);
        }
    };
    const navigate = useNavigate();
    const okClick = () => {
        navigate("/");
    };
    useDocumentTitle(`${programName} Consent`);
    return (React.createElement(Container, null,
        React.createElement(Header, { title: `${programName} Access` }),
        React.createElement(Content, null,
            React.createElement(ContentMain, { fullWidth: true },
                React.createElement(LoadingWrapper, { isLoading: loading },
                    !hasSubmitted && (React.createElement(React.Fragment, null,
                        React.createElement("p", null,
                            programName,
                            " is requesting access to your Bushel Farm account as part of your program enrollment. By accepting this request, you are giving ",
                            programName,
                            " full access to Contracts, Marketing, and Market Prices features and view-only access to the Fields feature of your Bushel Farm account for the duration of your participation in ",
                            programName,
                            "."),
                        React.createElement(RadioGroup, { name: "consent_radio", onChange: ({ value }) => answeredConsent(value === "accept") },
                            React.createElement(Radio, { label: React.createElement("span", null,
                                    "I ",
                                    React.createElement("b", null, "ACCEPT"),
                                    " the ",
                                    programName,
                                    " request to access my account"), value: "accept" }),
                            React.createElement(Radio, { label: React.createElement("span", null,
                                    "I ",
                                    React.createElement("b", null, "DENY"),
                                    " the ",
                                    programName,
                                    " request to access my account"), value: "deny" })),
                        React.createElement(Button, { type: "submit", color: "primary", disabled: !submitButtonEnabled, onClick: handleSubmit }, "Submit"))),
                    hasSubmitted && consentDecision && (React.createElement(React.Fragment, null,
                        React.createElement("h2", null, "Getting Started"),
                        React.createElement("p", null,
                            "Thank you for confirming access. Please continue the set-up instructions for your ",
                            programName,
                            " Bushel Farm account."))),
                    hasSubmitted && !consentDecision && (React.createElement(React.Fragment, null,
                        React.createElement("h2", null, "Thank You For Confirming"),
                        React.createElement("p", null,
                            "This account will not be shared with ",
                            programName,
                            ". A support team member will contact you to help set up a separate Bushel Farm account."))),
                    hasSubmitted && (React.createElement(Button, { type: "submit", color: "primary", onClick: okClick }, "Ok")))))));
};
export default ConsentPage;
