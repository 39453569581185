import { useMutation } from "@apollo/client";
import { getPlatform } from "mobile/mobileManager";
import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import { getCurrentSubscription } from "collection/graphql/subscription";
import { CREATE_BUSINESS_TRIAL } from "collection/graphql/subscription/mutations/createBusinessTrial";
import SubscriptionSaveError from "modules/billing/exceptions/SubscriptionSaveError";
const useSubscriptionMutations = () => {
    const platform = getPlatform();
    const [createBusinessTrial] = useMutation(CREATE_BUSINESS_TRIAL, {
        onError: (error) => {
            throw new SubscriptionSaveError("Failed to activate business trial", {
                cause: error,
                platform,
            });
        },
        refetchQueries: [getCurrentSubscription, GET_CURRENT_ENTERPRISE],
    });
    return {
        createBusinessTrial,
    };
};
export default useSubscriptionMutations;
