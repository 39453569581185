import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { GenericStyledText } from "settings/GenericStyledText";
import styled from "styled-components";
import Icon from "components/fl-ui/Icons/Icon";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const Container = styled.div`
  align-items: center;
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.spacing16};
  padding-bottom: ${Spacing.spacing16};
  ${(props) =>
  props.bordered &&
  `
    border: ${Borders.regular};
    border-radius: ${BorderRadius.medium};
    padding: ${Spacing.spacing16};
  `}

  @media only screen and (max-width: 62.5rem) {
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;
  }
`;

const DataRowContentContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 62.5rem) {
    width: 100%;
  }
`;

const StyledIcon = styled(Icon)`
  height: ${Spacing.spacing24} !important;
  margin-right: ${Spacing.spacing16};
  min-width: ${Spacing.spacing24} !important;
  width: ${Spacing.spacing24} !important;

  @media only screen and (max-width: 62.5rem) {
    margin-right: ${Spacing.spacing12};
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  margin-right: ${Spacing.spacing16};

  @media only screen and (max-width: 62.5rem) {
    margin-bottom: ${Spacing.spacing8};
    margin-right: ${Spacing.spacing4};
  } ;
`;

const DataImportRow = ({ children, copy, icon, title }) => (
  <Row>
    <Col xs={12}>
      <Container>
        <Content>
          {icon && <StyledIcon icon={icon} />}
          <div>
            <GenericStyledText>
              <strong>{title}</strong>
            </GenericStyledText>
            <GenericStyledText>{copy}</GenericStyledText>
          </div>
        </Content>

        <DataRowContentContainer>{children}</DataRowContentContainer>
      </Container>
    </Col>
  </Row>
);

DataImportRow.propTypes = {
  children: PropTypes.any,
  copy: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default DataImportRow;
