import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { ALERTS_ALERT_DISMISSED } from "lib/metrics/events";
import { Button } from "components/fl-ui";
import { Cluster } from "components/fl-ui/Layout";
import NotificationActions from "components/fl-ui/Layout/Navigation/ToolbarNotificationMenu/NotificationActions";
import NotificationItemIcon from "components/fl-ui/Layout/Navigation/ToolbarNotificationMenu/NotificationItemIcon";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing, Typography, Mixins } from "components/fl-ui/constants";
const styles = StyleSheet.create({
    categoryIcon: {
        color: UIColors.blue,
        height: Spacing.spacing16,
        width: Spacing.spacing16,
    },
    container: {
        borderBottom: `1px solid ${GreyColors.smoke90}`,
        padding: Spacing.spacing16,
        ":last-child": {
            border: 0,
        },
    },
    header: {
        marginBottom: Spacing.spacing8,
    },
    metaData: {
        fontSize: Typography.sizes.sm,
        fontWeight: Typography.weights.medium,
        marginBottom: 0,
        marginLeft: Spacing.spacing4,
    },
    metaType: {
        textTransform: "capitalize",
    },
    title: {
        color: Typography.colors.primary,
        fontSize: Typography.sizes.rg,
        fontWeight: Typography.weights.medium,
        lineHeight: 1.357,
        margin: 0,
    },
    body: {
        lineHeight: 1.357,
    },
    blue: {
        color: UIColors.blue,
    },
    medium: {
        color: UIColors.medium,
    },
    marginRight: {
        marginRight: Mixins.toRem(2),
    },
    lowerCase: {
        textTransform: "lowercase",
    },
});
const NotificationItem = ({ dismissNotification, notification, className }) => {
    const [created, setCreated] = useState(moment(notification.created));
    const updateTimeout = () => {
        const now = moment();
        const nextMinute = moment(now).add(1, "minute").startOf("minute");
        const timeout = setTimeout(() => {
            setCreated(moment(notification.created));
        }, nextMinute.valueOf() - now.valueOf());
        return () => clearTimeout(timeout);
    };
    useEffect(() => {
        const cleanup = updateTimeout();
        return cleanup;
    }, [notification.created]);
    const handleDismissClick = () => {
        ALERTS_ALERT_DISMISSED.track({ alertId: notification.id });
        dismissNotification(notification.id);
    };
    const { body, title, type } = notification;
    const itemType = {
        imagery: "Imagery",
        "market-prices": "Marketing",
        news: "Bushel Farm",
        rainfall: "Rainfall",
        "work-orders": "Work Orders",
    }[type];
    return (React.createElement("div", { className: classNames(css(styles.container), className) },
        React.createElement(Cluster, { between: true, className: css(styles.header), around: undefined, center: undefined, end: undefined, even: undefined, start: undefined },
            React.createElement(Cluster, { around: undefined, between: undefined, center: undefined, end: undefined, even: undefined, start: undefined },
                React.createElement(NotificationItemIcon, { className: css(styles.categoryIcon, styles.marginRight), notification: notification }),
                React.createElement("p", { className: css(styles.metaData) },
                    React.createElement("span", { className: css(styles.metaType, styles.marginRight, styles.blue) }, itemType),
                    React.createElement("span", { className: css(styles.medium, styles.marginRight) }, "\u00B7"),
                    React.createElement("span", { className: css(styles.lowerCase, styles.medium) }, moment(created).fromNow()))),
            React.createElement(Button, { color: "primary", link: true, onClick: handleDismissClick, size: "sm" }, "Dismiss")),
        React.createElement(Row, null,
            React.createElement(Col, { xs: true },
                React.createElement("h3", { className: css(styles.title) }, title),
                React.createElement("p", { className: css(styles.medium, styles.body) }, body))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: true },
                React.createElement(NotificationActions, { notification: notification })))));
};
export default NotificationItem;
