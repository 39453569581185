export async function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64WithDataUrlPrefix = reader.result;
            // strips off the _data:${mimeType};base64_ prefix
            const base64String = base64WithDataUrlPrefix.split(",")[1];
            resolve(base64String);
        };
        reader.onerror = () => reject(new Error("Error occurred reading file"));
        reader.readAsDataURL(blob);
    });
}
