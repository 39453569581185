import styled from "styled-components";
import { Borders } from "components/fl-ui/constants";
export const TableBody = styled.tbody `
  ${(props) => (props.borderBottom ? `border-bottom: ${Borders.regular};` : "")}
  ${(props) => (props.borderTop ? `border-top: ${Borders.regular};` : "")}
  ${(props) => props.bordered
    ? `
    td, th {
      border-bottom: ${Borders.regular};
    };
  `
    : ""}
`;
