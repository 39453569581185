import defaultCache from "collection/graphql/cache/defaultCache";
import { getUserConfig } from "collection/graphql/config";

/**
 * @typedef {Object} ActivityFilters
 * @property {Number[]} [crops=[]] an array of **commodity** ids.
 * @property {DateLike} [endDate=null] a {@link DateLike} indicating a maximum date.
 * @property {Number[]} [equipment=[]] an array of implement and/or tractor ids.
 * @property {Number[]} [fields=[]] an array of field ids.
 * @property {Number[]} [people=[]] an array of person ids.
 * @property {Number[]} [products=[]] an array of product ids.
 * @property {String} [search=''] a string used to match against activities.
 * @property {ActivitySort} [sortCriteria='category'] a sort indicator
 * @property {ActivitySourceIdentifier[]} [sources=[]] an array of {@link ActivitySourceIdentifier}s.
 * @property {DateLike} [startDate=null] a {@link DateLike} indicating a minimum date.
 * @property {ActivityStatusIdentifier[]} [status=[]] an array of {@link ActivityStatusIdentifier}s.
 * @property {Number[]} [type=[]] an array of activity type ids.
 */

/**
 * Returns the default filters for the activity index page.
 * @return {ActivityFilters}
 */
const getDefaultActivityFilters = () => {
  const sortCriteria = defaultCache.readQuery({ query: getUserConfig }).userConfig.activitySortCriteria;

  return {
    crops: [],
    endDate: null,
    equipment: [],
    fields: [],
    people: [],
    products: [],
    search: "",
    sortCriteria,
    sources: [],
    startDate: null,
    status: [],
    type: [],
  };
};

export default getDefaultActivityFilters;
