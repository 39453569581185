import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import useCommodities from "hooks/useCommodities";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import { CommoditySection } from "modules/settings/pages/Commodities/containers/CommoditySection";
import { Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import Search from "components/fl-ui/Search";
const Container = styled((props) => React.createElement(Stack, Object.assign({}, props))) `
  margin-top: 1rem;
`;
const CommoditiesSettingsPage = () => {
    const { commodities, loading } = useCommodities();
    const [search, setSearch] = useState("");
    const hasFieldsFeature = useEnterpriseFeature("fields");
    const canWriteCommodities = usePermissions().canWrite("commodities") && hasFieldsFeature;
    let sortedCommodities = _.sortBy(commodities, "name");
    if (search) {
        sortedCommodities = sortedCommodities.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
    }
    const [used, unused] = _.partition(sortedCommodities, "isUsed");
    if (loading) {
        return React.createElement(LoadingWrapper, { isLoading: true });
    }
    return (React.createElement(Container, null,
        React.createElement(Search, { onChange: (value) => setSearch(_.trim(value)), placeholder: `Type to search \u2026`, autoFocus: true }),
        React.createElement(CommoditySection, { abridge: !search, commodities: used, description: "Create custom commodities or manage a customized commodity's unit or use.", showAddButton: canWriteCommodities, title: "Custom Commodities" }),
        React.createElement(CommoditySection, { abridge: !search, commodities: unused, description: "Edit unit or use for any standard commodity. Customized commodities will appear under Custom Commodities.", title: "Standard Commodities" })));
};
export { CommoditiesSettingsPage };
