import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { SSOSignUpFormSchema } from "sso/forms/SSOSignUpFormSchema";
import styled from "styled-components";

import { useSSOContext } from "context/SSOContext";
import useContactInfo from "hooks/useContactInfo";

import { Button } from "components/fl-ui";
import { Checkbox, ControlledFormGroup, Form, Input } from "components/fl-ui/Form";
import { GreyColors } from "components/fl-ui/colors";
import Spacing from "components/fl-ui/constants/spacing";

const howDidYouHearAboutUsOptions = [
  "Word of mouth",
  "Grain Buyer or Ag Retailer",
  "Internet Search",
  "App Store or Google Play Store",
  "Social Media",
  "Ad",
  "Other",
];

const SSOSignUpForm = styled(({ className, onComplete }) => {
  const { supportUrl } = useContactInfo();
  const { createUserMembership } = useSSOContext();

  const methods = useForm({
    defaultValues: SSOSignUpFormSchema.cast(
      {
        firstName: "",
        lastName: "",
        farmName: "",
        postalCode: "",
        howDidYouHearAboutUs: {},
        receiveNotificationEmails: false,
      },
      { assert: false, stripUnknown: true },
    ),
    mode: "onTouched",
    resolver: yupResolver(SSOSignUpFormSchema),
  });

  const onSubmit = async (data) => {
    const userMembershipPayload = {
      enterpriseName: data.farmName,
      firstName: data.firstName,
      foundUsWhere: data.howDidYouHearAboutUs?.name,
      isTransactionEmailEnabled: data.receiveNotificationEmails,
      lastName: data.lastName,
      postalCode: data.postalCode,
    };

    await createUserMembership(userMembershipPayload);
    onComplete();
  };

  return (
    <FormProvider {...methods} schema={SSOSignUpFormSchema}>
      <div className={className}>
        <p className="top-text">Please provide your information:</p>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup name="firstName" render={({ field }) => <Input {...field} placeholder="First" />} />

          <ControlledFormGroup name="lastName" render={({ field }) => <Input {...field} placeholder="Last" />} />

          <ControlledFormGroup
            name="farmName"
            render={({ field }) => <Input {...field} placeholder="Example Farm" />}
          />

          <ControlledFormGroup name="postalCode" render={({ field }) => <Input {...field} placeholder="00000" />} />

          <ControlledFormGroup
            name="howDidYouHearAboutUs"
            render={({ field }) => (
              <ReactSelect
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selectedOption) => field.onChange(selectedOption)}
                options={howDidYouHearAboutUsOptions.map((option, index) => ({ id: index, name: option }))}
              />
            )}
          />

          <br />

          <ControlledFormGroup
            name="receiveNotificationEmails"
            render={({ field }) => {
              return (
                <div className="checkbox-row">
                  <Checkbox {...field} />
                  <p>I want to receive emails from Bushel Farm about my account.</p>
                </div>
              );
            }}
          />

          <br />

          <p className="bottom-grey-text">
            Already have a Bushel Farm account?{" "}
            <a href={supportUrl} rel="noreferrer" target="_blank">
              Connect with our support team here
            </a>
            .
          </p>

          <br />

          <Button className="save-button" color="primary" disabled={!_.isEmpty(methods.formState.errors)} type="submit">
            Next
          </Button>
        </Form>
      </div>
    </FormProvider>
  );
})`
  padding: ${Spacing.spacing20} 5% 0 5%;

  .checkbox-row {
    display: inline-block;

    input {
      display: inline-block;
      vertical-align: top;
      width: 5%;
    }

    p {
      display: inline-block;
      margin: 0;
      width: 90%;
    }
  }

  .save-button {
    width: 100%;
  }

  .top-text {
    text-align: left;
  }

  .bottom-grey-text {
    color: ${GreyColors.smoke70};

    a {
      color: inherit;
    }
  }
`;

export { SSOSignUpForm };
