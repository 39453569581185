import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const ReportsBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Reports for free" : "Upgrade Plan",
        secondaryText: "Discover reporting capabilities tailored for each package.",
        imagePath: "images/pay_gates/banners/reports.png",
        title: "With Reports you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Quickly generate reports using the information in your Bushel Farm account."),
                React.createElement(ValuePropListItem, null, "Use filters to easily customize report details."),
                React.createElement(ValuePropListItem, null, "Export your reports as a CSV or PDF file to easily share with partners.")))));
};
export { ReportsBlankSlateBanner };
