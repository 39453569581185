import useDocumentTitle from "hooks/useDocumentTitle";
import { upperFirst } from "lodash";
import numeral from "numeral";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import useBoolean from "hooks/useBoolean";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useFeatureRedirect from "hooks/useFeatureRedirect";
import usePermissions from "hooks/usePermissions";
import useViewType from "hooks/useViewType";
import { FieldEditModal } from "modules/fields/components";
import FieldDetailHeaderContent from "modules/fields/components/FieldDetailHeaderContent";
import FieldDetailSidebar from "modules/fields/components/FieldDetailSidebar";
import FieldDetailsMainContent from "modules/fields/components/FieldDetailsMainContent";
import useFieldData from "modules/fields/hooks/useFieldData";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";
const FieldDetailPage = () => {
    const { id } = useParams();
    const fieldId = Number(id);
    const { executeGroupCreate, fields, loading } = useFieldData();
    const year = useCurrentCropYear()[0];
    const field = fields === null || fields === void 0 ? void 0 : fields.find(({ id }) => id === fieldId);
    const title = (field === null || field === void 0 ? void 0 : field.name) ? field.name : "Field detail";
    useDocumentTitle(title);
    const isMobile = useViewType() === "mobile";
    const [showFieldEditModal, { off, toggle }] = useBoolean(false);
    const hasFieldFeature = useEnterpriseFeature("fields");
    const isOnlyField = field && (fields === null || fields === void 0 ? void 0 : fields.length) === 1;
    const showOnboardingPreview = isOnlyField && !hasFieldFeature;
    useFeatureRedirect({ feature: "fields", skip: loading || showOnboardingPreview });
    const hasWritePermissions = usePermissions().hasPermission("fields", ["write"]);
    if (loading && !field) {
        return React.createElement(LoadingWrapper, null);
    }
    if (!hasWritePermissions) {
        return React.createElement(RestrictedAccessWithHeader, { title: title });
    }
    if (showOnboardingPreview) {
        return React.createElement(Navigate, { to: `/fields/onboarding-dashboard/${fieldId}` });
    }
    if (field) {
        const commonFieldDetailProps = { field, fieldId, isMobile, toggleEditModal: toggle, year };
        const acreage = numeral(field === null || field === void 0 ? void 0 : field.acreage).format("0[.]0");
        const description = (field === null || field === void 0 ? void 0 : field.group)
            ? [`${upperFirst(field.group.name)}`, `${acreage} ac`]
            : [`${acreage} ac`];
        return (React.createElement(Container, null,
            React.createElement(Header, { description: description, fullWidth: true, title: !isMobile ? upperFirst((field === null || field === void 0 ? void 0 : field.name) || "") : "" },
                React.createElement(FieldDetailHeaderContent, { toggle: toggle })),
            React.createElement(Content, null,
                React.createElement(FieldDetailSidebar, Object.assign({}, commonFieldDetailProps, { description: description })),
                React.createElement(FieldDetailsMainContent, Object.assign({}, commonFieldDetailProps)),
                showFieldEditModal && React.createElement(FieldEditModal, { createGroup: executeGroupCreate, fieldId: fieldId, onClose: off }))));
    }
    return null;
};
export default FieldDetailPage;
