import { useEffect } from "react";

import useWindow from "hooks/useWindow.js";

const useDocumentTitle = (pageTitle) => {
  const { document } = useWindow();
  useEffect(() => {
    const updatedTitle = ["Bushel Farm", pageTitle].filter((x) => x).join(" - ");
    if (document.title !== updatedTitle) {
      document.title = updatedTitle;
    }
  }, [pageTitle]);
};

export default useDocumentTitle;
