import React from "react";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
const YieldDataBlankSlateBanner = () => {
    const bannerProps = {
        actionButtonText: "Upgrade Plan",
        imagePath: "images/pay_gates/banners/farm.png",
        title: "Upload Machine Data files",
        secondaryText: "The Machine Data feature is included in the Business plan and requires a paid subscription.",
    };
    return (React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps, { size: "lg" }),
        React.createElement("ul", null,
            React.createElement(ValuePropListItem, null, "Uploading machine data files will automatically convert as-applied, as-planted, or yield files into Activities so you can accurately track field work and the costs associated."))));
};
export { YieldDataBlankSlateBanner };
