import EventChannel from "lib/events";
import currentEnterpriseVar from "collection/graphql/auth/vars/currentEnterpriseVar";
import ssoUserVar from "collection/graphql/auth/vars/ssoUserVar";
const toastChannel = EventChannel.getChannel("toast");
/**
 * Creates an {@link SSOAuthHeaders} object containing the appropriate SSO token header values if they exist.
 */
const authHeaders = () => {
    const ssoUser = ssoUserVar();
    const currentEnterprise = currentEnterpriseVar();
    const headers = {};
    // On Legacy Auth, token will be undefined
    if (ssoUser === null || ssoUser === void 0 ? void 0 : ssoUser.access_token) {
        headers["Authorization"] = `Bearer ${ssoUser.access_token}`;
        if (currentEnterprise) {
            headers["BF-Enterprise"] = currentEnterprise;
        }
    }
    return headers;
};
/**
 * Creates a unique identifier for a GraphQL object having a `__typename` property.
 */
const dataIdFromObject = (o) => o.__typename + (o.id || Math.random());
const toastAfterWare = ({ response, data }) => {
    const ON_THE_CASE = "We've been notified of the problem and will fix it soon!";
    const INTERNAL_SERVER_ERROR_MSG = `There was an internal server error. ${ON_THE_CASE}`;
    const GENERIC_ERROR_MESSAGE = `There was a problem processing your request. ${ON_THE_CASE}`;
    if (!response.ok) {
        if (response.status === 500) {
            toastChannel.fire("warn", { message: INTERNAL_SERVER_ERROR_MSG });
        }
        else {
            toastChannel.fire("warn", { message: GENERIC_ERROR_MESSAGE });
        }
    }
    else if (data === null || data === void 0 ? void 0 : data.errors) {
        toastChannel.fire("warn", { message: GENERIC_ERROR_MESSAGE });
    }
};
const trackingCallback = (operation, forward) => forward(operation).map(({ data, errors }) => {
    try {
        const { response } = operation.getContext();
        if (response) {
            toastAfterWare({ data, response });
        }
    }
    catch (e) {
        console.error(e);
    }
    return { data, errors };
});
export { authHeaders, dataIdFromObject, trackingCallback };
