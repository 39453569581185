import React from "react";
import styled from "styled-components";
import { Button } from "components/fl-ui";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import { Spacing } from "components/fl-ui/constants";
const FieldDetailHeaderContent = styled(({ className, toggle }) => (React.createElement("div", { className: className },
    React.createElement(Button, { color: "primary", hollow: true, lightWeight: true, onClick: toggle }, "Edit field details"),
    React.createElement("div", { className: "right-content" },
        React.createElement(CropYearPicker, null))))) `
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .right-content {
    margin-left: ${Spacing.spacing16};
  }

  @media only screen and (max-width: 62.5rem) {
    align-items: end;
    justify-content: space-between;
    margin-top: ${Spacing.spacing8};
  }
`;
export default FieldDetailHeaderContent;
