import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import TableHeading from "products/components/TableHeading";
import ProductActivityCard from "products/containers/ProductActivityCard";
import { Table } from "profit_and_loss/styles";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import useViewType from "hooks/useViewType";

import { UIColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";
import ProductApplyUnit from "components/product/ProductApplyUnit";


const ClickableCell = styled.td`
  cursor: pointer;

  ${(props) =>
    props.primary &&
    `
    color: ${UIColors.blue};
    font-weight: ${Typography.weights.bold} !important;
    text-align: left !important;
  `}
`;

const ProductActivitiesDetailTable = ({ onActivityClick, purchaseSummary }) => {
  const { activityInputs } = purchaseSummary;
  const isPopulated = _.size(activityInputs) > 0;
  const isMobile = useViewType() === "mobile";

  const heading = (
    <TableHeading
      title="Activities"
      subtitle={`${activityInputs.length} ${activityInputs.length === 1 ? "activity" : "activities"}`}
    />
  );
  if (isMobile && isPopulated) {
    return (
      <div>
        {heading}
        {activityInputs.map((activityInput, i) => {
          return <ProductActivityCard key={i} activityInput={activityInput} />;
        })}
      </div>
    );
  }

  return (
    <div>
      {heading}

      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Field</th>
            <th>Area</th>
            <th>Rate/ac</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          {_.map(activityInputs, (activityInput, i) => {
            const { area, activity, amountPerAcre, amountTotal } = activityInput;
            const { date, field, type } = activity;

            const handleRowClick = () => {
              onActivityClick(activity);
            };

            return (
              <tr key={[activity.id, i].join("-")} onClick={handleRowClick}>
                <ClickableCell primary>{moment(date).format("MM/DD/YYYY")}</ClickableCell>
                <ClickableCell>{type?.name ?? "Unknown"}</ClickableCell>
                <ClickableCell>{field.name}</ClickableCell>
                <ClickableCell>{numeral(area).format("0.0")} ac</ClickableCell>
                <ClickableCell>
                  <ProductApplyUnit source={activityInput} amount={numeral(amountPerAcre).format("0.0")} />
                </ClickableCell>
                <ClickableCell>
                  <ProductApplyUnit source={activityInput} amount={numeral(amountTotal).format("0,0")} />
                </ClickableCell>
              </tr>
            );
          })}

          {!isPopulated && (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No activities for this product in the selected year.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

ProductActivitiesDetailTable.propTypes = {
  onActivityClick: PropTypes.func.isRequired,
  purchaseSummary: PropTypes.shape({
    activityInputs: PropTypes.arrayOf(
      PropTypes.shape({
        activity: PropTypes.shape({
          acreage: PropTypes.number.isRequired,
          date: PropTypes.string.isRequired,
          field: PropTypes.shape({
            name: PropTypes.string.isRequired,
          }).isRequired,
          id: PropTypes.number.isRequired,
          type: PropTypes.shape({
            name: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
        amountPerAcre: PropTypes.number.isRequired,
        amountRate: PropTypes.string.isRequired,
        amountTotal: PropTypes.number.isRequired,
        amountUnit: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};

export default ProductActivitiesDetailTable;
