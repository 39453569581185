import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { AUTHENTICATED, useAuthStore } from "collection/graphql/auth/hooks/AuthStore";
import { last24RainfallClient } from "collection/graphql/client/rainfallClient";
import { getLastDaysRainfall } from "collection/graphql/fields/queries";
const useRecentRainfall = () => {
    const status = useAuthStore(useShallow(state => state.status));
    const { data, loading, refetch } = useQuery(getLastDaysRainfall, {
        client: last24RainfallClient,
        pollInterval: 10 * 60 * 1000, // call every 10 minutes
        skip: status !== AUTHENTICATED,
    });
    const recentRainfallMap = useMemo(() => {
        const sortedLeastToMost = _.sortBy(data === null || data === void 0 ? void 0 : data.last24Rainfall, ({ last24 }) => last24.total);
        return _.reduce(sortedLeastToMost, (map, { fieldId, last24 }) => map.set(fieldId, last24), new Map());
    }, [data]);
    return {
        data: data === null || data === void 0 ? void 0 : data.last24Rainfall,
        loading,
        recentRainfallMap,
        refetch,
    };
};
export default useRecentRainfall;
