import React from "react";
import { ReportsBlankSlateBanner } from "components/advertisements/banners/ReportsBlankSlateBanner";
import PayGateCommonBlankSlatePage from "components/advertisements/pages/PayGateCommonBlankSlatePage";
const ReportsBlankSlatePage = () => {
    const steps = [
        "Create reports for inputs, planted acres, field data, spraying records, and marketing data",
        "Use filters to easily customize report details",
        "Download your reports as a CSV or PDF file to easily share with partners",
    ];
    return (React.createElement("div", null,
        React.createElement(PayGateCommonBlankSlatePage, { banner: ReportsBlankSlateBanner, steps: steps })));
};
export default ReportsBlankSlatePage;
