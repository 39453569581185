import useDocumentTitle from "hooks/useDocumentTitle";
import React from "react";
import { WalletCommonBlankSlateBanner } from "modules/wallet/components/WalletCommonBlankSlateBanner";
import { Button } from "components/fl-ui";
import { useNavigate } from "react-router-dom";
const FinishWalletSetupPage = () => {
    useDocumentTitle("Finish Wallet Setup");
    const navigate = useNavigate();
    return (React.createElement(WalletCommonBlankSlateBanner, { title: "Finish setting up your Wallet" },
        React.createElement("h2", null, "Unlock 4% APR cash back and enjoy all the benefits of your new account\u2014just a few steps away!"),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(Button, { color: "primary", onClick: () => navigate("/wallet/sign-up") }, "Continue")));
};
export { FinishWalletSetupPage };
