import React from "react";
import styled from "styled-components";
import { IconContainer } from "components/fl-ui/Icons";
import { UIColors } from "components/fl-ui/colors";
import { Typography, Spacing } from "components/fl-ui/constants";
const plans = ["Rainfall", "Lite", "Essentials", "Business"];
const comparePlansFeatures = [
    { name: "Recent Rainfall", rainfall: true, lite: true, essentials: true, business: true },
    { name: "Field Mapping", rainfall: true, lite: true, essentials: true, business: true },
    // { name: "Wallet Interest Bearing Account", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Automated Contracts", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Scouting", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Soil Maps", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Rain & Heat History", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Inputs", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Futures Prices", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Monthly Rainfall Report", rainfall: false, lite: true, essentials: true, business: true },
    { name: "Marketing", rainfall: false, lite: false, essentials: true, business: true },
    { name: "Field Activities", rainfall: false, lite: false, essentials: true, business: true },
    { name: "Satellite Imagery", rainfall: false, lite: false, essentials: true, business: true },
    { name: "Local Prices", rainfall: false, lite: false, essentials: true, business: true },
    { name: "Field Attachments", rainfall: false, lite: false, essentials: true, business: true },
    { name: "Field, Activity, and Marketing Reports", rainfall: false, lite: false, essentials: true, business: true },
    { name: "Profit & Loss", rainfall: false, lite: false, essentials: false, business: true },
    { name: "Machine Data Connections", rainfall: false, lite: false, essentials: false, business: true },
    { name: "Land Costs", rainfall: false, lite: false, essentials: false, business: true },
    { name: "Work Orders", rainfall: false, lite: false, essentials: false, business: true },
    { name: "Custom Activities", rainfall: false, lite: false, essentials: false, business: true },
    { name: "Land Cost Reports", rainfall: false, lite: false, essentials: false, business: true },
];
const TableWrapper = styled.div `
  overflow-x: auto;
  max-width: 100%;
`;
const Table = styled.table `
  width: 100%;
  border-collapse: collapse;
`;
const ComparePlansTableHeader = styled.th `
  font-weight: ${Typography.weights.medium};
  padding: ${Spacing.spacing12};
  vertical-align: bottom;
`;
const ComparePlansHeaderText = styled.div `
  color: ${UIColors.dark};
  font-weight: ${Typography.weights.medium};
  transform: rotate(180deg);
  width: 30px;
  white-space: nowrap;
  writing-mode: vertical-lr;
`;
const ComparePlansTableRow = styled.tr `
  &:nth-child(even) {
    background-color: ${UIColors.white};
  }

  &:nth-child(odd) {
    background-color: ${UIColors.greyLight};
  }
`;
const ComparePlansFeatureName = styled.td `
  padding: ${Spacing.spacing12};
  text-align: left;
`;
const ComparePlansTableData = styled.td `
  color: ${UIColors.green};
  text-align: center;
`;
const ComparePlansTable = () => (React.createElement(TableWrapper, null,
    React.createElement(Table, null,
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement(ComparePlansTableHeader, null),
                plans.map((plan) => (React.createElement(ComparePlansTableHeader, { key: plan },
                    React.createElement(ComparePlansHeaderText, null, plan)))))),
        React.createElement("tbody", null, comparePlansFeatures.map(({ name, rainfall, lite, essentials, business }, i) => (React.createElement(ComparePlansTableRow, { key: i },
            React.createElement(ComparePlansFeatureName, null, name),
            React.createElement(ComparePlansTableData, { key: `${name}-rainfall` }, rainfall && React.createElement(IconContainer, { icon: "circleCheck" })),
            React.createElement(ComparePlansTableData, { key: `${name}-lite` }, lite && React.createElement(IconContainer, { icon: "circleCheck" })),
            React.createElement(ComparePlansTableData, { key: `${name}-essentials` }, essentials && React.createElement(IconContainer, { icon: "circleCheck" })),
            React.createElement(ComparePlansTableData, { key: `${name}-business` }, business && React.createElement(IconContainer, { icon: "circleCheck" })))))))));
export { ComparePlansTable };
