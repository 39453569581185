import React from "react";
import styled from "styled-components";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing } from "components/fl-ui/constants";
const DeluxeSelector = styled(({ className, options }) => (React.createElement("div", { className: className, id: "deluxe-selector" }, options.map(({ key, icon, text, onClick }) => (React.createElement("div", { className: "deluxe-selector-item", key: key, onClick: onClick },
    React.createElement("span", { className: "deluxe-selector-icon" },
        React.createElement(IconContainer, { size: Spacing.spacing24 },
            React.createElement(Icon, { icon: icon }))),
    React.createElement("span", { className: "deluxe-selector-text" }, text))))))) `
  #deluxe-selector {
    width: 100%;
    padding: ${Spacing.spacing24};
  }

  .deluxe-selector-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${Spacing.spacing12};
    margin: ${Spacing.spacing16} 0;
    border-radius: ${BorderRadius.large};
    cursor: pointer;
    border: 1px solid ${GreyColors.smoke90};
    min-height: ${Spacing.spacing80};

    .deluxe-selector-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${Spacing.spacing20};
      margin-left: ${Spacing.spacing12};
    }

    &:hover {
      border: 1px solid ${UIColors.blue};

      .deluxe-selector-icon svg {
        color: ${UIColors.blue};
      }
    }
  }
`;
export { DeluxeSelector };
