import React from "react";
import { LocalPricesBlankSlateBanner } from "components/advertisements/banners/LocalPricesBlankSlateBanner";
import PayGateCommonBlankSlatePage from "components/advertisements/pages/PayGateCommonBlankSlatePage";
const LocalPricesBlankSlatePage = () => {
    const steps = [
        "Select counties and delivery windows",
        "See sorted bids based on price & distance",
        "Click any location to see all posted bids",
    ];
    return (React.createElement("div", null,
        React.createElement(PayGateCommonBlankSlatePage, { banner: LocalPricesBlankSlateBanner, steps: steps })));
};
export default LocalPricesBlankSlatePage;
