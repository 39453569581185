import React from "react";
import { BaseWalletPage } from "modules/wallet/pages/BaseWalletPage";
export default [
    {
        children: [
            {
                path: "/wallet/*",
                element: React.createElement(BaseWalletPage, null),
            },
        ],
    },
];
