import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { Icon } from "components/fl-ui/Icons";
import { getMainMenuItemActiveClasses } from "components/fl-ui/Layout/MainMenu/utils/getMainMenuItemActiveClasses";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const labelMixin = css `
  &:after {
    border: 4px solid ${UIColors.orange};
    border-radius: 100%;
    content: "";
    position: absolute;
    left: calc(50% + 5px);
    top: 3px;
  }
`;
const TabBarListItem = styled(({ className, icon, id, onClick, title, showRouteAsActive, to }) => (React.createElement("li", { className: className },
    React.createElement(NavLink, { className: getMainMenuItemActiveClasses({ showRouteAsActive, to }), id: id, onClick: onClick, to: to },
        React.createElement(Icon, { icon: icon }),
        React.createElement("span", null, title))))) `
  a {
    align-items: center;
    background-color: ${({ active, theme }) => (active ? theme.colors.lightestBlue : "transparent")};
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
    position: relative;
    text-decoration: none !important;

    > :nth-child(1) {
      color: ${UIColors.blue};
      height: ${Spacing.spacing24};
      width: ${Spacing.spacing24};
    }

    > :nth-child(2) {
      font-size: ${Typography.sizes.sm};
      line-height: 1.125rem;
      white-space: nowrap;
    }

    ${({ label }) => label && labelMixin}
  }
`;
export { TabBarListItem };
