import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const MarketingBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Marketing for free" : "Move to Essentials today",
        imagePath: "images/pay_gates/banners/marketing.svg",
        title: "With Marketing you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Calculate breakeven for your farm."),
                React.createElement(ValuePropListItem, null, "Track grain sales and hedges as you make them."),
                React.createElement(ValuePropListItem, null, "See the value of your unsold bushels as market prices move."),
                React.createElement(ValuePropListItem, null, "Make decisions on the go with a complete view of your current marketing position.")))));
};
export { MarketingBlankSlateBanner };
