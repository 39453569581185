//Random thing I noticed... whenever we have these constant files, we use all sorts of different naming schemes,
// for example, here we have xlarge, where in some of the other locations we'd have something like xl.
// doesn't seem like there is any rhyme or reason to it, just something I noticed.
const BorderRadius = {
  xsmall: "0.125rem",
  small: "0.188rem",
  medium: "0.25rem",
  large: "0.375rem",
  xlarge: "0.5rem",
  xxlarge: "1rem",
  xxxlarge: "1.5rem",
  radius34: "2.125rem", // 34px
};

export default BorderRadius;
