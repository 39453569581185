import NewPurchaseButton from "products/containers/NewPurchaseButton";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import CropYearPicker from "components/fl-ui/CropYearPicker";
import { BreadCrumbHeader } from "components/fl-ui/Layout";
import { Spacing } from "components/fl-ui/constants";

const Spacer = styled.span`
  margin-right: ${Spacing.spacing8};
`;

const ProductPageHeader = ({forceIndexLink, onCreatePurchase, product }) => {
  const hasProductsFeature = useEnterpriseFeature("products");

  const crumbs = [{ label: "Inputs" }];
  if (product || forceIndexLink) {
    crumbs[0].enabled = true;
    crumbs[0].link = "/products";
    if (product) {
      crumbs.push({ label: product.name });
    }
  }

  const handleClick = () => {
    onCreatePurchase();
  };

  return (
    <div>
      <BreadCrumbHeader links={crumbs}>
        <Spacer>{hasProductsFeature && <NewPurchaseButton onSuccessfulClick={handleClick} primary />}</Spacer>

        <CropYearPicker />
      </BreadCrumbHeader>
    </div>
  );
};

ProductPageHeader.propTypes = {
  onCreatePurchase: PropTypes.func.isRequired,
  forceIndexLink: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

ProductPageHeader.defaultProps = {
  forceIndexLink: false,
};

export default ProductPageHeader;
