import { useQuery } from "@apollo/client";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import getGrainBuyers from "collection/graphql/contracts/queries/getGrainBuyers";
import { useSSOContext } from "context/SSOContext";
import useDocumentTitle from "hooks/useDocumentTitle";
import useIntegrationConnectionLink from "modules/settings/components/Integrations/hooks/useIntegrationsConnectionLink";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import { Button } from "components/fl-ui";
const SSOConnectToContracts = styled(({ className, onComplete }) => {
    var _a;
    useDocumentTitle("Connect to Contracts");
    const grainBuyerCount = _.size((_a = useQuery(getGrainBuyers).data) === null || _a === void 0 ? void 0 : _a.buyers);
    const { connect, disabled } = useIntegrationConnectionLink({ integrationSlug: "bushel" });
    // @ts-ignore
    const { saveReminderStatus } = useSSOContext();
    const remindMeLater = async (event) => {
        event.preventDefault();
        await saveReminderStatus().then(onComplete);
    };
    return (React.createElement(BaseSSOPageContainer, { title: "Automate Your Contract Entry" },
        React.createElement("div", { className: className },
            React.createElement("br", null),
            React.createElement("p", null,
                "Automatically import grain contracts from grain buyers in the Bushel Network. It looks like you can connect to",
                " ",
                React.createElement("b", null,
                    grainBuyerCount,
                    " additional grain buyers"),
                " in the Bushel Network."),
            React.createElement("br", null),
            React.createElement(Button, { link: true, disabled: disabled, onClick: connect }, "Connect"),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(Button, { color: "primary", hollow: true, onClick: remindMeLater, type: "button" }, "Remind me later"))));
}) `
  button,
  .btn {
    width: 100%;
  }
`;
export { SSOConnectToContracts };
