import React from "react";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import DetailsWidget from "modules/fields/components/DetailsWidget";
import FieldDetailsTable from "modules/fields/components/FieldDetailsTable";
import { FieldDetailsWidgetContainer } from "modules/fields/components/FieldDetailsWidgetContainer";
import Attachments from "components/field/Attachments";
import Loads from "components/field/Loads";
import SatelliteImagery from "components/field/SatelliteImagery";
import ScoutingNotes from "components/field/ScoutingNotes";
import Soil from "components/field/Soil";
import Yield from "components/field/Yield";
import Activities from "components/field/activities";
import CropStatusSummary from "components/field/weather/CropStatusSummary";
import ContentMain from "components/fl-ui/Layout/ContentMain";
const FieldDetailsMainContent = ({ field, fieldId, isMobile, toggleEditModal, year, }) => {
    const hasLoads = useEnterpriseFeature("legacy_storage_and_loads");
    const showSatelliteImagery = useEnterpriseFeature("crop_health_enterprise");
    return (React.createElement(ContentMain, null,
        React.createElement(FieldDetailsWidgetContainer, null,
            React.createElement(CropStatusSummary, { fieldId: fieldId, cropYear: year })),
        showSatelliteImagery && (React.createElement("div", { className: "field-detail-widget" },
            React.createElement(SatelliteImagery, { acreage: (field === null || field === void 0 ? void 0 : field.acreage) || 0, fieldId: fieldId, year: year }))),
        React.createElement(DetailsWidget, { more: "All activities", title: "Activities", to: `/fields/${fieldId}/activities` },
            React.createElement(Activities, { fieldId: fieldId, year: year })),
        React.createElement(DetailsWidget, { more: "All notes", title: "Scouting", to: `/fields/${fieldId}/scouting` },
            React.createElement(ScoutingNotes, { fieldId: fieldId, fieldName: field === null || field === void 0 ? void 0 : field.name, year: year })),
        React.createElement(DetailsWidget, { more: "All yield", title: "Yield", to: `/fields/${fieldId}/yield` },
            React.createElement(Yield, { fieldId: fieldId, year: year })),
        React.createElement(DetailsWidget, { more: "All attachments", title: "Attachments", to: `/fields/${fieldId}/attachments` },
            React.createElement(Attachments, { fieldId: fieldId })),
        hasLoads && (React.createElement(DetailsWidget, { more: "All loads", title: "Loads", to: `/fields/${fieldId}/loads` },
            React.createElement(Loads, { fieldId: fieldId, year: year }))),
        React.createElement(Soil, { field: field }),
        isMobile && (React.createElement(DetailsWidget, { title: "Field Details" },
            React.createElement(FieldDetailsTable, { field: field, onAddLandValue: toggleEditModal })))));
};
export default FieldDetailsMainContent;
