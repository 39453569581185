import ActivityCommodityChooser from "activity/containers/ActivityCommodityChooser";
import ActivityEquipmentChooser from "activity/containers/ActivityEquipmentChooser";
import ActivityFieldChooser from "activity/containers/ActivityFieldChooser";
import ActivityProductChooser from "activity/containers/ActivityProductChooser";
import ActivitySortControl from "activity/containers/ActivitySortControl";
import ActivitySourceButtons from "activity/containers/ActivitySourceButtons";
import ActivityStatusButtons from "activity/containers/ActivityStatusButtons";
import ActivityTypeChooser from "activity/containers/ActivityTypeChooser";
import useActivityFilters from "activity/hooks/useActivityFilters";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import useViewType from "hooks/useViewType";
import ActivityUserChooser from "modules/activity/containers/ActivityUserChooser.js";

import FilterActionButtonGroup from "components/fl-ui/Filters/FilterActionButtonGroup";
import { ClearAllFormGroup, FormGroup } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import { css } from "components/fl-ui/aphrodite";
import { DatePicker } from "components/fl-ui/common";
import styles from "fields/common/styles";

const ActivityFilterModal = ({ onClose }) => {
  const { filters: initialFilters, resetFilters, updateFilters } = useActivityFilters();
  const isMobile = useViewType() === "mobile";

  const [formFields, setFormFields] = useState(() => _.cloneDeep(initialFilters));
  const setFilter = (updatedFilter) => {
    setFormFields((modalFilterState) => ({ ...modalFilterState, ...updatedFilter }));
  };
  const setterFor = (filterName) => (value) => setFilter({ [filterName]: value });

  const handleFilterApply = () => {
    updateFilters(formFields);
    onClose();
  };

  const handleFilterReset = () => {
    resetFilters();
    onClose();
  };

  return (
    <Modal width={500}>
      <ModalHeader>
        <ModalTitle>Filter activities</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        {isMobile && (
          <FormGroup label="Sort by">
            <ActivitySortControl
              variant="dropdown"
              onChange={setterFor("sortCriteria")}
              value={formFields.sortCriteria}
            />
          </FormGroup>
        )}

        <ClearAllFormGroup label="Type" onClear={() => setFilter({ type: [] })}>
          <ActivityTypeChooser onChange={(type) => setFilter({ type })} value={formFields.type} excludeArchived />
        </ClearAllFormGroup>

        <ActivityCommodityChooser onChange={setterFor("crops")} value={formFields.crops} />
        <ActivitySourceButtons onChange={setterFor("sources")} value={formFields.sources} />
        <ActivityStatusButtons onChange={setterFor("status")} value={formFields.status} />

        <Row>
          <Col xs md={6} className={css(styles.bottom_margin)}>
            <ClearAllFormGroup
              label="Start date"
              onClear={() => setFilter({ startDate: null })}
              style={{ width: "180px" }}
            >
              <DatePicker onChange={setterFor("startDate")} value={formFields.startDate} />
            </ClearAllFormGroup>
          </Col>

          <Col xs md={6} className={css(styles.bottom_margin)}>
            <ClearAllFormGroup label="End date" onClear={() => setFilter({ endDate: null })} style={{ width: "180px" }}>
              <DatePicker onChange={setterFor("endDate")} value={formFields.endDate} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <ClearAllFormGroup label="Fields" onClear={() => setFilter({ fields: [] })}>
              <ActivityFieldChooser onChange={setterFor("fields")} value={formFields.fields} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <ClearAllFormGroup label="People" onClear={() => setFilter({ people: [] })}>
              <ActivityUserChooser onChange={setterFor("people")} value={formFields.people} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <ClearAllFormGroup label="Inputs" onClear={() => setFilter({ products: [] })}>
              <ActivityProductChooser onChange={setterFor("products")} value={formFields.products} />
            </ClearAllFormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <ClearAllFormGroup label="Equipment" onClear={() => setFilter({ equipment: [] })}>
              <ActivityEquipmentChooser onChange={setterFor("equipment")} value={formFields.equipment} />
            </ClearAllFormGroup>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <FilterActionButtonGroup
          apply={{ action: handleFilterApply }}
          cancel={{ action: onClose }}
          reset={{ action: handleFilterReset }}
        />
      </ModalFooter>
    </Modal>
  );
};

ActivityFilterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ActivityFilterModal;
