import { useQuery, useReactiveVar } from "@apollo/client";
import { withErrorBoundary } from "@sentry/react";
import { css } from "aphrodite/no-important";
import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "collection/graphql/auth";
import currentEnterpriseVar from "collection/graphql/auth/vars/currentEnterpriseVar";
import ssoUserVar from "collection/graphql/auth/vars/ssoUserVar";
import { getCurrentSubscription } from "collection/graphql/subscription";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import usePermissions from "hooks/usePermissions";
import useReleaseFeature from "hooks/useReleaseFeature";
import useWindow from "hooks/useWindow";
import App from "layout/app";
import CurrentSubscriptionCopy from "modules/billing/components/CurrentSubscriptionCopy";
import { SupportEmailLink, SupportLink, SupportPhoneLink } from "modules/billing/lib";
import { Button } from "components/fl-ui";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Cluster } from "components/fl-ui/Layout";
import MenuItem from "components/fl-ui/Layout/Navigation/components/MenuItem";
import MyProfileModal from "components/fl-ui/Layout/Navigation/components/MyProfileModal";
import UserProfileIcon from "components/fl-ui/Layout/Navigation/components/UserProfileIcon";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import styles from "components/fl-ui/Layout/Navigation/userMenuStyles";
import getName from "components/fl-ui/Layout/Navigation/utils/getName";
import navStyles from "components/fl-ui/Layout/styles/navStyles";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const Version = styled.div `
  color: ${UIColors.lightest};
  font-size: ${Typography.sizes.sm};
  margin-top: ${Spacing.spacing16};
`;
const Menu = withErrorBoundary(({ close, status }) => {
    var _a, _b;
    const { cancelAtPeriodEnd, daysRemaining, isSubscribed, isTrialing } = ((_a = useQuery(getCurrentSubscription).data) === null || _a === void 0 ? void 0 : _a.subscription) || {};
    const { canRead, canWrite } = usePermissions();
    const { logout } = useAuth();
    const { currentEnterprise } = useCurrentEnterprise();
    const { currentUser } = useAuth();
    const [showMyProfileModal, setShowMyProfileModal] = useState(false);
    const ssoUser = useReactiveVar(ssoUserVar);
    const win = useWindow();
    const style = Object.assign(Object.assign({}, styleVars.userMenuDefaultStyle), styleVars.userMenuTransitionStyles[status]);
    const handleSignOutClick = () => {
        close();
        logout();
    };
    const handleChangeFarm = () => {
        currentEnterpriseVar(null);
        win.location.reload();
    };
    const canViewActivityPage = canWrite("activity_types");
    const canViewBillingPage = canRead("subscription");
    const canViewFarmPage = canRead("farm_info");
    const canViewPeoplePage = canRead("access_control");
    const canViewDemoPage = useReleaseFeature("debug").enabled;
    const userRoleString = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.role)
        ? ((_b = currentUser.role[0]) === null || _b === void 0 ? void 0 : _b.toUpperCase()) + currentUser.role.slice(1).toLowerCase()
        : "";
    const { firstName, lastName } = getName(currentUser);
    return (React.createElement("div", { className: css(navStyles.menuContainerOuter, navStyles.menuContainerOuter_mobile), style: style },
        React.createElement("div", { className: css(navStyles.menuContainer, navStyles.menuContainer_mobile, styles.menuContainer) },
            React.createElement("div", { className: css(styles.menuHeader) },
                React.createElement("div", { className: css(styles.userID) },
                    React.createElement("div", { className: css(styles.navToggle) },
                        React.createElement(UserProfileIcon, { onClick: close })),
                    React.createElement("div", { className: css(styles.userCopy) },
                        React.createElement("h2", { className: css(styles.userName) },
                            firstName,
                            " ",
                            lastName),
                        (currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) && React.createElement("p", { className: css(styles.userEmail) }, currentUser.email))),
                React.createElement(CloseX, { onClick: close })),
            ssoUser && currentEnterprise && (React.createElement("div", { className: css(styles.menuSection) },
                React.createElement("div", { className: css(styles.farmName) }, currentEnterprise.name),
                React.createElement("div", { className: css(styles.role) }, userRoleString),
                React.createElement(Button, { color: "primary", hollow: true, onClick: handleChangeFarm }, "Change Farm"))),
            React.createElement("div", { className: css(styles.menuSection) },
                React.createElement("div", { className: css(styles.promo) },
                    React.createElement("p", { className: css(styles.promoText) }, !isSubscribed ? "Get started with Bushel Farm today" : "Current Subscription"),
                    isSubscribed && (React.createElement("p", { className: css(styles.noMargin) },
                        React.createElement(CurrentSubscriptionCopy, null),
                        (cancelAtPeriodEnd || isTrialing) && daysRemaining !== undefined && (React.createElement("span", { className: css(styles.lineBreak, isTrialing ? styles.greenText : styles.redText) }, daysRemaining === 1 ? "1 day remaining" : `${daysRemaining} days remaining`)))))),
            React.createElement("div", { className: css(styles.menuSection) },
                React.createElement("ul", { "data-cy": "userMenuItemList" },
                    ssoUser ? (React.createElement("li", null,
                        React.createElement("a", { className: css(styles.menuLink), onClick: () => setShowMyProfileModal(true), onMouseOver: () => { }, role: "link" }, "My Profile"))) : (React.createElement(MenuItem, { to: "/settings" }, "Account Settings")),
                    canViewFarmPage && React.createElement(MenuItem, { to: "/settings/farm" }, "Farm Settings"),
                    canViewBillingPage && (React.createElement(MenuItem, { to: !isSubscribed ? "/billing" : "/settings/billing" }, "Subscription Plan & Billing")),
                    canViewPeoplePage && React.createElement(MenuItem, { to: "/settings/users" }, "Manage Users"),
                    canViewActivityPage && React.createElement(MenuItem, { to: "/settings/activities" }, "Manage Activity Types"),
                    React.createElement(MenuItem, { to: "/settings/data" }, "Data"),
                    React.createElement(MenuItem, { to: "/settings/connections" }, "Connections"),
                    canViewDemoPage && React.createElement(MenuItem, { to: "/demo" }, "Debug Page"))),
            React.createElement("div", { className: css(styles.menuSection) },
                React.createElement(Button, { className: css(styles.signOutButton), color: "primary", hollow: true, onClick: handleSignOutClick }, "Sign out"),
                React.createElement("span", { className: css(styles.menuFooter) },
                    "If you need support, please visit our ",
                    React.createElement(SupportLink, null, "support site"),
                    ", or contact us at",
                    " ",
                    React.createElement(SupportEmailLink, null),
                    " or ",
                    React.createElement(SupportPhoneLink, null),
                    "."),
                React.createElement(Cluster, { align: "center", around: false, between: false, center: false, end: true, even: false, start: false, space: "1rem" },
                    React.createElement(Version, null,
                        "version ",
                        process.env.CLIENT_VERSION)))),
        showMyProfileModal && React.createElement(MyProfileModal, { close: () => setShowMyProfileModal(false) })));
}, {
    onError: () => {
        App.notify("An unexpected error occurred");
    },
});
export default Menu;
