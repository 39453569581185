import React from "react";
import getAllUsers from "collection/graphql/users/queries/getAllUsers";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import UserSettingsCargillElevateBanner from "modules/settings/components/UserSettingsCargillElevateBanner";
import { UserSettingsGroup } from "modules/settings/components/UserSettingsGroup";
import { UserSettingsPageHeader } from "modules/settings/pages/Users/components/UserSettingsPageHeader";
import { Stack } from "components/fl-ui/Layout";
const UserSettingsPage = () => {
    const { data: { users }, } = useRestSuspenseQuery(getAllUsers);
    const roles = ["admin", "contributor", "manager", "no_access"];
    const userGroups = roles.reduce((acc, role) => {
        acc[role] = users.filter((user) => user.role === role);
        return acc;
    }, {});
    return (React.createElement(Stack, { className: "margin-top-10" },
        React.createElement(UserSettingsPageHeader, null),
        React.createElement(UserSettingsCargillElevateBanner, null),
        React.createElement(UserSettingsGroup, { noUsersText: "No admins", description: "Admins have full access to your entire Bushel Farm account, including the financial data you enter.", title: "Admins", role: "admin", users: userGroups.admin }),
        React.createElement(UserSettingsGroup, { noUsersText: "No managers", description: "Managers have full access to everything except financial data.", title: "Managers", role: "manager", users: userGroups.manager }),
        React.createElement(UserSettingsGroup, { noUsersText: "No contributors", description: "Contributors can log in and record activities but cannot see financial data or edit other users.", title: "Contributors", role: "contributor", users: userGroups.contributor }),
        React.createElement(UserSettingsGroup, { noUsersText: "No no-access users", description: "These users cannot view your Bushel Farm account. However, other users can still record activities for this person.", role: "no_access", title: "No Access", users: userGroups.no_access })));
};
export { UserSettingsPage };
