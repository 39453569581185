import _ from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import useCommodityCustomaryUnits from "collection/graphql/commodities/hooks/useCommodityCustomaryUnits";
import usePermissions from "hooks/usePermissions";
import CommodityFormModal from "components/commodity/CommodityFormModal";
import { Button } from "components/fl-ui";
import CropBadge from "components/fl-ui/CropBadge";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";
const Card = styled.div `
  align-items: center;
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  display: flex;
  gap: 10px;
  padding: ${Spacing.spacing16};
  > :nth-child(2) {
    flex-grow: 2;
    > * {
      display: block;
    }
  }
`;
const CommodityCard = ({ commodity }) => {
    const [showModal, setShowModal] = useState(false);
    const canWriteCommodities = usePermissions().canWrite("commodities");
    const yieldUnits = useCommodityCustomaryUnits();
    const unit = useMemo(() => _.find(yieldUnits, { value: commodity.defaultYieldUnit }), [commodity === null || commodity === void 0 ? void 0 : commodity.defaultYieldUnit, yieldUnits]);
    return (React.createElement(Card, null,
        React.createElement(CropBadge, { abbr: commodity.abbr, color: commodity.color }),
        React.createElement("div", null,
            React.createElement("strong", null,
                commodity.name,
                " (",
                commodity.abbr,
                ")"),
            unit && (React.createElement("small", null,
                unit.name,
                " (",
                unit.abbr,
                ")"))),
        canWriteCommodities && (React.createElement(Button, { color: "secondary", onClick: () => setShowModal(true) }, "Edit")),
        showModal && (React.createElement(CommodityFormModal, { commodityId: commodity.id, onClose: () => setShowModal(false), onSave: () => setShowModal(false) }))));
};
export { CommodityCard };
