import dollarFormat from "marketing/utils/dollarFormat";
import moment from "moment";
import { CardHeading, CardText, NonPrintCheckBox, Row, StyledCard, Table } from "profit_and_loss/styles";
import { allocateTypeMap } from "profit_and_loss/utils";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import withViewType from "hoc/withViewType";

import { Button } from "components/fl-ui";
import PopoverButton from "components/fl-ui/Buttons/PopoverButton";
import { Cluster } from "components/fl-ui/Layout";

const ActionCell = ({ action, children, transaction }) => (
  <td onClick={() => action(transaction)} style={{ cursor: "pointer" }}>
    {children || "--"}
  </td>
);

const ExpenseRevenueListing = ({ onSelect, selection, setExpensesAndRevenueModalData, transactions, viewType }) =>
  viewType === "desktop" ? (
    <div style={{ overflowX: "scroll" }}>
      <Table>
        <thead>
          <tr>
            <th>
              <NonPrintCheckBox
                checked={!!transactions.length && selection.size === transactions.length}
                onChange={() => onSelect(selection.size === transactions.length ? [] : transactions)}
              />
            </th>
            <th>Date</th>
            <th>Account name</th>
            <th>Allocated to</th>
            <th>Notes</th>
            <th>Amount</th>
          </tr>
        </thead>

        <tbody>
          {transactions.map((transaction) => {
            const { account, allocateType, amount, date, id, notes } = transaction;

            return (
              <Row isSelected={selection.has(transaction)} key={id}>
                <td>
                  <NonPrintCheckBox checked={selection.has(transaction)} onChange={() => onSelect(transaction)} />
                </td>
                <ActionCell action={setExpensesAndRevenueModalData} transaction={transaction}>
                  <Link>{moment(date).format("MM/DD/YYYY")}</Link>
                </ActionCell>
                <ActionCell action={setExpensesAndRevenueModalData} transaction={transaction}>
                  {account.name}
                </ActionCell>
                <ActionCell action={setExpensesAndRevenueModalData} transaction={transaction}>
                  {allocateTypeMap[allocateType]}
                </ActionCell>
                <ActionCell action={setExpensesAndRevenueModalData} transaction={transaction}>
                  {notes}
                </ActionCell>
                <ActionCell action={setExpensesAndRevenueModalData} transaction={transaction}>
                  {dollarFormat(amount)}
                </ActionCell>
              </Row>
            );
          })}
        </tbody>
      </Table>
    </div>
  ) : (
    transactions.map((transaction) => {
      const { account, allocateType, amount, date, id } = transaction;

      return (
        <StyledCard key={id} onClick={null}>
          <Cluster between>
            <div>
              <CardHeading>{account.name}</CardHeading>
              <Button color="primary" link onClick={() => setExpensesAndRevenueModalData(transaction)} size="lg">
                {`${dollarFormat(amount)} on ${moment(date).format("MM/DD/YYYY")}`}
              </Button>
              <CardText>{allocateTypeMap[allocateType]}</CardText>
            </div>

            <div>
              <PopoverButton
                options={[{ label: "Edit", onSelect: () => setExpensesAndRevenueModalData(transaction) }]}
              />
            </div>
          </Cluster>
        </StyledCard>
      );
    })
  );

ExpenseRevenueListing.propTypes = {
  onSelect: PropTypes.func,
  selection: PropTypes.object,
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  viewType: PropTypes.oneOf(["desktop", "mobile"]),
};

export default withViewType(ExpenseRevenueListing);
