import styled from "styled-components";
import { Borders, Spacing } from "components/fl-ui/constants";
export const FieldDetailsWidgetContainer = styled.div `
  padding-bottom: ${Spacing.spacing16};
  border-bottom: ${Borders.regular};
  &:last-child {
    border-bottom: none;
  }
  @media only screen and (max-width: 62.5rem) {
    padding: 0;
  }
`;
