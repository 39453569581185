import useCurrentEnterprise from "hooks/useCurrentEnterprise";
/**
 * A hook that returns a boolean value indicating whether the current user
 * has a specific enterprise flag enabled on their account.
 */
const useEnterpriseFeature = (featureId) => {
    var _a, _b;
    return (_b = (_a = useCurrentEnterprise().currentEnterprise) === null || _a === void 0 ? void 0 : _a.features) === null || _b === void 0 ? void 0 : _b.includes(featureId);
};
export default useEnterpriseFeature;
