import { useMutation } from "@apollo/client";
import { useMemo } from "react";
import { notificationsClient } from "collection/graphql/client";
import dismissAllUserNotifications from "collection/graphql/notifications/mutations/dismissAllUserNotifications";
import dismissUserNotification from "collection/graphql/notifications/mutations/dismissUserNotification";
const useNotificationMutations = () => {
    const [dismissAll] = useMutation(dismissAllUserNotifications, {
        client: notificationsClient,
        update(cache) {
            cache.modify({
                fields: {
                    // @ts-ignore
                    getUserNotifications() {
                        return [];
                    },
                },
            });
        },
    });
    const [dismissNotification] = useMutation(dismissUserNotification, {
        client: notificationsClient,
    });
    return useMemo(() => {
        return {
            dismissAllNotifications: dismissAll,
            dismissNotification: (id) => {
                return dismissNotification({
                    update(cache) {
                        const normalizedId = cache.identify({ id, __typename: "Notification" });
                        cache.evict({ id: normalizedId });
                        cache.gc();
                    },
                    variables: {
                        id,
                    },
                });
            },
        };
    }, []);
};
export default useNotificationMutations;
