import FormGroup from "./FormGroup";
import { css, StyleSheet } from "aphrodite/no-important";
import Color from "color";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  label: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    color: Typography.colors.primary,
    backgroundColor: UIColors.white,
    fontSize: Typography.sizes.rg,
    lineHeight: 1,
    padding: Spacing.spacing12,
    marginRight: Spacing.spacing8,
    marginBottom: Spacing.spacing8,
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    ":active": {
      backgroundColor: UIColors.primary,
      borderColor: UIColors.primary,
      color: UIColors.white,
    },
    ":hover": {
      cursor: "pointer",
    },
  },
  label_selected: {
    backgroundColor: UIColors.primary,
    borderColor: UIColors.primary,
    color: UIColors.white,
    ":hover": {
      backgroundColor: Color(UIColors.primary).lighten(0.05).string(),
      borderColor: Color(UIColors.primary).lighten(0.05).string(),
      cursor: "pointer",
    },
  },
  input: {
    display: "none",
  },
});

const Label = ({ isMulti, label, onChange, options, selectedValues, showBulkSelect }) => {
  if (!label) {
    return null;
  } else if (!isMulti) {
    return label;
  }

  const optionValues = _.map(options, "value");
  const hasIdenticalValues = optionValues.every((value) => selectedValues.includes(value));
  const isSameLength = options.length === selectedValues.length;
  const allSelected = hasIdenticalValues && isSameLength;

  const handleClick = () => onChange(allSelected ? [] : optionValues);

  return (
    <>
      {label}

      {showBulkSelect && (
        <>
          {` · `}
          <Button color="primary" link onClick={handleClick} size="mn">
            {allSelected ? "Deselect all" : "Select all"}
          </Button>
        </>
      )}
    </>
  );
};

/**
 * A UI component for selecting one or more values from an array of options.
 */
export const TagSelectGroup = (props) => {
  const { id, isMulti, label, onChange, options, preserveOptions, value } = props;

  const selectedOptions = useMemo(() => {
    const optionsByValue = _.keyBy(options, "value");
    return [value].flat(Infinity).reduce((values, value) => {
      if (value) {
        const option = optionsByValue[value.value ?? value];
        return option ? values.set(option.value, option) : values;
      }

      return values;
    }, new Map());
  }, [isMulti, label, options, value]);

  const selectedValues = useMemo(() => {
    return Array.from(selectedOptions.values()).map((option) => option.value);
  }, [selectedOptions]);

  const toggle = (option) => {
    let newValue = Array.from(selectedOptions.values());
    if (selectedOptions.has(option.value)) {
      newValue = _.reject(newValue, { value: option.value });
    } else {
      newValue = newValue.concat(option);
    }

    if (!preserveOptions) {
      newValue = _.map(newValue, "value");
    }

    if (!isMulti) {
      newValue = newValue.pop();
    }

    onChange(newValue);
  };

  return (
    <FormGroup label={<Label {...props} selectedValues={selectedValues} />}>
      <div className={css(styles.container)}>
        {_.map(options, (option, i) => {
          const isChecked = selectedOptions.has(option.value);
          const Prefix = typeof option.prefix === "function" ? option.prefix : () => null;

          const onLabelClick = (event) => {
            event.preventDefault();
            toggle(option);
          };

          return (
            <label className={css(styles.label, isChecked && styles.label_selected)} key={i} onClick={onLabelClick}>
              <Prefix isSelected={isChecked} />
              <input
                checked={isChecked}
                className={css(styles.input)}
                name={id}
                readOnly
                type={isMulti ? "checkbox" : "radio"}
                value={option.value}
              />
              {option.label}
            </label>
          );
        })}
      </div>
    </FormGroup>
  );
};

TagSelectGroup.propTypes = {
  /**
   * Corresponds to the `name` attribute on the underlying radio/checkbox.
   */
  id: PropTypes.string.isRequired,
  /**
   * Allows for multiple options to be selected at once. If `isMulti` and `showBulkSelect` are `true`,
   * a select/deselect all link appears.
   */
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  /**
   * A callback function fired when the selection is changed. It is called with a single
   * argument: `value` of the selected option for single-select, an array of `value`s for
   * multi-select.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * An array of option objects with a `label` and a `value`.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      prefix: PropTypes.func,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ).isRequired,
  /**
   * If true, the `onChange` callback is called with the full option object (or array
   * of objects if `isMulti` is `true`) instead of only the value.
   */
  preserveOptions: PropTypes.bool,
  /**
   * Shows a select/deselect all button if `true` and this component allows for multi-select.
   * A `label` must also be present for this to show.
   */
  showBulkSelect: PropTypes.bool,
  /**
   * A value or array of values corresponding to options in the `options` prop.
   */
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
};

TagSelectGroup.defaultProps = {
  isMulti: false,
  label: null,
  preserveOptions: false,
  showBulkSelect: false,
};

export default TagSelectGroup;
