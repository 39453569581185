import { useMemo } from "react";
import { useMainMenuFeatures } from "components/fl-ui/Layout/MainMenu/useMainMenuFeatures";
const useMainMenuSharedListMap = () => {
    const mainMenuFeatures = useMainMenuFeatures();
    const primaryCategoryMappings = {
        FARM: ["fields", "activities", "scouting", "imagery", "map", "storage", "loads"],
        FINANCIAL: [
            "wallet",
            "profitAndLoss",
            "marketing",
            "contracts",
            "marketingPrices",
            "inputs",
            "landCosts",
            "expense",
        ],
        MORE: ["reports", "equipment"],
    };
    const additionalFeaturesCategoryMappings = {
        "Business Plan": ["profitAndLoss", "landCosts", "expense"],
        "Essentials Plan": ["activities", "marketing", "imagery"],
        "Lite Plan": ["marketingPrices", "contracts", "inputs", "reports", "scouting", "equipment"],
    };
    return useMemo(() => {
        // Main Features
        const primaryFeaturesMap = new Map();
        Object.entries(primaryCategoryMappings).forEach(([category, menuFeatureIds]) => {
            const sortedFeatures = mainMenuFeatures
                .filter(({ enabled, id }) => enabled && menuFeatureIds.includes(id))
                .sort((a, b) => menuFeatureIds.indexOf(a.id) - menuFeatureIds.indexOf(b.id)); // Sort by index in primaryCategoryMappings
            primaryFeaturesMap.set(category, sortedFeatures);
        });
        // Additional Features (not enabled, AKA pay-gated)
        const additionalFeaturesMap = new Map();
        Object.entries(additionalFeaturesCategoryMappings).forEach(([category, menuFeatureIds]) => {
            const sortedFeatures = mainMenuFeatures
                .filter(({ enabled, id }) => !enabled && menuFeatureIds.includes(id))
                .sort((a, b) => menuFeatureIds.indexOf(a.id) - menuFeatureIds.indexOf(b.id)); // Sort by index in additionalFeaturesMappings
            if (sortedFeatures.length > 0) {
                additionalFeaturesMap.set(category, sortedFeatures);
            }
        });
        return {
            primaryFeaturesMap,
            additionalFeaturesMap,
        };
    }, [mainMenuFeatures]);
};
export { useMainMenuSharedListMap };
