import { useAuth } from "collection/graphql/auth";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import createUser from "collection/graphql/users/mutations/createUser";
import updateUser from "collection/graphql/users/mutations/updateUser";
import getAllUsers from "collection/graphql/users/queries/getAllUsers";
import { LegacyUserSchema } from "collection/graphql/users/schemas/LegacyUserSchema";
import useRestMutation from "hooks/useRestMutation";
import App from "layout/app";
const useCreateAndEditUser = () => {
    const { currentUser } = useAuth();
    const createUserMutation = useRestMutation(createUser)[0];
    const updateUserMutation = useRestMutation(updateUser)[0];
    const onSave = async (newData) => {
        const { id, role } = newData;
        const isNew = !id;
        const save = isNew ? createUserMutation : updateUserMutation;
        const additionalFields = {
            isAdmin: role === "admin" || role === "manager",
        };
        const input = LegacyUserSchema.cast(Object.assign(Object.assign({}, newData), additionalFields), {
            assert: false,
            stripUnknown: true,
        });
        const refetchQueries = [getAllUsers];
        if (id === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id)) {
            refetchQueries.push(GET_CURRENT_USER);
        }
        try {
            await save({
                refetchQueries,
                variables: { input },
            });
            App.notify("User added/updated.");
        }
        catch (error) {
            App.notify("Unable to update user");
            throw error;
        }
    };
    return {
        onSave,
    };
};
export { useCreateAndEditUser };
