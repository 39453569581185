import React from "react";
import styled from "styled-components";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing } from "components/fl-ui/constants";
const ToggleWrapper = styled.label `
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: ${Spacing.spacing40};
  height: ${Spacing.spacing20};
`;
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" }) `
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
`;
const Slider = styled.span `
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.checked ? `${UIColors.blue}` : `${GreyColors.smoke80}`)};
  transition: 0.2s;
  border-radius: ${BorderRadius.radius34};

  &:before {
    content: "";
    position: absolute;
    height: ${Spacing.spacing14};
    width: ${Spacing.spacing14};
    left: ${Spacing.spacing3};
    bottom: ${Spacing.spacing3};
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
    transform: ${(props) => (props.checked ? `translateX(${Spacing.spacing20})` : "translateX(0)")};
  }
`;
const Toggle = ({ checked, onChange }) => (React.createElement("span", { onClick: (e) => e.stopPropagation() },
    React.createElement(ToggleWrapper, null,
        React.createElement(HiddenCheckbox, { checked: checked, onChange: onChange }),
        React.createElement(Slider, { checked: checked }))));
export { Toggle };
