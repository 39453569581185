import { useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

import { GET_ALL_MARKETED_CROPS } from "collection/graphql/marketing";

import Chooser, { components } from "components/fl-ui/Chooser";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const CropYear = styled.span`
  color: ${(props) => (props.isSelected ? ColorPalette.white : GreyColors.smoke50)};
  font-size: ${Typography.sizes.rg};
  margin-left: ${Spacing.spacing4};
`;

const Option = (props) => {
  const {
    data: { name, year },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      {name} <CropYear isSelected={isSelected}>{year}</CropYear>
    </components.Option>
  );
};

const ContractMarketedCropDropdown = ({ disabled = false, onBlur, onChange, placeholder, value }) => {
  const form = useFormContext();
  const commodityId = form.watch("commodityId");
  const cropYear = form.watch("cropYear");
  const marketedCrops = useQuery(GET_ALL_MARKETED_CROPS).data?.marketedCrops ?? [];
  const options = useMemo(() => {
    const commodityFilter = commodityId ? ({ commodity: { id } }) => id === commodityId : () => true;
    const yearFilter = cropYear ? ({ year }) => year === cropYear : () => true;
    const grouped = _.groupBy(marketedCrops.filter(commodityFilter).filter(yearFilter), "commodity.id");
    const groups = _.keys(grouped).map((commodityId) => {
      const crops = grouped[+commodityId];
      return {
        label: crops[0].commodity.name,
        options: _.orderBy(crops, ["year", "name"], ["desc", "asc"]),
      };
    });

    return _.sortBy(groups, "label");
  }, [commodityId, cropYear, marketedCrops]);

  const selectedValue = useMemo(() => {
    return _.find(marketedCrops, { id: value }) ?? null;
  }, [commodityId, marketedCrops, value]);
  // console.info(selectedValue, value)

  useEffect(() => {
    if (selectedValue && (selectedValue?.commodity?.id !== commodityId || selectedValue?.year !== cropYear)) {
      onChange(null);
    }
  }, [commodityId, cropYear, selectedValue]);

  return (
    <Chooser
      components={{
        Option,
      }}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isClearable
      isDisabled={disabled}
      noOptionsMessage={() => "No marketed crops"}
      onBlur={onBlur}
      onChange={(option) => onChange(option?.id ?? null)}
      options={options}
      placeholder={placeholder}
      value={selectedValue}
    />
  );
};

ContractMarketedCropDropdown.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default ContractMarketedCropDropdown;
