import React from "react";
import styled from "styled-components";
import AddonPrompt from "modules/billing/components/AddonPrompt";
import { ComparePlansPrompt } from "modules/billing/components/ComparePlansPrompt";
import CustomPlanPrompt from "modules/billing/components/CustomPlanPrompt";
import PrimaryPlanList from "modules/billing/components/PlanLists/PrimaryPlanList";
import SecondaryPlanList from "modules/billing/components/PlanLists/SecondaryPlanList";
import { Stack } from "components/fl-ui/Layout";
const ProductSelectionList = styled(({ className, onComparePlansClicked }) => (React.createElement("div", { id: "productSelectionList", className: className },
    React.createElement(Stack, { space: "32px" },
        React.createElement(PrimaryPlanList, null),
        React.createElement(SecondaryPlanList, null),
        React.createElement(CustomPlanPrompt, null),
        React.createElement(AddonPrompt, null),
        React.createElement(ComparePlansPrompt, { onComparePlansClicked: onComparePlansClicked }))))) `
  margin-bottom: 32px;
`;
export default ProductSelectionList;
