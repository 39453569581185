import styled from "styled-components";
const Stack = styled.div `
  > * {
    margin: 0;
  }
  > * + * {
    margin-top: ${(props) => props.space || "1rem"};
  }
`;
Stack.defaultProps = {
    space: "1rem",
};
export default Stack;
