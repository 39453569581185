import useDocumentTitle from "hooks/useDocumentTitle";
import React from "react";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import { SSOCreateNewEnterpriseForm } from "modules/sso/forms/SSOCreateNewEnterpriseForm";
const SSOCreateNewEnterprise = ({ onComplete }) => {
    useDocumentTitle("Create New Enterprise");
    return (React.createElement(BaseSSOPageContainer, { title: "Create a New Farm Account" },
        React.createElement(SSOCreateNewEnterpriseForm, { onComplete: onComplete })));
};
export { SSOCreateNewEnterprise };
