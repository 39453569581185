import { css, StyleSheet } from "aphrodite/no-important";
import React from "react";
import { Grid } from "react-styled-flexboxgrid";
import { ThemeProvider } from "styled-components";

import { Mixins, Spacing, Variables } from "components/fl-ui/constants";

const sizes = {
  paddingLarge: Spacing.spacing20,
  paddingSmall: Spacing.spacing16,
  paddingSmaller: Spacing.spacing12,
};

const styles = StyleSheet.create({
  containerFluid: {
    margin: `0 ${Spacing.spacing8}`,
    paddingTop: sizes.paddingLarge,
    paddingBottom: sizes.paddingLarge,
    paddingLeft: sizes.paddingSmall,
    paddingRight: sizes.paddingSmall,
    minHeight: "100%",
    maxWidth: Mixins.toRem(1400),
    "@media only screen and (max-width: 30em)": {
      paddingLeft: Spacing.spacing8,
      paddingRight: sizes.paddingSmaller,
      paddingTop: sizes.paddingSmaller,
    },
  },
});

const Container = ({ children, ...props }) => (
  <ThemeProvider theme={Variables.gridTheme}>
    <Grid {...props} fluid className={css(styles.containerFluid)}>
      {children}
    </Grid>
  </ThemeProvider>
);

export default Container;
