import { StyleSheet, css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";

import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import { Spacing, Typography } from "components/fl-ui/constants";

//TODO: This component needs to be refactored to a function component

const styles = StyleSheet.create({
  modalHeader: {
    paddingBottom: Spacing.spacing8,
    borderBottom: 0,
  },
  modalTitle: {
    lineHeight: 1.4,
  },
  modalBody: {
    fontSize: Typography.sizes.lg,
    lineHeight: 1.375,
    paddingTop: 0,
    paddingBottom: 0,
    color: Typography.colors.secondary,
    borderBottom: 0,
    minHeight: Spacing.spacing16,
  },
  modalFooter: {
    paddingTop: Spacing.spacing16,
  },
});

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.escape = this.escape.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.escape);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.escape);
  }

  escape = (e) => {
    if (e.key === "Escape") {
      this.props.onClose();
    }
  };

  render() {
    const { dialogHeading, dialogBody, dialogControls } = this.props;
    return (
      <Modal className={css(styles.modal)}>
        {dialogHeading && (
          <ModalHeader className={css(styles.modalHeader)}>
            <ModalTitle className={css(styles.modalTitle)}>{dialogHeading}</ModalTitle>
          </ModalHeader>
        )}
        {dialogBody && <ModalBody className={css(styles.modalBody)}>{dialogBody}</ModalBody>}
        {dialogControls && <ModalFooter className={css(styles.modalFooter)}>{dialogControls}</ModalFooter>}
      </Modal>
    );
  }
}

Dialog.propTypes = {
  dialogHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogBody: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogControls: PropTypes.object,
};

export default Dialog;
