import GreyColors from "components/fl-ui/colors/grey";
import ColorPalette from "components/fl-ui/colors/palette";

const BrandColors = {
  bushelBlue: ColorPalette["bushel-blue"],
  green: "#41A259", // FarmLogs Brand Color
  red: "#E3684C",
  blue: "#1E7DDC",
};

const UIColors = {
  primary: BrandColors.blue,
  secondary: GreyColors.smoke95,
  info: BrandColors.blue,
  success: BrandColors.green,
  danger: BrandColors.red,
  white: "#FFFFFF",
  black: "#000000",
  green: BrandColors.green, // FarmLogs Brand Color
  red: BrandColors.red,
  blue: BrandColors.blue,
  darkBlue: ColorPalette["dark-blue"],
  darkGrey: "#454a54",
  greyLight: "#F7FAFC",
  bushelBlue: BrandColors.bushelBlue,
  blueLight: "#B8D7F9", // #B8D7F9
  blueLightest: "#EBF5FF", // #EBF5FF
  orange: "#F79A2F", // #F79A2F
  purple: ColorPalette.purple,
  darker: GreyColors.smoke10,
  dark: GreyColors.smoke20,
  regular: GreyColors.smoke30,
  medium: GreyColors.smoke50,
  light: GreyColors.smoke60,
  lighter: GreyColors.smoke70,
  lightest: GreyColors.smoke80,
  shadowOne: "rgba(0, 0, 0, 0.07)",
  shadowTwo: "rgba(0, 0, 0, 0.18)",
  overlay: "rgba(23, 24, 28, 0.55)",
  overlayLight: "rgba(255, 255, 255, 0.5)",
  gradientWhiteGray: "linear-gradient(-180deg, #FFFFFF 0%, #FAFAFA 100%)",
};

export default UIColors;
