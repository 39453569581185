import { StyleSheet, css } from "aphrodite/no-important";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { createContext, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { ThemeProvider } from "styled-components";

import EventChannel from "lib/events";

import { UIColors, GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Mixins, Spacing, Variables } from "components/fl-ui/constants";

const modalSeparator = `1px solid ${GreyColors.smoke90}`;

//TODO: Clean Up and reevaluate this file, there are multiple comments and items needed throughout
// that should be addressed in this ticket: https://bushel.atlassian.net/browse/FARM-9809
const styles = StyleSheet.create({
  body: {
    overflow: "hidden",
  },
  hideModal: {
    display: "none",
    zIndex: -10000,
  },
  modalWrapper: {
    position: "fixed",
    background: "transparent",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 4000,
    backfaceVisibility: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    overflow: "auto",
    "@media only screen and (max-width: 34.285rem)": {
      justifyContent: "flex-start",
    },
  },
  modalBackground: {
    background: UIColors.overlay,
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  modalWindow: {
    position: "relative",
    color: UIColors.regular,
    display: "block",
    overflow: "visible",
    background: UIColors.white,
    boxShadow: `0 4px 10px ${UIColors.shadowTwo}`,
    zIndex: 6000,
    borderRadius: BorderRadius.small,
    marginTop: "4em",
    marginBottom: "4em",
    width: "500px",
    maxWidth: "100%",
    "@media only screen and (max-width: 34.285rem)": {
      margin: 0,
    },
  },
  modalHeader: {
    position: "relative",
    padding: Spacing.spacing24,
    borderBottom: modalSeparator,
    lineHeight: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  modalTitle: {
    margin: `0 3em 0 0`, // room for close-x
    lineHeight: 1,
  },
  modalContent: {
    height: "auto",
  },
  modalSection: {
    padding: `0 ${Spacing.spacing24}`,
    borderBottom: modalSeparator,
  },
  modalBody: {
    position: "relative",
    padding: `${Spacing.spacing20} ${Spacing.spacing8}`,
    borderBottom: modalSeparator,
    minHeight: Mixins.toRem(100),
  },
  modalRibbon: {}, // TO DO: extract modal ribbon styles from css
  modalFooter: {
    padding: Spacing.spacing24,
    textAlign: "right",
  },
  modalFooterLeft: {
    fontWeight: 500,
    color: UIColors.regular,
    float: "left",
    lineHeight: "34px",
    cursor: "pointer",
    paddingLeft: "1em",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  modalFooterButton: {
    display: "block",
    width: "100%",
    textAlign: "center",
  },
  modalFooterButtonLink: {
    display: "block",
    color: UIColors.blue,
    padding: Spacing.spacing24,
    textAlign: "center",
  },
});

const modalChannel = EventChannel.getChannel("modal");
const modalRoot = document.getElementById("dialog");

const getBackgroundImage = (url) => {
  if (url) {
    const styles = {
      background: `url(${url})`,
      backgroundSize: "cover",
    };
    return Mixins.getOverrideStyles(styles);
  } else {
    return "";
  }
};

export const modalContext = createContext();
const ModalContextProvider = modalContext.Provider;

const Modal = ({ backgroundImage, children, className, contentClassName, hideModal, id, style, width }) => {
  const el = useRef(document.createElement("div"));
  const ref = useRef();
  const context = useMemo(() => ({ container: ref.current }), [ref.current]);

  useEffect(() => {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(el.current);
    document.body.classList.add(css(styles.body));
    modalChannel.fire("open");

    return () => {
      modalRoot.removeChild(el.current);
      document.body.classList.remove(css(styles.body));
      modalChannel.fire("close");
    };
  }, []);

  useEffect(() => {
    if (hideModal) {
      document.body.classList.remove(css(styles.body));
    } else {
      document.body.classList.add(css(styles.body));
    }
  }, [hideModal]);

  return ReactDOM.createPortal(
    <ModalContextProvider value={context}>
      <div id={id} className={css(styles.modalWrapper, hideModal && styles.hideModal)} ref={ref}>
        <div className={css(styles.modalBackground, getBackgroundImage(backgroundImage))} />
        <div
          className={classNames(
            css(styles.modalWindow, Mixins.getOverrideStyles({ width: `${width}px` }), Mixins.getOverrideStyles(style)),
            className
          )}
        >
          <div className={classNames(css(styles.modalContent), contentClassName)}>{children}</div>
        </div>
      </div>
      ,
    </ModalContextProvider>,
    el.current
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  windowClass: PropTypes.string,
  width: PropTypes.number,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  style: PropTypes.object,
  backgroundImage: PropTypes.string,
};

Modal.defaultProps = {
  type: "",
  windowClass: "",
  width: 500,
  className: "",
  contentClassName: "",
};

const ModalHeader = ({ className = "", children }) => (
  <div className={classNames(css(styles.modalHeader), className)}>{children}</div>
);

const ModalTitle = ({ children }) => <h2 className={classNames(css(styles.modalTitle))}>{children}</h2>;

const ModalBody = ({ className = "", children }) => (
  <div className={classNames(css(styles.modalBody), className)}>
    <ThemeProvider theme={Variables.gridTheme}>
      <Grid fluid>
        <Row>
          <Col xs>{children}</Col>
        </Row>
      </Grid>
    </ThemeProvider>
  </div>
);

const ModalSection = ({ className = "", children }) => (
  <div className={classNames(css(styles.modalSection), className)}>{children}</div>
);

//I don't think ModalRibbon is used, but there are some strange references to it in modal.jsx that I'm not sure are valid
const ModalRibbon = ({ className = "", children }) => (
  <div className={classNames(css(styles.modalRibbon), className)}>{children}</div>
);

const ModalFooter = ({ className = "", children }) => (
  <div className={classNames(css(styles.modalFooter), className)}>{children}</div>
);

//I don't think ModalFooterButton is used, but there are some strange references to it in modal.jsx that I'm not sure are valid
const ModalFooterButton = (props) => {
  return (
    <div className={classNames(css(styles.modalFooterButton), props.className)}>
      <a {...props} className={css(styles.modalFooterButtonLink)} />
    </div>
  );
};

export { Modal, ModalHeader, ModalTitle, ModalBody, ModalSection, ModalRibbon, ModalFooter, ModalFooterButton };
