const Spacing = {
  spacing1: "0.063rem", // 1px
  spacing2: "0.125rem", // 2px
  spacing3: "0.1875em", // 3px
  spacing4: "0.25rem", // 4px
  spacing8: "0.5rem", // 8px
  spacing10: "0.625rem", // 10px
  spacing12: "0.75rem", // 12px
  spacing14: "0.875rem", // 14px
  spacing16: "1rem", // 16px
  spacing20: "1.25rem", // 20px
  spacing24: "1.5rem", // 24px
  spacing28: "1.75rem", // 28px
  spacing32: "2rem", // 32px
  spacing36: "2.25rem", // 36px
  spacing40: "2.5rem", // 40px
  spacing48: "3rem", // 48px
  spacing60: "4rem", // 60px
  spacing70: "4.375rem", // 70px
  spacing80: "6rem", // 80px
  spacing120: "8rem", // 120px
};

export default Spacing;
