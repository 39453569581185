import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const ContractsBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Contracts for Free" : "Move to Lite today",
        imagePath: "images/pay_gates/banners/contract.svg",
        title: "With Contracts you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Automate the entry of grain contract data from grain buyers into the Bushel Network."),
                React.createElement(ValuePropListItem, null, "Organize, view, and easily access all of your grain contracts from all of your buyers."),
                React.createElement(ValuePropListItem, null, "Quickly access real-time marketing and financial insights generated using your contract and cost of production details in the Marketing and Profit & Loss features.")))));
};
export { ContractsBlankSlateBanner };
