import { useQuery } from "@apollo/client";
import { StyleSheet, css } from "aphrodite/no-important";
import { isEmpty } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { notificationsClient } from "collection/graphql/client";
import useNotificationMutations from "collection/graphql/notifications/hooks/useNotificationMutations";
import getUserNotifications from "collection/graphql/notifications/queries/getUserNotifications";
import useViewType from "hooks/useViewType";
import { ALERTS_DISMISS_ALL_CLICKED, ALERTS_VIEW_ALL_CLICKED, ALERTS_SETTINGS_ICON_CLICKED } from "lib/metrics/events";
import { BlankSlate } from "components/fl-ui/BlankSlate";
import DropDown from "components/fl-ui/DropDown";
import Icon from "components/fl-ui/Icons/Icon";
import NotificationIcon from "components/fl-ui/Layout/Navigation/ToolbarNotificationMenu/NotificationIcon";
import NotificationItem from "components/fl-ui/Layout/Navigation/ToolbarNotificationMenu/NotificationItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import ToolTip from "components/fl-ui/Tooltip";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Mixins, Spacing, Typography } from "components/fl-ui/constants";
const styles = StyleSheet.create({
    blankSlateHeaderText: {
        fontSize: Typography.sizes.xl,
        fontWeight: Typography.weights.medium,
        margin: "1em 0 0",
    },
    blankSlateBodyText: {
        lineHeight: "1.5em",
        margin: "1em 0",
        fontSize: Typography.sizes.lg,
    },
    blankSlateImage: {
        maxWidth: Mixins.toRem(40),
        margin: "auto",
    },
    errorText: {
        color: UIColors.red,
        marginBottom: Spacing.spacing32,
        textAlign: "center",
    },
    menuFooter: {
        borderTop: `1px solid ${GreyColors.smoke90}`,
        padding: Spacing.spacing16,
        textAlign: "center",
    },
    menuFooterText: {
        color: UIColors.blue,
        textDecoration: "none",
        fontSize: Typography.sizes.rg,
        fontWeight: Typography.weights.medium,
        lineHeight: 1.357,
        ":hover": {
            color: UIColors.blue,
        },
    },
    menuHeader: {
        borderBottom: `1px solid ${GreyColors.smoke90}`,
        padding: Spacing.spacing16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    menuTitle: {
        fontSize: Typography.sizes.lg,
        margin: 0,
    },
    menuContents: {
        height: "auto",
        maxHeight: Mixins.toRem(350),
        overflowY: "auto",
        boxShadow: "inset 0 1px 5px 0 rgba(0,0,0,0.05)",
    },
    menuSettings: {
        display: "flex",
    },
    iconSpacer: {
        width: Spacing.spacing16,
    },
    alertIcon: {
        width: Spacing.spacing24,
        maxWidth: Spacing.spacing24,
        height: Spacing.spacing24,
        maxHeight: Spacing.spacing24,
        cursor: "pointer",
    },
});
const ToolbarNotificationMenu = () => {
    var _a;
    const { dismissAllNotifications, dismissNotification } = useNotificationMutations();
    const { data, error, loading } = useQuery(getUserNotifications, {
        client: notificationsClient,
        pollInterval: 5 * 60 * 1000, // every 5 minutes
    });
    const notifications = (_a = data === null || data === void 0 ? void 0 : data.notifications) !== null && _a !== void 0 ? _a : [];
    const errorMessage = "An unexpected error occurred. Please try again later.";
    const target = React.createElement(NotificationIcon, { count: notifications.length });
    const navigate = useNavigate();
    const handleDismissAllClick = () => {
        ALERTS_DISMISS_ALL_CLICKED.track({});
        dismissAllNotifications();
    };
    const handleViewAllClick = () => {
        ALERTS_VIEW_ALL_CLICKED.track({});
        navigate("/alerts");
    };
    const handleSettingsClick = () => {
        ALERTS_SETTINGS_ICON_CLICKED.track({});
        navigate("/settings/alerts");
    };
    const isDesktop = useViewType() === "desktop";
    return (React.createElement("div", null,
        !isDesktop && React.createElement("div", { onClick: handleViewAllClick }, target),
        isDesktop && (React.createElement(DropDown, { width: 360, height: 500, target: target, offset: 24 },
            React.createElement("div", { className: css(styles.menuHeader) },
                React.createElement("h2", { className: css(styles.menuTitle) }, "Alerts"),
                React.createElement("div", { className: css(styles.menuSettings) },
                    React.createElement(ToolTip, { message: "Dismiss All" },
                        React.createElement(Icon, { className: css(styles.alertIcon), icon: "dismissAll", onClick: handleDismissAllClick })),
                    React.createElement("div", { className: css(styles.iconSpacer) }),
                    React.createElement(ToolTip, { message: "Settings" },
                        React.createElement(Icon, { className: css(styles.alertIcon), icon: "settings", onClick: handleSettingsClick })))),
            React.createElement(LoadingWrapper, { isLoading: loading, loadingText: "Loading your alerts..." },
                error && React.createElement("p", { className: css(styles.errorText) }, errorMessage),
                isEmpty(notifications) ? (React.createElement(BlankSlate, null,
                    React.createElement(Icon, { icon: "alertInactive", className: css(styles.blankSlateImage) }),
                    React.createElement("p", { className: css(styles.blankSlateHeaderText) }, `You're all caught up!`),
                    React.createElement("p", { className: css(styles.blankSlateBodyText) }, `When new alerts arrive they'll display here.`))) : (React.createElement("div", null,
                    React.createElement("div", { className: css(styles.menuContents) }, notifications.slice(0, 10).map((notification) => (React.createElement(NotificationItem, { dismissNotification: dismissNotification, key: notification.id, notification: notification })))),
                    React.createElement("div", { className: css(styles.menuFooter) },
                        React.createElement("a", { className: css(styles.menuFooterText), onClick: handleViewAllClick }, "View all alerts")))))))));
};
export default ToolbarNotificationMenu;
