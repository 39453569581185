import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import styled from "styled-components";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import useReleaseFeature from "hooks/useReleaseFeature";
import useViewType from "hooks/useViewType";

import { Spacing } from "components/fl-ui/constants";

const Tab = ({ activeTab, label, route, thisTab }) => (
  <li className={cx("tab js_settings_tab", activeTab === thisTab && "active")}>
    <Link to={route}>
      <div className="indicator" />
      {label}
    </Link>
  </li>
);

const DropdownContainer = styled.div`
  margin-bottom: ${Spacing.spacing4};
`;

const SettingsNav = (props) => {
  const navigate = useNavigate();
  const isMobile = useViewType() === "mobile";
  const { canRead } = usePermissions();
  const hasActivitiesFeature = useEnterpriseFeature("activities");
  const hasProductsFeature = useEnterpriseFeature("products");
  const hasInputsFeature = hasActivitiesFeature && hasProductsFeature;
  const hasProfitAndLoss = useEnterpriseFeature("profit_and_loss") && canRead("profit_and_loss");
  const hasWallet = useReleaseFeature("wallet-enabled");
  const tabs = [];

  tabs.push({ label: "Alerts", route: "/settings/alerts", thisTab: "alerts" });
  tabs.push({ label: "Farm", route: "/settings/farm", thisTab: "farm" });
  tabs.push({ label: "Plan & Billing", route: "/settings/billing", thisTab: "billing" });
  tabs.push({ label: "Users", route: "/settings/users", thisTab: "users" });
  tabs.push({ label: "Activities", route: "/settings/activities", thisTab: "activities" });

  if (hasProfitAndLoss) {
    tabs.push({
      label: "Expense & Revenue Accounts",
      route: "/settings/expense_and_revenue",
      thisTab: "expense_and_revenue",
    });
  }

  if (hasInputsFeature) {
    tabs.push({ label: "Inputs", route: "/settings/inputs", thisTab: "inputs" });
  }

  tabs.push({ label: "Data", route: "/settings/data", thisTab: "data" });
  tabs.push({ label: "Connections", route: "/settings/connections", thisTab: "connections" });
  tabs.push({ label: "Commodities", route: "/settings/commodities", thisTab: "commodities" });

  if (hasWallet) {
    tabs.push({ label: "Wallet", route: "/settings/wallet", thisTab: "wallet" });
  }

  /*
  TODO: This entire settings nav is basically the exact same implementation of the NavBar component.
    unfortunately, this one does a bunch of funky stuff with routes and permissions and filtering with the pages right now
   so it didn't refactor smoothly.I'm opening up this ticket (https://bushel.atlassian.net/browse/FARM-10251)
   so we can tackle this in the future where Yield timelines are not so pressing.
   */
  return isMobile ? (
    <DropdownContainer>
      <ReactSelect
        getOptionLabel={({ label }) => label}
        getOptionValue={({ thisTab }) => thisTab}
        isSearchable={false}
        onChange={({ route }) => navigate(route)}
        options={tabs}
        styles={chooserStyles}
        value={tabs.find(({ thisTab }) => thisTab === props.activeTab)}
      />
    </DropdownContainer>
  ) : (
    <div className="settings-tabs-container">
      <ul className="tabs settings-tabs horizontal-tabs">
        {tabs.map((tab, i) => (
          <Tab {...props} {...tab} key={`tab${i}`} />
        ))}
      </ul>
    </div>
  );
};

SettingsNav.propTypes = {
  activeTab: PropTypes.oneOf([
    "activities",
    "alerts",
    "billing",
    "commodities",
    "data",
    "expense_and_revenue",
    "farm",
    "inputs",
    "sharing",
    "users",
    "connections",
  ]).isRequired,
};

export default SettingsNav;
