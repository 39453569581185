import { StyleSheet, css } from "aphrodite/no-important";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";

import Icon from "components/fl-ui/Icons/Icon";
import { UIColors } from "components/fl-ui/colors";
import { Borders, Mixins, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  footer: {
    marginTop: Spacing.spacing48,
    borderTop: Borders.regular,
    padding: `${Spacing.spacing28} 0`,
    fontSize: Typography.sizes.sm,
  },
  hover: {
    ":hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  helpIcon: {
    height: Mixins.toRem(16),
    marginRight: Spacing.spacing4,
    width: Mixins.toRem(16),
  },
  iconCellLink: {
    alignItems: "center",
    color: UIColors.blue,
    display: "flex",
    justifyContent: "flex-end",
  },
  logo: {
    display: "block",
    width: Mixins.toRem(100),
    minHeight: Mixins.toRem(10),
    height: "auto",
    marginTop: Spacing.spacing16,
    marginBottom: Spacing.spacing12,
  },
  rightCol: {
    textAlign: "right",
  },
});

const Disclaimer = () => {
  const Link = (props) => (
    <a href={props.to} className="blue-link" target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );

  return (
    <div className={css(styles.footer)}>
      <Row>
        <Col xs={6}>Mark-to-market current as of {moment().format("h:mm a")}</Col>

        <Col xs={6} className={css(styles.rightCol)}>
          <a
            target="_blank"
            className={`${css(styles.iconCellLink)} ${css(styles.hover)}`}
            href="https://support.bushelfarm.com/hc/en-us/articles/360000801486-Understanding-the-Marketing-calculations"
            rel="noreferrer"
          >
            <Icon className={css(styles.helpIcon)} icon="help" />
            Help
          </a>
        </Col>
      </Row>

      <Row className={css(styles.disclaimer)}>
        <Col xs={12}>
          <p>
            <img className={css(styles.logo)} src="/images/marketing/cmegroup-logo-2x.png" />
            All <Link to="https://www.barchart.com/solutions/">market data</Link>
            {` is provided by Barchart Market Data
            Solutions. Futures are delayed at least 10 minutes. Information is provided "as is" and solely for
            informational purposes, not for trading purposes or advice. To see all exchange delays and terms of use,
            please see `}
            <Link to="https://www.barchart.com/solutions/terms">disclaimer</Link>. Copyright {moment().format("YYYY")}.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Disclaimer;
