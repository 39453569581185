import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import getCurrentSubscription from "collection/graphql/subscription/getCurrentSubscription";
import useReleaseFeature from "hooks/useReleaseFeature";
import { ESSENTIALS, LITE, PREMIUM, RAINFALL } from "model/Subscription/constants";
const businessPreWallet = ["fields", "activities", "contracts", "marketing"];
const businessPostWallet = ["fields", "activities", "wallet", "marketing"];
const essentialsPreWallet = ["fields", "activities", "contracts", "marketing"];
const essentialsPostWallet = ["fields", "activities", "wallet", "marketing"];
const litePreWallet = ["fields", "activities", "contracts", "marketingPrices"];
const litePostWallet = ["fields", "contracts", "wallet", "marketingPrices"];
const rainfallPreWallet = ["fields", "map"];
const rainfallPostWallet = ["fields", "wallet", "map"];
const useTabBarPreferredFeatures = () => {
    const { data: subscriptionData, loading: currentSubscriptionLoading } = useQuery(getCurrentSubscription);
    const currentSubscription = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.subscription;
    const hasWallet = useReleaseFeature("wallet-enabled");
    const preferredFeatures = useMemo(() => {
        var _a;
        if (currentSubscriptionLoading) {
            return [];
        }
        switch ((_a = currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.currentPlan) === null || _a === void 0 ? void 0 : _a.id) {
            case PREMIUM:
                return hasWallet ? businessPostWallet : businessPreWallet;
            case ESSENTIALS:
                return hasWallet ? essentialsPostWallet : essentialsPreWallet;
            case LITE:
                return hasWallet ? litePostWallet : litePreWallet;
            case RAINFALL:
                return hasWallet ? rainfallPostWallet : rainfallPreWallet;
            default:
                return hasWallet ? businessPostWallet : businessPreWallet; // default to business features
        }
    }, [currentSubscription, hasWallet]);
    return preferredFeatures;
};
export { useTabBarPreferredFeatures };
