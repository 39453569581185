import React from "react";
import styled from "styled-components";
import _ from "underscore";
import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
const InputGroup = styled(({ className, children, onAdd, title }) => (React.createElement("div", { className: className },
    React.createElement("h2", { className: "input-group-heading-underline" }, title),
    React.createElement("br", null),
    React.createElement("div", { className: "input-group-settings-item-wrapper" }, children || null),
    onAdd && (React.createElement(Button, { color: "secondary", onClick: onAdd }, `+ Add ${_.titleize(_.singularize(title))}`))))) `
  .input-group-heading-underline {
    border-bottom: 1px solid ${GreyColors.smoke90};
    padding-bottom: 0.5em;
    overflow: visible;
  }

  .input-group-settings-item-wrapper {
    border-radius: 3px;
    background: ${GreyColors.smoke94};
    margin-bottom: 1rem;
    padding: 0.33em;
    border: 1px solid ${GreyColors.smoke90};
  }
`;
export { InputGroup };
