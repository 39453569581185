import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { LeftButton } from "components/fl-ui/Buttons";
import styles from "components/fl-ui/Buttons/styles";
import Popover from "components/fl-ui/Popover/Popover";
import { Spacing, Typography } from "components/fl-ui/constants";

const DropdownContainer = styled.div`
  display: flex;
  margin: ${Spacing.spacing8} 0;
`;

const SimpleDropdownButton = styled(Button)`
  display: flex !important;
  font-size: ${Typography.sizes.rg} !important;
  font-weight: ${Typography.weights.regular} !important;
  justify-content: space-between;
  margin-bottom: ${Spacing.spacing8};
  min-height: ${Spacing.spacing32};
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: rgb(249, 252, 255) !important;
    border-color: rgb(123, 178, 234);
  }
`;

const BulkActions = ({ danger, options, selection, selectionStatus }) =>
  !![...selection].length && (
    <DropdownContainer>
      <Popover
        content={options.map((option, index) => (
          <SimpleDropdownButton
            icon={option.icon || ""}
            iconClassName={css(option.icon && styles.green)}
            iconRight={!!option.icon}
            key={`${option.label}-${index}`}
            link
            onClick={option.onClick}
          >
            {option.label}
          </SimpleDropdownButton>
        ))}
        placement="bottom"
      >
        <LeftButton color="secondary" hollow icon="buttonChevronDown" iconRight>
          {`${selectionStatus} selected`}
        </LeftButton>
      </Popover>

      {danger && (
        <Button color="danger" hollow onClick={danger.action}>
          {`${danger.buttonText || "Delete"} ${selectionStatus}`}
        </Button>
      )}
    </DropdownContainer>
  );

BulkActions.propTypes = {
  danger: PropTypes.shape({
    action: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selection: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  selectionStatus: PropTypes.string.isRequired,
};

export default BulkActions;
