import React, { useState } from "react";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import usePermissions from "hooks/usePermissions";
import useWindow from "hooks/useWindow";
import CommonIntegrationDataRow from "modules/settings/components/Integrations/components/CommonIntegrationDataRow";
import useIntegrationConnectionLink from "modules/settings/components/Integrations/hooks/useIntegrationsConnectionLink";
import { ContractsBlankSlateBanner } from "components/advertisements/banners/ContractsBlankSlateBanner";
import PayGateCommonBlankSlateModal from "components/advertisements/modals/PayGateCommonBlankSlateModal";
import { Button } from "components/fl-ui";
import { LeftButton } from "components/fl-ui/Buttons";
const BushelIntegration = ({ integration }) => {
    const windowManager = useWindow();
    const showButtons = usePermissions().canWrite("integrations");
    const { connect, disabled } = useIntegrationConnectionLink({ integrationSlug: "bushel" });
    const [showPayGateModal, setShowPayGateModal] = useState(false);
    const { showPayGate } = useFeatureAndPermissionAccess({
        featureName: "contracts",
        permissionName: "contracts",
    });
    const handleOnClick = () => {
        if (showPayGate) {
            setShowPayGateModal(true);
        }
        else {
            connect();
        }
    };
    const handleCloseModal = () => {
        setShowPayGateModal(false);
    };
    return (React.createElement(CommonIntegrationDataRow, { copy: "Automatically import contract data from grain buyers in the Bushel network.", imgSrc: "/images/layout/bushel-logo.svg", integration: integration }, showButtons && (React.createElement(React.Fragment, null,
        React.createElement(LeftButton, { color: "primary", link: true, onClick: () => windowManager.open("https://go.bushelfarm.com/connect-to-bushel-network", "_blank") }, "Learn more"),
        integration.enabled && (React.createElement(Button, { color: "primary", disabled: disabled, hollow: true, icon: "settings", onClick: connect })),
        !integration.enabled && (React.createElement(React.Fragment, null,
            React.createElement(Button, { color: "primary", disabled: disabled, hollow: true, onClick: handleOnClick }, "Connect"),
            showPayGateModal && (React.createElement(PayGateCommonBlankSlateModal, { banner: ContractsBlankSlateBanner, onClose: handleCloseModal }))))))));
};
export { BushelIntegration };
