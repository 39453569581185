import Big from "big.js";
import { getUnitTypes } from "lib/units";
import _ from "lodash";
import { number, object, string } from "yup";
const percentNumber = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return number()
        .optional()
        .nullable()
        .min(0)
        .max(100)
        .typeError("${path} must be numeric");
};
const FertilizerProductSchema = object({
    brand: string().trim().nullable().label("Brand").default(""),
    density: number().min(0).nullable().label("Density"),
    id: number().optional().positive().integer().label("Product ID"),
    name: string().trim().required().label("Fertilizer name"),
    notes: string().nullable().default("").label("Notes"),
    percentNitrogen: percentNumber().label("Percent Nitrogen"),
    percentPhosphate: percentNumber().label("Percent Phosphate"),
    percentPotash: percentNumber().label("Percent Potash"),
    percentSulfate: percentNumber().label("Percent Sulfate"),
    percentZinc: percentNumber().label("Percent Zinc"),
    type: string().lowercase().oneOf(["fertilizer"]).required().label("Type"),
    unitType: string()
        .lowercase()
        .oneOf(getUnitTypes().map(_.toLower), "Invalid ${path}")
        .required()
        .label("Unit type"),
});
const percentageFieldNames = ["percentNitrogen", "percentPhosphate", "percentPotash", "percentSulfate", "percentZinc"];
export const PercentagesSchema = object()
    .transform((subject) => (percentageFieldNames.reduce((result, key) => Object.assign(result, { [key]: Number(subject[key]) }), {})))
    .test("required", "If one percentage is provided, all are required", (subject, { createError }) => {
    const numericValues = _.values(subject).filter(value => !Number.isNaN(value));
    if (numericValues.length > 0 && numericValues.length < percentageFieldNames.length) {
        return createError({ path: "percentages" });
    }
    return true;
})
    .test("sum", "Sum of percentages must be less than or equal to 100", (subject, { createError }) => {
    const numericValues = _.values(subject).filter(value => !Number.isNaN(value));
    const sum = numericValues.reduce((sum, value) => sum.add(value), new Big(0));
    return sum.lte(100) || createError({ path: "percentages" });
});
export default FertilizerProductSchema;
