import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import { getMainMenuItemActiveClasses } from "components/fl-ui/Layout/MainMenu/utils/getMainMenuItemActiveClasses";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing, Typography } from "components/fl-ui/constants";
const DesktopMenuLabel = styled.div `
  background-color: ${UIColors.blueLight};
  border-radius: 3px;
  color: ${UIColors.darkBlue};
  font-size: ${Typography.sizes.md};
  font-weight: ${Typography.weights.bold};
  margin-right: ${Spacing.spacing4};
  padding: ${Spacing.spacing4};
  text-transform: uppercase;
  transform: translateY(-1px);
`;
const SharedListItem = styled.li `
  border-radius: ${BorderRadius.medium};
  font-weight: ${Typography.weights.regular};
  height: ${Spacing.spacing48};
  margin-bottom: ${Spacing.spacing8};
  transition: background-color 100ms ease-in;
  width: 100%;

  &:has(a.active) {
    background-color: ${UIColors.blueLightest};
  }

  a {
    align-items: center;
    color: ${GreyColors.smoke40};
    display: flex;
    gap: ${Spacing.spacing20};
    justify-content: flex-start;
    padding: ${Spacing.spacing12} 0 ${Spacing.spacing12} ${Spacing.spacing14};
    text-decoration: none;
  }
  a:hover {
    font-weight: ${Typography.weights.medium};
  }
  a.active {
    color: ${UIColors.blue};
    font-weight: ${Typography.weights.bold};
  }

  span {
    padding-left: ${({ $enabled }) => ($enabled ? "0" : Spacing.spacing40)};
  }
`;
const MainMenuSharedListItem = ({ enabled = true, icon, label, onClick, showRouteAsActive, title, to, }) => (React.createElement(SharedListItem, { "$enabled": enabled },
    React.createElement(NavLink, { className: getMainMenuItemActiveClasses({ showRouteAsActive, to }), onClick: onClick, to: to },
        enabled && (React.createElement(IconContainer, { size: "medium" },
            React.createElement(Icon, { icon: icon }))),
        React.createElement("span", null, enabled ? title : `• ${title}`),
        label && React.createElement(DesktopMenuLabel, null, label))));
export { MainMenuSharedListItem };
