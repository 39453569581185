import { Navigate } from "react-router-dom";
import React from "react";
import { useAuthStore } from "collection/graphql/auth/hooks/AuthStore";
const BaseRouteRedirect = () => {
    const { isAuthenticated } = useAuthStore();
    if (!isAuthenticated) {
        return React.createElement(Navigate, { to: "/sign_in" });
    }
    return React.createElement(Navigate, { to: "/sso" });
};
export default BaseRouteRedirect;
