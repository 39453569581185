import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const InputItem = styled(({ className, children = null, onEdit, sub, title }) => (
  <div className={className}>
    <Button className="float-right btn btn-tiny btn-white" color="secondary" onClick={onEdit}>
      Edit
    </Button>

    <div>
      {children || null}
      <div className="title">{title}</div>
      <div className="sub">{sub}</div>
    </div>
  </div>
))`
  border: 1px solid ${GreyColors.smoke90};
  padding: ${Spacing.spacing16};
  margin: 0 0 -1px;
  background: white;

  .title {
    font-size: ${Typography.sizes.lg};
  }

  .sub {
    color: ${GreyColors.smoke60};
    font-size: ${Typography.sizes.md};
  }
`;

export default InputItem;
