import { StyleSheet } from "aphrodite";

import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  authContainer: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
    justifyContent: "center",
    padding: "2.5rem",
    "@media only screen and (max-width: 62.5rem)": {
      display: "block",
    },
  },
  blueText: {
    color: UIColors.blue,
  },
  contactText: {
    marginTop: Spacing.spacing16,
  },
  copyText: {
    color: UIColors.light,
    fontSize: Typography.sizes.lg,
    lineHeight: Spacing.spacing24,
  },
  errorText: {
    display: "block",
    lineHeight: 1.5,
  },
  footerContainer: {
    marginTop: Spacing.spacing24,
    textAlign: "center",
  },
  formContainer: {
    marginTop: Spacing.spacing24,
  },
  fullscreen: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    overflow: "scroll",
  },
  sideBackground: {
    backgroundImage: `url('/images/pay_gates/banners/countryroad.png')`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    height: "100%",
  },
  toggleText: {
    fontSize: Typography.sizes.sm,
    textDecoration: "none",
  },
});

export default styles;
