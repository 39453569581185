import _ from "lodash";
import { isNative } from "mobile/mobileManager";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import defaultCache from "collection/graphql/cache/defaultCache";
import { getCurrentSubscription } from "collection/graphql/subscription";
import { SubscriptionProductFragment } from "collection/graphql/subscription/fragments";
import useRestQuery from "hooks/useRestQuery";

const useBillingOptions = () => {
  const subscription = useRestQuery(getCurrentSubscription).data?.subscription;
  const isOnAnnualSubscription = subscription?.billingInterval === "year";

  const selectedPlanId = useFormContext().watch("selectedPlanId");
  const selectedProduct = useMemo(() => {
    return defaultCache.readFragment({
      fragment: SubscriptionProductFragment,
      id: defaultCache.identify({ id: selectedPlanId, __typename: "SubscriptionProduct" }),
    });
  }, [selectedPlanId]);

  const billingOptions = useMemo(() => {
    return (
      [
        { id: "month", label: "Monthly billing", value: "month" },
        { id: "year", label: "Yearly billing", value: "year" },
      ]
        // hide billing options on native platforms
        .filter(() => !isNative())
        // return options with plan intervals that are supported by the currently selected plan
        // return all options if no plan is selected
        .filter(({ value }) => {
          if (selectedProduct?.plans) {
            return !!_.find(selectedProduct.plans, { interval: value });
          } else {
            return true;
          }
        })
        // only allow monthly options if the user has not currently subscribed to an annual plan
        .filter(({ value }) => {
          return !isOnAnnualSubscription || value === "year";
        })
    );
  }, [isOnAnnualSubscription, selectedProduct]);

  const showBillingOptions = billingOptions.length > 1;

  return {
    billingOptions,
    offerYearlyBilling: showBillingOptions && !!_.find(billingOptions, { value: "year" }),
    showBillingOptions,
  };
};

export default useBillingOptions;
