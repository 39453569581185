import React from "react";
import styled from "styled-components";
import useBoolean from "hooks/useBoolean";
import { FullPageOverlay } from "modules/billing/layouts/FullPageOverlay";
import { Logo } from "components/fl-ui/Icons";
import { MainMenuSharedList } from "components/fl-ui/Layout/MainMenu/SharedList/MainMenuSharedList";
import { TabBarListItem } from "components/fl-ui/Layout/MainMenu/TabBar/TabBarListItem";
import { useTabBarMainMenuFeatures } from "components/fl-ui/Layout/MainMenu/TabBar/useTabBarMainMenuFeatures";
import Toolbar from "components/fl-ui/Layout/Navigation/Toolbar";
import NavigationHeader from "components/fl-ui/Layout/Navigation/components/NavigationHeader";
import { UIColors } from "components/fl-ui/colors";
import { BorderRadius, Spacing, Typography } from "components/fl-ui/constants";
const TabBarContainer = styled.ul `
  background-color: ${UIColors.white};
  border-radius: ${BorderRadius.medium};
  bottom: ${Spacing.spacing12};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  display: grid;
  grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);
  left: 0.5rem;
  padding: ${Spacing.spacing4};
  position: fixed;
  transition: height 400ms cubic-bezier(0.37, 0, 0.63, 1);
  width: calc(100% - 1rem);
  z-index: 3000;

  > li a {
    height: 3.5rem;
    padding: 0.5rem;

    > :nth-child(2) {
      color: ${UIColors.darkBlue};
      font-weight: ${Typography.weights.bold};
    }

    &.active,
    &#back {
      background-color: ${UIColors.blueLightest};

      > :nth-child(2) {
        color: ${UIColors.blue};
      }
    }
  }
`;
const MainMenuResponsive = () => {
    const [showMenuList, { off, toggle }] = useBoolean(false);
    const tabBarMainMenuFeatures = useTabBarMainMenuFeatures();
    const handleMainMenuFeatureClick = (event, menuFeature) => {
        if (menuFeature.id === "moreMenu") {
            event.preventDefault();
            toggle();
        }
        else {
            if (typeof menuFeature.onClick === "function") {
                menuFeature.onClick(event);
            }
            off();
        }
    };
    const handleOnCancel = () => {
        off();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(NavigationHeader, null,
            React.createElement(Logo, { size: "sm" }),
            React.createElement(Toolbar, null)),
        tabBarMainMenuFeatures.length > 0 && (React.createElement(TabBarContainer, { columnCount: tabBarMainMenuFeatures.length, "data-cy": "mobileMenu", id: "mobileMenuContainer" }, tabBarMainMenuFeatures === null || tabBarMainMenuFeatures === void 0 ? void 0 : tabBarMainMenuFeatures.map((mainMenuFeature) => (React.createElement(TabBarListItem, Object.assign({}, mainMenuFeature, { key: mainMenuFeature.id, onClick: (event) => handleMainMenuFeatureClick(event, mainMenuFeature) })))))),
        showMenuList && (React.createElement(FullPageOverlay, { onCancel: handleOnCancel },
            React.createElement(MainMenuSharedList, { onMenuFeatureClick: off })))));
};
export { MainMenuResponsive };
