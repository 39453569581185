import React, { useState } from "react";
import styled from "styled-components";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const AccordionContainer = styled.div `
  border-bottom: 1px solid ${GreyColors.grey40};
  padding: ${Spacing.spacing12} 0;
`;
const AccordionHeader = styled(({ className, children, onClick }) => (React.createElement("div", { className: className, onClick: onClick }, children))) `
  display: flex;
  padding: 6px 0;
  justify-content: space-between;
`;
const AccordionIcon = styled(({ className }) => (React.createElement("span", { className: className },
    React.createElement("img", { alt: "accordion-caret-icon", src: "images/wallet/wallet-accordion-caret.svg" })))) `
  cursor: pointer;
  margin-left: ${Spacing.spacing12};

  img {
    margin: 0 ${Spacing.spacing12};
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    width: ${Spacing.spacing12};
  }
`;
const AccordionContent = styled.p `
  font-size: ${Typography.regular};
  color: ${UIColors.darkGrey};
  margin: ${Spacing.spacing32} ${Spacing.spacing12};
`;
const SignUpAccordionItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement(AccordionContainer, null,
        React.createElement(AccordionHeader, { onClick: () => setIsOpen(!isOpen) },
            question,
            React.createElement(AccordionIcon, { isOpen: isOpen })),
        isOpen && React.createElement(AccordionContent, null, answer)));
};
export { SignUpAccordionItem };
