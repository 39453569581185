import { css } from "aphrodite/no-important";
import tableStyleSheet from "marketing/tables/styles";
import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo, useState } from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import MarketingBlankSlateModal from "components/advertisements/modals/MarketingBlankSlateModal";
import { useNavigate } from "react-router-dom";

const contractTypeSlugs = Object.freeze({
  CashContract: "cash",
  FuturesContract: "futures",
  OptionsContract: "options",
});

const contractTableContext = createContext(null);
const useContractTables = () => useContext(contractTableContext);

export const ContractTableContextProvider = ({
  children,
  contractType,
  filters,
  marketedCropId,
  updateFilters,
  year,
}) => {
  const slug = contractTypeSlugs[contractType];
  const baseURL = `/marketing/${marketedCropId}/contracts/${slug}`;
  const hasGrainMarketing = useEnterpriseFeature("grain_marketing");
  const [showAdModal, setShowAdModal] = useState(false);
  const navigate = useNavigate();

  const context = useMemo(() => {
    const memoizedContext = {
      baseURL,

      onAdd: () => {
        if (hasGrainMarketing) {
          navigate(`${baseURL}/new`);
        } else {
          setShowAdModal(true);
        }
      },

      onEdit: (contract) => {
        const path = contractType === "CashContract" ? contract.id : `${contract.id}/edit`;
        navigate(`${baseURL}/${path}`);
      },
    };

    if (filters && updateFilters) {
      memoizedContext.filters = filters;
      memoizedContext.updateFilters = updateFilters;
    }

    return memoizedContext;
  }, [filters, hasGrainMarketing, marketedCropId, updateFilters, year]);

  return (
    <contractTableContext.Provider value={context}>
      <div className={css(tableStyleSheet.container)}>{children}</div>

      {showAdModal && <MarketingBlankSlateModal onClose={() => setShowAdModal(false)} />}
    </contractTableContext.Provider>
  );
};

ContractTableContextProvider.propTypes = {
  children: PropTypes.any,
  contractType: PropTypes.oneOf(["CashContract", "FuturesContract", "OptionsContract"]).isRequired,
  filters: PropTypes.object,
  marketedCropId: PropTypes.number.isRequired,
  updateFilters: PropTypes.func,
  year: PropTypes.number.isRequired,
};

export default useContractTables;
