export const NETWORK_ERROR = "NETWORK_ERROR";
export const UNEXPECTED_ERROR = "UNEXPECTED_ERROR";
const SSOErrorIds = {
    NETWORK_ERROR,
    UNEXPECTED_ERROR,
};
class SSOAuthError extends Error {
    constructor(message, errorId, options) {
        // @ts-ignore
        super(message, options);
        this.name = "SSOAuthError";
        this.errorId = errorId;
    }
}
export default SSOAuthError;
