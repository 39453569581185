import { captureException } from "@sentry/react";
import React, { useEffect } from "react";
import { useSSOContext } from "context/SSOContext";
import { ErrorComponent } from "components/fl-ui/Layout/ErrorBoundary";
const SSOErrorView = () => {
    // @ts-ignore
    const { error } = useSSOContext();
    useEffect(() => {
        captureException(error);
    }, [error]);
    return (React.createElement("div", null,
        React.createElement(ErrorComponent, null)));
};
export { SSOErrorView };
