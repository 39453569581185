import { gql } from "@apollo/client";

export default gql`
  mutation updateUser($input: UpdatePersonInput!) {
    person: updatePerson(input: $input)
      @rest(type: "LegacyPerson", path: "/api/people/{args.input.id}", method: "PUT") {
      id
      created
      email
      enterpriseId
      firstName
      icon
      isArchived
      landingPage
      lastLogin
      lastName
      name
      notes
      phone
      role
      updated
    }
  }
`;
