import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import { useSSOContext } from "context/SSOContext";
import useWindow from "hooks/useWindow";
import { SSOCreateNewEnterpriseFormSchema } from "modules/sso/forms/SSOCreateNewEnterpriseFormSchema";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form";
import { GreyColors } from "components/fl-ui/colors";

const SSOCreateNewEnterpriseForm = styled(({ className, onComplete }) => {
  const { createEnterprise } = useSSOContext();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    defaultValues: SSOCreateNewEnterpriseFormSchema.cast(
      {
        farmName: "",
        postalCode: "",
      },
      { assert: false, stripUnknown: true },
    ),
    mode: "onTouched",
    resolver: yupResolver(SSOCreateNewEnterpriseFormSchema),
  });

  const win = useWindow();

  const onSubmit = async (data) => {
    setLoading(true);
    await createEnterprise({
      enterpriseName: data.farmName,
      postalCode: data.postalCode,
    });

    onComplete();
  };

  return (
    <FormProvider {...methods} schema={SSOCreateNewEnterpriseFormSchema}>
      <div className={className}>
        <p className="top-text">Please provide your information:</p>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ControlledFormGroup
            name="farmName"
            render={({ field }) => <Input {...field} placeholder="Example Farm" />}
          />

          <ControlledFormGroup name="postalCode" render={({ field }) => <Input {...field} placeholder="00000" />} />

          <br />

          <p className="bottom-grey-text">
            Looking for an existing farm account?{" "}
            <Button color="primary" link onClick={() => win.location.reload()}>
              Select another account
            </Button>
            .
          </p>

          <br />

          <Button
            className="save-button"
            color="primary"
            disabled={!_.isEmpty(methods.formState.errors) || loading}
            type="submit"
          >
            Next
          </Button>
        </Form>
      </div>
    </FormProvider>
  );
})`
  padding: 20px 5% 0 5%;

  .checkbox-row {
    display: inline-block;

    input {
      display: inline-block;
      vertical-align: top;
      width: 5%;
    }

    p {
      display: inline-block;
      margin: 0;
      width: 90%;
    }
  }

  .save-button {
    width: 100%;
  }

  .top-text {
    text-align: left;
  }

  .bottom-grey-text {
    color: ${GreyColors.smoke70};

    a {
      color: inherit;
    }
  }
`;

export { SSOCreateNewEnterpriseForm };
