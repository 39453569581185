import BasicInput from "agreements/forms/components/BasicInput";
import BasicSelect from "agreements/forms/components/BasicSelect";
import LineItemFieldset from "agreements/forms/components/LineItemFieldset";
import MicroLabel from "agreements/forms/components/MicroLabel";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

import { BASE_RENT, CROP_SHARE, EXPENSE_SHARE, YIELD_ADJUSTMENT } from "modules/agreements/constants";

import CommodityChooser from "components/commodity/CommodityChooser";
import { Spacing } from "components/fl-ui/constants";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const SelectContainer = styled.div`
  margin-left: -${Spacing.spacing8};
  min-width: 16rem;
  width: 30%;
`;

const categoryOptions = [
  { key: "ALL", value: "ALL", label: "All expenses" },
  { key: "INPUTS", value: "INPUTS", label: "InputsSettingsPage only" },
  { key: "FERTILIZER", value: "FERTILIZER", label: "Fertilizer only" },
];
const baseRentOptions = [
  { key: "PER_ACRE", value: "PER_ACRE", label: "per acre" },
  { key: "TOTAL", value: "TOTAL", label: "total" },
];

const leaseTermOptions = [
  { key: BASE_RENT, value: BASE_RENT, label: "Base rent" },
  { key: CROP_SHARE, value: CROP_SHARE, label: "Crop share" },
  { key: YIELD_ADJUSTMENT, value: YIELD_ADJUSTMENT, label: "Yield adjustment" },
  { key: EXPENSE_SHARE, value: EXPENSE_SHARE, label: "Expense share" },
];

const LeaseTerm = ({ getDefaultsForType, ordinal, onChange, onRemove, term, terms, total, typeBlacklist }) => {
  const { termType } = term;
  const termTypeWhitelist = _.reject(leaseTermOptions, ({ value }) => {
    return value !== termType && typeBlacklist.has(value);
  });

  const handleChange = (changes) => {
    let updatedTerm = {};
    if ("termType" in changes) {
      updatedTerm = {
        ...getDefaultsForType(changes.termType),
        ...term,
        termType: changes.termType,
      };
    } else {
      updatedTerm = {
        ...term,
        ...changes,
      };
    }

    onChange(updatedTerm);
  };

  return (
    <LineItemFieldset label={`Term ${ordinal} of ${total}`} onRemove={onRemove}>
      <MicroLabel label="Type">
        <BasicSelect
          name="termType"
          onChange={(payload) => handleChange(payload)}
          options={termTypeWhitelist}
          placeholder="Select..."
          value={termType}
        />
      </MicroLabel>

      {termType === BASE_RENT && (
        <MicroLabel label="Base rent amount">
          <BasicInput
            name="amount"
            onChange={({ target: { name, value } }) => handleChange({ [name]: value })}
            type="currency"
            value={term.amount}
          />
          <BasicSelect
            name="baseRentRate"
            onChange={(payload) => handleChange(payload)}
            options={baseRentOptions}
            value={term.baseRentRate}
          />
        </MicroLabel>
      )}

      {termType === CROP_SHARE && (
        <MicroLabel label="Grower yield share">
          <BasicInput
            name="tenantRevenueAllocation"
            onChange={({ target: { name, value } }) => handleChange({ [name]: value })}
            type="percentage"
            value={term.tenantRevenueAllocation}
          />
        </MicroLabel>
      )}

      {termType === EXPENSE_SHARE && (
        <>
          <MicroLabel label="Category">
            <BasicSelect
              name="expenseCategory"
              onChange={(payload) => handleChange(payload)}
              options={categoryOptions}
              value={term.expenseCategory}
            />
          </MicroLabel>

          <MicroLabel label="Grower expense share">
            <BasicInput
              name="tenantCostAllocation"
              onChange={({ target: { name, value } }) => handleChange({ [name]: value })}
              type="percentage"
              value={term.tenantCostAllocation}
            />
          </MicroLabel>
        </>
      )}

      {termType === YIELD_ADJUSTMENT && (
        <>
          <MicroLabel label="Yield ≥">
            <BasicInput
              name="yieldTrigger"
              onChange={({ target: { name, value } }) => handleChange({ [name]: +value })}
              suffix={<CommodityYieldUnit as="small" commodity={term.commodity} per="acre" />}
              value={term.yieldTrigger || ""}
            />
          </MicroLabel>

          <MicroLabel label="Adjustment amount">
            <BasicInput
              name="amount"
              onChange={({ target: { name, value } }) => handleChange({ [name]: value })}
              type="currency"
              value={term.amount}
            />
          </MicroLabel>

          <MicroLabel borderless label="Commodity">
            <SelectContainer>
              <CommodityChooser
                commodityId={term?.commodity?.id}
                onChange={(commodity) => handleChange({ commodity })}
                placeholder="Select a commodity"
                sanitizer={(item) => ({
                  ...item,
                  isDisabled: terms.some(([key, { commodity }]) => commodity?.id === item.id),
                  value: item.id,
                })}
              />
            </SelectContainer>
          </MicroLabel>
        </>
      )}
    </LineItemFieldset>
  );
};

export default LeaseTerm;
