import { css } from "aphrodite/no-important";
import React from "react";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import ReactSelect from "react-select";
import useViewType from "hooks/useViewType";
import chooserStyles from "modules/activity/components/choosers/FieldCropChooser/chooserStyles";
import { localBidsStyles as styles } from "modules/prices/styles";
import Tabs, { Tab } from "components/fl-ui/Tabs";
const NavBar = ({ tabsArray }) => {
    var _a;
    const navigate = useNavigate();
    const location = useLocation();
    const activeTab = ((_a = tabsArray.find((tab) => { var _a; return (_a = location.pathname.split("/").pop()) === null || _a === void 0 ? void 0 : _a.includes(tab.route); })) === null || _a === void 0 ? void 0 : _a.label) || "";
    const isMobile = useViewType() === "mobile";
    const options = tabsArray.map((tab) => ({
        label: tab.label,
        value: tab.route,
    }));
    const selectedOption = options.find((option) => option.label === activeTab) || null;
    const onChangeTab = (newTab) => {
        const targetTab = tabsArray.find((tab) => tab.label === newTab);
        if (targetTab) {
            navigate(targetTab.route);
        }
    };
    return (React.createElement("div", null,
        isMobile ? (React.createElement("div", { className: css(styles.reactSelect) },
            React.createElement(ReactSelect, { getOptionLabel: (option) => option.label, getOptionValue: (option) => option.value, isSearchable: false, onChange: (selectedOption) => {
                    if (selectedOption) {
                        onChangeTab(selectedOption.label);
                    }
                }, options: options, styles: Object.assign(Object.assign({}, chooserStyles), { menuPortal: (base) => (Object.assign(Object.assign({}, base), { zIndex: 9999 })), menu: (base) => (Object.assign(Object.assign({}, base), { zIndex: 9999 })) }), value: selectedOption, menuPortalTarget: document.body }))) : (React.createElement("div", { className: css(styles.tabBar) },
            React.createElement(Tabs, { onChange: (tab) => onChangeTab(tab), value: activeTab, size: "large", className: css(styles.tabBarContainer) }, tabsArray.map((tab) => (React.createElement(Tab, { key: tab.label, value: tab.label }, tab.label)))))),
        React.createElement("div", { className: "content-area" },
            React.createElement(Routes, null,
                tabsArray.map((tab) => (React.createElement(Route, { key: tab.label, path: tab.route, element: tab.component }))),
                React.createElement(Route, { path: "/", element: React.createElement("p", null,
                        "The route you are looking for was not found. Try going",
                        " ",
                        React.createElement("a", { onClick: () => navigate(tabsArray[0].route) }, "here"),
                        " instead.") })))));
};
export default NavBar;
