import { css } from "aphrodite/no-important";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import styles from "components/advertisements/styles";
import { Button } from "components/fl-ui";
import { Card } from "components/fl-ui/Card";
import { ContentMain } from "components/fl-ui/Layout";
const PayGateCommonBlankSlatePage = ({ banner: Banner, steps = [] }) => {
    const getColor = (i) => {
        switch (i) {
            case 1:
                return styles.steps_color_green;
            case 2:
                return styles.steps_color_red;
            case 3:
                return styles.steps_color_yellow;
            default:
                return;
        }
    };
    const navigate = useNavigate();
    const onClick = () => navigate("/billing");
    const renderSteps = (steps) => {
        if (steps.length > 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement("hr", { className: css(styles.promo_hr) }),
                React.createElement("h2", { className: css(styles.promo_h2, styles.promo_centeredContent, styles.marginBottom_24) }, "How it works"),
                React.createElement(Row, null, steps.map((body, i) => (React.createElement(Col, { className: css(styles.steps_col), key: `col-${i}`, xs: 12, smOffset: 1, sm: 10, mdOffset: 0, md: 4 },
                    React.createElement(Card, { className: css(styles.steps_card), status: undefined },
                        React.createElement("h3", { className: css(styles.steps_number) },
                            React.createElement("span", { className: css(styles.steps_color, getColor(i + 1)) }, i + 1)),
                        React.createElement("p", { className: css(styles.steps_text) }, body)))))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, smOffset: 2, sm: 8, mdOffset: 1, md: 4 },
                        React.createElement("img", { alt: "Rainfall", height: "161", src: "images/pay_gates/rainfall-footer.png", width: "267" })),
                    React.createElement(Col, { xs: 12, smOffset: 2, sm: 8, mdOffset: 0, md: 6 },
                        React.createElement("h3", { className: css(styles.footer_h3, styles.centered_on_mobile) }, "Looking for more features? Check out our other plans & add-ons"),
                        React.createElement(Button, { className: css(styles.promo_cta, styles.centered_on_mobile), color: "primary", onClick: onClick }, "View plans"),
                        React.createElement("p", { className: css(styles.promo_text, styles.centered_on_mobile) },
                            "For more information, visit",
                            " ",
                            React.createElement(Link, { to: "https://bushelfarm.com/", rel: "noopener noreferrer", target: "_blank" }, "bushelfarm.com"),
                            ".")))));
        }
    };
    return (React.createElement(ContentMain, { fullWidth: true },
        React.createElement(Banner, null),
        renderSteps(steps)));
};
export default PayGateCommonBlankSlatePage;
