import { SupportEmailLink } from "billing/lib";
import React from "react";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";

import { Button } from "components/fl-ui";
import Dialog from "components/fl-ui/Dialog";
import { Borders, Spacing, Typography } from "components/fl-ui/constants";

const DialogBody = styled.div`
  border-bottom: ${Borders.regular};
  border-top: ${Borders.regular};
  padding: ${Spacing.spacing8} 0;

  p {
    font-size: ${Typography.sizes.lg};
    line-height: 1.5;
  }
`;

const OrderErrorDialog = (props) => {
  const { actionType, error, onClose } = props;
  const { enterpriseId = "" } = useAuth().currentUser ?? {};
  const isSave = actionType === "save";
  const sentryEventId = (error.cause ?? error).options?.sentryEventId;

  const body = `\r\n\r\nSentry event id: ${sentryEventId}`;
  const subject = isSave
    ? `Error on subscription save (EID: ${enterpriseId})`
    : `Error on subscription cancel (EID: ${enterpriseId})`;

  const message = (
    <DialogBody>
      <p>
        {isSave
          ? "An error occurred while attempting to save your subscription."
          : "An error occurred while attempting to cancel your subscription."}
      </p>

      <p>
        Please <SupportEmailLink mailtoFields={{ body, subject }}>contact support for assistance</SupportEmailLink>.
      </p>
    </DialogBody>
  );

  return (
    <Dialog
      dialogBody={message}
      dialogControls={<Button onClick={onClose}>Close</Button>}
      dialogHeading="An error has occurred"
      onClose={onClose}
    />
  );
};


OrderErrorDialog.displayName = "OrderErrorDialog";

export default OrderErrorDialog;
