import { useSuspenseQuery } from "@apollo/client";
import { css } from "aphrodite/no-important";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import getField from "collection/graphql/fields/queries/getField";
import { getCurrentSubscription } from "collection/graphql/subscription/index";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import { ONBOARDING_START_TRIAL } from "lib/metrics/events";
import { Button } from "components/fl-ui";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import CropHistoryCard from "modules/fields/onboarding/CropHistoryCard";
import RainfallTrendChart from "modules/fields/onboarding/RainfallTrendChart";
import SoilMapCard from "modules/fields/onboarding/SoilMapCard";
import styles from "modules/fields/onboarding/onboardingStyles";
import OnboardingFooterHelp from "modules/fields/onboarding/OnboardingFooterHelp";
const OnboardingDashboardMain = ({ handleMore }) => {
    var _a;
    const { id } = useParams();
    const fieldId = Number(id);
    const { data: fieldData } = useSuspenseQuery(getField, {
        skip: !fieldId,
        variables: { fieldId },
    });
    // @ts-ignore
    const { data: subscriptionData } = useRestSuspenseQuery(getCurrentSubscription);
    const navigate = useNavigate();
    if (!(fieldData === null || fieldData === void 0 ? void 0 : fieldData.field)) {
        return React.createElement(LoadingWrapper, { isLoading: true });
    }
    const { geometry, name } = fieldData.field;
    const { isSubscribed } = (_a = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.subscription) !== null && _a !== void 0 ? _a : { isSubscribed: false };
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Col, { xs: true },
                React.createElement("div", { className: css(styles.banner) },
                    React.createElement("h2", { className: css(styles.bannerHeading) }, `Great job adding "${name}" as your first field. Check out some of the information Bushel Farm has for this field below.`),
                    React.createElement("hr", { className: css(styles.bannerHr) })))),
        React.createElement(Row, { className: css(styles.rowPadding) },
            React.createElement(Col, { xs: true },
                React.createElement(RainfallTrendChart, { fieldId: fieldId, title: "Rainfall history" }))),
        React.createElement(Row, null,
            React.createElement(Col, { className: css(styles.colPadding), xs: 12, sm: 12, md: 6 },
                React.createElement(SoilMapCard, { fieldId: fieldId, geometry: geometry, skip: false })),
            React.createElement(Col, { className: css(styles.colPadding), xs: 12, sm: 12, md: 6 },
                React.createElement(CropHistoryCard, { geometry: geometry }))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: true },
                React.createElement("div", { className: css(styles.banner) },
                    React.createElement("h3", { className: css(styles.bannerHeading) }, "See what else you can do with Bushel Farm"),
                    React.createElement("div", { className: css(styles.buttonGroupContainer) }, isSubscribed ? (React.createElement(React.Fragment, null,
                        React.createElement(Button, { className: css(styles.button), color: "primary", display: "inline", onClick: () => navigate("/fields"), size: "lg" }, "Go to Fields"),
                        React.createElement(Button, { className: css(styles.button), color: "primary", display: "inline", hollow: true, onClick: () => navigate("/billing"), size: "lg" }, "Manage Subscription"))) : (React.createElement(React.Fragment, null,
                        React.createElement(Button, { className: css(styles.button), color: "primary", display: "inline", onClick: handleMore, size: "lg" }, "See more"),
                        React.createElement(Button, { className: css(styles.button), color: "primary", display: "inline", hollow: true, onClick: () => {
                                ONBOARDING_START_TRIAL.track({});
                                navigate("/billing");
                            }, size: "lg" }, "Start a free trial now")))),
                    React.createElement(OnboardingFooterHelp, null))))));
};
export default OnboardingDashboardMain;
