import { WebStorageStateStore } from "oidc-client-ts";
/**
 * This function's job is to remove any code or state parameters from the url upon returning from the
 * identity provider and replacing the state in `window.history`. Without doing this the token will remain
 * in the url and will be present upon page refresh and via the back button.
 *
 * Per the [react-oidc-context documentation](https://github.com/authts/react-oidc-context/blob/84e1c9a8b83fded0dac80fff58e570db423de69a/README.md#getting-started):
 * > You **must** provide an implementation of `onSigninCallback` to `oidcConfig` to remove the
 * > payload from the URL upon successful login. Otherwise if you refresh the page and the payload
 * is still there, `signinSilent` - which handles renewing your token - won't work.
 *
 * @see [Sample implementation](https://github.com/authts/react-oidc-context/blob/f175dcba6ab09871b027d6a2f2224a17712b67c5/src/AuthProvider.tsx#L20-L30)
 */
const onSignInCallback = () => {
    window.history.replaceState({}, "", window.location.pathname + window.location.hash);
};
const oidcConfiguration = {
    authority: process.env.KEYCLOAK_AUTH_ISSUER,
    client_id: process.env.KEYCLOAK_CLIENT_ID,
    redirect_uri: process.env.KEYCLOAK_REDIRECT_URL,
    onSigninCallback: onSignInCallback,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};
export default oidcConfiguration;
