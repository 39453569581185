import { useOnlineState } from "beautiful-react-hooks";
import { isNative } from "mobile/mobileManager";
import { useMemo } from "react";

import { useAuth } from "collection/graphql/auth";
import { restClient } from "collection/graphql/client";
import { getAllProducts } from "collection/graphql/subscription";
import getMobileProducts from "collection/graphql/subscription/hooks/utils/getMobileProducts";
import useAsync from "hooks/useAsync";

const useSubscriptionProducts = () => {
  const { isLoggingOut } = useAuth();
  const isOnline = useOnlineState();

  const { loading, value } = useAsync(async () => {
    if (isLoggingOut) {
      return {
        error: new Error("Unable to execute while logging out"),
      };
    } else if (!isOnline) {
      return {
        error: new Error("Network connection required"),
      };
    }

    try {
      const { data } = await restClient.query({ query: getAllProducts });
      return {
        products: !isNative() ? data.allProducts : await getMobileProducts(data.allProducts),
      };
    } catch (error) {
      return { error };
    }
  }, [isLoggingOut, isOnline]);

  return useMemo(() => {
    return {
      data: value?.products ?? undefined,
      error: value?.error,
      loading,
    };
  }, [isLoggingOut, loading, value]);
};

export default useSubscriptionProducts;
