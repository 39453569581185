import { useQuery } from "@apollo/client";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import ContractDetailsFooter from "marketing/forms/ContractDetails/ContractDetailsFooter";
import ContractDetailsForm from "marketing/forms/ContractDetails/ContractDetailsForm";
import ContractFormContext from "marketing/forms/ContractDetails/context/ContractFormContext";
import {
  ADD_CONTRACT,
  EDIT_CONTRACT,
  CASH_CONTRACT,
  FUTURES_CONTRACT,
  OPEN,
  OPTIONS_CONTRACT,
} from "marketing/utils/contractEnums";
import { getBreadCrumbLinks } from "marketing/utils/getBreadCrumbLinks";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import getEditableContract from "collection/graphql/contracts/queries/getEditableContract";
import { GET_MARKETED_CROP } from "collection/graphql/marketing";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";
import {
  MARKETING_MODAL_CASH_OPEN,
  MARKETING_MODAL_FUTURES_OPEN,
  MARKETING_MODAL_OPTIONS_OPEN,
} from "lib/metrics/events";

import MarketingBlankSlatePage from "components/advertisements/pages/MarketingBlankSlatePage";
import { BreadCrumbHeader, Container, Content, ContentMain } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const contractTypenameEnum = Object.freeze({
  cash: CASH_CONTRACT,
  futures: FUTURES_CONTRACT,
  options: OPTIONS_CONTRACT,
});

const ContractAddEdit = ({ action, contract, contractType, marketedCrop, year }) => {
  const contractTypeEnumValue = contractTypenameEnum[contractType] ?? CASH_CONTRACT;

  const reducer = (state, updates) => ({ ...state, ...updates });
  const [formFields, updateFormFields] = useReducer(reducer, {}, () => {
    if (action === EDIT_CONTRACT) {
      return { ...contract };
    }

    return {
      contractStatus: OPEN,
      marketedCrop,
    };
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    switch (contractTypeEnumValue) {
      case CASH_CONTRACT:
        MARKETING_MODAL_CASH_OPEN.track();
        break;

      case FUTURES_CONTRACT:
        MARKETING_MODAL_FUTURES_OPEN.track();
        break;

      case OPTIONS_CONTRACT:
        MARKETING_MODAL_OPTIONS_OPEN.track();
        break;
    }
  }, []);

  const getAction = () => (action === EDIT_CONTRACT ? "Edit" : "New");

  const breadCrumbProps = useMemo(
    () => ({
      action,
      commodityName: marketedCrop.name || marketedCrop.commodity.name,
      contentType: "contracts",
      contractId: contract?.id,
      cropId: marketedCrop.id,
      contractType,
      year,
    }),
    []
  );

  const contractFormContext = useMemo(() => {
    let disableExchangePricing = !!marketedCrop.disableExchangePricing;
    if (marketedCrop.commodity.isExchangeTraded === false) {
      disableExchangePricing = true;
    }

    return { disableExchangePricing };
  }, []);

  return (
    <Container>
      <BreadCrumbHeader links={getBreadCrumbLinks(breadCrumbProps)} />
      <Content>
        <ContentMain fullWidth>
          <ContractFormContext.Provider value={contractFormContext}>
            <ContractDetailsForm
              contractType={contractType}
              contract={formFields}
              getAction={getAction}
              getContractTypeEnum={() => contractTypeEnumValue}
              handleContractChange={updateFormFields}
              marketedCrop={marketedCrop}
            />

            <Row>
              <Col xs={12} lg={10}>
                <hr style={{ margin: "2em 0" }} />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={10}>
                <ContractDetailsFooter
                  cropId={marketedCrop.id}
                  action={action}
                  year={year}
                  contractType={contractType}
                  contract={formFields}
                  handleIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </ContractFormContext.Provider>
        </ContentMain>
      </Content>
    </Container>
  );
};

ContractAddEdit.propTypes = {
  action: PropTypes.string.isRequired,
  contractType: PropTypes.string.isRequired,
};

const ContractDetails = ({ action }) => {
  const { contractId, contractType, marketedCropId } = useParams();
  const hasGrainMarketing = useEnterpriseFeature("grain_marketing");

  const contractQuery = useQuery(getEditableContract, {
    skip: !hasGrainMarketing || action === ADD_CONTRACT,
    variables: {
      id: contractId,
    },
  });

  const cropQuery = useQuery(GET_MARKETED_CROP, {
    skip: !!contractId,
    variables: {
      id: +marketedCropId,
    },
  });

  const baseUrl = `/marketing/${marketedCropId}/contracts`;
  const marketedCrop = contractQuery.data?.contract?.marketedCrop ?? cropQuery.data?.marketedCrop;
  const navigate = useNavigate();
  const currentCropYear = useCurrentCropYear()[0];
  const year = marketedCrop?.year || currentCropYear;

  if (!hasGrainMarketing) {
    return <MarketingBlankSlatePage />;
  } else if (contractQuery.error) {
    App.notify("Unable to load specified contract");
    navigate(baseUrl);
    return null;
  } else if (cropQuery.error) {
    App.notify("Unable to load associated marketed crop");
    navigate(baseUrl);
    return null;
  } else {
    if ((contractType === "cash") ^ (marketedCrop && !marketedCrop.allowsSpeculativeContracts)) {
      const url = baseUrl.replace(/\/contracts$/, "");
      navigate(url, { replace: true });
    }
  }

  return (
    <LoadingWrapper isLoading={contractQuery.loading || cropQuery.loading}>
      <ContractAddEdit
        action={action}
        contract={contractQuery.data?.contract ?? null}
        contractType={contractType}
        marketedCrop={marketedCrop}
        year={year}
      />
    </LoadingWrapper>
  );
};

export default ContractDetails;
