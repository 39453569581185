import styled from "styled-components";

import { Spacing } from "components/fl-ui/constants";
import { Button } from "components/fl-ui/index";

const LeftButton = styled(Button)`
  margin-right: ${Spacing.spacing12};
`;

export default LeftButton;
