import SSOAuthError, { NETWORK_ERROR, UNEXPECTED_ERROR } from "sso/workflows/SSOAuthError";
import debug from "debug";
import _ from "lodash";
import { COLLECT_EMAIL, COLLECT_USER_DETAILS, CONFIRM_FARM_NAME, CONNECT_TO_CONTRACTS, DONE, ERROR, SELECT_MEMBERSHIP, SIGN_UP } from "sso/workflows/constants";
import getGrainBuyers from "collection/graphql/contracts/queries/getGrainBuyers";
import getUserMemberships from "collection/graphql/auth/queries/getUserMemberships";
import { RAINFALL } from "model/Subscription/constants";
import getIntegrations from "collection/graphql/integrations/queries/getIntegrations";
import getCurrentUser from "collection/graphql/auth/queries/getCurrentUser";
import { getCurrentSubscription } from "collection/graphql/subscription";
const log = debug("SSOAuthWorkflow");
class SSOAuthWorkflow {
    constructor(store, logEnabled = false) {
        this.log = log;
        this.store = store;
        this.state = store.getState();
        this.log.enabled = logEnabled;
    }
    async start() {
        this.log("starting");
        let currentNode = this.checkAuthenticated;
        while (true) {
            const result = await this.execute(currentNode);
            if (typeof result !== "function") {
                return result;
            }
            currentNode = result;
        }
    }
    async execute(node) {
        this.log("%c%s", "font-weight: bold;", `${node.name}()`, "starting");
        this.state = this.store.getState();
        const result = await node.apply(this);
        const resultMessage = typeof result === "function" ? `next: ${result.name}()` : result;
        this.log("%c%s", "font-weight: bold;", `${node.name}()`, "finished", resultMessage);
        return result;
    }
    async checkAuthenticated() {
        var _a, _b, _c;
        /*
         * Check the user's auth status. If the response is a 403 and they have an enterprise uuid set, this indicates that
         * the enterprise token is invalid. We will delete this token and retry the request in this case.
         *
         * If a 403 is returned and the enterprise uuid was not set, this indicates that the user is new and should sign up.
         */
        const [error, currentUser] = await this.state.query(getCurrentUser);
        if (this.state.isAuthenticated && currentUser) {
            // user is authenticated and we were able to fetch their data
            return this.getMemberships;
        }
        else if (currentUser) {
            // user is not sso-authenticated, but has a session cookie
            if (currentUser.ssoOnly) {
                // redirects the browser
                await this.state.signInRedirect((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) !== null && _a !== void 0 ? _a : "");
            }
            /*
             * This will only happen if the user has a session cookie but is not sso-authenticated. It is a rare
             * case, but would occur if such a user ends up on /sso erroneously
             */
            return DONE;
        }
        else if (!this.state.isAuthenticated) {
            return COLLECT_EMAIL;
        }
        /*
         * If we get to this point an error should have occurred. Handle known errors.
         */
        const maxRetries = 1;
        const enterpriseTokenWasSet = !!this.state.getCurrentEnterpriseUUID();
        if (((_c = (_b = error === null || error === void 0 ? void 0 : error.networkError) === null || _b === void 0 ? void 0 : _b.response) === null || _c === void 0 ? void 0 : _c.status) === 403) {
            if (enterpriseTokenWasSet) {
                this.state.unsetCurrentEnterpriseUUID();
            }
            if (this.state.authRequestRetryCount < maxRetries && enterpriseTokenWasSet) {
                /*
                 * The request to getCurrentUser failed because the enterprise token present but was invalid. Retry this method.
                 */
                this.log("retying auth request");
                this.state.incrementAuthRequestRetryCount();
                return this.checkAuthenticated;
            }
            // the user is sso-authenticated but not in the system
            return SIGN_UP;
        }
        if (error) {
            // there was an issue with the request
            this.state.setError(new SSOAuthError("Network error occurred", NETWORK_ERROR, {
                cause: error,
            }));
        }
        else {
            this.state.setError(new SSOAuthError("Unexpected error occurred", UNEXPECTED_ERROR));
        }
        return ERROR;
    }
    async getMemberships() {
        const [, userMemberships = []] = await this.state.query(getUserMemberships);
        this.log("userMemberships: ", userMemberships);
        this.store.setState(() => ({ userMemberships }));
        return userMemberships.length > 0 ? this.checkSelectedMembership : SIGN_UP;
    }
    async checkSelectedMembership() {
        if (_.isEmpty(this.state.userMemberships)) {
            throw new Error("userMemberships should not be empty");
        }
        const { getCurrentEnterpriseUUID, setSelectedMembership, userMemberships } = this.state;
        const selectedMembership = _.find(userMemberships, ({ enterprise }) => {
            return enterprise.uuid === getCurrentEnterpriseUUID();
        });
        if (selectedMembership) {
            this.log("currentEnterpriseUUID: ", getCurrentEnterpriseUUID());
            setSelectedMembership(selectedMembership);
            return this.checkCurrentUserValidity;
        }
        else if (getCurrentEnterpriseUUID()) {
            setSelectedMembership(null);
            this.log("invalid membership enterprise id", getCurrentEnterpriseUUID());
        }
        this.log("Returning: ", SELECT_MEMBERSHIP);
        return SELECT_MEMBERSHIP;
    }
    async checkCurrentUserValidity() {
        const { selectedMembership } = this.state;
        if (!selectedMembership) {
            throw new Error("selectedMembership is required");
        }
        const { firstName, lastName } = selectedMembership.user;
        const hasBothNames = !!(firstName && lastName);
        this.log("hasBothNames: ", hasBothNames, { firstName, lastName });
        return hasBothNames ? this.checkCurrentUserRole : COLLECT_USER_DETAILS;
    }
    async checkCurrentUserRole() {
        const { selectedMembership } = this.state;
        if (!selectedMembership) {
            throw new Error("selectedMembership is required");
        }
        this.log("userRole: ", selectedMembership.role);
        return selectedMembership.role.name === "admin" ? this.confirmFarmName : DONE;
    }
    async confirmFarmName() {
        const { selectedMembership } = this.state;
        if (!selectedMembership) {
            throw new Error("selectedMembership is required");
        }
        const { name } = selectedMembership.enterprise;
        this.log("enterpriseName: ", name);
        return name ? this.checkContractsConnectionStatus : CONFIRM_FARM_NAME;
    }
    async checkContractsConnectionStatus() {
        const hasDismissedConnectionPrompt = await this.checkHasDismissedContractsPrompt();
        if (hasDismissedConnectionPrompt) {
            return DONE;
        }
        const hasRainfallPlan = await this.checkHasRainfallPlan();
        if (hasRainfallPlan) {
            return DONE;
        }
        const inBushelNetwork = await this.checkIsInBushelNetwork();
        if (!inBushelNetwork) {
            return DONE;
        }
        const canConnectToIntegration = await this.checkIsAbleToConnectToIntegration();
        if (!canConnectToIntegration) {
            return DONE;
        }
        return CONNECT_TO_CONTRACTS;
    }
    async checkHasDismissedContractsPrompt() {
        /*
         * Do not offer contracts connection if the user has already been prompted to connect during the SSO process.
         */
        const [, hasDismissedReminder] = await this.state.getContractsReminderStatus();
        this.log("hasDismissedReminder: ", hasDismissedReminder);
        return !!hasDismissedReminder;
    }
    async checkHasRainfallPlan() {
        var _a;
        /*
         * Do not offer contracts connection if the user is on a Rainfall plan
         */
        const [, subscription] = await this.state.query(getCurrentSubscription);
        this.log("subscription: ", subscription);
        // @ts-ignore
        return ((_a = subscription === null || subscription === void 0 ? void 0 : subscription.currentPlan) === null || _a === void 0 ? void 0 : _a.id) === RAINFALL;
    }
    async checkIsInBushelNetwork() {
        /*
         * The user is considered to be in the Bushel network if they have one or more grain buyers.
         */
        const [, buyers] = await this.state.query(getGrainBuyers);
        const inBushelNetwork = _.size(buyers) > 0;
        this.log("buyers: ", buyers);
        return inBushelNetwork;
    }
    async checkIsAbleToConnectToIntegration() {
        /*
         * Do not offer contracts connection unless the user can connect to Bushel but has not already done so.
         */
        const [, integrations] = await this.state.query(getIntegrations);
        const bushelIntegration = _.find(integrations, { id: "bushel" });
        const bushelIntegrationIsAvailable = !!bushelIntegration;
        const bushelIntegrationIsEnabled = !!(bushelIntegration === null || bushelIntegration === void 0 ? void 0 : bushelIntegration.enabled);
        this.log("bushelIntegrationIsEnabled: ", bushelIntegrationIsEnabled);
        return bushelIntegrationIsAvailable && !bushelIntegrationIsEnabled;
    }
}
export default SSOAuthWorkflow;
