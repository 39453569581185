import { useMutation, useQuery } from "@apollo/client";
import { find } from "lodash";

import {
  IMPORT_MACHINE_DATA_BY_RANGE,
  REVOKE_INTEGRATION,
  SET_MACHINE_DATA_IMPORT_CONFIG,
  CREATE_FIELD_FROM_MACHINE_DATA,
} from "collection/graphql/integrations/mutations";
import getMachineDataImportConfig from "collection/graphql/integrations/queries/getMachineDataImportConfig";

const useIntegrationMutations = (integrationId) => {
  const { data, loading: isLoadingConfig } = useQuery(getMachineDataImportConfig);
  const config = find(data?.config, { id: integrationId }) || {
    activityTypeCategories: [],
    enableAutomaticImport: null,
    fields: [],
    id: null,
  };
  const refetchQueries = ["GetIntegrations"];

  // mutations
  const [createFieldFromMachineData, { loading: isImportingFields }] = useMutation(CREATE_FIELD_FROM_MACHINE_DATA, {
    refetchQueries,
  });
  const [importMachineDataByRange, { loading: isImporting }] = useMutation(IMPORT_MACHINE_DATA_BY_RANGE, {
    refetchQueries,
  });
  const [revokeIntegration, { loading: isRevoking }] = useMutation(REVOKE_INTEGRATION, { refetchQueries });
  const [setMachineDataImportConfig, { loading: isUpdating }] = useMutation(SET_MACHINE_DATA_IMPORT_CONFIG, {
    refetchQueries,
  });
  const isSaving = [isImporting, isRevoking, isUpdating, isImportingFields].some((x) => x);

  return {
    config,
    importFieldFromMachineDataProvider: (provider) => createFieldFromMachineData({ variables: { provider } }),
    importByDateRange: (fields) => importMachineDataByRange({ variables: fields }),
    isLoadingConfig,
    revoke: (integrationId) => revokeIntegration({ variables: { id: integrationId } }),
    updateImportConfig: (fields) => setMachineDataImportConfig({ variables: fields }),
    isSaving,
  };
};

export { useIntegrationMutations };
