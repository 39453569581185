import { css, StyleSheet } from "aphrodite";
import React from "react";
import Icon from "components/fl-ui/Icons/Icon";
import { Spacing } from "components/fl-ui/constants";
const styles = StyleSheet.create({
    alertIcon: {
        cursor: "pointer",
        height: Spacing.spacing32,
        margin: `${Spacing.spacing8} 0 0 ${Spacing.spacing8}`,
        position: "relative",
        width: Spacing.spacing32,
    },
    alertIconActive: {
        height: Spacing.spacing48,
        margin: 0,
        width: Spacing.spacing48,
    },
});
const NotificationIcon = ({ count }) => (React.createElement(Icon, { className: css(styles.alertIcon, count && styles.alertIconActive), dataCyValue: "alert-bell-icon", icon: count ? "alertActive" : "alertInactive" }));
export default NotificationIcon;
