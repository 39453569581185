import { useQuery } from "@apollo/client";
import AddPricingAndDeliveryButton from "contracts/components/AddPricingAndDeliveryButton";
import CardRow from "contracts/components/CardRow";
import ContractQuantity from "contracts/components/ContractQuantity";
import { MarketedCropLink } from "contracts/components/ExpandedRowArea";
import NotesArea from "contracts/components/NotesArea";
import Price from "contracts/components/Price";
import PricingAndDeliverySubCard from "contracts/components/PricingAndDeliverySubcard";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import getPricelines from "collection/graphql/contracts/queries/getPricelines";

import ContractStatusCopy from "components/copy/ContractStatusCopy";
import ContractSubtypeCopy from "components/copy/ContractSubtypeCopy";
import { Button } from "components/fl-ui";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import CloseX from "components/fl-ui/Icons/CloseX";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import Tooltip from "components/fl-ui/Tooltip";
import { BaseElement } from "components/fl-ui/common";
import { Spacing } from "components/fl-ui/constants";

const MarketingCropLabel = () => {
  const tooltipText = useContractPermissions().requiresMarketingUpgrade()
    ? "Upgrade your subscription to organize your contracts into Marketing Crops"
    : "Create new Marketing Crops in the Marketing feature";

  return (
    <BaseElement alignItems="center" display="flex" gap={Spacing.spacing2}>
      <div>Marketing Crop</div>

      <Tooltip message={tooltipText}>
        <IconContainer icon="help" size={Spacing.spacing16} />
      </Tooltip>
    </BaseElement>
  );
};

const ContractCardModal = styled(({ className, contract, onClose, onEdit }) => {
  const { data: pricelineData } = useQuery(getPricelines, {
    variables: {
      id: contract.id,
    },
  });
  const pricelines = pricelineData?.getPricelines ?? [];
  const isEditable = useContractPermissions().canWrite(contract);

  return (
    <Modal className={className} width={600}>
      <ModalHeader>
        <ModalTitle className="contract-card-title">
          <span className="modal-header-text">Contract {contract.contractNumber}</span>
          {contract.dataSource === "PARTNER" && (
            <IconContainer>
              <Icon icon="bushelLogo" />
            </IconContainer>
          )}
        </ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <>
          <h3>Breakdown</h3>
          <h4>DETAILS</h4>

          <div>
            <CardRow title="Buyer">{contract.buyer ?? "--"}</CardRow>

            <CardRow title="Contract Number">{contract.contractNumber ?? "--"}</CardRow>

            <CardRow title="Commodity">{contract.commodity?.name ?? "--"}</CardRow>

            <CardRow title="Booked Date">{moment(contract.contractDate).format("MM/DD/YYYY")}</CardRow>

            <CardRow title="Type">
              {contract.contractType ? (
                <ContractSubtypeCopy subtype={contract.contractType} product={contract.product} />
              ) : (
                "--"
              )}
            </CardRow>

            <CardRow title="Contract Status">
              {contract.contractStatus ? <ContractStatusCopy status={contract.contractStatus} /> : "--"}
            </CardRow>

            <CardRow title="Pricing">
              <Price price={contract.netPrice} variant={contract.contractFinalStatus !== "Final" ? "YELLOW" : null} />
            </CardRow>

            <CardRow title="Quantity Contracted">
              <ContractQuantity contract={contract} quantity={contract?.quantity} />
            </CardRow>

            <CardRow title="Quantity Delivered">
              <ContractQuantity contract={contract} quantity={contract?.delivered?.quantity} />
            </CardRow>

            <CardRow title="Quantity Canceled">
              <ContractQuantity contract={contract} quantity={contract?.quantityCanceled} />
            </CardRow>

            <CardRow title="Quantity Priced">
              <ContractQuantity contract={contract} quantity={contract?.quantityPriced} />
            </CardRow>

            <CardRow title={<MarketingCropLabel />}>
              {contract.marketedCrop ? <MarketedCropLink marketedCrop={contract.marketedCrop} /> : "--"}
            </CardRow>
          </div>

          {isEditable && (
            <BaseElement display="flex" justifyContent="flex-end">
              <Button
                className="edit-button"
                color="primary"
                display="block"
                hollow
                onClick={() => onEdit(contract.id)}
                size="mn"
              >
                Edit Details
              </Button>
            </BaseElement>
          )}

          <h3>Pricing & Delivery</h3>

          {pricelines.map((priceline) => (
            <PricingAndDeliverySubCard
              contract={contract}
              key={priceline.lineNumber + "-" + priceline.underlying + "-" + priceline.deliveryStartDate}
              priceline={priceline}
            />
          ))}

          {contract.dataSource !== "PARTNER" && (
            <BaseElement display="flex" justifyContent="flex-end">
              <AddPricingAndDeliveryButton contract={contract} />
            </BaseElement>
          )}

          <NotesArea contract={contract} />
        </>
      </ModalBody>
    </Modal>
  );
})`
  .contract-card-title {
    display: flex;
    gap: ${Spacing.spacing12};
  }
  .modal-header-text {
    display: inline-block;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .modal-header-icon {
    display: inline-block;
  }
  .edit-button {
    margin-top: ${Spacing.spacing20};
    width: auto;
  }
`;

export default ContractCardModal;

ContractCardModal.propTypes = {
  contracts: PropTypes.object,
  close: PropTypes.func,
};
