import { css } from "aphrodite/no-important";
import React from "react";

import styles from "modules/prices/styles/futuresTabStyles";

import CropBadge from "components/fl-ui/CropBadge";

const MarketedCropListingHeader = ({ crop }) => {
  const { commodity } = crop;

  return (
    <div className={css(styles.listingHeader)}>
      <div className={css(styles.listingTitle)}>
        <CropBadge marginRight color={commodity.color} abbr={commodity.abbr} />
        <h1 className={css(styles.listing_h1)}>{commodity.name}</h1>
      </div>
    </div>
  );
};

export default MarketedCropListingHeader;
