import { useMemo, useState } from "react";
const useBoolean = (initialState = false) => {
    const [state, setState] = useState(initialState);
    return useMemo(() => {
        return [
            state,
            {
                off: () => setState(false),
                on: () => setState(true),
                toggle: () => setState((value) => !value),
            },
        ];
    }, [state]);
};
export default useBoolean;
