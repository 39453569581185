import { StyleSheet } from "aphrodite/no-important";

import { Spacing, Typography } from "components/fl-ui/constants/index";

const tableStyleSheet = StyleSheet.create({
  container: {
    margin: `0px 0px ${Spacing.spacing24}`,
  },
  title: {
    marginRight: "0.25em",
  },
  infoText: {
    ...Typography.infoText,
    marginLeft: 0,
  },
  header: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: Spacing.spacing12,
  },
  footerCol: {
    borderBottom: "none !important",
  },
});

export default tableStyleSheet;
