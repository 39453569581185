import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { AUTHENTICATED, useAuthStore } from "collection/graphql/auth/hooks/AuthStore";
import { historicalRainfallClient } from "collection/graphql/client/rainfallClient";
import { getHistoricalRainfall } from "collection/graphql/fields/queries";
const useHistoricalRainfall = () => {
    const status = useAuthStore(useShallow(state => state.status));
    const { data, loading, refetch } = useQuery(getHistoricalRainfall, {
        client: historicalRainfallClient,
        pollInterval: 24 * 60 * 60 * 1000, // call every 24 hours
        skip: status !== AUTHENTICATED,
    });
    const historicalRainfallMap = useMemo(() => {
        const sortedLeastToMost = _.sortBy(data === null || data === void 0 ? void 0 : data.historicalRainfall, [
            ({ recent }) => (recent.state === "recent" ? -1 : 1),
            ({ recent }) => recent.amount,
        ]);
        return _.reduce(sortedLeastToMost, (map, { fieldId, recent }) => map.set(fieldId, recent), new Map());
    }, [data]);
    return {
        data: data === null || data === void 0 ? void 0 : data.historicalRainfall,
        loading,
        historicalRainfallMap,
        refetch,
    };
};
export default useHistoricalRainfall;
