import { useQuery } from "@apollo/client";
import getCurrentSubscription from "collection/graphql/subscription/getCurrentSubscription";
import useReleaseFeature from "hooks/useReleaseFeature";
const useTrialStatus = () => {
    const noCCRequired = useReleaseFeature("no-cc-required-for-trial");
    const { data: subscriptionData, loading: currentSubscriptionLoading } = useQuery(getCurrentSubscription);
    const currentSubscription = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.subscription;
    const isSubscribed = !!(currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.isSubscribed);
    const trialAvailable = !!(currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.trialAvailable);
    const showActivateBusinessTrial = !currentSubscriptionLoading && noCCRequired && !isSubscribed && trialAvailable;
    return {
        loading: currentSubscriptionLoading,
        showActivateBusinessTrial,
    };
};
export default useTrialStatus;
