import { MobileMaxWidth } from "constants/mobileBreakpointConstants";
import styled from "styled-components";
const HeaderControls = styled.div `
  display: flex;

  > * {
    margin-right: 0.5rem;
  }

  @media only screen and (max-width: ${MobileMaxWidth}) {
    flex-flow: row wrap;
    > * {
      margin-bottom: 0.75rem;
    }
  }
`;
export { HeaderControls };
