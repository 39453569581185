var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Icon from "components/fl-ui/Icons/Icon";
import NotificationIcon from "components/fl-ui/Layout/Navigation/ToolbarNotificationMenu/NotificationIcon";
const NotificationItemIcon = (_a) => {
    var { count = -1, notification } = _a, props = __rest(_a, ["count", "notification"]);
    const icon = {
        imagery: "imagery",
        "market-prices": "marketing",
        news: "news",
        rainfall: "rainfall",
        "work-orders": "activities",
    }[notification.type];
    return icon ? React.createElement(Icon, Object.assign({ icon: icon }, props)) : React.createElement(NotificationIcon, { count: count });
};
export default NotificationItemIcon;
