import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "components/fl-ui";
import { Spacing, Typography } from "components/fl-ui/constants";
const WalletSignUpButton = styled(({ className }) => {
    const navigate = useNavigate();
    return (React.createElement(Button, { className: className, color: "primary", display: "block", onClick: () => navigate("/wallet/dashboard/overview") }, "Sign Up"));
}) `
  padding: ${Spacing.spacing12} ${Spacing.spacing24};
  font-size: ${Typography.medium};
  width: 100px !important;
`;
export { WalletSignUpButton };
