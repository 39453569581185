import { useCallback, useState } from "react";

/**
 * Returns a function that forces a rerender similar to
 * forceUpdate() within react class components;
 * @return {Function}
 */
const useForceUpdate = () => {
  const set = useState()[1];
  return useCallback(() => set(Math.random()), []);
};

export default useForceUpdate;
