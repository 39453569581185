import { useReducer, useCallback } from "react";

const actions = {
  CLEAR: "CLEAR",
  TOGGLE: "TOGGLE",
  RECONSTITUTE: "RECONSTITUTE",
};

const setReducer = (state, action) => {
  switch (action.type) {
    case actions.CLEAR:
      return new Set();
    case actions.TOGGLE: {
      const newSet = new Set(state);
      if (newSet.has(action.payload)) {
        newSet.delete(action.payload);
      } else {
        newSet.add(action.payload);
      }
      return newSet;
    }
    case actions.RECONSTITUTE:
      return new Set(action.payload);
    default:
      return state;
  }
};

const useImmutableSet = (initialItems = []) => {
  const [items, dispatch] = useReducer(setReducer, new Set(initialItems));

  return {
    clear: useCallback(() => dispatch({ type: actions.CLEAR }), []),
    has: useCallback((key) => items.has(key), [items]),
    items: useCallback(() => Array.from(items), [items]),
    reconstitute: useCallback((values) => dispatch({ type: actions.RECONSTITUTE, payload: values }), []),
    size: items.size,
    toggle: useCallback((key) => dispatch({ type: actions.TOGGLE, payload: key }), []),
  };
};

export default useImmutableSet;
