import { useQuery } from "@apollo/client";
import { map, sortBy } from "lodash";
import getMachineDataProductMatch from "collection/graphql/integrations/queries/getMachineDataProductMatch";
const usePossibleMatches = (matchId) => {
    var _a, _b;
    const { data } = useQuery(getMachineDataProductMatch, {
        variables: { id: matchId },
    });
    const possibleMatches = (_b = (_a = data === null || data === void 0 ? void 0 : data.productMatch) === null || _a === void 0 ? void 0 : _a.possibleMatches) !== null && _b !== void 0 ? _b : [];
    return map(sortBy(possibleMatches, "matchScore").reverse(), "product");
};
export { usePossibleMatches };
