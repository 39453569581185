import React from "react";
import styled from "styled-components";
import Button from "components/fl-ui/Button";
import { Typography } from "components/fl-ui/constants";
const ComparePlansPrompt = styled(({ className, onComparePlansClicked }) => {
    return (React.createElement("div", { className: className },
        React.createElement(Button, { color: "primary", link: true, onClick: onComparePlansClicked, size: "lg" }, "Need help choosing a plan? Compare all of our features here \u2192")));
}) `
  button {
    white-space: wrap;
  }

  font-size: ${Typography.sizes.xxxl};
  text-align: center;
`;
export { ComparePlansPrompt };
