import { find } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import useIntegrations from "hooks/useIntegrations";
import { ContractsCardVariant, MarketingCardVariant } from "modules/marketing/cards/components";

import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const ConnectToBushelCard = styled(({ className, variant = "contracts" }) => {
  const { integrations } = useIntegrations();
  const bushelIntegration = find(integrations, { id: "bushel" });
  const showCard = bushelIntegration && !bushelIntegration.enabled;

  return !showCard ? null : (
    <div className={className}>{variant === "marketing" ? <MarketingCardVariant /> : <ContractsCardVariant />}</div>
  );
})`
  box-shadow: ${Borders.tableRow};
  border-radius: ${BorderRadius.large};
  border: ${Borders.regular};
  padding: ${Spacing.spacing24};
`;

ConnectToBushelCard.propTypes = {
  variant: PropTypes.oneOf(["contracts", "marketing"]),
};

export { ConnectToBushelCard };
