import { gql } from "@apollo/client";

const MARKETED_CROP_GOAL_FRAGMENT = gql`
  fragment MarketedCropGoalFragment on MarketedCropGoal {
    dueDate
    targetPercentMarketed
    targetBushelsMarketed
    percentMarketed
    bushelsMarketed
    bushelsRemaining
    status
  }
`;

export { MARKETED_CROP_GOAL_FRAGMENT };
