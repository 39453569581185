import { capitalize } from "lodash";
import React from "react";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useUnits from "hooks/useUnits";
import EstimatedLandValue from "modules/fields/components/EstimatedLandValue";
import FieldDetailsTableRow from "modules/fields/components/FieldDetailsTableRow";
import { TableBody } from "modules/fields/components/TableBody";
import { Table } from "modules/profit_and_loss/styles";
import FieldAgreements from "components/field/FieldAgreements";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const FieldDetailsTable = ({ field, onAddLandValue }) => {
    var _a;
    const sourceEnumValue = ((_a = useUnits().findEnumValue("FieldSource", field === null || field === void 0 ? void 0 : field.source)) === null || _a === void 0 ? void 0 : _a.display) || "";
    const hasMachineData = useEnterpriseFeature("machine_data");
    if (!field) {
        return React.createElement(LoadingWrapper, null);
    }
    const [longitude, latitude] = field.centroid.coordinates;
    const latLng = `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`;
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, null,
            React.createElement(TableBody, { bordered: true, borderTop: true },
                React.createElement(EstimatedLandValue, { field: field, onAddLandValue: onAddLandValue }),
                React.createElement(FieldDetailsTableRow, { label: "Lat/Lng", value: latLng }),
                field.county && React.createElement(FieldDetailsTableRow, { label: "County", value: field.county.name }),
                field.township && React.createElement(FieldDetailsTableRow, { label: "Township Name", value: field.township.name }),
                !!field.section && React.createElement(FieldDetailsTableRow, { label: "Section", value: field.section.name }),
                !!field.township && React.createElement(FieldDetailsTableRow, { label: "Township/Range", value: field.township.range }),
                !!field.fsaFarm && React.createElement(FieldDetailsTableRow, { label: "FSA Farm", value: field.fsaFarm }),
                !!field.fsaTract && React.createElement(FieldDetailsTableRow, { label: "FSA Tract", value: field.fsaTract }),
                React.createElement(FieldDetailsTableRow, { label: "Irrigated", value: field.isIrrigated ? "Yes" : "No" }),
                React.createElement(FieldDetailsTableRow, { label: "Ownership", value: field.isRented ? "Rented" : "Owned" }),
                field.source && hasMachineData && (React.createElement(FieldDetailsTableRow, { label: "Source", value: capitalize(sourceEnumValue) })),
                field.isRented && field.landlord && React.createElement(FieldDetailsTableRow, { label: "Landlord", value: field.landlord }),
                field.notes && React.createElement("p", null, field.notes))),
        React.createElement(FieldAgreements, { fieldId: field.id })));
};
export default FieldDetailsTable;
