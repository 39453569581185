/* eslint-disable react/display-name */
import ActivityAddEditModal from "activity/components/ActivityAddEditModal";
import NotifyUserModal from "activity/components/NotifyUserModal";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useReducer, useState } from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import ActivitiesBlankSlateModal from "components/advertisements/modals/ActivitiesBlankSlateModal";

const reducer = (state, action) => {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case "abortActivityWrite":
      newState.activityDescriptor.wasCompleted = false;
      newState.wasFullyCompleted = false;
      newState.phase = "complete";
      return newState;

    case "abortNotify":
      newState.notificationDescriptor.wasCompleted = false;
      newState.wasFullyCompleted = false;
      newState.phase = "complete";
      return newState;

    case "completeActivityWrite":
      const { notificationDescriptor } = newState;

      const activityDescriptor = {
        action: action.saveResult.type,
        wasCompleted: true,
      };

      if (action.saveResult.type === "create") {
        activityDescriptor.activities = action.saveResult.activities;
        notificationDescriptor.activityCount = action.saveResult.activities.length;
      } else {
        activityDescriptor.activity = action.saveResult.activity;
        notificationDescriptor.activityCount = 1;
      }

      if (action.saveResult.shouldNotify) {
        newState.phase = "notifyUser";
        notificationDescriptor.recipientId =
          action.saveResult.type === "create"
            ? action.saveResult.activities[0].people[0].id
            : action.saveResult.activity.people[0].id;
      } else {
        newState.phase = "complete";
        newState.wasFullyCompleted = true;
      }

      return {
        ...newState,
        activityDescriptor,
        notificationDescriptor,
      };

    case "notificationSuccess":
      newState.phase = "complete";
      newState.wasFullyCompleted = newState.activityDescriptor.wasCompleted;
      newState.notificationDescriptor.message = action.message;
      newState.notificationDescriptor.wasCompleted = true;

      return newState;
  }
};

const WriteActivityWorkflow = (props) => {
  const { onAbort, onCompletion, ...rest } = props;
  const hasActivities = useEnterpriseFeature("activities");
  const [state, dispatch] = useReducer(reducer, {
    phase: "addEditActivity",
    wasFullyCompleted: false,
    activityDescriptor: {
      wasCompleted: false,
    },
    notificationDescriptor: {
      wasCompleted: false,
    },
  });

  switch (state.phase) {
    case "addEditActivity":
      if (!hasActivities) {
        return <ActivitiesBlankSlateModal onClose={onAbort} />;
      }

      return (
        <ActivityAddEditModal
          {...rest}
          onAbort={() => dispatch({ type: "abortActivityWrite" })}
          onSaveSuccess={(saveResult) => dispatch({ type: "completeActivityWrite", saveResult })}
        />
      );

    case "notifyUser":
      const { activityCount, recipientId } = state.notificationDescriptor;
      return (
        <NotifyUserModal
          activityCount={activityCount}
          recipientId={recipientId}
          onAbort={() => dispatch({ type: "abortNotify" })}
          onSendSuccess={(payload) => dispatch({ type: "notificationSuccess", ...payload })}
        />
      );

    case "complete":
      const result = _.pick(state, ["activityDescriptor", "notificationDescriptor", "wasFullyCompleted"]);
      onCompletion(result);
      return null;
  }
};

WriteActivityWorkflow.propTypes = {
  onAbort: PropTypes.func,
  onCompletion: PropTypes.func,
};

WriteActivityWorkflow.defaultProps = {
  onAbort: () => {},
  onCompletion: () => {},
};

export default WriteActivityWorkflow;

export const withWriteActivityWorkflow = (WrappedComponent) => (props) => {
  const [isWorkOrder, setIsWorkOrder] = useState(false);
  const [showWorkflow, setShowWorkflow] = useState(false);
  const onClose = () => setShowWorkflow(false);
  const onStartWorkflow = ({ isWorkOrder }) => {
    setIsWorkOrder(!!isWorkOrder);
    setShowWorkflow(true);
  };

  return (
    <>
      <WrappedComponent {...props} startWorkflow={onStartWorkflow} />
      {showWorkflow && <WriteActivityWorkflow onAbort={onClose} defaults={{ isWorkOrder }} onCompletion={onClose} />}
    </>
  );
};
