import { startCase } from "lodash";

import { CropsColorPalette } from "components/fl-ui/colors/palette";

const orderedColorIds = [
  "white",
  "almost-white",
  "grey",
  "black",
  "light-red",
  "red",
  "dark-red",
  "red-orange",
  "orange",
  "dark-yellow",
  "yellow",
  "light-yellow",
  "light-green",
  "green",
  "dark-green",
  "sky-blue",
  "light-blue",
  "blue",
  "dark-blue",
  "light-purple",
  "purple",
  "dark-purple",
  "light-tan",
  "tan",
  "dark-tan",
  "light-brown",
  "brown",
  "dark-brown",
];

/**
 * Returns an array of color options based on the system crop colors.
 * @private
 * @return {CommodityColorOption[]}
 */
const useColorOptions = () => {
  return orderedColorIds.map((key) => ({
    color: CropsColorPalette[key],
    label: startCase(key),
    value: key,
  }));
};

export default useColorOptions;

/**
 * @typedef {Object} CommodityColorOption
 * @property {String} color the color hsl/hex/rgb value usable by CSS/JS, example: "hsl(300, 40%, 30%)"
 * @property {String} label a text representation of the color, example: "Dark Purple"
 * @property {String} value a color identifier, example: "dark-purple"
 */
