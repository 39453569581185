import _ from "lodash";
import moment from "moment";
import * as yup from "yup";

import defaultCache from "collection/graphql/cache/defaultCache";
import { getAllUnits } from "collection/graphql/queries";

/**
 * @module yup
 * @typedef {Object} DateSchema
 * @property {function(ref: Reference<DateLike>, message?: string): DateSchema} before Tests a target date to check if
 *   it is before a given date ref.
 */

/**
 * @module yup
 * @typedef {Object} NumberSchema
 * @property {function(message?: string): NumberSchema} currency Tests that a target value is numeric with no more than
 *   2 decimal places.
 * @property {function(config: { max?: number, min?: number }): NumberSchema} precision Schema to validate floating
 *   point precision.
 */

/**
 * @module yup
 * @typedef {Object} StringSchema
 * @property {function(ref: Reference<string[]>, message?: string): StringSchema} inArray Tests that a target string
 *   value is within a referenced array.
 * @property {function(enumName: string, message?: string): StringSchema} inEnum Validates that a string enum value is
 *   valid for a given enumName.
 */

/**
 * Tests a target date to check if it is before a given date ref.
 *
 * @param {Reference<DateLike>} ref
 * @param {string} [message]
 * @return {yup.DateSchema<T, C>}
 */
export function before(ref, message) {
  return this.test({
    name: "before",
    message: message || "${path} must be before the specified date",
    test: function (value) {
      const referenceValue = this.resolve(ref);
      if (!_.isNil(value) && !_.isNil(referenceValue)) {
        return moment(value).isBefore(referenceValue);
      }

      return true;
    },
  });
}

/**
 * Tests that a target value is numeric with no more than 2 decimal places.
 * At the present time this only validates USD.
 *
 * @param {string} [message]
 * @return {yup.NumberSchema<T, C>}
 */
export function currency(message) {
  return this.test({
    name: "currency",
    message: message || "${path} must be a valid dollar amount",
    test: function (value) {
      return _.isNil(value) || /^-?\d*(\.\d{1,2})?$/.test(value);
    },
  });
}

/**
 * Tests that a target string value is within a referenced array. Array
 * reference can be in the context object or another field in the values
 * passed to the schema on validate.
 *
 * @example
 * const schema = yup.string().inArray(["test"]);
 * schema.validateSync("test");   // passes
 * schema.validateSync("blah");   // fails
 *
 * @param {Reference<string[]>} ref
 * @param {string} [message]
 * @return {yup.StringSchema<T, C>}
 */
export function inArray(ref, message) {
  return this.test({
    name: "inArray",
    message: message || "${path} is invalid",
    test: function (value) {
      const reference = this.resolve(ref);
      if (_.isArray(reference)) {
        return _.isNil(value) || reference.includes(value);
      }

      return this.createError({
        path: ref,
        message: "${path} is not a valid reference",
      });
    },
  });
}

/**
 * Validates that a string enum value is valid for a given enumName. Requires that the getEnums
 * query has been preloaded.
 *
 * @example
 * // see CropYieldUnit enum in grain marketing graphql
 * const schema = yup.string().inEnum("CropYieldUnit");
 * schema.validateSync("POUND");  // passes
 * schema.validateSync("BLAH");  // fails
 *
 * @param {string} enumName - The name of the enum to check against.
 * @param {string} [message] - The validation error message.
 * @returns {this} Returns the schema instance for chaining.
 */
export function inEnum(enumName, message) {
  return this.test("inEnum", function (value, { schema }) {
    if (!value && schema.tests.some((test) => test.OPTIONS.name === "required")) {
      return true;
    }

    // pull all enum data from the cache
    const result = defaultCache.readQuery({
      query: getAllUnits,
    });
    if (!result) {
      // the getAllUnits query has not been loaded into the cache
      return this.createError({ message: "Source data not loaded" });
    }

    // find the enum referenced by enumName
    const values = _.find(result.allEnums, { name: enumName })?.values;
    const acceptableValues = _.map(values, "data");
    if (acceptableValues.length === 0) {
      // couldn't find that enum in the data. was it spelled correctly?
      return this.createError({ message: "No source data found for ${path}" });
    }

    if (!_.isNil(value) && !acceptableValues.includes(value)) {
      return this.createError({
        message: message || "${path} is invalid",
      });
    }

    return true;
  });
}

/**
 * Yup throws unhelpful errors when a numeric field is required casts to NaN. This
 * function nullifies NaN values.
 * @see https://github.com/jquense/yup/issues/1330
 */
export function nanTransform(newValue, originalValue) {
  const nullishValue = this.spec.nullable ? null : undefined;
  return Number.isNaN(originalValue) || originalValue === "" ? nullishValue : newValue;
}

/**
 * Schema to validate floating point precision.
 *
 * @param {object} config
 * @param {number} [config.max] the maximum amount of allowed decimal places
 * @param {number} [config.min] the minimum amount of allowed decimal places
 *
 * @example
 * const schema = yup.number().precision({ min: 1 });
 * schema.validateSync(1.1);    // passes
 * schema.validateSync(1.12);   // passes
 * schema.validateSync(1);      // fails
 *
 * @example
 * const schema = yup.number().precision({ max: 1 });
 * schema.validateSync(1);      // passes
 * schema.validateSync(1.1);    // passes
 * schema.validateSync(1.23);   // fails
 *
 * @example
 * const schema = yup.number().precision({ max: 3, min: 1 });
 * schema.validateSync(1);        // fails
 * schema.validateSync(1.1);      // passes
 * schema.validateSync(1.23);     // passes
 * schema.validateSync(1.234);    // passes
 * schema.validateSync(1.2345);   // fails
 */
export function precision(config) {
  return this.test({
    name: "precision",
    test: function (value) {
      const validMinMax = yup.number().required().moreThan(0).integer();

      if (_.isNil(value) || value === "") {
        return true;
      } else if (!config) {
        throw new Error("Config value required");
      } else if ("max" in config && !validMinMax.isValidSync(config.max)) {
        throw new Error("Invalid max value in precision validation");
      } else if ("min" in config && !validMinMax.isValidSync(config.min)) {
        throw new Error("Invalid min value in precision validation");
      }

      const { max, min } = config;
      const decimalLength = _.size((value + "").split(".")[1]);
      if (min && decimalLength < min) {
        return this.createError({ message: `\${path} must be more than ${min} decimal place(s)` });
      } else if (max && decimalLength > max) {
        return this.createError({ message: `\${path} must be less than ${max} decimal place(s)` });
      }

      return true;
    },
  });
}

yup.addMethod(yup.date, "before", before);
yup.addMethod(yup.number, "currency", currency);
yup.addMethod(yup.string, "inArray", inArray);
yup.addMethod(yup.string, "inEnum", inEnum);
yup.addMethod(yup.number, "precision", precision);
