import { gql } from "@apollo/client";

import { MINIMAL_CASH_CONTRACT_FRAGMENT } from "collection/graphql/contracts/fragments/MinimalCashContractFragment";

export default gql`
  query getCashContractDetails($id: Int!) {
    contract: getContract(id: $id) {
      ...MinimalCashContractFragment
    }
  }

  ${MINIMAL_CASH_CONTRACT_FRAGMENT}
`;
