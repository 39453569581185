import { css } from "aphrodite";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import styles from "modules/activity/common/styles";

import { Icon } from "components/fl-ui/Icons";
import { UIColors } from "components/fl-ui/colors";
import { Borders, BorderRadius, Spacing } from "components/fl-ui/constants";

const ImageContainer = styled.div`
  align-items: center;
  border: ${Borders.regular};
  border-radius: ${BorderRadius.medium};
  display: flex;
  justify-content: center;
  margin-bottom: ${Spacing.spacing12};
  margin-right: ${Spacing.spacing12};
  position: relative;
  ${(props) => props.selectable && "cursor: pointer;"}
  ${(props) => props.selected && `box-shadow: ${UIColors.blue} 0 0 0 3px;`}
`;

const ImageThumbnails = (props) => {
  const { images, onImageSelect, selectedId, thumbnailSize } = props;

  return images.length === 0 ? null : (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {images.map((image, i) => {
        const selectable = typeof onImageSelect === "function";
        const handleClick = () => selectable && onImageSelect(image.id);

        return (
          <ImageContainer key={i} selectable={selectable} onClick={handleClick} selected={image.id === selectedId}>
            <img
              className={css(styles.roundedImage)}
              height={thumbnailSize}
              src={image.thumbUrl}
              width={thumbnailSize}
            />
            {image.isRecording && <Icon icon="play" className={css(styles.playIcon)} />}
          </ImageContainer>
        );
      })}
    </div>
  );
};

ImageThumbnails.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      isRecording: PropTypes.bool,
    })
  ).isRequired,
  onImageSelect: PropTypes.func,
  selectedId: PropTypes.any,
};

export default ImageThumbnails;
