import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const OtherExpensesAndRevenueBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Other Expenses & Revenue for free" : "Move to Business today",
        imagePath: "images/pay_gates/banners/otherExpense.png",
        title: "With Other Expenses & Revenue you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Track expenses that are not related to fieldwork or inputs, such as crop insurance or drying fees. Or revenue that is not related to crop sales, such as custom work."),
                React.createElement(ValuePropListItem, null, "Attribute expenses to your entire business as overhead, certain Marketing crops, or specific field crops."),
                React.createElement(ValuePropListItem, null, "Keep an accurate Profit & Loss statement.")))));
};
export { OtherExpensesAndRevenueBlankSlateBanner };
