import formatCurrency from "marketing/cards/utils/formatCurrency";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { CropsColorPalette } from "components/fl-ui/colors/palette";
import { BorderRadius, Spacing } from "components/fl-ui/constants";

const Price = styled(({ className, price, variant }) => <span className={className}>{formatCurrency(price, 4)}</span>)`
  background-color: ${(props) => {
    let bgcolor = "inherit";
    if (props.price > 0) {
      bgcolor =
        props.variant && props.variant === "YELLOW"
          ? CropsColorPalette["light-yellow"]
          : CropsColorPalette["seafoam-green"];
    }
    return bgcolor;
  }};
  border-radius: ${BorderRadius.xsmall};
  color: ${(props) => {
    let color = "inherit";
    if (props.price > 0) {
      color =
        props.variant && props.variant === "YELLOW" ? CropsColorPalette["brown"] : CropsColorPalette["dark-green"];
    }
    return color;
  }};
  height: fit-content;
  padding: ${Spacing.spacing4};
`;

export default Price;

Price.propTypes = {
  price: PropTypes.string,
  variant: PropTypes.string,
};
