import { makeVar, useQuery, useReactiveVar } from "@apollo/client";
import { login, logout } from "collection/graphql/auth/lib";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import useWindow from "hooks/useWindow";
import { fetch } from "lib/ajax";
import { RegistrationFailedError, UnauthorizedError } from "lib/errors";
import { LOGIN_SIGNUP } from "lib/metrics/events";
const POLL_INTERVAL = 5 * 60 * 1000; // every 5 minutes
export const sharedState = makeVar({ isLoggingOut: false });
const useAuth = () => {
    const { data, loading, refetch } = useQuery(GET_CURRENT_USER, {
        fetchPolicy: "cache-and-network",
        pollInterval: POLL_INTERVAL,
    });
    const currentUser = data === null || data === void 0 ? void 0 : data.currentUser;
    const { isLoggingOut } = useReactiveVar(sharedState);
    const isLoggedIn = !!currentUser && !isLoggingOut;
    const isLoggedOut = !currentUser;
    const win = useWindow();
    //TODO: Each of these is an independent function and has no reason to be housed inside of useAuth any longer.
    // We should break all of these out into their own files (or removed completely once SSO is fully implemented)
    const getPasswordResetToken = async (email) => {
        const response = await fetch("/v2.0/users/password/reset", {
            body: JSON.stringify({ email }),
            cache: "no-store",
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });
        if (!response.ok) {
            throw new Error("An error occurred while requesting password reset token");
        }
        return response.json();
    };
    const register = async (userRegistration) => {
        const response = await fetch("/v2.0/users/register", {
            body: JSON.stringify(userRegistration),
            cache: "no-store",
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });
        if (!response.ok) {
            LOGIN_SIGNUP.track({ email: userRegistration.email, outcome: "failure" });
            throw new RegistrationFailedError("User registration failed");
        }
        await refetch();
        LOGIN_SIGNUP.track({ email: userRegistration.email, outcome: "success" });
    };
    const resetPassword = async ({ password, token }) => {
        const response = await fetch(`/v2.0/users/password/reset/${token}`, {
            body: JSON.stringify({ new_password: password }),
            cache: "no-store",
            headers: { "Content-Type": "application/json" },
            method: "POST",
        });
        const payload = await response.json();
        if (response.ok) {
            await refetch();
        }
        else if ((payload === null || payload === void 0 ? void 0 : payload.error) === "unauthorized") {
            throw new UnauthorizedError(payload.message);
        }
        else {
            throw new Error(payload);
        }
    };
    const loginUser = async ({ email, password }) => {
        await login(email, password).then(async () => {
            win.location.href = "/";
        });
    };
    const logoutUser = async () => {
        sharedState({ isLoggingOut: true });
        try {
            return await logout();
        }
        finally {
            if (win.navigator.onLine) {
                win.location.href = "/";
            }
        }
    };
    return {
        currentUser,
        isLoggedIn,
        isLoggedOut,
        isLoggingOut,
        loading,
        getPasswordResetToken,
        register,
        resetPassword,
        login: loginUser,
        logout: logoutUser,
        refetch,
    };
};
export default useAuth;
