import React from "react";
import { redirect } from "react-router-dom";
import { SettingsPage } from "modules/settings/pages/SettingsPage";
export default [
    {
        path: "/settings/activities",
        element: React.createElement(SettingsPage, { activeRoute: "activities" }),
    },
    {
        path: "/settings/alerts",
        element: React.createElement(SettingsPage, { activeRoute: "alerts" }),
    },
    {
        path: "/settings/billing",
        element: React.createElement(SettingsPage, { activeRoute: "billing" }),
    },
    {
        path: "/settings/commodities",
        element: React.createElement(SettingsPage, { activeRoute: "commodities" }),
    },
    {
        path: "/settings/connections",
        element: React.createElement(SettingsPage, { activeRoute: "connections" }),
    },
    {
        path: "/settings/data",
        element: React.createElement(SettingsPage, { activeRoute: "data" }),
    },
    {
        path: "/settings/data/fieldBoundaries",
        element: React.createElement(SettingsPage, { activeRoute: "data", subRoute: "fieldBoundaries" }),
    },
    {
        path: "/settings/data/yieldData",
        element: React.createElement(SettingsPage, { activeRoute: "data", subRoute: "yieldData" }),
    },
    {
        path: "/settings/expense_and_revenue",
        element: React.createElement(SettingsPage, { activeRoute: "expense_and_revenue" }),
    },
    {
        path: "/settings/farm",
        element: React.createElement(SettingsPage, { activeRoute: "farm" }),
    },
    {
        path: "/settings/inputs",
        element: React.createElement(SettingsPage, { activeRoute: "inputs" }),
    },
    {
        path: "/settings/users",
        element: React.createElement(SettingsPage, { activeRoute: "users" }),
    },
    {
        path: "/settings/wallet",
        element: React.createElement(SettingsPage, { activeRoute: "wallet" }),
    },
    /*
     * Redirects
     */
    {
        path: "/settings/data_integrations",
        loader: () => redirect("/settings/connections"),
    },
    {
        path: "/settings/notifications",
        loader: () => redirect("/settings/alerts"),
    },
    {
        path: "/settings/import/yield",
        loader: () => redirect("/settings/data/yieldData"),
    },
    {
        path: "/settings/integrations",
        loader: () => redirect("/settings/connections"),
    },
    {
        path: "/settings/subscriptions",
        loader: () => redirect("/settings/billing"),
    },
];
