import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FieldDetailsWidgetContainer } from "modules/fields/components/FieldDetailsWidgetContainer";
import { WidgetHeader } from "modules/fields/components/WidgetHeader";
import { IconContainer } from "components/fl-ui/Icons";
import { ColorPalette } from "components/fl-ui/colors";
export const MoreLink = styled(Link) `
  align-items: center;
  color: ${ColorPalette.blue};
  display: flex;
`;
const DetailsWidget = ({ children, more, title, to }) => (React.createElement(FieldDetailsWidgetContainer, null,
    React.createElement(WidgetHeader, null,
        React.createElement("h2", null, title),
        more && to && (React.createElement(MoreLink, { to: to },
            more,
            React.createElement(IconContainer, { icon: "chevronRight" })))),
    children));
export default DetailsWidget;
