import React, { useState } from "react";
import { ProductMatchBlankSlate } from "modules/settings/pages/ProductAndMachineMatching/components/ProductMatchBlankSlate";
import { ProductMatchCard } from "modules/settings/pages/ProductAndMachineMatching/components/ProductMatchCard";
import { ProductMatchModal } from "modules/settings/pages/ProductAndMachineMatching/components/ProductMatchModal";
import { useSortedProductMatches } from "modules/settings/pages/ProductAndMachineMatching/hooks/useSortedProductMatches";
import { GenericStyledText } from "modules/settings/GenericStyledText";
import { Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import styled from "styled-components";
import { Spacing, Typography } from "components/fl-ui/constants";
const MatchGrid = styled.div `
  display: grid;
  grid-gap: ${Spacing.spacing20};
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
`;
const ResponsiveHeading = styled.h2 `
  font-size: ${Typography.sizes.lg};
  text-transform: capitalize;
  @media only screen and (min-width: 62.5rem) {
    font-size: ${Typography.sizes.xl};
  } ;
`;
const ProductAndMachineMatching = () => {
    const { loading, matches } = useSortedProductMatches();
    const [matchToEdit, setMatchToEdit] = useState(null);
    const showBlankSlate = !loading && matches.length === 0;
    const close = () => setMatchToEdit(null);
    return (React.createElement(Stack, null,
        React.createElement(ResponsiveHeading, null, "Input Matching"),
        React.createElement(GenericStyledText, null, "To finalize activities, please find a match for unidentified inputs."),
        React.createElement(LoadingWrapper, { isLoading: loading },
            showBlankSlate && React.createElement(ProductMatchBlankSlate, null),
            !showBlankSlate && (React.createElement(MatchGrid, null, matches.map((match) => (React.createElement(ProductMatchCard, { key: match.id, onEdit: () => setMatchToEdit(match), match: match }))))),
            matchToEdit && React.createElement(ProductMatchModal, { onClose: close, onSave: close, productMatch: matchToEdit }))));
};
export { ProductAndMachineMatching };
