import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const FileAttachmentsBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Field Attachments for free" : "Move to Essentials today",
        imagePath: "images/pay_gates/banners/fileAttachments.png",
        title: "With Field Attachments you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "Attach and manage digital files that you want to have at your fingertips when viewing your field records in Bushel Farm."),
                React.createElement(ValuePropListItem, null, "PDFs, images, and text files as well as your favorite Word, Excel, and PowerPoint files can be uploaded so that you have access to your digital field records in one place!")))));
};
export { FileAttachmentsBlankSlateBanner };
