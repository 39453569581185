var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import Icon from "components/fl-ui/Icons/Icon";
import { BaseElement } from "components/fl-ui/common";
import { Spacing } from "components/fl-ui/constants";
const IconContainer = styled((_a) => {
    var { children, icon } = _a, props = __rest(_a, ["children", "icon"]);
    return (React.createElement(BaseElement, Object.assign({}, props), icon ? React.createElement(Icon, { icon: icon }) : children));
}) `
  display: inline-block;
  vertical-align: middle;
  max-height: ${({ size }) => size !== null && size !== void 0 ? size : Spacing.spacing24};
  max-width: ${({ size }) => size !== null && size !== void 0 ? size : Spacing.spacing24};
  width: ${({ size }) => size !== null && size !== void 0 ? size : Spacing.spacing24};
`;
export default IconContainer;
