import { useSuspenseQuery } from "@apollo/client";
import useDocumentTitle from "hooks/useDocumentTitle";
import React, { Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GET_AGREEMENT } from "collection/graphql/agreements";
import { CreateAgreementForm, EditAgreementForm } from "modules/agreements/forms";

import BreadCrumbHeader from "components/fl-ui/Layout/BreadCrumbHeader";
import Container from "components/fl-ui/Layout/Container";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const WrappedEditForm = ({ id }) => {
  const navigate = useNavigate();
  const {
    data: { agreement },
  } = useSuspenseQuery(GET_AGREEMENT, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: {
      id,
    },
  });

  if (!agreement) {
    navigate("/land_costs");
    return null;
  }

  return <EditAgreementForm agreement={agreement} />;
};

const AgreementDetailPage = ({ action }) => {
  const { id } = useParams();
  const isEditing = action === "edit";
  useDocumentTitle(isEditing ? "Edit agreement" : "Add agreement");
  const links = !isEditing
    ? [{ enabled: false, label: "Add agreement" }]
    : [
        { enabled: true, label: "Land Costs", link: "/land_costs" },
        { enabled: false, label: `#${id}` },
      ];

  return (
    <Container>
      <BreadCrumbHeader links={links} />

      <Suspense fallback={<LoadingWrapper isLoading />}>
        {isEditing ? <WrappedEditForm id={id} /> : <CreateAgreementForm />}
      </Suspense>
    </Container>
  );
};

export default AgreementDetailPage;
