import React from "react";

import useCurrentCropYear from "hooks/useCurrentCropYear";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import ProfitAndLossBlankSlateBannerPage from "components/advertisements/pages/ProfitAndLossBlankSlateBannerPage";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";

const ProfitAndLossDashboardWrapper = ({ banner, children, ...otherProps }) => {
  const cropYear = useCurrentCropYear()[0];
  const WrappedDashboardContent = children;

  const { showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "profit_and_loss",
    permissionName: "profit_and_loss",
  });

  return (
    <Container>
      <Header title="Profit & Loss">{showContent && <CropYearPicker />}</Header>

      {showPayGate && <ProfitAndLossBlankSlateBannerPage banner={banner} />}
      {showRestricted && <RestrictedAccessBlankSlate />}
      {showContent && <WrappedDashboardContent {...otherProps} cropYear={cropYear} hasAccess={showContent} />}
    </Container>
  );
};

export default ProfitAndLossDashboardWrapper;
