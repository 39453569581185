import React from "react";
import useViewType from "hooks/useViewType";
import { MainMenuDesktop } from "components/fl-ui/Layout/MainMenu/MainMenuDesktop";
import { MainMenuResponsive } from "components/fl-ui/Layout/MainMenu/MainMenuResponsive";
const MainMenu = () => {
    const isDesktop = useViewType() === "desktop";
    return (React.createElement(React.Fragment, null,
        isDesktop && React.createElement(MainMenuDesktop, null),
        !isDesktop && React.createElement(MainMenuResponsive, null)));
};
export { MainMenu };
