import { gql } from "@apollo/client";
const getCurrentUserMembership = gql(`
  query getCurrentUserMembership {
    currentMembership {
      enterprise {
        id
        name
      }
      role {
        id
        name
      }
      user {
        id
        name
      }
    }
  }
`);
export default getCurrentUserMembership;
