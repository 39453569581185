import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import useWindow from "hooks/useWindow";
import { Spacing } from "components/fl-ui/constants";
// see nginx/error.html
const GlobalStyle = createGlobalStyle `
  html.notFound {
    background-color: #2d3748;
    height: 100%;
    
    #layout {
      align-items: center;
      background-color: #2d3748;
      display: flex;
      place-content: center;
    }
    
    body {
      background-attachment: fixed;
      background-color: #2d3748;
      color: #fff;
      font-family: "Rubik", "Helvetica Neue", Helvetica, Arial, sans-serif;
      height: 100%;
      -webkit-font-smoothing: antialiased;
    }
    #error {
      margin: 100px;
      max-width: 600px;
      width: 80%;
    }
    @media only screen and (max-width: ${PrimaryMaxWidth}) {
      #error {
        margin: 100px auto;
      }
    }
    h1 {
      font-size: 60px;
    }
    h1 small {
      color: #f2bc23;
      font-size: 40px;
      text-shadow: none;
      text-transform: uppercase
    }
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
    a.btn {
      background: #f2bc23;
      border: 1px solid #f2bc23;
      border-radius: 5px;
      color: #2d3748;
      display: inline-block;
      font-weight: bold;
      height: ${Spacing.spacing36};
      line-height: 36px;
      margin: 10px 0;
      padding: 0 ${Spacing.spacing24};
      text-decoration: none;
    }
    a.btn:hover {
      background-color: transparent;
      color: #f2bc23;
    }
    .bushel-icon {
      bottom: -20%;
      filter: blur(25px);
      height: 100%;
      opacity: .1;
      position: absolute;
      right: -20%;
      z-index: -1000;
    }
  }
`;
const NoMatchRouteLayout = () => {
    const win = useWindow();
    useEffect(() => {
        const html = win.document.body.parentNode;
        html.classList.add("notFound");
        return () => html.classList.remove("notFound");
    }, []);
    return (React.createElement("div", null,
        React.createElement(GlobalStyle, null),
        React.createElement("h1", null,
            "Oops! ",
            React.createElement("small", null, "Error 404")),
        React.createElement("h2", null, "The page you\u2019re looking for has run away from home."),
        React.createElement("p", null, "You might have accidentally mistyped the address or the page may have moved."),
        React.createElement("p", null, "We\u2019re terribly sorry for this inconvenience."),
        React.createElement("p", null,
            React.createElement(Link, { className: "btn complain-tn", to: "/" }, "Return to the Bushel Farm App")),
        React.createElement("img", { className: "bushel-icon", src: "/images/app_icons/bushel-farm-icon-white.svg", alt: "" })));
};
export default NoMatchRouteLayout;
