import { MicroLabel } from "agreements/forms/components";
import { StyleSheet, css } from "aphrodite";
import { exists, isPositive } from "marketing/utils/validators";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/fl-ui";
import { FormGroup, Select } from "components/fl-ui/Form";
import { BasicInput } from "components/fl-ui/Form/Input";
import { Borders, Mixins, Spacing } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  costInputs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: Spacing.spacing8,
    "@media only screen and (max-width: 34.375em)": {
      marginTop: Spacing.spacing16,
      marginLeft: 0,
    },
  },
  formGroup: {
    border: Borders.regular,
    display: "flex",
    flexDirection: "row",
    padding: Spacing.spacing12,
    position: "relative",
    "@media only screen and (max-width: 34.375em)": {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  input: {
    maxWidth: Mixins.toRem(160),
    marginRight: Spacing.spacing8,
  },
  removeButton: {
    padding: 0,
    position: "absolute",
    right: `-${Spacing.spacing12}`,
    top: `-${Spacing.spacing12}`,
  },
  select: {
    maxWidth: Mixins.toRem(160),
    marginRight: Spacing.spacing8,
  },
});

const defaultOption = { display: "Select a category", data: "SELECT" };

const ItemizedProductionCosts = (props) => {
  const { category, cost, editItem, error, idx, productionCostCategory, productionCostUnits, removeItem, unit } = props;
  const handleChange = (changes) => {
    if (changes.hasOwnProperty("cost")) {
      if (isPositive(changes.cost) || !exists(changes.cost)) {
        editItem({ category, unit, ...changes }, idx);
      }
    } else {
      editItem({ category, cost, unit, ...changes }, idx);
    }
  };

  return (
    <FormGroup className={css(styles.formGroup)} error={error?.itemizedProductionCosts?.msg} horizontal key={idx}>
      <MicroLabel label="Category">
        <Select
          className={css(styles.select)}
          id="itemized-production-category"
          minimal
          name="category"
          onChange={(e, category) => handleChange(category)}
          options={
            productionCostCategory
              ? [defaultOption, ...productionCostCategory].map((val, index) => ({
                  key: index,
                  label: val.display,
                  value: val.data,
                }))
              : [defaultOption]
          }
          value={category}
        />
      </MicroLabel>

      <div className={css(styles.costInputs)}>
        <span style={{ marginRight: ".5rem" }}>
          <MicroLabel compact label="Amount">
            <BasicInput
              className={css(styles.input)}
              controlled
              min="0"
              name="cost"
              onChange={(e, cost) => handleChange(cost)}
              prefix="$"
              size="large"
              type="number"
              value={cost || ""}
            />
          </MicroLabel>
        </span>

        <MicroLabel label="Rate">
          <Select
            id="itemized-production-cost-unit"
            minimal
            name="unit"
            onChange={(e, unit) => handleChange(unit)}
            options={
              productionCostUnits
                ? productionCostUnits.map((val, index) => ({
                    key: index,
                    label: val.data === "PER_ACRE" ? "/ ac" : val.display,
                    value: val.data,
                  }))
                : []
            }
            value={unit}
          />
        </MicroLabel>
      </div>

      <Button
        className={css(styles.removeButton)}
        color="danger"
        disabled={idx === 0 && category === "SELECT"}
        icon="remove"
        link
        onClick={() => removeItem(idx)}
        size="lg"
      />
    </FormGroup>
  );
};

ItemizedProductionCosts.propTypes = {
  category: PropTypes.string,
  cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editItem: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  productionCostCategory: PropTypes.arrayOf(PropTypes.object),
  productionCostUnits: PropTypes.arrayOf(PropTypes.object),
  removeItem: PropTypes.func.isRequired,
  unit: PropTypes.string,
};

export default ItemizedProductionCosts;
