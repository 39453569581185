import { css } from "aphrodite/no-important";
import NewLocalPrices from "prices/containers/NewLocalPrices";
import { localBidsStyles as styles } from "prices/styles";
import React from "react";
import { Link } from "react-router-dom";

import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import LocalPricesBlankSlatePage from "components/advertisements/pages/LocalPricesBlankSlatePage";
import ZipCodeInput from "components/fl-ui/ZipCodeInput";

const LocalBids = ({ buyerId = null }) => {
  const { currentEnterprise } = useCurrentEnterprise();
  const hasLocalPricesFeature = useEnterpriseFeature("local_prices");
  const zipCode = currentEnterprise?.postalCode;

  if (!hasLocalPricesFeature) {
    return <LocalPricesBlankSlatePage />;
  } else if (!zipCode) {
    return (
      <div className={css(styles.blankslateContainer)}>
        <h2>Where is your farm located?</h2>
        <p className={css(styles.blankslateText)}>
          {
            "Bushel Farm uses your postal code to calculate average basis and county yield averages for your crops. You can always change this later in your "
          }
          <Link className={css(styles.blankslateLink)} to="/settings/farm">
            Farm settings
          </Link>
          .
        </p>
        <ZipCodeInput defaultValue={zipCode} icon="marketingPrices" />
      </div>
    );
  }

  return <NewLocalPrices buyerId={buyerId} zipCode={zipCode} />;
};

export default LocalBids;
