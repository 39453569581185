import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import React from "react";
import styled from "styled-components";
import { SignUpDescriptionText } from "modules/wallet/components/sign_up/SignUpDescriptionText";
import { SignUpHeaderTitle } from "modules/wallet/components/sign_up/SignUpHeaderTitle";
import { SignUpSecondaryTitle } from "modules/wallet/components/sign_up/SignUpSecondaryTitle";
import { WalletSignUpSection } from "modules/wallet/components/sign_up/WalletSignUpSection";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";
const FeatureGrid = styled.div `
  display: flex;
  justify-content: space-between;
  gap: ${Spacing.spacing20};
  margin: ${Spacing.spacing16} auto;
  width: 90%;

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    flex-direction: column;
    align-items: center;
    width: 95%;
  }
`;
const FeatureCard = styled.div `
  background-color: ${GreyColors.smoke98};
  padding: ${Spacing.spacing16};
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  box-shadow: 0 2px 5px ${UIColors.shadowOne};
  flex: 1;
  text-align: center;

  img {
    padding: ${Spacing.spacing32};
  }

  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    width: 100%;
  }
`;
const FeatureTitle = styled.h3 `
  text-align: left;
  color: ${UIColors.darkGrey};
  margin-top: 0;
  margin-bottom: ${Spacing.spacing12};
`;
const FeatureDescription = styled.p `
  color: ${UIColors.darkGrey};
  text-align: left;
`;
const WalletSignUpThirdSection = styled(({ className }) => (React.createElement(WalletSignUpSection, { backgroundColor: "white" },
    React.createElement("div", { className: className },
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(SignUpHeaderTitle, null, "Streamline Your Payments and Access Your Funds Instantly:"),
        React.createElement("br", null),
        React.createElement("br", null),
        React.createElement(FeatureGrid, null,
            React.createElement(FeatureCard, null,
                React.createElement(FeatureTitle, null, "Simplify payments and get paid instantly."),
                React.createElement(FeatureDescription, null, "No more checks or delays. Whether it\u2019s getting paid for grain deliveries or paying on inputs, payments are now faster and more reliable."),
                React.createElement("img", { className: "", src: "images/wallet/simplify-payments.png", alt: "Simplify Payments" })),
            React.createElement(FeatureCard, null,
                React.createElement(FeatureTitle, null, "Instant access to your funds."),
                React.createElement(FeatureDescription, null, "With the Bushel Debit Card, you can access your Wallet balance anytime. Make purchases or withdraw funds instantly, all while enjoying the same security and ease of use as the rest of our platform."),
                React.createElement("img", { className: "", src: "images/wallet/bushel-debit-card.png", alt: "Bushel Debit Card" })),
            React.createElement(FeatureCard, null,
                React.createElement(FeatureTitle, null, "Take control of your credit line with full transparency."),
                React.createElement(FeatureDescription, null, "Access and manage your line of credit anytime to meet your farm\u2019s needs, with built-in fraud protection ensuring your account stays safe."),
                React.createElement("img", { className: "", src: "images/wallet/full-transparency.png", alt: "Full Transparency" }))),
        React.createElement("div", { className: "bottom-section" },
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(SignUpSecondaryTitle, null, "Plan smarter with tools tailored to your farm."),
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement(SignUpDescriptionText, null, "Gain data-driven insights to make better financial decisions for the future, all while knowing your data is encrypted and secure."),
            React.createElement("img", { className: "", src: "images/wallet/line-of-credit-management.png", alt: "Line of Credit Management" })))))) `
  h1 {
    margin: 0 auto;
    text-align: center;
    width: 60%;

    @media only screen and (max-width: ${PrimaryMaxWidth}) {
      width: 100%;
    }
  }

  .bottom-section {
    text-align: center;

    img {
      width: 30%;
      margin: ${Spacing.spacing24} 0;
    }
  }
`;
export { WalletSignUpThirdSection };
