import { css } from "aphrodite";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import withViewType from "hoc/withViewType";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import AgreementCard from "modules/agreements/components/AgreementCard";
import AgreementRow from "modules/agreements/components/AgreementRow";
import BlankSlate from "modules/agreements/components/BlankSlate";
import CreateAgreementButton from "modules/agreements/components/CreateAgreementButton";
import GroupedAgreements from "modules/agreements/components/GroupedAgreements";
import { isPlural } from "modules/agreements/utils";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { FilterButton } from "components/fl-ui/Buttons";
import BulkActions from "components/fl-ui/Buttons/BulkActions";
import DropdownMenu from "components/fl-ui/DropdownMenu";
import { Cluster } from "components/fl-ui/Layout";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import PageSummary, { ResponsiveFigure } from "components/fl-ui/PageSummary";
import Search from "components/fl-ui/Search";
import { Spacing } from "components/fl-ui/constants";
import styles from "fields/common/styles";

const Dashboard = (props) => {
  const { agreements, bulkSelection, groupedAgreements, onBulkSelect, sort, toggleModal, totalAgreements } = props;
  const agreementCount = agreements.length;
  const hasLandCosts = useEnterpriseFeature("land_costs");
  const RenderAgreements = () => {
    if (!agreementCount) {
      return <BlankSlate totalAgreements={totalAgreements} />;
    }

    if (groupedAgreements) {
      return Object.keys(groupedAgreements).map((group) => (
        <GroupedAgreements
          agreements={groupedAgreements[group]}
          bulkSelection={bulkSelection}
          groupName={group}
          key={group}
          onBulkSelect={onBulkSelect}
        />
      ));
    }

    return props.viewType === "mobile"
      ? agreements.map((agreement) => <AgreementCard key={agreement.id} {...agreement} />)
      : agreements.map((agreement) => (
          <AgreementRow key={agreement.id} {...agreement} bulkSelection={bulkSelection} onBulkSelect={onBulkSelect} />
        ));
  };

  const description = `${agreementCount} ${agreementCount === 1 ? "agreement" : "agreements"}`;

  const sortOptions = [
    { label: "Type", value: "agreementType" },
    { label: "Expiration date", value: "maturityDate" },
    { label: "Creation date", value: "creationDate" },
  ];

  return (
    <Container>
      <Header description={description} title="Land Costs">
        {hasLandCosts && <CreateAgreementButton style={{ marginRight: Spacing.spacing16 }} />}
      </Header>

      {!agreementCount && !hasLandCosts ? (
        <PayGateAdBanner
          customBodyText="Unlock the land costs area and get a clear view of your land agreements, costs, and income—all in one place. Track expenses by field and acre, generate flex rent and crop share reports, and keep detailed records for smarter decisions."
          customTitleText="Better Land Cost Management"
          featureName="Land Costs"
          planIncludedIn="Business"
        />
      ) : (
        <>
          <Cluster between>
            <div className={css(styles.filterBar_controls)}>
              <Search onChange={_.debounce(props.handleSearch, 500)} placeholder="Find..." style={{ marginLeft: 0 }} />
              <FilterButton onClick={toggleModal} />
            </div>

            <div className={css(styles.filterBar_controls, styles.hide_on_mobile)}>
              <p className={css(styles.filterBar_text)}>Sort by</p>
              <DropdownMenu options={sortOptions} onChange={props.handleSort} value={sort} />
            </div>
          </Cluster>

          <PageSummary>
            <ResponsiveFigure
              label="Agreements"
              value={
                <span>
                  {agreementCount} <small>of</small> {totalAgreements}
                </span>
              }
            />

            <ResponsiveFigure
              label="Loans"
              value={agreements.filter(({ agreementType }) => agreementType === "LOAN").length}
            />

            <ResponsiveFigure
              label="Leases"
              value={agreements.filter(({ agreementType }) => agreementType === "LEASE").length}
            />

            <ResponsiveFigure
              label="Revenue"
              value={agreements.filter(({ agreementType }) => agreementType === "REVENUE").length}
            />
          </PageSummary>

          <BulkActions
            danger={{ action: props.onBulkDelete }}
            options={props.bulkSelectionOptions}
            selection={bulkSelection}
            selectionStatus={`${bulkSelection.length} ${isPlural(bulkSelection) ? "agreements" : "agreement"}`}
          />

          <RenderAgreements />
        </>
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.object).isRequired,
  bulkSelection: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  bulkSelectionOptions: PropTypes.arrayOf(PropTypes.object),
  groupedAgreements: PropTypes.object,
  handleSearch: PropTypes.func,
  handleSort: PropTypes.func,
  onBulkDelete: PropTypes.func,
  onBulkSelect: PropTypes.func,
  toggleModal: PropTypes.func,
  totalAgreements: PropTypes.number,
};

export default withViewType(Dashboard);
