import React from "react";
import styled from "styled-components";

import { Spacing } from "components/fl-ui/constants";

const CustomPlanPrompt = styled(({ className }) => (
  <div className={className}>
    <p>
      Have more than 10,000 acres? Contact us to build a customized plan designed specifically for larger operations.
    </p>
    <p>
      Call <a href="tel:7344316788">(734) 431-6788</a> or email{" "}
      <a href="mailto:sales@bushelfarm.com">sales@bushelfarm.com</a>
    </p>
  </div>
))`
  text-align: center;
  margin-bottom: ${Spacing.spacing28};

  a {
    color: #1e7ddc;
    text-decoration: underline;
  }
`;

export default CustomPlanPrompt;
