import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import useBoolean from "hooks/useBoolean";
import { GenericStyledText, } from "modules/settings/GenericStyledText";
import { Icon } from "components/fl-ui/Icons";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";
import MachineDataErrorText from "modules/settings/components/Integrations/components/MachineDataErrorText";
import { IntegrationStatusStrings } from "types/graphql";
import { ColorPalette, GreyColors } from "components/fl-ui/colors";
const Container = styled.div `
  align-items: center;
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Spacing.spacing16};
  padding-bottom: ${Spacing.spacing16};
  ${({ bordered }) => bordered &&
    `
    border: ${Borders.regular};
    border-radius: ${BorderRadius.medium};
    padding: ${Spacing.spacing16};
  `}

  @media only screen and (max-width: 62.5rem) {
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;
  }
`;
const DataRowContentContainer = styled.div `
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 62.5rem) {
    width: 100%;
  }
`;
const Content = styled.div `
  align-items: center;
  display: flex;
  margin-right: ${Spacing.spacing16};

  @media only screen and (max-width: 62.5rem) {
    margin-bottom: ${Spacing.spacing8};
    margin-right: ${Spacing.spacing4};
  } ;
`;
const StyledLogo = styled.div `
  border: ${Borders.regular};
  display: grid;
  margin-right: ${Spacing.spacing16};
  height: 50px;
  min-width: 110px;
  place-items: center;
  width: 110px;

  @media only screen and (max-width: 62.5rem) {
    margin-right: ${Spacing.spacing12};
  }
`;
const TopErrorText = styled.div `
  background-color: ${ColorPalette["medium-red"]};
  border-radius: ${BorderRadius.medium};
  color: ${GreyColors.smoke20};
  display: inline-flex;
  font-size: ${Typography.sizes.sm};
  justify-content: space-between;
  margin-bottom: ${Spacing.spacing14};
  padding: ${Spacing.spacing8} ${Spacing.spacing16};
  width: 100%;

  & > svg {
    width: 6%;
  }
  & > div {
    width: 88%;
    display: flex;
    align-items: center;
  }

  a,
  button {
    color: ${GreyColors.smoke20};
    font-weight: normal;
    font-size: ${Typography.sizes.sm};
    text-decoration: underline;
  }
`;
const BorderedContainer = styled.div `
  border: ${Borders.regular};
  border-radius: ${BorderRadius.medium};
  padding: ${Spacing.spacing12};
  margin-bottom: ${Spacing.spacing16};

  & div {
    border: none;
    margin-bottom: 0;
  }
`;
const IntegrationImage = styled.img `
  filter: grayscale(${({ disabled }) => (disabled ? 1 : 0)});
  max-height: 40px;
  max-width: 100px;
`;
const CommonIntegrationDataRow = styled(({ className, children, copy, imgDisabled = false, imgSrc, integration, }) => {
    const [errorTextDismissed, { on }] = useBoolean(false);
    const hasErrorStatus = integration.status === IntegrationStatusStrings.UNAUTHENTICATED ||
        integration.status === IntegrationStatusStrings.MISSING_PERMISSION;
    const showErrorText = integration.enabled && !errorTextDismissed && hasErrorStatus;
    return (React.createElement(Row, { className: className },
        React.createElement(Col, { xs: 12 },
            React.createElement(BorderedContainer, null,
                showErrorText && (React.createElement(TopErrorText, null,
                    React.createElement(Icon, { className: "warning error-text-icon", icon: "warningOutline" }),
                    React.createElement("div", null,
                        React.createElement(MachineDataErrorText, { integrationId: integration.id, integrationStatus: integration.status })),
                    React.createElement(Icon, { className: "close error-text-icon", icon: "close", onClick: on }))),
                React.createElement(Container, null,
                    React.createElement(Content, null,
                        imgSrc && (React.createElement(StyledLogo, null,
                            React.createElement(IntegrationImage, { disabled: imgDisabled, src: imgSrc }))),
                        React.createElement("div", null,
                            React.createElement(GenericStyledText, null,
                                React.createElement("strong", null, integration.name)),
                            React.createElement(GenericStyledText, null, copy))),
                    React.createElement(DataRowContentContainer, null, children))))));
}) `
  .error-text-icon {
    color: black;
    display: inline-block;
    height: 1.3rem;
    margin-top: ${Spacing.spacing2};
    vertical-align: middle;
    width: 1.3rem;

    &.warning {
      margin-right: ${Spacing.spacing8};
    }
  }
`;
export default CommonIntegrationDataRow;
