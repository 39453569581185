import * as yup from "yup";
const AddUserFormSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required").label("Email"),
    accessLevel: yup
        .string()
        .oneOf(["admin", "manager", "contributor", "no_access"], "Invalid access level")
        .required("Access level is required")
        .label("Access level"),
    firstName: yup
        .string()
        .label("First Name")
        .when("access_level", {
        is: "no_access",
        then: (schema) => schema.required("First name is required for no access users"),
        otherwise: (schema) => schema.notRequired(),
    }),
    lastName: yup
        .string()
        .label("Last Name")
        .when("access_level", {
        is: "no_access",
        then: (schema) => schema.required("Last name is required for no access users"),
        otherwise: (schema) => schema.notRequired(),
    }),
});
export { AddUserFormSchema };
