import { gql } from "@apollo/client";

const MINIMAL_CASH_CONTRACT_FRAGMENT = gql`
  fragment MinimalCashContractFragment on CashContract {
    buyer
    commodity {
      id
      name
    }
    contractDate
    contractFinalStatus
    contractNumber
    contractStatus
    contractType
    cropYear
    dataSource
    deliveryEndDate
    deliveryStartDate
    id
    marketedCrop {
      id
      name
      year
    }
    netPrice
    notes
    product
    quantity
    quantityCanceled
    quantityDelivered
    quantityPriced
    quantityUnit
  }
`;

export { MINIMAL_CASH_CONTRACT_FRAGMENT };
