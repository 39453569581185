import React from "react";
import useDocumentTitle from "hooks/useDocumentTitle";
import BaseSSOPageContainer from "modules/sso/components/BaseSSOPageContainer";
import { SSOUserInformationForm } from "modules/sso/forms/SSOUserInformationForm";
const SSOUserInformationScreen = ({ onComplete }) => {
    useDocumentTitle("User Information Screen");
    return (React.createElement(BaseSSOPageContainer, { title: "Welcome to Bushel Farm" },
        React.createElement(SSOUserInformationForm, { onComplete: onComplete })));
};
export { SSOUserInformationScreen };
