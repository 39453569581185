import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import PrimaryBanner from "components/banners/PrimaryBanner";
import { MainMenu } from "components/fl-ui/Layout/MainMenu/MainMenu";
import ContentContainer from "components/fl-ui/Layout/Navigation/ContentContainer";
import Toast from "components/fl-ui/Toast";
import LoggedOutModal from "components/modals/LoggedOutModal";
//import { useAuth } from "collection/graphql/auth";
const DefaultLayout = () => {
    //const { hasInvalidated } = useAuth();
    const hasInvalidated = false;
    return (React.createElement("div", null,
        React.createElement(PrimaryBanner, null),
        React.createElement("div", { id: "appContent" },
            React.createElement(MainMenu, null),
            React.createElement(ContentContainer, null,
                React.createElement(Outlet, null)),
            React.createElement(Toast, null),
            hasInvalidated && React.createElement(LoggedOutModal, null),
            React.createElement(ScrollRestoration, null))));
};
export { DefaultLayout };
