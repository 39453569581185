import { TabletMaxWidth } from "constants/mobileBreakpointConstants";
import React, { useState } from "react";
import styled from "styled-components";
import { AddUserModal } from "modules/settings/pages/Users/components/AddUserModal";
import { Button } from "components/fl-ui";
import { Borders, Spacing } from "components/fl-ui/constants";
const UserSettingsPageHeader = styled(({ className }) => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "content-left" },
            React.createElement("h1", null, "Add users to your farm"),
            React.createElement("p", null, "Adding users to your farm can help you and your team collaborate easily and stay in sync.")),
        React.createElement("div", { className: "content-right" },
            React.createElement(Button, { color: "primary", onClick: () => setShowAddUserModal(true) }, "Add User"),
            showAddUserModal && React.createElement(AddUserModal, { onClose: () => setShowAddUserModal(false) }))));
}) `
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${Borders.regular};

  .content-left {
    flex: 1;

    p {
      padding: ${Spacing.spacing12} 0;
    }
  }

  .content-right {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: ${TabletMaxWidth}) {
    flex-direction: column;
    align-items: flex-start;

    .content-left {
      order: 2;
      width: 100%;
    }

    .content-right {
      order: 1;
      width: 100%;
      justify-content: flex-start;
      margin-bottom: ${Spacing.spacing8};
    }
  }
`;
export { UserSettingsPageHeader };
