import { css, StyleSheet } from "aphrodite/no-important";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import * as yup from "yup";

import "lib/validation/yupPolyfill";

import { NumericInput } from "components/fl-ui/Form";
import Icon from "components/fl-ui/Icons/Icon";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Mixins, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  basis_container: {
    position: "relative !important",
  },
  prefix_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: Spacing.spacing4,
    marginRight: Spacing.spacing4,
  },
  input_button: {
    display: "flex",
    alignItems: "center",
    height: Mixins.toRem(32),
    backgroundColor: GreyColors.smoke90,
    borderRadius: BorderRadius.small,
    marginRight: Spacing.spacing4,
    padding: `${Spacing.spacing8} ${Spacing.spacing4}`,
  },
  sign_icon: {
    width: Mixins.toRem(20),
  },
  toggle_icon: {
    width: Mixins.toRem(12),
  },
  dropdown_container: {
    position: "absolute",
    zIndex: "100",
    top: Mixins.toRem(72),
    borderRadius: BorderRadius.medium,
    boxShadow: `0 2px 4px 0 ${UIColors.shadowOne}`,
  },
  dropdown_item: {
    minWidth: Mixins.toRem(315),
    background: UIColors.white,
    border: `1px ${GreyColors.smoke90} solid`,
    borderRadius: `${BorderRadius.medium} ${BorderRadius.medium} 0 0`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: Spacing.spacing16,
    ":last-child": {
      borderRadius: `0 0 ${BorderRadius.medium} ${BorderRadius.medium}`,
      borderTop: "transparent",
    },
  },
  dropdown_item_isSelectable: {
    cursor: "pointer",
  },
  dropdown_icon: {
    padding: Spacing.spacing4,
    width: Mixins.toRem(34),
    height: Mixins.toRem(34),
    color: UIColors.white,
    backgroundColor: UIColors.medium,
    borderRadius: BorderRadius.small,
  },
  dropdown_icon_isSelectable: {
    backgroundColor: UIColors.primary,
  },
  dropdown_text: {
    flexGrow: 2,
    marginLeft: Spacing.spacing12,
    marginRight: Spacing.spacing16,
  },
  dropdown_itemTitle_isSelectable: {
    color: UIColors.primary,
  },
  isActive_icon: {
    width: Mixins.toRem(20),
    height: Mixins.toRem(20),
    color: UIColors.medium,
  },
  noMargin: {
    margin: 0,
  },
  helpText: {
    ...Typography.helpText,
    marginTop: Spacing.spacing8,
    marginBottom: 0,
  },
  helpTextLink: {
    color: `${UIColors.blue}`,
    textDecoration: "none",
  },
});

const POS = "+";
const NEG = "-";

const schema = yup.number().required().precision({ max: 2 });
const isValidBasis = (basis) => schema.isValidSync(basis);

const BasisInputDropdownTrigger = ({ disabled, setDropdownVisibility, showDropDown, sign }) => {
  const toggle = disabled ? () => {} : () => setDropdownVisibility((shown) => !shown);
  return (
    <div className={css(styles.prefix_container)} onClick={toggle}>
      <div className={css(styles.input_button)} style={{ cursor: disabled ? "default" : "pointer" }}>
        <div className={css(styles.sign_icon)}>
          <Icon icon={sign === POS ? "circleAdd" : "circleRemove"} />
        </div>
        <div className={css(styles.toggle_icon)}>
          <Icon icon={showDropDown ? "chevronUp" : "chevronDown"} />
        </div>
      </div>
      <div className={css(styles.input_currency)}>$</div>
    </div>
  );
};

const BasisInputDropdown = ({ onChange, sign }) => {
  return (
    <div className={css(styles.dropdown_container)}>
      <BasisInputDropdownItem
        icon={NEG}
        isActive={sign === NEG}
        onClick={onChange}
        text="Basis is almost always negative"
        title="Negative basis"
      />

      <BasisInputDropdownItem
        icon={POS}
        isActive={sign === POS}
        onClick={onChange}
        text="Basis is rarely positive"
        title="Positive basis"
      />
    </div>
  );
};

const BasisInputDropdownItem = ({ onClick, icon, title, text, isActive }) => {
  return (
    <div
      className={css(styles.dropdown_item, !isActive && styles.dropdown_item_isSelectable)}
      onClick={() => onClick(icon)}
    >
      <div className={css(styles.dropdown_icon, !isActive && styles.dropdown_icon_isSelectable)}>
        <Icon icon={icon === POS ? "circleAdd" : "circleRemove"} />
      </div>
      <div className={css(styles.dropdown_text)}>
        <h3 className={css(styles.noMargin, !isActive && styles.dropdown_itemTitle_isSelectable)}>{title}</h3>
        <p className={css(styles.noMargin)}>{text}</p>
      </div>
      {isActive && (
        <div className={css(styles.isActive_icon)}>
          <Icon icon="check" />
        </div>
      )}
    </div>
  );
};

const BasisInput = ({ disabled = false, helpText = "", onChange, step = 0.01, suffix, value }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [sign, setSign] = useState(NEG);
  const containerRef = useRef();
  const displayValue = (value + "").replace(/^-/, "");

  const getInput = () => {
    return containerRef.current.querySelector("input");
  };

  const handleBlur = (event) => {
    if (event.target.value === ".") {
      event.target.value = "";
      triggerChangeEvent();
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const handleSignChange = (sign) => {
    setSign(sign);
    setShowDropDown(false);
    if (isValidBasis(value) && value !== 0) {
      const input = getInput();
      if (sign === NEG) {
        input.value = "-" + input.value;
      }

      triggerChangeEvent();
    }
  };

  const handleChange = (event) => {
    if (isValidBasis(event.target.value) && sign === NEG) {
      event.target.value = NEG + event.target.value;
    }
    onChange(event);
  };

  const triggerChangeEvent = () => {
    const changeEvent = new CustomEvent("change", {
      bubbles: true,
    });
    getInput().dispatchEvent(changeEvent);
    onChange(changeEvent);
  };

  const onPaste = (event) => {
    if (!isValidBasis(event.clipboardData.getData("text/plain"))) {
      event.preventDefault();
      return false;
    }
  };

  return (
    <div className={css(styles.basis_container)} ref={containerRef}>
      <NumericInput
        display="block"
        maxPrecision={2}
        name="basis"
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
        placeholder="0.00"
        prefix={
          <BasisInputDropdownTrigger
            disabled={disabled}
            setDropdownVisibility={setShowDropDown}
            showDropDown={showDropDown}
            sign={sign}
          />
        }
        size="large"
        step={step}
        suffix={suffix}
        type="float"
        value={displayValue}
      />
      {helpText && <p className={css(styles.helpText)}>{helpText}</p>}
      {showDropDown && <BasisInputDropdown onChange={handleSignChange} sign={sign} />}
    </div>
  );
};

BasisInput.propTypes = {
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default BasisInput;
