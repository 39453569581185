import { useQuery } from "@apollo/client";
import { sortBy, partition } from "lodash";
import { useMemo } from "react";
import getMachineDataProductMatches from "collection/graphql/integrations/queries/getMachineDataProductMatches";
const useSortedProductMatches = () => {
    const { data, loading } = useQuery(getMachineDataProductMatches);
    const matches = useMemo(() => {
        var _a;
        const sortedMatches = sortBy((_a = data === null || data === void 0 ? void 0 : data.allMatches) !== null && _a !== void 0 ? _a : [], "search");
        const [withMatches, withoutMatches] = partition(sortedMatches, (match) => match.expectedProduct || match.matchedProduct);
        return [...withoutMatches, ...withMatches];
    }, [data === null || data === void 0 ? void 0 : data.allMatches]);
    return {
        loading,
        matches,
    };
};
export { useSortedProductMatches };
