import { StyleSheet } from "aphrodite";
import { PrimaryMaxWidth } from "constants/mobileBreakpointConstants";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  blueText: {
    color: UIColors.blue,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardBody: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  cardColumn: {
    marginBottom: Spacing.spacing16,
  },
  cardHeader: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.spacing4,
  },
  colStyles: {
    alignItems: "center",
    display: "flex",
  },
  copyText: {
    fontSize: Typography.sizes.rg,
    lineHeight: 1.3,
  },
  errorColumn: {
    alignItems: "center",
    display: "flex",
    padding: Spacing.spacing16,
  },
  errorContainer: {
    background: "rgb(255, 252, 251)",
    border: "1px solid rgb(227, 104, 76)",
    borderRadius: BorderRadius.medium,
    margin: `0 ${Spacing.spacing4} ${Spacing.spacing16}`,
  },
  errorText: {
    color: UIColors.red,
    display: "block",
    fontSize: Typography.sizes.mn,
    marginBottom: Spacing.spacing8,
    marginTop: Spacing.spacing8,
  },

  footerButtons: {
    display: "flex",
    flexFlow: "row-reverse",
    justifyContent: "space-between",
  },
  icon: {
    color: UIColors.red,
    marginRight: Spacing.spacing16,
    minWidth: Spacing.spacing32,
    width: Spacing.spacing32,
  },
  lightText: {
    color: UIColors.light,
  },
  mediumSpacedText: {
    lineHeight: Spacing.spacing20,
  },
  noMarginBottom: { marginBottom: 0 },
  reverseRow: {
    flexFlow: "row-reverse",
  },
  rightAligned: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rightCol: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  rightMargin: {
    marginRight: Spacing.spacing16,
  },
  rowBorderBottom: {
    borderBottom: Borders.regular,
    marginBottom: Spacing.spacing16,
    marginTop: Spacing.spacing8,
    paddingBottom: Spacing.spacing16,
  },
  rowBottomMargin: {
    "@media (max-width: 62.5rem)": {
      marginBottom: Spacing.spacing14,
    },
  },
  summary: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
  },
  summaryRight: {
    alignItems: "flex-end",
    marginLeft: Spacing.spacing32,
  },

  userModalBlurb: {
    borderBottom: `1px solid ${GreyColors.smoke90}`,
    lineHeight: "1.5em",
    marginBottom: Spacing.spacing16,
    paddingBottom: Spacing.spacing16,
  },
});

export const CopyText = styled.p`
  display: inline-block;
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.regular};
  margin-bottom: ${Spacing.spacing4};
`;

export const CancellationText = styled(CopyText)`
  color: ${UIColors.red};
`;

export const TitleText = styled(CopyText)`
  font-weight: ${Typography.weights.medium};
`;
export const HeaderText = styled(TitleText)`
  font-size: ${Typography.sizes.lg};
`;
export const Link = styled(RouterLink)`
  color: ${UIColors.blue};
  text-decoration: none;
`;
export const PlaceholderText = styled.p`
  padding-bottom: ${Spacing.spacing16};
  text-align: center;
  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    border-bottom: ${Borders.regular};
    margin-bottom: ${Spacing.spacing32};
    text-align: left;
  }
`;
export const SectionContainer = styled.div`
  margin-bottom: ${Spacing.spacing48};
  @media only screen and (max-width: ${PrimaryMaxWidth}) {
    border-bottom: ${Borders.regular};
    margin-bottom: ${Spacing.spacing28};
    padding-bottom: ${Spacing.spacing28};
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`;
export const TableContainer = styled.div`
  overflow-x: auto;
`;
export const TrialText = styled(CopyText)`
  color: ${UIColors.green};
`;

export default styles;
