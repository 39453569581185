import styled from "styled-components";
import { GreyColors } from "components/fl-ui/colors";
import { Typography } from "components/fl-ui/constants";
import React from "react";
const WalletStyledContentArea = styled(({ className, children }) => {
    return React.createElement("div", { className: className }, children);
}) `
  h2 {
    color: ${GreyColors.smoke40};
  }

  h4 {
    font-size: ${Typography.sizes.lg};
    font-weight: ${Typography.weights.light};
    color: rgba(23, 24, 28, 1);
  }

  .bottom-grey-text {
    color: rgba(143, 150, 163, 1);
    font-weight: ${Typography.weights.light};
  }
`;
export { WalletStyledContentArea };
