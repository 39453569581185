import React from "react";
import { AddUserForm } from "modules/settings/pages/Users/components/forms/AddUserForm";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
export const AddUserModal = ({ className, onClose }) => (React.createElement(Modal, { width: 500, hideModal: undefined, id: "add-user-modal", className: className },
    React.createElement(ModalHeader, null,
        React.createElement(ModalTitle, null, "Add New User"),
        React.createElement(CloseX, { onClick: () => onClose() })),
    React.createElement(ModalBody, null,
        React.createElement(AddUserForm, { close: () => onClose() }))));
export default {
    AddUserModal,
};
