import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import withActivityTypes from "collection/graphql/activities/withActivityTypes";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import ActivityTypeAddEditModal from "modules/settings/components/ActivityTypeAddEditModal";
import SelectionList from "modules/settings/components/SelectionList";
import { getSortedTypes } from "modules/settings/utils";
import ActivitiesBlankSlatePage from "components/advertisements/pages/ActivitiesBlankSlatePage";
const ActivitySettings = ({ data }) => {
    const { canRead } = usePermissions();
    const [showModal, setShowModal] = useState(false);
    const [activityData, setActivityData] = useState(null);
    const { activityTypes, categories } = data;
    const [archivedActivityTypes, activeActivityTypes] = getSortedTypes(activityTypes);
    const hasActivitiesFeature = useEnterpriseFeature("activities");
    const navigate = useNavigate();
    const handleClose = () => {
        setActivityData(null);
        setShowModal(false);
    };
    const handleCreateData = () => {
        setActivityData({ name: "", operationalCostRate: "PER_ACRE" });
        setShowModal(true);
    };
    const handleEditData = (type) => {
        setActivityData(type);
        setShowModal(true);
    };
    if (!canRead("activity_types")) {
        navigate("/settings", { replace: true });
        return null;
    }
    if (!hasActivitiesFeature) {
        return React.createElement(ActivitiesBlankSlatePage, null);
    }
    return (React.createElement(Fragment, null,
        React.createElement(SelectionList, { buttonText: "Add activity type", copy: "Customize activity types and set operational costs for your field work.", handleCreateData: handleCreateData, handleEditData: handleEditData, header: "Configure activities", list: activeActivityTypes, placeholder: "You haven\u2019t created any activity types yet" }),
        React.createElement(SelectionList, { copy: "Archived activity types will no longer display when filtering, editing, or creating activities. Any activities that have an archived type will be unaffected by this change.", handleEditData: handleEditData, header: "Archived activity types", list: archivedActivityTypes, placeholder: "You have no archived activity types", secondary: true }),
        showModal && (React.createElement(ActivityTypeAddEditModal
        // @ts-ignore
        , { 
            // @ts-ignore
            activityTypes: activityTypes, categories: categories, data: activityData, onClose: handleClose }))));
};
// @ts-ignore
const ActivitiesSettingsPage = withActivityTypes(ActivitySettings);
export { ActivitiesSettingsPage };
