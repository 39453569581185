import { useQuery } from "@apollo/client";
import getIntegrations from "collection/graphql/integrations/queries/getIntegrations";
import usePermissions from "hooks/usePermissions";
const useIntegrations = () => {
    const { canRead } = usePermissions();
    const { data, loading } = useQuery(getIntegrations, {
        skip: !canRead("integrations"),
    });
    return {
        integrations: data === null || data === void 0 ? void 0 : data.integrations,
        loading,
    };
};
export default useIntegrations;
