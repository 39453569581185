import { number, object, string } from "yup";
const MobileForcedAppUpdatePlatformDetailsSchema = object({
    deprecatedRange: string().required(),
    expiredRange: string().required(),
}).defined();
const MobileForcedAppUpdateConfigSchema = object({
    android: MobileForcedAppUpdatePlatformDetailsSchema.required(),
    deprecatedAlertCoolDownSeconds: number(),
    ios: MobileForcedAppUpdatePlatformDetailsSchema.required(),
}).defined();
export default MobileForcedAppUpdateConfigSchema;
