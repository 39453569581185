import React from "react";
import { Navigate } from "react-router-dom";
import { WalletOverview } from "modules/wallet/components/WalletOverview";
import { WalletRequests } from "modules/wallet/components/WalletRequests";
import { WalletTransactions } from "modules/wallet/components/WalletTransactions";
import { useWalletConnectionStatus } from "modules/wallet/utils/useWalletConnectionStatus";
import NavBar from "components/fl-ui/NavBar";
const WalletDashboard = () => {
    const walletConnectionStatus = useWalletConnectionStatus();
    if (walletConnectionStatus == "WALLET_AVAILABLE_NOT_CONNECTED") {
        return React.createElement(Navigate, { replace: true, to: "/wallet/not-connected" });
    }
    if (walletConnectionStatus == "WALLET_SETUP_INCOMPLETE") {
        return React.createElement(Navigate, { replace: true, to: "/wallet/finish-setup" });
    }
    if (walletConnectionStatus == "WALLET_ACCOUNT_LOCKED") {
        return React.createElement(Navigate, { replace: true, to: "/wallet/account-locked" });
    }
    return (React.createElement(NavBar, { tabsArray: [
            {
                component: React.createElement(WalletOverview, null),
                label: "Overview",
                route: "overview",
            },
            {
                component: React.createElement(WalletRequests, null),
                label: "Requests (4)",
                route: "requests",
            },
            {
                component: React.createElement(WalletTransactions, null),
                label: "Transactions",
                route: "transactions",
            },
        ] }));
};
export { WalletDashboard };
