import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "collection/graphql/auth";
import LoadingSpinner from "components/ui/loading";
const FinishSSOFlow = () => {
    const { currentUser, loading } = useAuth();
    if (loading || !currentUser) {
        return React.createElement(LoadingSpinner, null);
    }
    const pathToRedirectAfterLogin = localStorage.getItem("redirectAfterLogin");
    if (pathToRedirectAfterLogin) {
        localStorage.removeItem("redirectAfterLogin");
        return React.createElement(Navigate, { replace: true, to: pathToRedirectAfterLogin });
    }
    if (currentUser.landingPage) {
        return React.createElement(Navigate, { replace: true, to: "/" + currentUser.landingPage });
    }
    /*
    TODO:
     After wallet deploys (and wallet-enabled is live)
     uncomment this and delete the /fields route navigation below.
     We always want to force users to the sso-wallet-survey and fill out a
     landing page if they don't have one already.
    return <Navigate replace to="/sso-wallet-survey" />;
     */
    return React.createElement(Navigate, { replace: true, to: "/fields" });
};
export { FinishSSOFlow };
