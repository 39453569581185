import { gql } from "@apollo/client";

import EditableBaseContract from "collection/graphql/contracts/fragments/EditableBaseContract";

export default gql`
  fragment EditableOptionsContract on OptionsContract {
    ...EditableBaseContract
    contractSubtypeOptions: contractType
    currentMarketPremium
    expirationDate
    fees
    premium
    quantity
    revenueImpact
    strikePrice
  }

  ${EditableBaseContract}
`;
