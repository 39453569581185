import { useSuspenseQuery } from "@apollo/client";
import useDocumentTitle from "hooks/useDocumentTitle";
import _ from "lodash";
import NoteCard from "notes/components/NoteCard";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import getAllNotes from "collection/graphql/notes/queries/getAllNotes";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import { Container, Content, ContentMain, ContentSidebar, Header } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";
import Sidebar from "components/ui/sidebar";
import { withBasicFields } from "fields/hooks/useFieldData";

const ScoutingIndexPage = withBasicFields(({ fields }) => {
  const [filters, setFilters] = useState({ commodity_id: null });
  const { notes } = useSuspenseQuery(getAllNotes).data;
  const year = useCurrentCropYear()[0];
  const { getCropById } = useYearFieldCrops();
  const { currentEnterprise } = useCurrentEnterprise();
  useDocumentTitle("Scouting list");

  const fieldsById = useMemo(() => _.keyBy(fields, "id"), [fields]);

  const filteredNotes = useMemo(() => {
    const getNotesYear = ({ cropId, date }) => (cropId ? getCropById(cropId)?.cropYear : +date.substring(0, 4));

    return notes
      .filter((note) => note.enterpriseId === currentEnterprise.id)
      .filter((note) => getNotesYear(note) === year)
      .filter(({ cropId }) => {
        return !filters.commodity_id || getCropById(cropId)?.commodity?.id === filters.commodity_id;
      });
  }, [currentEnterprise, filters, getCropById, notes, year]);

  const { loadingAccess, showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "scouting",
    permissionName: "activities",
  });

  if (showRestricted) {
    return <RestrictedAccessWithHeader title="Scouting" />;
  }

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        {showPayGate && (
          <>
            <Header title="Scouting" />
            <PayGateAdBanner
              customBodyText="Log geotagged notes and photos while scouting. Keep detailed records, track issues, and securely share field insights when it matters most."
              customTitleText="Capture & Share Field Insights"
              featureName="Scouting"
              planIncludedIn="Lite"
            />
          </>
        )}

        {showContent && (
          <>
            <Header
              title="Scouting"
              description={`${filteredNotes.length} ${filteredNotes.length === 1 ? "note" : "notes"}`}
            >
              <CropYearPicker />
            </Header>
            <Content>
              <ContentSidebar>
                <Link to="/scouting/add" className="btn btn-block">
                  Add Note
                </Link>

                <Sidebar filterState={filters} onChange={(filters) => setFilters(filters)} year={year} />
              </ContentSidebar>

              <ContentMain>
                <div className="note-list xs-block-grid-1 md-block-grid-2">
                  {filteredNotes.length === 0 && (
                    <div className="blank-slate">
                      <h2>No scouting notes added in {year}</h2>
                    </div>
                  )}

                  {filteredNotes.map((note) => (
                    <NoteCard key={note.id} fieldName={fieldsById[note.fieldId]?.name ?? ""} note={note} />
                  ))}
                </div>
              </ContentMain>
            </Content>
          </>
        )}
      </LoadingWrapper>
    </Container>
  );
});

export default ScoutingIndexPage;
