import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";
import { useMarketingPageContext } from "context/MarketingPageContext";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import MarketingBlankSlatePage from "components/advertisements/pages/MarketingBlankSlatePage";
import { Button } from "components/fl-ui";
import { BlankSlate } from "components/fl-ui/BlankSlate";
import Icon from "components/fl-ui/Icons/Icon";
import { Typography } from "components/fl-ui/constants";
const AddCropBlankSlate = () => {
    const styles = StyleSheet.create({
        blankSlateHeaderText: {
            fontSize: Typography.sizes.xl,
            fontWeight: 500,
            margin: "1em 0 0",
        },
        blankSlateBodyText: {
            lineHeight: "1.5em",
            margin: "1em 0",
            fontSize: Typography.sizes.lg,
        },
        blankSlateImage: {
            maxWidth: Typography.sizes.xxxl,
            margin: "auto",
        },
    });
    // @ts-ignore
    const { openCropEditModal } = useMarketingPageContext();
    return !useEnterpriseFeature("grain_marketing") ? (React.createElement(MarketingBlankSlatePage, null)) : (React.createElement(BlankSlate, null,
        React.createElement("div", null,
            React.createElement(Icon, { className: css(styles.blankSlateImage), icon: "dollar" }),
            React.createElement("p", { className: css(styles.blankSlateHeaderText) }, `You haven't added any crops yet`),
            React.createElement("p", { className: css(styles.blankSlateBodyText) }, `You haven't added any crops yet. As you add crops, they'll appear here.
          Add a new crop to get started.`)),
        React.createElement(Button, { color: "primary", id: "add-new-crop-button", onClick: openCropEditModal, size: "lg" }, "Add a Crop")));
};
export { AddCropBlankSlate };
