import React from "react";
import styled from "styled-components";
import { Icon, IconContainer } from "components/fl-ui/Icons";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography } from "components/fl-ui/constants";
const Enterprise = ({ className, membership, onSelect, selected }) => {
    const { enterprise, role } = membership;
    return (React.createElement("div", { className: `${className} ${selected ? "selected" : ""}`, onClick: () => {
            onSelect(membership);
        } },
        React.createElement("div", { className: "icon-area" },
            React.createElement(IconContainer, { size: Spacing.spacing24 },
                React.createElement(Icon, { icon: "farm" }))),
        React.createElement("div", { className: "text-area" },
            React.createElement("h4", null, enterprise.name || "Unnamed"),
            React.createElement("p", null, role.title))));
};
export default styled(Enterprise) `
  align-items: center;
  border: ${Borders.regular};
  border-radius: ${BorderRadius.large};
  display: inline-flex;
  margin: ${Spacing.spacing8} auto;
  width: 100%;

  &.selected {
    border: 1px solid ${UIColors.blue} !important;
  }

  &:hover {
    border: 1px solid ${UIColors.primary};
    cursor: pointer;
  }

  .icon-area {
    width: 60px;
    text-align: center;
  }

  .text-area {
    padding: ${Spacing.spacing16} 0;

    h4 {
      font-weight: ${Typography.weights.medium};
      margin: ${Spacing.spacing8} 0;
    }

    p {
      color: ${GreyColors.smoke60};
      font-size: ${Typography.sizes.small};
      margin: ${Spacing.spacing2} 0;
    }
  }

  div p {
    margin: ${Spacing.spacing8};
  }
`;
