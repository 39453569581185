import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import getCurrentSubscription from "collection/graphql/subscription/getCurrentSubscription";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import useReleaseFeature from "hooks/useReleaseFeature";
const useMainMenuFeatures = () => {
    const { currentEnterprise } = useCurrentEnterprise();
    const hasWallet = useReleaseFeature("wallet-enabled");
    const { data: subscriptionData, loading: currentSubscriptionLoading } = useQuery(getCurrentSubscription);
    const currentSubscription = subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.subscription;
    function getEnabled(featureId) {
        if (currentSubscription && !currentSubscription.isSubscribed) {
            // Non-paying users should see all features
            return true;
        }
        return currentEnterprise.features.includes(featureId);
    }
    return useMemo(() => {
        if (currentSubscriptionLoading) {
            return [];
        }
        const mainMenuFeatures = [
            {
                enabled: getEnabled("fields"),
                icon: "fields",
                id: "fields",
                title: "Fields",
                to: "/fields",
            },
            {
                enabled: getEnabled("fields"),
                icon: "map",
                id: "map",
                title: "Map",
                to: "/map",
            },
            {
                enabled: getEnabled("contracts"),
                icon: "contracts",
                id: "contracts",
                title: "Contracts",
                to: "/contracts",
            },
            {
                enabled: getEnabled("activities"),
                icon: "activities",
                id: "activities",
                title: "Activities",
                to: "/activity",
            },
            {
                enabled: getEnabled("profit_and_loss"),
                icon: "profitAndLoss",
                id: "profitAndLoss",
                title: "Profit & Loss",
                to: "/profit_and_loss",
            },
            {
                enabled: getEnabled("monthly_rainfall_summary_report"),
                icon: "reports",
                id: "reports",
                title: "Reports",
                to: "/reports",
            },
            {
                enabled: getEnabled("land_costs"),
                icon: "landCosts",
                id: "landCosts",
                title: "Land Costs",
                to: "/land_costs",
            },
            {
                enabled: getEnabled("grain_marketing"),
                icon: "marketing",
                id: "marketing",
                title: "Marketing",
                to: "/marketing",
            },
            {
                enabled: getEnabled("scouting"),
                icon: "scouting",
                id: "scouting",
                title: "Scouting",
                to: "/scouting",
            },
            {
                enabled: getEnabled("market_prices"),
                icon: "marketingPrices",
                id: "marketingPrices",
                title: "Market Prices",
                to: "/prices",
            },
            {
                enabled: getEnabled("products"),
                icon: "inputs",
                id: "inputs",
                title: "Inputs",
                to: "/products",
            },
            {
                enabled: getEnabled("equipment"),
                icon: "equipment",
                id: "equipment",
                title: "Equipment",
                to: "/equipment",
            },
            {
                enabled: getEnabled("legacy_storage_and_loads"),
                icon: "storage",
                id: "storage",
                title: "Storage",
                to: "/inventory",
            },
            {
                enabled: getEnabled("legacy_storage_and_loads"),
                icon: "loads",
                id: "loads",
                title: "Loads",
                to: "/loads",
            },
            {
                enabled: getEnabled("profit_and_loss"),
                icon: "expense",
                id: "expense",
                showRouteAsActive: "/other_expenses_and_revenue",
                title: "Other Expenses & Rev",
                to: "/other_expenses_and_revenue/expenses",
            },
            {
                enabled: getEnabled("crop_health_enterprise"),
                icon: "imagery",
                id: "imagery",
                title: "Satellite Imagery",
                to: "/imagery",
            },
        ];
        // wallet feature is only included if release flag turned on
        if (hasWallet) {
            mainMenuFeatures.push({
                enabled: true,
                icon: "wallet",
                id: "wallet",
                label: "NEW",
                showRouteAsActive: "/wallet",
                title: "Wallet",
                to: "/wallet/dashboard/overview",
            });
        }
        return mainMenuFeatures;
    }, [currentEnterprise, subscriptionData, hasWallet]);
};
export { useMainMenuFeatures };
