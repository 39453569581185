import { css } from "aphrodite";
import { omit } from "lodash";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styles from "settings/styles";
import { hasEmptyRequiredField, isInvalidName } from "settings/utils";

import {
  withActivityTypeArchive,
  withActivityTypeUnarchive,
} from "collection/graphql/activities/withActivityTypeArchive";
import withActivityTypeCreate from "collection/graphql/activities/withActivityTypeCreate";
import withActivityTypeUpdate from "collection/graphql/activities/withActivityTypeUpdate";
import usePermissions from "hooks/usePermissions";

import { Button } from "components/fl-ui";
import { FormGroup, Input, RateSelector, Select } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";

const ActivityTypeAddEditModal = (props) => {
  const [data, setData] = useState(props.data);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const canWriteActivityTypeCosts = usePermissions().canWrite("activity_type_costs");
  const { category, id, isArchived, isDefault, name, operationalCost, operationalCostRate } = data;
  const {
    activityTypes,
    archiveActivityType,
    categories = [],
    createActivityType,
    onClose,
    unarchiveActivityType,
    updateActivityType,
  } = props;

  const categoryList = categories
    .filter(({ data }) => data !== "UNKNOWN" && data !== "OTHER")
    .map(({ data, display }) => ({ id: data, label: display, value: data }));

  const handleArchiveAction = () => {
    const archiveAction = isArchived ? unarchiveActivityType : archiveActivityType;
    archiveAction(id);
    onClose();
  };

  const handleChange = (value) => setData({ ...data, ...value });

  const handleSave = async () => {
    setIsSaving(true);

    if (hasEmptyRequiredField(data)) {
      setErrors({ required: true });
      setIsSaving(false);
      return null;
    }

    if (isInvalidName(activityTypes, data)) {
      setErrors({ name: true });
      setIsSaving(false);
      return null;
    }

    if (id) {
      await updateActivityType(omit(data, "__typename", "isDefault"));
    } else {
      await createActivityType(data);
    }

    return onClose();
  };

  const hasCostError = operationalCost < 0;
  const placeholderText = "Select...";
  const requiredMessage = "This field is required";

  return (
    <Modal width={500}>
      <ModalHeader>
        <ModalTitle>{`${id ? "Edit" : "Add"} activity type`}</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col xs md={6}>
            <FormGroup label="Name">
              <Input
                disabled={isDefault}
                hasError={errors.name || (errors.required && !name)}
                onChange={({ target }) => {
                  handleChange({ name: target.value });
                  setErrors({ name: false });
                }}
                value={name}
              />
              {errors.required && !name && <p className={css(styles.errorText)}>{requiredMessage}</p>}
              {errors.name && <p className={css(styles.errorText)}>This activity name already exists</p>}
            </FormGroup>
          </Col>

          <Col xs md={6}>
            <FormGroup label="Category">
              <Select
                disabled={isDefault}
                hasError={errors.required && !category}
                onChange={({ target }) => target.value !== placeholderText && handleChange({ category: target.value })}
                options={categoryList}
                placeholder={placeholderText}
                value={category}
              />
              {errors.required && !category && <p className={css(styles.errorText)}>{requiredMessage}</p>}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs>
            <FormGroup
              label={
                <p className={css(styles.noMarginBottom)}>
                  Operational cost <span className={css(styles.lightText)}>(optional)</span>
                </p>
              }
            >
              <p className={css(styles.noMarginBottom, styles.mediumSpacedText)}>
                Operational costs cover operational expenses (fuel, labor, etc). Input costs are automatically
                calculated based on Product prices and application rates.
              </p>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs md={6}>
            <Input
              disabled={!canWriteActivityTypeCosts}
              hasError={hasCostError}
              onChange={({ target }) => handleChange({ operationalCost: target.value })}
              prefix="$"
              suffix={
                canWriteActivityTypeCosts && (
                  <RateSelector
                    onClick={(operationalCostRate) => handleChange({ operationalCostRate })}
                    selected={operationalCostRate}
                  />
                )
              }
              type="number"
              value={!operationalCost && operationalCost !== 0 ? "" : operationalCost}
            />
            {hasCostError && <p className={css(styles.errorText)}>Enter a positive value</p>}
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <div className={css(styles.footerButtons)}>
          <div>
            <Button className={css(styles.rightMargin)} color="primary" link onClick={onClose}>
              Cancel
            </Button>
            <Button color="primary" disabled={hasCostError || isSaving} onClick={handleSave}>
              Save
            </Button>
          </div>
          {id && (
            <Button color="danger" link onClick={handleArchiveAction}>
              {`${isArchived ? "Unarchive" : "Archive"} activity type`}
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};


export default withActivityTypeUnarchive(
  withActivityTypeArchive(withActivityTypeCreate(withActivityTypeUpdate(ActivityTypeAddEditModal)))
);
