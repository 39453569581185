import { css } from "aphrodite";
import _ from "lodash";
import React, { useMemo, useState } from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import { Button } from "components/fl-ui";
import FilterActionButtonGroup from "components/fl-ui/Filters/FilterActionButtonGroup";
import { TagSelectGroup, Select, FormGroup } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "components/fl-ui/Modal/Modal";
import styles from "fields/common/styles";
import useFieldData from "fields/hooks/useFieldData";

const irrigationOptions = [
  { label: "Irrigated", value: "irrigation_true" },
  { label: "Not irrigated", value: "irrigation_false" },
];

const providersOptions = [
  { label: "Climate Field View", value: "CLIMATE" },
  { label: "John Deere Operations Center", value: "JOHN_DEERE" },
];

const toOptions = (collection, key) => {
  collection = _.map(collection, key)
    .filter((x) => x)
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  return _.sortBy(_.uniqBy(collection, "value"), "label");
};

const FilterModal = ({ onClose, onFilterReset, onSubmit, options, selected, selectedFilters, year }) => {
  const { fields } = useFieldData();
  const { crops, loading } = useYearFieldCrops(year);

  const [selectedParams, setSelectedParams] = useState({
    ...selectedFilters,
    viewType: selected,
  });

  const commodityOptions = useMemo(() => toOptions(crops, "commodity"), [crops, loading]);
  const groupOptions = useMemo(() => toOptions(fields, "group"), [fields]);
  const hasMachineData = useEnterpriseFeature("machine_data");

  const handleApplyClick = () => {
    onSubmit({ selectedParams });
    onClose();
  };

  const handleOnChange = (name, selection) => {
    setSelectedParams({
      ...selectedParams,
      [name]: selection,
    });
  };

  return (
    <Modal width={500}>
      <ModalHeader>
        <ModalTitle>Filters</ModalTitle>
        <CloseX onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <FormGroup className={css(styles.hide_on_desktop)} label="Sort by">
          <Select
            block
            onChange={({ target }) => handleOnChange("sortCriteria", target.value)}
            options={options}
            value={selectedParams.sortCriteria}
          />
        </FormGroup>

        {groupOptions.length > 0 && (
          <TagSelectGroup
            id="groups"
            isMulti
            label="Field groups"
            onChange={(groups) => handleOnChange("groups", groups)}
            options={groupOptions}
            showBulkSelect
            value={selectedParams.groups}
          />
        )}

        {commodityOptions.length > 0 && (
          <TagSelectGroup
            id="commodities"
            isMulti
            label="Commodities"
            onChange={(commodities) => handleOnChange("commodities", commodities)}
            options={commodityOptions}
            showBulkSelect
            value={selectedParams.commodities}
          />
        )}

        <TagSelectGroup
          id="irrigation"
          isMulti
          label="Irrigation"
          onChange={(irrigation) => handleOnChange("irrigation", irrigation)}
          options={irrigationOptions}
          showBulkSelect
          value={selectedParams.irrigation}
        />

        {hasMachineData && (
          <TagSelectGroup
            id="source"
            isMulti
            label="Providers"
            onChange={(providers) => handleOnChange("source", providers)}
            options={providersOptions}
            showBulkSelect
            value={selectedParams.source}
          />
        )}

        <FormGroup className={css(styles.hide_on_desktop)} label="Display mode">
          <div className={css(styles.spacedBetweenContainer)}>
            <Button
              className={css(
                styles.filterModal_viewButton,
                selectedParams.viewType === "cards" && styles.filterModal_viewButton_selected,
              )}
              color={selectedParams.viewType === "cards" ? "primary" : "default"}
              hollow
              icon="cardsLayout"
              onClick={() => handleOnChange("viewType", "cards")}
            >
              Standard
            </Button>
            <Button
              className={css(
                styles.filterModal_viewButton,
                selectedParams.viewType === "list" && styles.filterModal_viewButton_selected,
              )}
              color={selectedParams.viewType === "list" ? "primary" : "default"}
              hollow
              icon="listLayout"
              onClick={() => handleOnChange("viewType", "list")}
            >
              Compact
            </Button>
          </div>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <FilterActionButtonGroup
          apply={{ action: handleApplyClick }}
          cancel={{ action: onClose }}
          reset={{ action: onFilterReset }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
