const ColorPalette = {
  "almost-white": "hsl(240, 11%, 98%)", // #fafafb
  white: "hsl(220, 12%, 95%)", // #f1f2f4
  black: "hsl(220, 10%, 30%)", // #454a54
  "bushel-blue": "#2d3748",
  "light-red": "hsl(11, 35%, 96%)", // #f7b1a1
  "medium-red": "#fed7d7",
  red: "hsl(12, 73%, 59%)", // #E3694C
  "dark-red": "hsl(11, 65%, 49%)", // #CE4B2C
  "red-orange": "hsl(17, 92%, 58%)", // #F76B33
  orange: "hsl(32, 93%, 58%)", // #F79A2F
  "dark-yellow": "hsl(48,  99%, 50%)", //  no equivalent in design spec
  yellow: "hsl(49, 100%, 68%)", // #FFE15C
  "light-yellow": "hsl(49,  100%, 79%)", // #FFEC95
  "seafoam-green": "#c6f6d5",
  "light-green": "hsl(135, 42%, 60%)", // #6dc383
  green: "hsl(135, 43%, 45%)", // #41a259
  "dark-green": "hsl(135, 60%, 42%)", // #2B6C3B
  "sky-blue": "hsl(207, 73%, 97%)", // #F2F8FD
  "light-blue": "hsl(210, 47%, 92%)", // #7BB2EA
  blue: "hsl(210, 76%, 49%)", // #1e7ddc
  "dark-blue": "hsl(210, 75%, 30%)", // #134B84
  purple: "hsl(299, 49%, 66%)", // #D17ED3
  "full-purple": "hsl(299, 32%, 50%)", // #A756A8
  "dark-purple": "hsl(300, 59%, 42%)", // #6C2C6C
  "light-tan": "hsl(30, 30%, 92%)", // #f1ebe5
  tan: "hsl(30, 32%, 85%)", // #e5d9cd
  "dark-tan": "hsl(30, 32%, 60%)", // #b99978
  "light-brown": "hsl(31, 32%, 45%)", // #96734d
  brown: "hsl(30,  30%, 30%)", // no equivalent in design spec
  "dark-brown": "hsl(30,  30%, 20%)", // no equivalent in design spec
  none: "hsl(210, 76%, 49%)", // #4c87c2
  "recommendation-bar-blue": "#61cef1",
  "default-bar-background": "#b0e7f8",
};

export const CropsColorPalette = {
  ...ColorPalette,
  white: "hsl(220, 10%, 98%)", // #f9fafa
  "light-red": "hsl(10, 80%, 80%)", // #f5b1a3
  "dark-green": "hsl(135, 40%, 30%)", // #2e6b3d
  "light-blue": "hsl(210, 70%, 70%)", // #7db3e8
  "light-purple": "hsl(299, 49%, 66%, 1)", // #D17ED3
  purple: "hsl(300, 30%, 50%)", // #a659a6
  "dark-purple": "hsl(300, 40%, 30%)", // #6b2e6b
  none: ColorPalette.black,
  grey: ColorPalette.black,
};

export default ColorPalette;
