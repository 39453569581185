import FuturesContracts from "marketing/tables/FuturesContracts";
import OptionsContracts from "marketing/tables/OptionsContracts";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";

const MarketedCropFuturesAndOptions = ({ marketedCropId, productionStats, year }) => (
  <div>
    <Row>
      <Col xs>
        <FuturesContracts marketedCropId={marketedCropId} productionStats={productionStats.futures} year={year} />
      </Col>
    </Row>

    <Row>
      <Col xs>
        <OptionsContracts marketedCropId={marketedCropId} productionStats={productionStats.options} year={year} />
      </Col>
    </Row>
  </div>
);

MarketedCropFuturesAndOptions.propTypes = {
  marketedCropId: PropTypes.number,
  productionStats: PropTypes.object.isRequired,
  year: PropTypes.number,
};

export default MarketedCropFuturesAndOptions;
