import { Collection } from "backbone";
import _ from "lodash";
import { useEffect, useState } from "react";

const getCollectionData = (collection) => {
  if (!(collection instanceof Collection)) {
    throw new Error("Instance of Backbone.Collection expected");
  } else if (collection.length === 0) {
    return [];
  }

  return collection.toJSON().map((modelData) => {
    return _.transform(
      modelData,
      (output, value, key) => {
        output[_.camelCase(key)] = value;
      },
      {},
    );
  });
};

/**
 * Listens to a given collection for changes and updates accordingly. Returns
 * the camelcased results of the collection's `toJSON` method.
 * @param {Backbone.Collection} collection
 * @return {Object[]}
 */
const useBackboneCollection = (collection) => {
  const [data, setData] = useState(getCollectionData(collection));

  useEffect(() => {
    const checkForUpdates = _.debounce(
      () => {
        setData((existingData) => {
          const newData = getCollectionData(collection);
          return _.isEqual(newData, existingData) ? existingData : newData;
        });
      },
      50,
      { trailing: true },
    );

    collection.on("add change destroy remove reset sort sync sync:complete", checkForUpdates);
    return () => collection.off(null, checkForUpdates);
  }, []);

  return data;
};

export default useBackboneCollection;
