import { css } from "aphrodite/no-important";
import React from "react";
import styles from "components/advertisements/styles";
import { WalletStyledContentArea } from "modules/wallet/components/WalletStyledContentArea";
const WalletCommonBlankSlateBanner = ({ children, title }) => (React.createElement("div", { className: css(styles.banner_container) },
    React.createElement("div", { className: css(styles.banner_content) },
        React.createElement("div", { className: css(styles.banner_textContainer) },
            React.createElement("h1", { className: css(styles.promo_h1, styles.centered_on_mobile) }, title),
            React.createElement(WalletStyledContentArea, null, children)),
        React.createElement("div", { className: css(styles.banner_imgContainer) },
            React.createElement("img", { className: css(styles.banner_img), src: "images/pay_gates/banners/wallet-details.png", alt: "Banner" })))));
export { WalletCommonBlankSlateBanner };
