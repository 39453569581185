import { css } from "aphrodite/no-important";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Col, Grid, Row } from "react-styled-flexboxgrid";
import { useUserConfig } from "collection/graphql/config";
import { ONBOARDING_SEE_MORE } from "lib/metrics/events";
import OnboardingDashboardMain from "modules/fields/onboarding/OnboardingDashboardMain";
import OnboardingDashboardMore from "modules/fields/onboarding/OnboardingDashboardMore";
import styles from "modules/fields/onboarding/onboardingStyles";
import { Logo } from "components/fl-ui/Icons";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import Fullscreen from "components/fl-ui/util/Fullscreen";
const OnboardingDashboard = () => {
    const [showMore, setShowMore] = useState(false);
    const containerRef = useRef(null);
    const userConfig = useUserConfig();
    useEffect(() => {
        if (!userConfig("didOnboarding")) {
            userConfig("didOnboarding", true);
        }
    }, [userConfig]);
    const handleMore = () => {
        var _a;
        ONBOARDING_SEE_MORE.track({});
        setShowMore(true);
        (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
    };
    return (React.createElement(Fullscreen, { className: css(styles.fullscreen), id: "onboarding-dashboard", innerRef: containerRef },
        React.createElement(Grid, { className: css(styles.grid) },
            React.createElement(Row, { className: css(styles.headerContainer) },
                React.createElement(Col, { xs: true },
                    React.createElement("div", { className: css(styles.headerBorder) },
                        React.createElement(Logo, { size: "sm" })))),
            React.createElement(Suspense, { fallback: React.createElement(LoadingWrapper, { isLoading: true }) }, showMore ? React.createElement(OnboardingDashboardMore, null) : React.createElement(OnboardingDashboardMain, { handleMore: handleMore })))));
};
export default OnboardingDashboard;
