import React from "react";
import { ChangeUserAccessLevelForm } from "modules/settings/pages/Users/components/forms/ChangeUserAccessLevelForm";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
const ChangeUserAccessLevelModal = ({ className, onClose, user }) => (React.createElement(Modal, { width: 500, hideModal: undefined, id: "edit-user-modal", className: className },
    React.createElement(ModalHeader, null,
        React.createElement(ModalTitle, null, "Change access level"),
        React.createElement(CloseX, { onClick: () => onClose() })),
    React.createElement(ModalBody, null,
        React.createElement(ChangeUserAccessLevelForm, { onClose: () => onClose(), user: user }))));
export { ChangeUserAccessLevelModal };
