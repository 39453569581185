import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const SoilBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Soil Maps for free" : "Move to Lite today",
        imagePath: "images/pay_gates/banners/soil.png",
        title: "With Soil Maps you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "See each soil type area and elevation features so you can evaluate new ground and find opportunities to improve management on existing fields."),
                React.createElement(ValuePropListItem, null, "Compare soil maps side-by-side with other field layers.")))));
};
export { SoilBlankSlateBanner };
