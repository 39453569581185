import useDocumentTitle from "hooks/useDocumentTitle";
import DebugInfo from "demo/demos/DebugInfo";
import EditProductDemo from "demo/demos/EditProductDemo";
import Flags from "demo/demos/Flags";
import IconDemo from "demo/demos/IconDemo";
import MobilePluginsDemo from "demo/demos/MobilePluginsDemo";
import MobileSubscriptionsDemo from "demo/demos/MobileSubscriptionsDemo";
import PayGateDemos from "demo/demos/PayGateDemos";
import PendoDemo from "demo/demos/PendoDemo";
import PurchaseFormsDemo from "demo/demos/PurchaseFormsDemo";
import ScratchPage from "demo/demos/ScratchPage";
import SentryTestDemo from "demo/demos/SentryTestDemo";
import SessionTestDemo from "demo/demos/SessionTestDemo";
import _ from "lodash";
import React from "react";
import { useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";

import { Container, Content, ContentMain, Header } from "components/fl-ui/Layout";

const Index = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") ?? "scratch";

  const options = [
    { label: "Scratch page", value: "scratch" },
    { label: "SSO Links", value: "ssoLinks" },
    { label: "FARM-8493 session test", value: "session" },
    { label: "Debug info", value: "debugInfo" },
    { label: "Sentry", value: "sentry" },
    { label: "Mobile Plugins", value: "mobilePlugins" },
    { label: "Pendo", value: "pendo" },
    { label: "RevenueCat", value: "mobileSubscriptions" },
    { label: "Flags", value: "flags" },
    { label: "Icons", value: "icons" },
    { label: "Pay gates", value: "payGates" },
    { label: "Purchase forms", value: "purchaseForms" },
    { label: "Product chooser", value: "editProduct" },
  ];
  const selectedOption = _.find(options, { value: currentTab });
  useDocumentTitle(`Demo - ${_.find(options, { value: currentTab }).label}`);

  return (
    <Container>
      <Header title="Demo Page" />
      <Content>
        <ContentMain fullWidth>
          <ReactSelect
            onChange={({ value }) => setSearchParams({ tab: value })}
            options={options}
            value={selectedOption}
          />

          <div style={{ marginTop: "20px" }}>
            {currentTab === "scratch" && <ScratchPage />}
            {currentTab === "session" && <SessionTestDemo />}
            {currentTab === "debugInfo" && <DebugInfo />}
            {currentTab === "sentry" && <SentryTestDemo />}
            {currentTab === "mobilePlugins" && <MobilePluginsDemo />}
            {currentTab === "mobileSubscriptions" && <MobileSubscriptionsDemo />}
            {currentTab === "pendo" && <PendoDemo />}
            {currentTab === "flags" && <Flags />}
            {currentTab === "icons" && <IconDemo />}
            {currentTab === "payGates" && <PayGateDemos />}
            {currentTab === "purchaseForms" && <PurchaseFormsDemo />}
            {currentTab === "editProduct" && <EditProductDemo />}
          </div>
        </ContentMain>
      </Content>
    </Container>
  );
};

export default Index;
