import React from "react";
import { Modal, ModalBody, ModalHeader } from "components/fl-ui/Modal/Modal";
const TwoWaysToAddFieldModal = ({ onClose }) => (React.createElement(Modal, { width: 600, hideModal: undefined, id: "two-ways-to-add-field-modal" },
    React.createElement(ModalHeader, null,
        React.createElement("button", { className: "close-x", onClick: onClose }, `\
  ×\
  `)),
    React.createElement(ModalBody, null,
        React.createElement("div", { className: "text-centered" },
            React.createElement("h2", null, "Two Ways to Add Your Field"),
            React.createElement("div", { style: { padding: "2rem 0" } },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col xs-5" },
                        React.createElement("p", { className: "small-caps" }, "Select A Shape"),
                        React.createElement("img", { style: { width: "130px" }, src: "/images/map/select-shape@2x.png", alt: "Select Shape" })),
                    React.createElement("div", { className: "col xs-2" },
                        React.createElement("div", { style: { margin: "2em 0" } },
                            React.createElement("div", null,
                                React.createElement("div", { style: { marginLeft: "50%", height: "40px" } },
                                    React.createElement("div", { className: "divider" })),
                                React.createElement("span", null,
                                    `\
      or\
      `,
                                    React.createElement("div", { style: { marginLeft: "50%", height: "40px" } },
                                        React.createElement("div", { className: "divider" })))))),
                    React.createElement("div", { className: "col xs-5" },
                        React.createElement("p", { className: "small-caps" }, "Draw Your Own"),
                        React.createElement("img", { style: { width: "130px" }, src: "/images/map/draw-shape@2x.png", alt: "Draw Shape" }))),
                React.createElement("button", { onClick: onClose, className: "btn btn-primary" }, `\
  Get Started\
  `))))));
export default TwoWaysToAddFieldModal;
