const useWalletSettingsInfo = () => {
    // TODO: This is just mock data currently, this will eventually be some data that comes from the wallet team
    return {
        businessName: "Franks Farm",
        dba: "Franks Great Farm",
        handle: "@frankguy",
        address: "1234 Maple Road",
    };
};
export { useWalletSettingsInfo };
