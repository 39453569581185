import React from "react";
import styled from "styled-components";
import { FeatureSectionAdditional } from "components/fl-ui/Layout/MainMenu/SharedList/FeatureSectionAdditional";
import { FeatureSectionPrimary } from "components/fl-ui/Layout/MainMenu/SharedList/FeatureSectionPrimary";
import { GroupHeaderListItem } from "components/fl-ui/Layout/MainMenu/SharedList/GroupHeaderListItem";
import { useMainMenuSharedListMap } from "components/fl-ui/Layout/MainMenu/SharedList/useMainMenuSharedListMap";
import { Spacing } from "components/fl-ui/constants";
const SharedList = styled.ul `
  list-style: none;
  overflow: auto;
  padding-bottom: ${Spacing.spacing120};
`;
const MainMenuSharedList = ({ onMenuFeatureClick }) => {
    const mainMenuSharedListMap = useMainMenuSharedListMap();
    return (React.createElement(SharedList, null,
        [...mainMenuSharedListMap.primaryFeaturesMap.entries()].map(([category, mainMenuFeatures]) => (React.createElement(FeatureSectionPrimary, { category: category, mainMenuFeatures: mainMenuFeatures, onMenuFeatureClick: onMenuFeatureClick, key: `primary-${category}` }))),
        mainMenuSharedListMap.additionalFeaturesMap.size > 0 && (React.createElement(React.Fragment, null,
            React.createElement(GroupHeaderListItem, null, "Additional Features"),
            [...mainMenuSharedListMap.additionalFeaturesMap.entries()].map(([category, mainMenuFeatures]) => (React.createElement(FeatureSectionAdditional, { category: category, key: `add-${category}`, mainMenuFeatures: mainMenuFeatures, onMenuFeatureClick: onMenuFeatureClick })))))));
};
export { MainMenuSharedList };
