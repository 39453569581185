var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import styled from "styled-components";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";
const Subtitle = styled.small `
  color: ${GreyColors.smoke70};
  font-size: ${Typography.sizes.md};
  margin-left: ${Spacing.spacing12};
`;
const TableHeading = styled((_a) => {
    var { subtitle, title } = _a, props = __rest(_a, ["subtitle", "title"]);
    return (React.createElement("h2", Object.assign({}, props),
        title,
        subtitle && React.createElement(Subtitle, null, subtitle)));
}) ``;
export default TableHeading;
