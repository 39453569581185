import CardHeader from "contracts/components/CardHeader";
import CardRow from "contracts/components/CardRow";
import ContractCardModal from "contracts/components/ContractCardModal";
import ContractDeliveryDateRange from "contracts/components/ContractDeliveryDateRange";
import ContractQuantity from "contracts/components/ContractQuantity";
import Price from "contracts/components/Price";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import ContractSubtypeCopy from "components/copy/ContractSubtypeCopy";
import { Button } from "components/fl-ui";
import { BorderRadius, Borders, Spacing } from "components/fl-ui/constants";

const CashContractCard = styled(({ className, contract, onEdit }) => {
  const [expanded, setExpanded] = useState(false);

  const viewContract = () => {
    setExpanded((currentState) => !currentState);
  };

  return (
    <div className={className}>
      <CardHeader contract={contract} />

      {expanded && (
        <ContractCardModal
          contract={contract}
          onClose={() => setExpanded(false)}
          onEdit={onEdit}
          onSave={() => setExpanded(false)}
        />
      )}

      <div className="card-rows">
        <CardRow title="Buyer">{contract.buyer ?? "--"}</CardRow>
        <CardRow title="Commodity">{contract.commodity?.name ?? "--"}</CardRow>
        <CardRow title="Delivery">
          <ContractDeliveryDateRange endDate={contract.deliveryEndDate} startDate={contract.deliveryStartDate} />
        </CardRow>
        <CardRow title="Type">
          {contract.contractType ? (
            <ContractSubtypeCopy subtype={contract.contractType} product={contract.product} />
          ) : (
            "--"
          )}
        </CardRow>
        <CardRow title="Quantity Contracted">
          <ContractQuantity contract={contract} quantity={contract?.quantity} />
        </CardRow>
        <CardRow title="Quantity Priced">
          <ContractQuantity contract={contract} quantity={contract?.quantityPriced} />
        </CardRow>
        <CardRow title="Pricing">
          <Price price={contract.netPrice} variant={contract.contractFinalStatus !== "Final" ? "YELLOW" : null} />
        </CardRow>
      </div>

      <Button className="view-button" color="primary" hollow onClick={viewContract} size="mn">
        View
      </Button>
    </div>
  );
})`
  border-radius: ${BorderRadius.xlarge};
  box-shadow: ${Borders.tableRow};
  margin: ${Spacing.spacing12} ${Spacing.spacing2};
  padding: ${Spacing.spacing12};

  .view-button {
    display: inline-block;
    margin-left: 50%;
    margin-top: ${Spacing.spacing20};
    transform: translate(-50%);
  }
`;

export default CashContractCard;

CashContractCard.propTypes = {
  contract: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
};
