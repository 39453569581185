import { css, StyleSheet } from "aphrodite/no-important";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { Col, Grid, Row } from "react-styled-flexboxgrid";

import { Mixins, Spacing } from "components/fl-ui/constants";

export const withSuspenseWrapper =
  (Component, loadingProps = {}) =>
  (props) =>
    (
      <Suspense fallback={<LoadingWrapper isLoading {...loadingProps} />}>
        <Component {...props} />
      </Suspense>
    );

const LoadingWrapper = ({ className, children, isLoading, loadingText, minHeight }) => {
  const styles = StyleSheet.create({
    grid: {
      height: "inherit",
    },
    grid_minHeight: {
      minHeight: Mixins.toRem(minHeight),
    },
    row: {
      height: "100%",
    },
    col: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    col_minHeight: {
      minHeight: Mixins.toRem(minHeight),
    },
    spinner_container: {
      padding: Spacing.spacing28,
    },
    spinner: {
      float: "left",
      display: "inline-block",
      width: Spacing.spacing24,
      height: Spacing.spacing24,
      position: "relative",
      marginRight: Spacing.spacing12,
    },
  });

  if (isLoading) {
    return (
      <Grid className={classNames(css(styles.grid, minHeight && styles.grid_minHeight), className)} fluid>
        <Row className={css(styles.row)} center="xs" middle="xs">
          <Col className={css(styles.col, minHeight && styles.col_minHeight)}>
            <div className={css(styles.spinner_container)}>
              <span className={classNames("loading-spinner-only", css(styles.spinner))} />
              {loadingText}
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }

  return children;
};

LoadingWrapper.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  className: PropTypes.string,
  minHeight: PropTypes.number,
};

LoadingWrapper.defaultProps = {
  isLoading: true,
  loadingText: "Loading...",
};

export default LoadingWrapper;
