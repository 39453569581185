import React from "react";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";

import CropBadge from "components/fl-ui/CropBadge";
import { Spacing } from "components/fl-ui/constants";

const StyledCropBadge = styled(CropBadge)`
  margin-right: ${Spacing.spacing12} !important;
`;

const Option = (props) => (
  <components.Option {...props}>
    <StyledCropBadge color={props.data.color} abbr={props.data.abbr} />
    {props.data.label}
  </components.Option>
);

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <StyledCropBadge color={props.data.color} abbr={props.data.abbr} />
    {props.children}
  </components.SingleValue>
);

const CommoditySelect = (props) => {
  const chooserProps = {
    ...props,
    components: { Option, SingleValue },
    getOptionValue: ({ id }) => id,
    isClearable: false,
    isSearchable: false,
    styles: { valueContainer: (base) => ({ ...base, padding: Spacing.spacing12 }) },
    value: props.options.find(({ id }) => id === props.value),
  };

  return <ReactSelect {...chooserProps} />;
};

export default CommoditySelect;
