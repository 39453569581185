import React from "react";
import { ProfitAndLossBlankSlateBanner } from "components/advertisements/banners/ProfitAndLossBlankSlateBanner";
import PayGateCommonBlankSlatePage from "components/advertisements/pages/PayGateCommonBlankSlatePage";
const ProfitAndLossBlankSlateBannerPage = ({ banner = ProfitAndLossBlankSlateBanner, }) => {
    const steps = [
        "Keep track of your product purchases, activity costs, land agreements and marketing contracts throughout the year.",
        "View your Profit & Loss statement to evaluate your overall position or look closely at the profitability of individual fields and crops.",
        "Discover ways to become more profitable and use this feature in conversations with your financial partners.",
    ];
    return (React.createElement("div", null,
        React.createElement(PayGateCommonBlankSlatePage, { banner: banner, steps: steps })));
};
export default ProfitAndLossBlankSlateBannerPage;
