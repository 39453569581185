import { ApolloClient, ApolloLink } from "@apollo/client";

import defaultCache from "collection/graphql/cache/defaultCache";
import { authHeaders } from "collection/graphql/client/common";
import { restLink } from "collection/graphql/client/links/restLink";

const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    return {
      headers: {
        ...headers,
        ...authHeaders(),
      },
    };
  });
  return forward(operation);
});

/**
 * Use `marketingClient` instead.
 * @deprecated
 */
const client = new ApolloClient({
  cache: defaultCache,
  link: ApolloLink.from([authRestLink, restLink]),
});

export default client;
