import { css } from "aphrodite/no-important";
import { SupportEmailLink } from "billing/lib";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ManageElsewhere from "settings/components/ManageElsewhere";
import styles from "settings/styles";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useReleaseFeature from "hooks/useReleaseFeature";
import useRestQuery from "hooks/useRestQuery";

import { Button } from "components/fl-ui";

const ManageSubscriptionAction = (props) => {
  const preventBilling = useReleaseFeature("disable-billing-page");
  const { onCancelSubscription } = props;
  const { data, loading } = useRestQuery(getCurrentSubscription);
  if (loading) {
    return null;
  } else if (preventBilling) {
    return <div>Subscription management temporarily disabled</div>;
  }
  const { canManageSubscription, currentPlan, isCancelable, isSubscribed, selfService, trialAvailable } =
    data.subscription;

  const shouldContactSupport = !currentPlan?.id || !selfService;
  if (shouldContactSupport) {
    return <SupportEmailLink>Contact support to manage your subscription</SupportEmailLink>;
  }

  if (!canManageSubscription) {
    return <ManageElsewhere />;
  }

  const getButtonText = () => {
    if (isSubscribed) {
      return "Manage subscription";
    } else if (trialAvailable) {
      return "Start a free trial";
    } else {
      return "Upgrade Plan";
    }
  };

  return (
    <>
      {isCancelable && (
        <Button className={css(styles.rightMargin)} color="danger" hollow onClick={onCancelSubscription}>
          Cancel subscription
        </Button>
      )}

      <Link className="btn btn-primary" to="/billing">
        {getButtonText()}
      </Link>
    </>
  );
};

ManageSubscriptionAction.propTypes = {
  onCancelSubscription: PropTypes.func.isRequired,
};

export default ManageSubscriptionAction;
