import styled from "styled-components";

import Icon from "components/fl-ui/Icons/Icon";
import { UIColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

export const EmptyResultContainer = styled.div`
  line-height: 1.5;
  margin: auto;
  max-width: 35rem;
  padding-top: ${Spacing.spacing32};
  text-align: center;
`;

export const EmptyResultIcon = styled(Icon)`
  color: ${UIColors.lightest};
  height: 4.5rem !important;
`;

export const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FilterControlsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;

  @media only screen and (min-width: 62.5em) {
    justify-content: flex-start;
  }

  @media print {
    display: none;
  } ;
`;

export const SortByLabel = styled.span`
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.bold};
  margin-right: 0;
`;
