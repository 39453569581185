import React from "react";
import { RainAndHeatBlankSlateBanner } from "components/advertisements/banners/RainAndHeatBlankSlateBanner";
import PayGateCommonBlankSlatePage from "components/advertisements/pages/PayGateCommonBlankSlatePage";
const RainAndHeatBlankSlatePage = () => {
    const steps = [
        "Get daily or weekly look at how much rain and heat units are accumulating on your fields",
        "Use charts to take a deep dive into the rain or heat history over any custom time period",
        "Ditch the rain gauge and weather station",
    ];
    return (React.createElement("div", null,
        React.createElement(PayGateCommonBlankSlatePage, { banner: RainAndHeatBlankSlateBanner, steps: steps })));
};
export default RainAndHeatBlankSlatePage;
