import React from "react";
import styled from "styled-components";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import { useWalletSettingsInfo } from "modules/settings/pages/Wallet/useWalletSettingsInfo";
import { Button } from "components/fl-ui";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { ColorPalette } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
const DisconnectFromWalletModal = styled(({ className, onClose }) => {
    const { handle } = useWalletSettingsInfo();
    const { currentEnterprise } = useCurrentEnterprise();
    const disconnectWallet = () => {
        console.log("Disconnecting wallet");
    };
    return (React.createElement(Modal, { className: className, width: 480, hideModal: undefined, id: "disconnect-from-wallet-modal" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Are you sure?"),
            React.createElement(CloseX, { onClick: onClose })),
        React.createElement(ModalBody, null,
            React.createElement("p", null,
                "Are you sure you want to disconnect the ",
                React.createElement("b", null, handle),
                " Wallet account from ",
                React.createElement("b", null, currentEnterprise.name),
                "?"),
            React.createElement("p", null, "By disconnecting these accounts, other users with Wallet access will no longer be able to access this account through Bushel Farm but may still have access directly through Bushel Wallet.")),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { className: "cancel-button", color: "secondary", display: "flex", size: "sm", onClick: onClose }, "Cancel"),
            React.createElement(Button, { className: "disconnect-button", color: "secondary", display: "flex", size: "sm", onClick: disconnectWallet }, "Disconnect Wallet"))));
}) `
  .cancel-button {
    border: none;
    margin-right: ${Spacing.spacing8};
  }

  .disconnect-button {
    color: ${ColorPalette["dark-red"]};
    border-color: ${ColorPalette["dark-red"]};
  }
`;
export { DisconnectFromWalletModal };
