import React from "react";
import useTrialStatus from "hooks/useTrialStatus";
import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import { ValuePropListItem } from "components/advertisements/banners/shared/ValuePropLineItem";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
const RainAndHeatBlankSlateBanner = () => {
    const { showActivateBusinessTrial, loading } = useTrialStatus();
    const bannerProps = {
        actionButtonText: showActivateBusinessTrial ? "Try Rain & Heat History for free" : "Move to Lite today",
        imagePath: "images/pay_gates/banners/rain_and_heat.png",
        title: "With Rain & Heat History you can:",
    };
    return (React.createElement(LoadingWrapper, { isLoading: loading },
        React.createElement(PayGateCommonBlankSlateBanner, Object.assign({}, bannerProps),
            React.createElement("ul", null,
                React.createElement(ValuePropListItem, null, "See rainfall history and heat unit accumulation for each field and compare it to prior seasons and the 10-year average."),
                React.createElement(ValuePropListItem, null, "Receive a detailed email with each rain event showing you the fields that received the most and least rainfall."),
                React.createElement(ValuePropListItem, null, "Use charts to take a deep dive into the rain or heat history over any custom time period over the past 20 years."),
                React.createElement(ValuePropListItem, null, "Rain and Heat history is available in the Lite, Essentials, and Business plans")))));
};
export { RainAndHeatBlankSlateBanner };
