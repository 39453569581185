import _ from "lodash";
import { EDIT, VIEW } from "marketing/constants";
import contractEnums from "marketing/utils/contractEnums";

import useCurrentCropYear from "hooks/useCurrentCropYear";

const { ADD_CONTRACT, EDIT_CONTRACT, ADD_MARKETED_CROP, EDIT_MARKETED_CROP } = contractEnums;

const getBreadCrumbLinks = (props) => {
  const { commodityName, cropId, contentType, contractType, contractId, action } = props;
  const [currentCropYear] = useCurrentCropYear();

  const breadCrumbs = [
    {
      label: `Marketing ${currentCropYear}`,
      link: "/marketing",
      enabled: true,
    },
  ];

  if (commodityName) {
    breadCrumbs.push({
      label: _.startCase(commodityName),
      link: action === EDIT || commodityName ? `/marketing/${cropId}` : "/marketing",
      enabled: true,
    });
  }

  if (contentType) {
    breadCrumbs.push({
      label: `${_.startCase(_.lowerCase(contentType))}`,
      link: `/marketing/${cropId}/${contentType.toLowerCase()}`,
      enabled: true,
    });
  }

  if (action) {
    // Contracts
    if (contentType === "contracts") {
      let label = "";
      switch (action) {
        case ADD_CONTRACT:
          label = `New ${_.startCase(_.lowerCase(contractType))} Contract`;
          break;
        case EDIT_CONTRACT:
        case VIEW:
          label = `/${contractId}`;
          break;
        default:
          break;
      }
      if (label !== "") {
        breadCrumbs.push({
          label: label,
          link: `/marketing/${cropId}/contracts/${contractType}/${contractId}`,
          enabled: contractType === "cash" && action === EDIT_CONTRACT,
        });
      }
      if (action === EDIT_CONTRACT || action === "Edit") {
        breadCrumbs.push({
          label: `Edit`,
          link: "",
          enabled: false,
        });
      }
    }
    // Marketed Crop
    else if (action === EDIT_MARKETED_CROP || action === ADD_MARKETED_CROP) {
      breadCrumbs.push({
        label: action === ADD_MARKETED_CROP ? `Add new crop` : `Edit crop`,
        link: "",
        enabled: false,
      });
    }
  }

  const lastItem = _.takeRight(breadCrumbs);
  lastItem[0].enabled = false;

  return breadCrumbs;
};

export { getBreadCrumbLinks };
