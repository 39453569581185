/* eslint-disable react/display-name */
import { StyleSheet, css } from "aphrodite/no-important";
import { useViewportSpy } from "beautiful-react-hooks";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import useHistoricalRainfall from "collection/graphql/weather/hooks/useHistoricalRainfall";

import { UIColors } from "components/fl-ui/colors";
import { Typography, Spacing, BorderRadius } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 48em)": {
      flexDirection: "column-reverse",
      alignItems: "flex-end",
    },
  },
  loading: {
    fontSize: Typography.sizes.rg,
    marginLeft: Spacing.spacing32,
  },
  noRainfall: {
    fontSize: Typography.sizes.rg,
    marginLeft: Spacing.spacing32,
    textAlign: "right",
  },
  time_window: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  precipitation: {
    marginBottom: 0,
    marginLeft: Spacing.spacing8,
    padding: `${Spacing.spacing4} ${Spacing.spacing8}`,
    background: UIColors.secondary,
    borderRadius: BorderRadius.small,
    minWidth: Spacing.spacing48,
    textAlign: "center",
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    ":hover": {
      textDecoration: "none",
    },
    "@media only screen and (max-width: 48em)": {
      marginBottom: Spacing.spacing4,
    },
  },
  precipitation_isRecent: {
    backgroundColor: UIColors.blue,
    color: UIColors.white,
    ":hover": {
      color: UIColors.white,
    },
  },
});

const EmptyState = () => <span className={css(styles.noRainfall)}>No rainfall data is available</span>;
const LoadingState = () => <span className={css(styles.loading)}>Loading rainfall data{"\u2026"}</span>;

const withLazyRender = (Component) => (props) => {
  const [shouldRender, setShouldRender] = useState(false);
  const ref = useRef();
  const visible = useViewportSpy(ref);
  const recent = useHistoricalRainfall().historicalRainfallMap;
  const isLoading = !recent;

  useEffect(() => {
    if (!shouldRender && visible && !isLoading) {
      setShouldRender(true);
    }
  }, [visible, isLoading]);

  return <div ref={ref}>{shouldRender ? <Component {...props} recent={recent} /> : <LoadingState />}</div>;
};

const RainfallSummary = withLazyRender((props) => {
  const { fieldId, recent } = props;
  const recentRainfall = recent.get(fieldId);
  if (!recent.has(fieldId) || !recentRainfall.amount) {
    return <EmptyState />;
  }

  const isRecent = recentRainfall.state === "recent";
  const isSignificant = isRecent && +recentRainfall.amount >= 0.1;
  const startDate = recentRainfall.startTime;
  const endDate = recentRainfall.endTime;

  return (
    <div className={css(styles.container)}>
      {isRecent ? (
        <h3 className={css(styles.time_window)}>
          {`${moment(startDate).format("ddd ha")} - ${moment(endDate).format("ddd ha")}`}
        </h3>
      ) : (
        <h3 className={css(styles.time_window)}>{`Last rainfall ${moment(recentRainfall.endTime).fromNow()}`}</h3>
      )}
      <Link
        className={css(styles.precipitation, isSignificant && styles.precipitation_isRecent)}
        to={`/fields/${fieldId}/rainfall`}
      >
        {`${recentRainfall.amount.toFixed(2)}"`}
      </Link>
    </div>
  );
});

RainfallSummary.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default RainfallSummary;
