var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { css } from "aphrodite/no-important";
import React from "react";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import styles from "components/fl-ui/Layout/styles/screenStyles";
export const NavigationScreen = (_a) => {
    var { state } = _a, rest = __rest(_a, ["state"]);
    return (React.createElement(Screen, Object.assign({}, rest, { style: Object.assign(Object.assign({}, styleVars.screenDefaultStyle), styleVars.screenTransitionStyles[state]) })));
};
const Screen = ({ onClick, style }) => React.createElement("div", { className: css(styles.screen), onClick: onClick, style: style });
export default Screen;
