import { StyleSheet } from "aphrodite/no-important";

import { GreyColors, UIColors } from "components/fl-ui/colors";
import { BorderRadius, Borders, Spacing, Typography, Mixins } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  fullscreen: {
    backgroundColor: UIColors.white,
    background: `linear-gradient(-180deg, #FFFFFF 0%, #FAFAFA 100%)`,
    paddingRight: Spacing.spacing16,
    paddingLeft: Spacing.spacing16,
    overflowY: "scroll",
  },
  grid: {
    position: "relative",
    height: "100%",
  },
  banner: {
    alignItems: "center",
    display: "flex",
    flexFlow: "column",
    padding: Spacing.spacing32,
    textAlign: "center",
  },
  headerContainer: {
    position: "relative",
    paddingTop: Spacing.spacing32,
    marginBottom: Spacing.spacing24,
  },
  headerBorder: {
    borderBottom: Borders.regular,
    paddingBottom: Spacing.spacing16,
  },
  bannerHeading: {
    fontSize: Typography.sizes.xxl,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.4,
    marginBottom: 0,
    maxWidth: "80%",
  },
  bannerHr: {
    margin: `${Spacing.spacing24} auto`,
    maxWidth: "40%",
    width: "40%",
  },
  bigIcon: {
    color: UIColors.white,
    height: Spacing.spacing32,
    width: Spacing.spacing32,
  },
  bigIcon_background: {
    alignItems: "center",
    background: UIColors.green,
    border: `1px solid ${UIColors.green}`,
    borderRadius: "50%",
    display: "flex",
    height: Mixins.toRem(72),
    justifyContent: "center",
    width: Mixins.toRem(72),
  },
  blue: {
    color: `${UIColors.blue} !important`,
  },
  bottomBorder: {
    borderBottom: Borders.regular,
    marginBottom: Spacing.spacing32,
    paddingBottom: Spacing.spacing16,
  },
  button: {
    marginTop: `${Spacing.spacing16} !important`,
    padding: `${Spacing.spacing14} ${Spacing.spacing48} !important`,
  },
  buttonGroupContainer: {
    margin: `${Spacing.spacing16} ${Spacing.spacing32} ${Spacing.spacing32}`,
  },
  card: {
    boxShadow: "0 2px 15px 0 rgba(0, 0, 0, 0.05)",
    minHeight: "100%",
  },
  cardHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    "@media only screen and (max-width: 30rem)": {
      alignItems: "flex-start",
      flexFlow: "column",
    },
  },
  cardHeaderSingle: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
  },
  colPadding: {
    paddingBottom: Spacing.spacing8,
    paddingTop: Spacing.spacing8,
  },
  columnMobile: {
    "@media only screen and (max-width: 48rem)": {
      alignItems: "center",
      display: "flex",
      flexFlow: "column",
      textAlign: "center",
    },
  },
  cropDataContainer: {
    display: "flex",
    marginBottom: Spacing.spacing16,
  },
  cropDataName: {
    fontWeight: Typography.weights.medium,
  },
  cropDataText: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.spacing8,
  },
  centeredContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    maxWidth: "75%",
    margin: "0 auto",
  },
  fieldAddCard: {
    maxWidth: "20rem",
    padding: Spacing.spacing24,
    textAlign: "center",
  },
  fieldAddLabel: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.3,
    "@media only screen and (max-width: 62.5em)": {
      fontSize: Typography.sizes.rg,
    },
  },
  fieldAddPrimaryButton: {
    "@media only screen and (max-width: 24rem)": {
      marginTop: Spacing.spacing16,
    },
  },
  footerText: {
    lineHeight: 1.4,
  },
  mainText: {
    margin: `${Spacing.spacing8} 0`,
  },
  modalFooter: {
    "@media only screen and (max-width: 30rem)": {
      textAlign: "center !important",
    },
  },
  onboardingText: {
    fontSize: Typography.sizes.rg,
    lineHeight: 2,
    maxWidth: "60%",
  },
  pricesText: {
    lineHeight: 1.4,
  },
  rainBadge: {
    background: UIColors.blue,
    borderRadius: BorderRadius.small,
    color: UIColors.white,
    display: "inline-block",
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    marginRight: Spacing.spacing16,
    padding: `${Spacing.spacing8} ${Spacing.spacing12}`,
  },
  rowColumnReverseMobile: {
    "@media only screen and (max-width: 48rem)": {
      alignItems: "center",
      display: "flex",
      flexFlow: "column-reverse",
    },
  },
  rowPadding: {
    paddingBottom: Spacing.spacing24,
    paddingTop: Spacing.spacing24,
  },
  sideBanner: {
    alignItems: "flex-start",
    display: "flex",
    flexFlow: "column",
    height: "100%",
    justifyContent: "center",
    padding: "2rem",
  },
  sideBannerText: {
    maxWidth: "80%",
  },
  soilData: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: Spacing.spacing12,
    paddingBottom: Spacing.spacing12,
  },
  soilDataContainer: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
  },
  soilDataText: {
    fontSize: Typography.sizes.lg,
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.spacing4,
  },
  soilLegend: {
    borderRadius: BorderRadius.medium,
    height: Spacing.spacing32,
    margin: `0 ${Spacing.spacing16}`,
    minWidth: Spacing.spacing32,
    width: Spacing.spacing32,
  },
  subText: {
    color: UIColors.medium,
    margin: `${Spacing.spacing4} 0`,
  },
  tooltipContainer: {
    background: "rgba(255,255,255,0.95)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.07)",
    border: `1px solid ${GreyColors.smoke90}`,
    borderRadius: "5px",
    padding: Spacing.spacing16,
  },
  tooltipDescription: {
    color: UIColors.medium,
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.regular,
    marginBottom: 0,
    textTransform: "uppercase",
  },
  tooltipTitle: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.regular,
    margin: 0,
  },
  tooltipValues: {
    fontSize: Typography.sizes.xl,
    fontWeight: Typography.weights.medium,
    margin: `${Spacing.spacing8} 0`,
  },
});

export default styles;
