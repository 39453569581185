import useDocumentTitle from "hooks/useDocumentTitle";
import withBillingFormState from "billing/containers/withBillingFormState";
import CheckoutPage from "billing/pages/CheckoutPage";
import BillingProductsProvider from "billing/providers/BillingProductsProvider";
import React from "react";

import useTrialStatus from "hooks/useTrialStatus";
import ActivateBusinessTrial from "modules/billing/pages/ActivateBusinessTrial";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const WrappedCheckoutPage = withBillingFormState(CheckoutPage);

const BillingPage = () => {
  useDocumentTitle("Tier Selection");
  const { showActivateBusinessTrial, loading } = useTrialStatus();

  return (
    <ErrorBoundary>
      <LoadingWrapper isLoading={loading}>
        {showActivateBusinessTrial && <ActivateBusinessTrial />}
        {!showActivateBusinessTrial && (
          <BillingProductsProvider>
            <WrappedCheckoutPage />
          </BillingProductsProvider>
        )}
      </LoadingWrapper>
    </ErrorBoundary>
  );
};

export default BillingPage;
