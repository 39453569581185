import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Search from "components/fl-ui/Search";
import { Spacing, Typography } from "components/fl-ui/constants";

const Container = styled.div`
  padding: ${Spacing.spacing8};
  input {
    font-size: ${Typography.sizes.rg};
  }
`;

const SearchInput = (props) => {
  const { placeholder, setText } = props.control;

  return (
    <Container>
      <Search autoFocus autoComplete="off" name="text" onChange={setText} placeholder={placeholder} />
    </Container>
  );
};

SearchInput.propTypes = {
  control: PropTypes.shape({
    placeholder: PropTypes.string,
    setText: PropTypes.func.isRequired,
  }).isRequired,
};

export default SearchInput;
