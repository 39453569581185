import { gql } from "@apollo/client";

const PRICE_SNAPSHOT_FRAGMENT = gql`
  fragment PriceSnapshotFragment on PriceSnapshot {
    __typename
    close
    current
    high
    low
    netChange
    open
    priceTimestamp
  }
`;

export { PRICE_SNAPSHOT_FRAGMENT };
