import React, { useState } from "react";
import styled from "styled-components";
import useViewType from "hooks/useViewType";
import { CommodityCard } from "modules/settings/pages/Commodities/containers/CommodityCard";
import { CommoditySectionBlankSlate } from "modules/settings/pages/Commodities/containers/CommoditySectionBlankSlate";
import CommodityFormModal from "components/commodity/CommodityFormModal";
import { Button } from "components/fl-ui";
import { Cluster, Stack } from "components/fl-ui/Layout";
const ABRIDGED_AMOUNT = 10;
const StyledCardList = styled.div `
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${({ columnCount }) => `repeat(${columnCount}, minmax(300px, 1fr))`};
`;
const CardList = ({ children }) => {
    const columnCount = useViewType() === "mobile" ? 1 : 2;
    return React.createElement(StyledCardList, { columnCount: columnCount }, children);
};
const CommoditySection = ({ abridge, commodities, description, showAddButton = false, title, }) => {
    const [showAll, setShowAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const showAbridgedList = !showAll && commodities.length > ABRIDGED_AMOUNT && abridge;
    const visibleCommodities = !showAbridgedList ? commodities : commodities.slice(0, ABRIDGED_AMOUNT);
    return (React.createElement(Stack, null,
        React.createElement(Cluster, { between: true, around: undefined, center: undefined, end: undefined, even: undefined, start: undefined },
            React.createElement("div", null,
                React.createElement("h2", null, title),
                description && React.createElement("p", null, description)),
            showAddButton && (React.createElement(Button, { onClick: () => setShowModal(true), color: "secondary" }, "+ Add custom commodity")),
            showModal && (React.createElement(CommodityFormModal, { onClose: () => setShowModal(false), onSave: () => setShowModal(false), commodityId: undefined }))),
        visibleCommodities.length > 0 && (React.createElement(CardList, null, visibleCommodities.map((commodity) => (React.createElement(CommodityCard, { key: commodity.id, commodity: commodity }))))),
        visibleCommodities.length === 0 && React.createElement(CommoditySectionBlankSlate, null),
        !showAll && commodities.length > ABRIDGED_AMOUNT && (React.createElement(Cluster, { center: true, around: undefined, between: undefined, end: undefined, even: undefined, start: undefined },
            React.createElement(Button, { link: true, onClick: () => setShowAll(true), color: "primary" },
                "Show all ",
                commodities.length)))));
};
export { CommoditySection };
