import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "components/fl-ui";
import { Input } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { Typography } from "components/fl-ui/constants";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
const StyledModalText = styled(({ children }) => (React.createElement("p", null, children))) `
  font-size: ${({ small }) => (small ? Typography.sizes.mn : Typography.sizes.lg)};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  line-height: 1.5em;
`;
const SubscriptionWarning = styled(({ className, platform }) => (React.createElement("div", { className: className }, platform.isWebPaymentProvider
    ? `Your subscription will be canceled as part of this process.`
    : `After completing user deletion, you will need to cancel your subscription through ${platform.storeName}.`))) `
  background: #cde8f6;
  border: 1px solid #0099e0;
  border-radius: 4px;
  margin-bottom: 15px;
  line-height: 1.5em;
  padding: 15px;
`;
const AccountDeactivationModal = ({ onClose, onConfirm, platform, role }) => {
    const [inputValue, setInputValue] = useState("");
    const { currentEnterprise } = useCurrentEnterprise();
    const isDisabled = inputValue !== "DELETE";
    const isAdmin = role === "admin";
    const confirmButtonCopy = (platform === null || platform === void 0 ? void 0 : platform.isWebPaymentProvider) ? "Deactivate subscription and accounts" : "Delete accounts";
    const handleClose = () => {
        onClose();
    };
    const farmName = (currentEnterprise === null || currentEnterprise === void 0 ? void 0 : currentEnterprise.name) || "Your Farm";
    return (React.createElement(Modal, { width: 625, hideModal: undefined, id: "account-deactivation-modal" },
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, `Are you sure you want to delete ${farmName}?`),
            React.createElement(CloseX, { onClick: handleClose })),
        React.createElement(ModalBody, null,
            isAdmin && platform && React.createElement(SubscriptionWarning, { platform: platform }),
            React.createElement(StyledModalText, null, `${farmName} and all associated data will be permanently deleted for all associated users. You will not be able
          to recover deleted accounts or any associated data. To save your data, you may keep your account without a
          paid subscription or you should export your data before proceeding.`),
            React.createElement(StyledModalText, { bold: true }, `By clicking Delete Account you agree to permanent deletion of all data associated with ${farmName} entered into
          the Bushel Farm system.`),
            React.createElement(StyledModalText, { bold: true, small: true }, "Please enter the word DELETE to confirm."),
            React.createElement(Input, { name: "deleteConfirmation", onChange: (event) => setInputValue(event.target.value), style: { width: "100%" }, type: "text", value: inputValue })),
        React.createElement(ModalFooter, null,
            React.createElement(Button, { onClick: handleClose }, "Cancel"),
            " ",
            React.createElement(Button, { color: "danger", disabled: isDisabled, onClick: onConfirm }, confirmButtonCopy))));
};
export default AccountDeactivationModal;
