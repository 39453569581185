import { skipToken, useSuspenseQuery } from "@apollo/client";
import { marketedCropDetailContext } from "marketing/hooks/useMarketedCropDetailContext";
import React, { useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { number } from "yup";

import { GET_CURRENT_ENTERPRISE } from "collection/graphql/enterprise/queries";
import { GET_MARKETED_CROP } from "collection/graphql/marketing";
import usePermissions from "hooks/usePermissions";

import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";

const MarketingCropDetailGuard = () => {
  const navigate = useNavigate();

  /*
   * First, ensure that the user has read permissions on marketing
   */
  if (!usePermissions().canRead("marketing")) {
    return <RestrictedAccessBlankSlate />;
  }

  /*
   * Second, ensure that the enterprise has a postal code set
   */
  const { currentEnterprise } = useSuspenseQuery(GET_CURRENT_ENTERPRISE).data;
  if (!currentEnterprise.postalCode) {
    // TODO toast message? "Zip code must be set view this data"
    navigate("/marketing");
    return null;
  }

  /*
   * Third, validate the marketed crop id and fetch its data
   */
  const marketedCropId = +useParams().marketedCropId;
  const isValidId = number().positive().integer().required().isValidSync(marketedCropId);
  const queryOptions = !isValidId
    ? { skipToken }
    : {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: {
          id: marketedCropId,
        },
      };

  const {
    data: { marketedCrop },
  } = useSuspenseQuery(GET_MARKETED_CROP, queryOptions);
  if (!marketedCrop) {
    // TODO toast message? "Unable to find the specified marketed crop"
    navigate("/marketing");
    return null;
  }

  /*
   * Lastly, provide the marketed crop via context
   */

  const context = useMemo(
    () => ({
      marketedCrop,
    }),
    [marketedCrop]
  );

  return (
    <marketedCropDetailContext.Provider value={context}>
      <Outlet />
    </marketedCropDetailContext.Provider>
  );
};

export default MarketingCropDetailGuard;
