import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

const ProductFilterChooser = ({ onChange, purchaseSummaries, value }) => (
    <ReactSelect
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      onChange={(product) => onChange(product)}
      options={_.map(purchaseSummaries, "product")}
      placeholder=""
      value={value}
    />
);

ProductFilterChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  purchaseSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default ProductFilterChooser;
