import { FullScreen } from "react-full-screen";
import styled from "styled-components";

import { Spacing } from "components/fl-ui/constants";

export const FullScreenContainer = styled(FullScreen)`
  background: white;
  > div {
    padding-top: 1.25rem;
  }
`;

export const FullScreenHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 ${Spacing.spacing16};
`;
