import { css } from "aphrodite";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { FilterButton } from "components/fl-ui/Buttons";
import DropdownMenu from "components/fl-ui/DropdownMenu";
import { FilterControlsContainer, FiltersContainer } from "components/fl-ui/Filters/styledComponents";
import { getFilterCount } from "components/fl-ui/Filters/utils";
import Search from "components/fl-ui/Search";
import styles from "fields/common/styles";

const Filters = ({ filters, search, setSearch, setShowFilterModal, setSortCriteria, sortCriteria, sortOptions }) => (
  <FiltersContainer>
    <FilterControlsContainer>
      {filters && <FilterButton filterCount={getFilterCount(filters)} onClick={() => setShowFilterModal(true)} />}
      {setSearch && (
        <Search
          onChange={debounce(setSearch, 500)}
          placeholder="Search"
          style={{ marginRight: "0.5rem" }}
          value={search}
        />
      )}
    </FilterControlsContainer>

    {sortOptions?.length > 0 && (
      <div className={css(styles.filterBar_controls, filters && styles.hide_on_mobile)}>
        <p className={css(styles.filterBar_text)}>Sort by</p>
        <DropdownMenu options={sortOptions} onChange={setSortCriteria} value={sortCriteria} />
      </div>
    )}
  </FiltersContainer>
);

Filters.propTypes = {
  filters: PropTypes.object,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  setShowFilterModal: PropTypes.func,
  setSortCriteria: PropTypes.func,
  sortCriteria: PropTypes.string,
  sortOptions: PropTypes.arrayOf(PropTypes.object),
};

export default Filters;
