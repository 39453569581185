import Backbone from "backbone";
import render from "inventory/templates";
import $ from "jquery";
import moment from "moment";
import BinView from "resources/bin";
import renderUI from "ui/templates";
import _ from "underscore";

import BinsCollection from "collection/bins";
import defaultCache from "collection/graphql/cache/defaultCache";
import { GET_COMMODITIES } from "collection/graphql/commodities/queries";
import inventory_transactions from "collection/inventory_transactions";
import { BIN_DETAIL_MODAL_OPEN_LOAD_EDIT, BIN_DETAIL_VIEW_FILTER_CHANGE } from "lib/metrics/events";

class DateRange {
  startDate = "";
  endDate = "";

  constructor(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  isValidEndDate(value) {
    const endDate = moment(value || "");
    const startDate = moment(this.startDate);
    if (startDate.isValid() && endDate.isValid()) {
      return endDate.isSameOrAfter(startDate, "day");
    }

    return endDate.isValid();
  }

  isValidStartDate(value) {
    const startDate = moment(value || "");
    const endDate = moment(this.endDate);
    if (startDate.isValid() && endDate.isValid()) {
      return startDate.isSameOrBefore(endDate, "day");
    }

    return startDate.isValid();
  }

  setEndDate(value) {
    this.endDate = this.isValidEndDate(value) ? moment(value).format("YYYY-MM-DD") : "";
  }

  setStartDate(value) {
    this.startDate = this.isValidStartDate(value) ? moment(value).format("YYYY-MM-DD") : "";
  }

  isValidRange() {
    const startDate = moment(this.startDate);
    const endDate = moment(this.endDate);

    return startDate.isValid() && endDate.isValid() && startDate.isSameOrBefore(endDate, "day");
  }

  toJSON() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
}

export default Backbone.View.extend({
  className: "analytics-page container-fluid",

  initialize({ getCropByInventoryNode }) {
    this.getCropByInventoryNode = getCropByInventoryNode;
    /*
     * this needs to be in here so we can reliably call .undelegateEvents from a parent React component.
     */
    this.delegateEvents({
      "click .harvest-loads tr": "editLoad",
      "click .js-log-load": "addLoad",
      "click .js-show-more": "showMore",
      "click .js-edit-bin": "editBin",
      "click .js-edit-location": "editLocation",
      'change input[type="date"]': "onDateChange",
    });

    // this relies on commodity data already being in the cache
    const { commodities } = defaultCache.readQuery({
      query: GET_COMMODITIES,
    });
    this.commodities = commodities.reduce((map, commodity) => map.set(commodity.id, commodity), new Map());

    const { startDate, endDate } = inventory_transactions.getDateRange(this.model.get("inventory_node_id"));
    this.range = new DateRange();
    if (startDate) {
      this.range.setStartDate(startDate);
    }
    if (endDate) {
      this.range.setEndDate(endDate);
    }

    if (this.model) {
      this.setTransactions();
    }
    this.listenTo(this.model, "sync", this.render);
    return this.listenTo(inventory_transactions, "add change destroy sync update", (t) => {
      // Update date picker on new/updated transaction
      if ((t.getSource && t.getSource() === this.model) || (t.getDestination && t.getDestination() === this.model)) {
        if (moment(this.range.startDate).isAfter(t.get("date"))) {
          this.setDateRange({ start: t.get("date") });
        }
        if (moment(this.range.endDate).isBefore(t.get("date"))) {
          this.setDateRange({ end: t.get("date") });
        }
      }

      this.setTransactions();
      return this.render();
    });
  },

  render() {
    const bin = this.model.toJSON();
    const transactions = this.getTransactions();
    let balance = this.get("balance");
    const capacity = bin.capacity || 0;
    const available_capacity = balance < 0 ? 0 : Math.max(this.get("available_capacity"), 0);
    if (balance < 0) {
      balance = 0;
    }
    const data = {
      id: bin.id,
      icon: this.getIcon(),
      name: bin.name,
      group: bin.group,
      commodity: this.get("commodity")?.name,
      location: [bin.city, bin.state].join(", "),
      capacity,
      available_capacity: _.numberFormat(available_capacity),
      balance: _.numberFormat(balance),
      rented: bin.rented ? "Rented" : "Owned",
      notes: bin.notes,
      transactions,
      details: renderUI("big_stats", this.getDetails()),
      ...this.range.toJSON(),
    };

    this.$el.html(render("bin", data));
    return this.setUI();
  },

  renderBinModal(model) {
    const binModal = new BinView({
      collection: BinsCollection,
      model,
    });
    binModal.render().setUI();
  },

  editBin() {
    this.renderBinModal(this.model);
  },

  editLocation(event) {
    event.preventDefault();
    const { locationId, locationType } = $(event.target).data();
    switch (locationType) {
      case "crop":
        this.trigger("editCrop", locationId);
        break;

      case "bin":
        this.trigger("viewBinDetails", locationId);
        break;
    }
  },

  onDateChange(event) {
    const dateFieldName = event.target.name;
    let { startDate: start, endDate: end } = this.range;
    if (dateFieldName === "startDate") {
      start = this.range.isValidStartDate(event.target.value)
        ? event.target.value
        : inventory_transactions.getDateRange(this.model.get("inventory_node_id")).startDate;
    } else if (dateFieldName === "endDate") {
      end = this.range.isValidEndDate(event.target.value)
        ? event.target.value
        : inventory_transactions.getDateRange(this.model.get("inventory_node_id")).endDate;
    }

    this.setDateRange({ start, end });
    this.render();
  },

  setUI() {
    if (this.transactions.length !== this.get("transactions").length) {
      return this.$(".js-show-more").show();
    } else {
      return this.$(".js-show-more").hide();
    }
  },

  getIcon() {
    const bin = this.model.toJSON();

    return renderUI("storage", {
      id: bin.id,
      commodity: this.get("commodity")?.name,
      percent: this.model.getUIPercentage(this.get("balance")),
      color: this.get("commodity")?.color,
    });
  },

  getTransactions() {
    return this.transactions.map((t) => {
      const transaction = t.toJSON();
      const sourceNodeDetails = t.getNodeDetails(t.get("source_id"), this.getCropByInventoryNode);
      const destinationNodeDetails = t.getNodeDetails(t.get("destination_id"));
      const locationNodeDetails = sourceNodeDetails?.node === this.model ? destinationNodeDetails : sourceNodeDetails;
      const { node: location, type: locationType } = locationNodeDetails ?? {};
      const isCropLocation = locationType === "crop";
      const locationName = isCropLocation
        ? `${location.field.name} (${location.cropYear} ${location.commodity.name})`
        : location?.get("name");

      return {
        id: transaction.id,
        image: transaction.img,
        is_in: t.getDestination() === this.model,
        date: t.formatDate(),
        commodity_id: t.get("commodity_id"),
        commodity: this.getCommodityById(t.get("commodity_id"))?.name,
        amount: transaction.amount,
        moisture: transaction.moisture * 1,
        ticket_num: transaction.ticket_number,
        location: locationName || "Unknown location",
        location_id: location?.id,
        location_group: !isCropLocation ? location?.get("group") || location?.get("city") : undefined,
        location_type: locationType,
        showLocationLink: () => ["bin", "crop"].includes(locationType) && location,
        unit_name: transaction.amount_unit,
      };
    });
  },

  getDetails() {
    const stats = [];
    if (this.get("balance") > 0) {
      stats.push({
        value: _.numberFormat(Math.max(0, this.get("balance"))),
        unit: "bu",
        description: this.get("commodity")?.name,
      });
    }
    if (this.model.get("capacity")) {
      stats.push({
        value: _.numberFormat(Math.min(this.model.get("capacity"), this.get("available_capacity")), 0),
        unit: "bu",
        description: "space remaining",
      });
    }
    return { stats };
  },

  setTransactions() {
    return (this.transactions = _.filter(this.get("transactions"), (t) => {
      const date = t.get("date");
      return (
        moment(this.range.startDate).subtract(1, "days").isBefore(date) &&
        moment(this.range.endDate).add(1, "days").isAfter(date)
      );
    }));
  },

  setDateRange({ start, end }) {
    if (start) {
      this.range.setStartDate(start);
    }
    if (end) {
      this.range.setEndDate(end);
    }

    this.setTransactions();
    return BIN_DETAIL_VIEW_FILTER_CHANGE.track(this.range.toJSON(), { deltas: true });
  },

  showMore() {
    const { length } = this.transactions;
    this.transactions = this.get("transactions").slice(0, length + 5); // Show 5 more transactions
    this.setDateRange({
      start: _(this.transactions).last().get("date"),
      end: _(this.transactions).first().get("date"),
    });
    return this.render();
  },

  editLoad(e) {
    if (!$(e.target).is("a")) {
      BIN_DETAIL_MODAL_OPEN_LOAD_EDIT.track();
      const transactionId = $(e.currentTarget).data("id");
      this.load = this.transactions.find(({ id }) => id === transactionId)?.toJSON() ?? {};
      this.trigger("editLoad", this.load);
    }
  },

  addLoad() {
    this.trigger("addLoad");
  },

  getBushelSize(commodityId) {
    return this.getCommodityById(commodityId)?.bushelSize;
  },

  getCommodityById(id) {
    return this.commodities.get(id);
  },

  get(prop) {
    const id = this.model.get("inventory_node_id");

    switch (prop) {
      case "commodity":
        return this.getCommodityById(inventory_transactions.getNodeCommodityId(id));
      case "available_capacity":
        return (
          this.model.get("capacity") -
          inventory_transactions.getNodeBalance(id, "bu", false, this.getBushelSize.bind(this))
        );
      case "balance":
        return inventory_transactions.getNodeBalance(id, "bu", false, this.getBushelSize.bind(this));
      case "transactions":
        return inventory_transactions.getNodeTransactions(id);
      default:
        throw new Error(prop + " is not defined.");
    }
  },
});
