import styled from "styled-components";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
const NavigationHeader = styled.div `
  align-items: center;
  background-color: ${UIColors.white};
  border-bottom: 1px solid ${GreyColors.smoke80};
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.spacing14} ${Spacing.spacing16} ${Spacing.spacing8};
  width: 100%;
`;
NavigationHeader.displayName = "NavigationHeader";
export default NavigationHeader;
