import { MicroLabel } from "agreements/forms/components";
import { StyleSheet, css } from "aphrodite/no-important";
import { exists, isPositive } from "marketing/utils/validators";
import PropTypes from "prop-types";
import React from "react";

import { BasicInput } from "components/fl-ui/Form/Input";
import { FormGroup, Select } from "components/fl-ui/Form/index";
import { Spacing, Mixins } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  productionCosts: {
    marginBottom: Spacing.spacing24,
  },
  input: {
    maxWidth: Mixins.toRem(160),
    marginRight: Spacing.spacing8,
  },
  select: {
    maxWidth: Mixins.toRem(160),
    marginRight: Spacing.spacing8,
  },
});

const SingleProductionCost = ({ onChange, error, productionCostUnits, productionCost, productionCostUnit }) => {
  const handleCostChange = (e) => {
    const val = e.target.value;
    if (isPositive(val) || !exists(val)) {
      onChange("productionCost", val);
    }
  };

  const handleUnitChange = (e) => {
    const val = e.target.value;
    onChange("productionCostUnit", val);
  };

  return (
    <FormGroup error={error?.productionCost?.msg} horizontal>
      <span style={{ marginRight: ".5rem" }}>
        <MicroLabel label="Amount">
          <BasicInput
            id="single-production-cost"
            type="text"
            size="large"
            className={css(styles.input)}
            value={productionCost || ""}
            onChange={handleCostChange}
            prefix="$"
          />
        </MicroLabel>
      </span>

      <MicroLabel label="Rate">
        <Select
          id="single-production-cost-unit"
          value={productionCostUnit}
          options={
            productionCostUnits &&
            productionCostUnits.reduce((acc, val, i) => {
              acc.push({
                key: i,
                label: val.data === "PER_ACRE" ? "/ ac" : val.display,
                value: val.data,
              });
              return acc;
            }, [])
          }
          onChange={handleUnitChange}
          minimal
        />
      </MicroLabel>
    </FormGroup>
  );
};

SingleProductionCost.propTypes = {
  error: PropTypes.object,
  onChange: PropTypes.func,
  productionCostUnit: PropTypes.string,
  productionCostUnits: PropTypes.array,
  productionCost: PropTypes.number,
};

export default SingleProductionCost;
