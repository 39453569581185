import { css, StyleSheet } from "aphrodite/no-important";
import classNames from "classnames";
import React from "react";
import autoBind from "react-autobind";

import Icon from "components/fl-ui/Icons/Icon";
import SortIndicator from "components/fl-ui/SortIndicator";
import Tooltip from "components/fl-ui/Tooltip";
import { GreyColors, UIColors } from "components/fl-ui/colors/index";
import { Mixins, Spacing, Typography } from "components/fl-ui/constants/index";

const styles = StyleSheet.create({
  // TO DO: (based on tables.less)
  // - row striping
  // - checklist table
  // - tablesorter
  table_container: {
    overflowX: "auto",
    width: "100%",
  },
  table: {
    fontWeight: 500,
    color: Typography.colors.primary,
    width: "100%",
    margin: "0",
    marginBottom: Spacing.spacing24,
    verticalAlign: "middle",
    borderSpacing: 0,
  },
  thead: {},
  th_td: {
    ":first-child": {
      whiteSpace: "nowrap",
      paddingLeft: "0 !important",
    },
    ":last-child": {
      paddingRight: "0 !important",
    },
    padding: Spacing.spacing16,
    fontSize: Typography.sizes.rg,
    color: Typography.colors.primary,
    textAlign: "left",
    fontWeight: 400,
  },
  thead_th: {
    position: "relative",
    fontWeight: 500,
    lineHeight: 1.42,
    whiteSpace: "nowrap",
    borderBottom: "2px solid hsl(216, 11%, 91%)",
  },
  clickableRow: {
    cursor: "pointer",
    ":hover": {
      cursor: "pointer",
      background: GreyColors.smoke99,
    },
  },
  tbody_td: {
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    border: "1px solid hsl(216, 11%, 91%)",
    borderWidth: "1px 0",
  },
  tfoot: {},
  clickable: {
    cursor: "pointer",
    ":hover": {
      cursor: "pointer",
      background: GreyColors.smoke99,
    },
  },
  string: {
    textAlign: "left",
  },
  number: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  center: {
    textAlign: "center",
  },
  top: {
    verticalAlign: "top",
  },
  bottom: {
    verticalAlign: "bottom",
  },
  noPadding: {
    padding: 0,
  },

  tooltip: {
    marginLeft: Spacing.spacing12,
    display: "inline-block",
  },
  colIcon: {
    width: Spacing.spacing16,
    height: Spacing.spacing16,
    display: "inline-block",
    verticalAlign: "middle",
  },
});

export const Table = ({ children, style, className }) => (
  <div className={css(styles.table_container)}>
    <table className={classNames(css(styles.table, style && Mixins.getOverrideStyles(style)), className)}>
      {children}
    </table>
  </div>
);

export const Header = ({ children, style, className }) => (
  <thead className={classNames(css(styles.thead, style && Mixins.getOverrideStyles(style)), className)}>
    {children}
  </thead>
);

export const Footer = ({ children, style, className }) => (
  <tfoot className={classNames(css(styles.tfoot, style && Mixins.getOverrideStyles(style)), className)}>
    {children}
  </tfoot>
);

export const Body = ({ children }) => <tbody>{children}</tbody>;

// Refactor this component to be a functional component

export class Row extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleClick(e) {
    if (!this.props.onClick) return;

    this.props.onClick(e, this.props);
  }

  render() {
    const { onClick, style, className, children, ...otherProps } = this.props;
    return (
      <tr
        onClick={this.handleClick}
        className={classNames(
          css(styles.tbody_tr, onClick && styles.clickableRow, style && Mixins.getOverrideStyles(style)),
          className
        )}
        {...otherProps}
      >
        {children}
      </tr>
    );
  }
}

export class HeaderCol extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleClick(e) {
    if (!this.props.onClick) return;

    this.props.onClick(e, this.props);
  }

  render() {
    const { onClick, style, width, children, type, align, sortAsc, sortBy, className, tooltip, ...otherProps } =
      this.props;
    return (
      <th
        {...otherProps}
        onClick={this.handleClick}
        className={classNames(
          css(
            styles.th_td,
            styles.thead_th,
            onClick && styles.clickable,
            type && styles[type],
            align && styles[align],
            style && Mixins.getOverrideStyles(style)
          ),
          className
        )}
        colSpan={width}
      >
        {children}
        {tooltip && (
          <span className={css(styles.tooltip)}>
            <Tooltip message={tooltip}>
              <Icon icon="help" className={css(styles.colIcon)} />
            </Tooltip>
          </span>
        )}
        {sortAsc !== undefined && <SortIndicator sortDirection={sortAsc ? "asc" : "desc"} />}
      </th>
    );
  }
}

HeaderCol.defaultProps = { width: 1 };

export class Col extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleClick(e) {
    if (!this.props.onClick) return;

    this.props.onClick(e, this.props);
  }

  render() {
    const { onClick, style, width, children, type, align, verticalAlign, noPadding, className, ...otherProps } =
      this.props;
    return (
      <td
        {...otherProps}
        onClick={this.handleClick}
        className={classNames(
          css(
            styles.th_td,
            styles.tbody_td,
            onClick && styles.clickable,
            type && styles[type],
            align && styles[align],
            verticalAlign && styles[verticalAlign],
            noPadding && styles.noPadding,
            style && Mixins.getOverrideStyles(style)
          ),
          className
        )}
        colSpan={width}
      >
        {children}
      </td>
    );
  }
}

Col.defaultProps = { width: 1 };
