import { gql } from "@apollo/client";

import { COMMODITY } from "collection/graphql/commodities/fragments";

const UNDERLYING_FRAGMENT = gql`
  fragment UnderlyingFragment on Underlying {
    commodity {
      ...Commodity
    }
    currentMarketPrice
    expirationDate
    fullName
    futuresExpirationDate
    optionsExpirationDate
    shortName
    symbol
  }

  ${COMMODITY}
`;

export { UNDERLYING_FRAGMENT };
