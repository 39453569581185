import { useQuery } from "@apollo/client";
import React from "react";
import CountUp from "react-countup";
import styled from "styled-components";

import { GET_MARKETED_CROPS_SUMMARY } from "collection/graphql/marketing";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import useViewType from "hooks/useViewType";
import { MARKETING_PAGE_POLL_INTERVAL } from "modules/marketing/constants";

import { IconContainer } from "components/fl-ui/Icons";
import ToolTip from "components/fl-ui/Tooltip";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing, Typography } from "components/fl-ui/constants";

const MarketingPositionSummaryCard = styled(({ className }) => {
  const [cropYear] = useCurrentCropYear();
  const isMobile = useViewType() === "mobile";

  const { data, loading } = useQuery(GET_MARKETED_CROPS_SUMMARY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    pollInterval: MARKETING_PAGE_POLL_INTERVAL,
    variables: { year: cropYear },
  });

  const summary = data?.summary;

  return (
    <div className={className}>
      <div className="summary-column">
        <div className="summary-amount">
          {loading || !summary?.protected ? (
            <span>--</span>
          ) : (
            <CountUp decimals={2} duration={1} end={summary.protected} prefix="$" />
          )}
        </div>
        <p>
          Projected Revenue
          {isMobile && <br />}
          <ToolTip message="Bushels that are sold and have a cash price on a contract price line." placement="bottom">
            <IconContainer color={GreyColors.smoke30} icon="help" size={Spacing.spacing16} />
          </ToolTip>
        </p>
      </div>

      <div className="summary-column">
        <div className="summary-amount">
          {loading || !summary?.unprotected ? (
            <span>--</span>
          ) : (
            <CountUp decimals={2} duration={2.25} end={summary.unprotected} prefix="$" />
          )}
        </div>
        <p>
          Mark to Market Unprotected
          {isMobile && <br />}
          <ToolTip
            message="Bushels that are unsold and unpriced that are exposed to mark-to-market prices."
            placement="bottom"
          >
            <IconContainer color={GreyColors.smoke30} icon="help" size={Spacing.spacing16} />
          </ToolTip>
        </p>
      </div>

      <div className="summary-column">
        <div className="summary-amount">
          {loading || !summary?.profit ? (
            <span>--</span>
          ) : (
            <CountUp decimals={2} duration={3.5} end={summary.profit} prefix="$" />
          )}
        </div>
        <p>
          Estimated Profit
          {isMobile && <br />}
          <ToolTip
            message="Projected revenue minus production costs in all marketing crops for the year."
            placement="bottom"
          >
            <IconContainer color={GreyColors.smoke30} icon="help" size={Spacing.spacing16} />
          </ToolTip>
        </p>
      </div>
    </div>
  );
})`
  margin: ${Spacing.spacing28} 0;
  padding: ${Spacing.spacing16} 0;
  border-top: 1px solid ${GreyColors.smoke90};
  border-bottom: 1px solid ${GreyColors.smoke90};
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  .summary-column {
    width: 33%;
    text-align: center;

    .summary-amount span {
      display: block;
      font-size: ${Typography.sizes.xl};
      font-weight: 700;
      margin-bottom: ${Spacing.spacing8};
      margin-top: ${Spacing.spacing8};
    }

    p {
      color: ${GreyColors.smoke60};
      font-size: ${Typography.sizes.rg};
    }

    @media (max-width: 39rem) {
      .summary-amount span {
        font-size: ${Typography.sizes.rg};
      }

      p {
        font-size: ${Typography.sizes.sm};
      }
    }

    @media (max-width: 23rem) {
      width: 100%;
    }
  }
`;

export default MarketingPositionSummaryCard;
