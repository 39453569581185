// eslint-disable-next-line no-restricted-imports
import "./../styles/app.less";
import "leaflet/dist/leaflet.css";
import "main.js";
import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import routerV2 from "routerV2";
if (module.hot) {
    module.hot.accept();
}
const element = document.getElementById("layout");
if (!element) {
    throw new Error("Root element not found");
}
const root = createRoot(element);
root.render(React.createElement(RouterProvider, { router: routerV2 }));
