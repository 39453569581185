import { UIColors, ColorPalette, GreyColors } from "components/fl-ui/colors";
import { Mixins } from "components/fl-ui/constants";
// Breakpoints in use:
// max-width 62.5em - mobile styles
const menuAnimationDuration = 250;
const styleVars = {
    brandLogo_color: UIColors.green,
    mainMenuWidth_desktop: Mixins.toRem(272),
    mainMenuWidth_tablet: Mixins.toRem(82),
    mainMenuWidth_mobile: Mixins.toRem(272),
    mainMenu_zIndex: 1000,
    mainMenuMobile_color: UIColors.regular,
    userMenu_zIndex: 3000,
    userMenu_linkColor: UIColors.primary,
    userMenu_backgroundColor: UIColors.white,
    userMenu_dividerColor: GreyColors.smoke90,
    userMenu_boxShadow: `0 2px 4px 0 rgba(0,0,0,0.15), inset 0 0 0 1px ${GreyColors.smoke90}`,
    userMenuMobile_zIndex: 1000,
    userMenuMobile_boxShadow: "-2px 0 4px 0 rgba(0,0,0,0.2)",
    userMenuMobile_backgroundColor: ColorPalette["almost-white"],
    header_color: UIColors.regular,
    header_backgroundColor: UIColors.white,
    header_border: `1px solid ${UIColors.lightest}`,
    header_height: Mixins.toRem(68),
    screenOverlay_zIndex: 2000,
    screenOverlayMobile_zIndex: 500,
    screenOverlay_color: "rgba(0, 0, 0, 0.35)",
    // Animation Transition Group Styles
    menuTransitionDuration: menuAnimationDuration,
    userMenuDefaultStyle: {
        transition: `height ${menuAnimationDuration}ms ease-in-out`,
        height: 0,
    },
    userMenuTransitionStyles: {
        entering: { height: 0 },
        entered: { height: "auto" },
    },
    screenDefaultStyle: {
        transition: "display 1ms, opacity 200ms",
        transitionDelay: `100ms`,
        transitionTimingFunction: "ease-in-out",
        display: "none",
    },
    screenTransitionStyles: {
        entering: {
            display: "flex",
            opacity: 0,
        },
        entered: {
            display: "flex",
            opacity: 1,
        },
        exiting: {
            display: "flex",
            opacity: 0,
        },
        exited: {
            display: "none",
            opacity: 0,
        },
    },
};
export default styleVars;
