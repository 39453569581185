import { css, StyleSheet } from "aphrodite/no-important";
import { useConditionalTimeout, useViewportSpy } from "beautiful-react-hooks";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { useFieldForecast } from "collection/graphql/weather";

import { Mixins, Spacing, Typography } from "components/fl-ui/constants";
import ForecastDetail from "fields/components/forecast/ForecastDetail";

const WEATHER_LOAD_DELAY = 1250;

const styles = StyleSheet.create({
  container: {
    minHeight: 70,
  },
  fallbackText: {
    alignItems: "center",
    display: "flex",
    fontWeight: Typography.weights.regular,
    height: Mixins.toRem(40),
    margin: `0 ${Spacing.spacing12}`,
  },
  smallText: {
    fontSize: Typography.sizes.sm,
    marginBottom: 0,
  },
});

const LazyForecastContainer = ({ fieldId }) => {
  const ref = useRef();
  const isInViewport = useViewportSpy(ref);
  const [loadForecast, setLoadForecast] = useState(!!isInViewport);
  const { enabled, forecast, loading } = useFieldForecast(fieldId, !loadForecast);
  const { dailyForecast, weeklyForecast } = forecast ?? {};
  useConditionalTimeout(
    () => {
      setLoadForecast(true);
    },
    WEATHER_LOAD_DELAY,
    isInViewport && !loadForecast
  );

  const showOffline = !enabled;
  const hasForecastData = !_.isEmpty(dailyForecast) && !_.isEmpty(weeklyForecast);
  const showNoData = enabled && !loading && !hasForecastData;
  const showLoading = enabled && (!loadForecast || loading);
  const showForecast = enabled && hasForecastData;

  return (
    <div ref={ref} className={css(styles.container)}>
      {showOffline && (
        <p className={css(styles.smallText)}>Field weather forecasts are temporarily under maintenance.</p>
      )}
      {showNoData && <h4 className={css(styles.fallbackText)}>No forecast data is available</h4>}
      {showLoading && <h4 className={css(styles.fallbackText)}>Loading forecast data{"\u2026"}</h4>}
      {showForecast && <ForecastDetail dailyForecast={dailyForecast} weeklyForecast={weeklyForecast} />}
    </div>
  );
};

LazyForecastContainer.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default LazyForecastContainer;
