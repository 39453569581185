import { useLocation } from "react-router-dom";
const getMainMenuItemActiveClasses = ({ showRouteAsActive, to }) => {
    const location = useLocation();
    if (showRouteAsActive) {
        return location.pathname.includes(showRouteAsActive) ? "active" : undefined;
    }
    else {
        return location.pathname === to ? "active" : undefined;
    }
};
export { getMainMenuItemActiveClasses };
