import React from "react";
import styled from "styled-components";
const FieldGroupTitle = styled(({ className, title }) => (React.createElement("span", { className: className }, title))) `
  display: table-cell;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;

  @media (min-width: 421px) and (max-width: 600px) {
    max-width: 14rem;
  }

  @media (max-width: 420px) {
    max-width: 7rem;
  }
`;
export default FieldGroupTitle;
