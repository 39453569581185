import { StyleSheet, css } from "aphrodite/no-important";
import React from "react";

import { Mixins, Spacing, Typography } from "components/fl-ui/constants";

const spinnerKeyframes = {
  from: {
    // ignore linter - these keys must be in quotes
    transform: "rotate(0deg)",
  },
  to: {
    transform: "rotate(720deg)",
  },
};

const styles = StyleSheet.create({
  h3: {},
  spinner: {
    ":before": {
      ...Mixins.beforeAfter,
      width: Spacing.spacing20,
      height: Spacing.spacing20,
      left: "50%",
      margin: `0 0 0 ${(Spacing.spacing20 * -1) / 2}`,
      borderRadius: "100%",
      borderWidth: `2px`,
      borderStyle: "solid",
      borderColor: `${Typography.colors.secondary} transparent`,
      animationName: spinnerKeyframes,
      animationDuration: "2.5s",
      animationTimingFunction: "ease",
      animationIterationCount: "infinite",
    },
  },
});

const Loader = (props) => {
  return (
    <h3>
      <span className={css(styles.spinner)} />
      {props.label}
    </h3>
  );
};

export default Loader;
