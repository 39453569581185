import React from "react";
import styled from "styled-components";
import { GreyColors, UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";
const WalletSignUpSection = styled(({ className, children }) => {
    return React.createElement("div", { className: className }, children);
}) `
  background: ${({ backgroundColor }) => backgroundColor === "grey" ? GreyColors.smoke98 : UIColors.white};
  margin: 0;
  padding: ${Spacing.spacing16};
`;
export { WalletSignUpSection };
