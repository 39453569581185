import React, { useState } from "react";
import InputItem from "settings/components/input_item";

import useProductRecipes from "collection/graphql/recipes/hooks/useProductRecipes";
import { InputGroup } from "modules/settings/components/InputGroup";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RecipeCrud from "components/product_recipe/crud";

const ProductRecipeList = () => {
  const { loading, recipes } = useProductRecipes();

  const [currentRecipe, setCurrentRecipe] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const hide = () => {
    setCurrentRecipe(undefined);
    setShowModal(false);
  };

  const show = (recipe) => {
    setCurrentRecipe(recipe);
    setShowModal(true);
  };

  return (
    <LoadingWrapper isLoading={loading}>
      <InputGroup onAdd={() => show()} title="Saved Mixes">
        {recipes.map((recipe) => (
          <InputItem
            key={recipe.id}
            onEdit={() => show(recipe)}
            sub={recipe.description || "(No ingredients)"}
            title={recipe.name}
          />
        ))}
      </InputGroup>

      {showModal && <RecipeCrud recipe={currentRecipe} onFinish={hide} />}
    </LoadingWrapper>
  );
};

export default ProductRecipeList;
