import { captureException } from "@sentry/react";
import { css } from "aphrodite/no-important";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import getAllUsers from "collection/graphql/users/queries/getAllUsers";
import { getCurrentSubscription, useSubscriptionPlatform, useUpdateSubscription, } from "collection/graphql/subscription";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import { TIER_SELECTION_PLAN_WELCOME_START } from "lib/metrics/events";
import CurrentSubscriptionCopy from "modules/billing/components/CurrentSubscriptionCopy";
import DownGradeModal from "modules/billing/components/DownGradeModal";
import SuccessModal from "modules/billing/components/SuccessModal";
import OrderErrorDialog from "modules/billing/components/dialogs/OrderErrorDialog";
import InvoiceHistory from "modules/settings/components/InvoiceHistory";
import ManageSubscriptionAction from "modules/settings/components/ManageSubscriptionAction";
import styles, { CancellationText, CopyText, HeaderText, TrialText } from "modules/settings/styles";
import LoadingDialog from "components/fl-ui/LoadingDialog";
import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";
const formatDate = (date) => moment(date).format("MMMM D, YYYY");
const BillingSettingsPage = withSuspenseWrapper(() => {
    var _a, _b;
    const navigate = useNavigate();
    const [dialogId, setDialogId] = useState("");
    const [saveError, setSaveError] = useState(null);
    const { billingInterval, cancelAtPeriodEnd, currentPlan, daysRemaining, isSubscribed, isTrialing, userLimits } = 
    // @ts-ignore
    useRestSuspenseQuery(getCurrentSubscription).data.subscription;
    const showInvoices = (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.source) === "stripe";
    const { cancelSubscription: cancelCurrentSubscription } = useUpdateSubscription();
    const subscriptionPlatform = useSubscriptionPlatform();
    // @ts-ignore
    const { users } = useRestSuspenseQuery(getAllUsers).data;
    const legacyPaidSeats = users.filter((user) => user.isLegacyPaidSeat).length;
    let planDetails = "";
    if ((currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.totalPrice) && currentPlan.source === "stripe" && billingInterval) {
        planDetails = `$${numeral(currentPlan.totalPrice).format("0,0")} / ${billingInterval}`;
        if (moment(currentPlan.periodEnd).isValid()) {
            planDetails += ` · Next payment due ${formatDate(currentPlan.periodEnd)}`;
        }
    }
    const closeDialog = () => {
        setSaveError(null);
        setDialogId("");
    };
    const onSuccess = () => {
        navigate("/fields", { replace: true });
        location.reload();
    };
    const cancelSubscription = async () => {
        var _a, _b;
        setDialogId("processing");
        try {
            await cancelCurrentSubscription();
            TIER_SELECTION_PLAN_WELCOME_START.track();
            onSuccess();
        }
        catch (error) {
            const context = (_b = (_a = error.getContext) === null || _a === void 0 ? void 0 : _a.call(error)) !== null && _b !== void 0 ? _b : {};
            const sentryEventId = captureException(error, context);
            console.error("Sentry event id: ", sentryEventId);
            setSaveError(error);
            setDialogId("error");
        }
    };
    const getPlanSummary = () => {
        if (subscriptionPlatform === null || subscriptionPlatform === void 0 ? void 0 : subscriptionPlatform.isMobilePaymentProvider) {
            return `You are subscribed via ${subscriptionPlatform.storeName}`;
        }
        return !isSubscribed ? "Get started with Bushel Farm today" : React.createElement(CurrentSubscriptionCopy, null);
    };
    return (React.createElement("div", null,
        React.createElement(Row, { className: css(styles.rowBorderBottom) },
            React.createElement(Col, { xs: 12, sm: 6, className: css(styles.colStyles) },
                React.createElement("div", null,
                    React.createElement(HeaderText, null, getPlanSummary()),
                    cancelAtPeriodEnd && (React.createElement(CancellationText, null,
                        "Scheduled for cancellation in ",
                        daysRemaining === 1 ? "1 day" : `${daysRemaining} days`,
                        " on",
                        " ",
                        moment(currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.periodEnd).format("MMMM DD, YYYY"),
                        ".")),
                    isTrialing && (React.createElement(TrialText, null,
                        "Trial ends on ",
                        moment(currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.periodEnd).format("dddd, MMMM DD, YYYY"))),
                    planDetails && React.createElement(CopyText, null, planDetails))),
            React.createElement(Col, { xs: 12, sm: 6, className: css(styles.colStyles, styles.rightAligned) },
                React.createElement(ManageSubscriptionAction, { onCancelSubscription: () => setDialogId("confirm") }))),
        React.createElement(Row, { className: css(styles.rowBorderBottom) },
            React.createElement(Col, { xs: 12, md: 6, className: css(styles.colStyles) },
                React.createElement(HeaderText, null, "User summary")),
            React.createElement(Col, { xs: 12, md: 6, className: css(styles.colStyles, styles.rightAligned) },
                React.createElement("div", { className: css(styles.summary, styles.summaryRight) },
                    React.createElement("span", null,
                        React.createElement(HeaderText, null, legacyPaidSeats),
                        React.createElement(CopyText, null,
                            "\u00A0 ",
                            legacyPaidSeats === 1 ? "user" : "users")),
                    React.createElement(CopyText, null, "Your account")),
                React.createElement("div", { className: css(styles.summary, styles.summaryRight) },
                    React.createElement("span", null,
                        React.createElement(HeaderText, null, (_b = (_a = userLimits === null || userLimits === void 0 ? void 0 : userLimits.users) === null || _a === void 0 ? void 0 : _a.max) !== null && _b !== void 0 ? _b : "Unlimited"),
                        React.createElement(CopyText, null, "\u00A0 users")),
                    React.createElement(CopyText, null, "Plan limit")))),
        showInvoices && React.createElement(InvoiceHistory, null),
        dialogId === "confirm" && React.createElement(DownGradeModal, { onClose: closeDialog, onConfirm: cancelSubscription }),
        dialogId === "processing" && React.createElement(LoadingDialog, { dialogMessage: "Processing..." }),
        dialogId === "success" && React.createElement(SuccessModal, { onClose: onSuccess }),
        dialogId === "error" && React.createElement(OrderErrorDialog, { actionType: "cancel", error: saveError, onClose: closeDialog })));
});
export { BillingSettingsPage };
