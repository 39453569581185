import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { Button } from "components/fl-ui";
import { UIColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const ClearAllButton = styled(({ className, onClick }) => (
  <Button className={className} color="danger" hollow lightWeight icon="circleClose" onClick={onClick}>
    Clear all
  </Button>
))`
  border: none !important;

  & > div {
    margin-right: ${Spacing.spacing2};

    svg {
      color: ${UIColors.red};
      padding: ${Spacing.spacing2} 0 ${Spacing.spacing2} 0;
    }
  }
`;

ClearAllButton.propTypes = {
  onClick: PropTypes.func,
};

export default ClearAllButton;
