import { gql } from "@apollo/client";
const getUserNotifications = gql `
  query getUserNotifications {
    notifications: getUserNotifications {
      body
      created
      data
      id
      title
      type
      user {
        id
      }
    }
  }
`;
export default getUserNotifications;
