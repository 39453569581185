import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { IconContainer } from "components/fl-ui/Icons";
import Tooltip from "components/fl-ui/Tooltip";
import { Spacing } from "components/fl-ui/constants";

export const Search = styled(
  ({
    autofocus = false,
    className,
    onChange,
    onSubmit = () => {},
    placeholder = null,
    style = null,
    tooltip = null,
    value = "",
  }) => {
    const [searchValue, setSearchValue] = useState(value);

    const handleSubmit = (event) => {
      event.preventDefault();
      onSubmit(event.target[0].value);
    };

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleSubmit(event);
      }
    };

    const handleChange = (event) => {
      setSearchValue(event.target.value);
    };

    const handleFetch = (input) => {
      onChange(input);
    };

    const handleClear = () => {
      setSearchValue("");
      onChange("");
    };

    const debouncedFetch = useMemo(() => debounce(handleFetch, 600), []);
    const didMount = useRef(false);

    useEffect(() => {
      if (didMount.current) {
        debouncedFetch(searchValue);
      } else {
        didMount.current = true;
      }
    }, [searchValue]);

    return (
      <form onSubmit={handleSubmit}>
        <div style={style} className={className}>
          <div className="prefix-icon" onClick={handleSubmit} onKeyPress={handleKeyPress}>
            <img src="/images/icons/search.svg" alt="" />
          </div>

          <input
            autoFocus={autofocus}
            type="text"
            data-qa-id="search"
            placeholder={placeholder}
            onChange={handleChange}
            className="search-text"
            value={searchValue}
          />

          {!!searchValue.length && (
            <div className="suffix-icon" onClick={handleClear}>
              <img src="/images/icons/circle_x_smoke.svg" alt="clear search" />
            </div>
          )}
          {!searchValue.length && tooltip && (
            <div className="suffix-icon">
              <Tooltip placement="bottom" message={tooltip}>
                <IconContainer icon="help" size={Spacing.spacing16} />
              </Tooltip>
            </div>
          )}
        </div>
      </form>
    );
  },
)`
  align-items: center;
  border: 1px solid rgb(230, 232, 235);
  display: flex;
  line-height: 16px;
  padding: 0.4375rem rem 1rem;

  .search-text {
    padding: 7px 0px;
    border: none;
    width: 100%;
  }
  .prefix-icon {
    padding: 0 8px;
    cursor: pointer;
  }
  .suffix-icon {
    padding: 0 8px;
    cursor: pointer;
  }
  img {
    height: 16px;
    max-width: 19px;
  }
`;

export default Search;
