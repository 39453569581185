import { css } from "aphrodite/no-important";
import React, { useEffect } from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import Screen from "components/fl-ui/Layout/Navigation/Screen";
import Menu from "components/fl-ui/Layout/Navigation/components/Menu";
import UserProfileIcon from "components/fl-ui/Layout/Navigation/components/UserProfileIcon";
import styleVars from "components/fl-ui/Layout/Navigation/styleVars";
import styles from "components/fl-ui/Layout/Navigation/userMenuStyles";
import { useLocation } from "react-router-dom";
import useBoolean from "hooks/useBoolean";
const ToolbarUserMenu = () => {
    const [showUserMenu, { off, on }] = useBoolean(false);
    const location = useLocation();
    useEffect(() => {
        off();
    }, [location]);
    return (React.createElement("div", null,
        React.createElement("div", { className: css(styles.navToggle), "data-cy": "userMenuTrigger" },
            React.createElement(UserProfileIcon, { onClick: on })),
        React.createElement(TransitionGroup, null, showUserMenu && (React.createElement(Transition, { key: "userMenuTransition", timeout: styleVars.menuTransitionDuration, unmountOnExit: true }, (status) => (React.createElement(React.Fragment, null,
            React.createElement(Menu, { close: off, status: status }),
            React.createElement(Screen, { onClick: off, style: Object.assign(Object.assign({}, styleVars.screenDefaultStyle), styleVars.screenTransitionStyles[status]) }))))))));
};
export default ToolbarUserMenu;
