import { useMemo } from "react";
import useUnits from "hooks/useUnits";
const ContractSubtypeCopy = ({ subtype, product }) => {
    const { data, getEnumKeyValues } = useUnits();
    const displayCopy = useMemo(() => {
        var _a;
        const combinedEnums = Object.assign(Object.assign(Object.assign({}, getEnumKeyValues("CashContractType")), getEnumKeyValues("FuturesContractType")), getEnumKeyValues("OptionsContractType"));
        return (_a = combinedEnums[subtype]) !== null && _a !== void 0 ? _a : "";
    }, [data, getEnumKeyValues]);
    return `${displayCopy}${product ? ` - ${product}` : ""}`;
};
export default ContractSubtypeCopy;
