import { useQuery } from "@apollo/client";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import ReactSelect from "react-select";

import getMinimalPurchases from "collection/graphql/products/getMinimalPurchases";
import useCurrentCropYear from "hooks/useCurrentCropYear";

export const useVendorOptions = () => {
  const { data } = useQuery(getMinimalPurchases, {
    variables: {
      year: useCurrentCropYear()[0],
    },
  });

  const vendors = {};
  _.each(data?.purchaseSummaries, ({ purchases }) => {
    _.each(purchases, ({ vendor }) => {
      if (_.trim(vendor?.name)) {
        vendors[vendor.name] = { label: vendor.name, value: vendor.name };
      }
    });
  });

  return _.sortBy(_.values(vendors), ({ label }) => _.toLower(label));
};

const ProductVendorChooser = ({ onChange, value }) => {
  const options = useVendorOptions();
  const newValue = _.find(options, { value });

  return (
    <ReactSelect
      isDisabled={options.length === 0}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ optionValue }) => optionValue}
      onChange={({ onChangeValue }) => onChange(onChangeValue)}
      options={options}
      placeholder=""
      value={newValue || ""}
    />
  );
};

ProductVendorChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default ProductVendorChooser;
