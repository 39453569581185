import { StartNavigation } from "@proteansoftware/capacitor-start-navigation";
import { upperFirst } from "lodash";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useCropLayer from "hooks/useCropLayer";
import DetailsWidget from "modules/fields/components/DetailsWidget";
import FieldDetailsTable from "modules/fields/components/FieldDetailsTable";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";
import BasicFieldMap from "components/field/BasicFieldMap";
import CropHistory from "components/field/CropHistory";
import { Button } from "components/fl-ui";
import ContentSidebar from "components/fl-ui/Layout/ContentSidebar";
import { Borders, Spacing } from "components/fl-ui/constants";
const ButtonContainer = styled.div `
  border-bottom: ${Borders.regular};
  display: flex;
  justify-content: space-between;
  margin: ${Spacing.spacing16} 0;
  padding: ${Spacing.spacing16} 0;
`;
const SidebarHeader = styled.div `
  margin-top: -${Spacing.spacing24};
  text-align: center;
`;
const FieldDetailSidebar = ({ description, field, fieldId, isMobile, toggleEditModal, year, }) => {
    const { getYearCropsForField } = useYearFieldCrops(year);
    const crops = getYearCropsForField(field === null || field === void 0 ? void 0 : field.id);
    const navigate = useNavigate();
    const isPoint = field.geometry.type.toLowerCase() === "point";
    const { layer, pattern } = useCropLayer({ commodity: null, crops, field, isLayerOnly: null });
    const { centroid, geometry, name } = field;
    const [longitude, latitude] = centroid.coordinates;
    const handleNavigate = () => {
        return StartNavigation.launchMapsApp({ latitude, longitude, name });
    };
    return (React.createElement(ContentSidebar, null,
        isMobile && (React.createElement(SidebarHeader, null,
            React.createElement("h1", { className: "sidebar-h1" }, upperFirst(name)),
            React.createElement("p", null, Array.isArray(description) ? (React.createElement("span", null,
                React.createElement("span", { className: "sidebar-p" }, description[0]),
                description[1] && React.createElement("span", null,
                    " - ",
                    description[1]))) : (description)))),
        React.createElement("div", { className: "sidebar-map-container" },
            React.createElement(BasicFieldMap, { centroid: centroid, geometry: geometry, layer: layer, onClick: () => navigate(`/map/${fieldId}`), pattern: pattern, style: { touchAction: "auto" } }),
            !isPoint && !isMobile && (React.createElement(Link, { className: "btn sidebar-map-edit-link", to: `/fields/${fieldId}/edit` }, "Edit"))),
        !isPoint && isMobile && (React.createElement(ButtonContainer, null,
            React.createElement(Button, { color: "primary", hollow: true, lightWeight: true, onClick: handleNavigate }, "Start Navigation"),
            React.createElement(Link, { className: "btn btn-hollow btn-light-weight btn-primary", to: `/fields/${fieldId}/edit` }, "Edit Boundary"))),
        React.createElement(DetailsWidget, { more: "All crops", title: "Crops", to: `/fields/${fieldId}/crop_history` },
            React.createElement(CropHistory, { fieldId: fieldId, year: year })),
        !isMobile && (React.createElement(DetailsWidget, { title: "Field Details" },
            React.createElement(FieldDetailsTable, { field: field, onAddLandValue: toggleEditModal })))));
};
export default FieldDetailSidebar;
