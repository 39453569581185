import React from "react";
import { DeleteUserConfirmationForm } from "modules/settings/pages/Users/components/forms/DeleteUserConfirmationForm";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
export const DeleteUserModal = ({ className, onClose, user }) => (React.createElement(Modal, { width: 500, hideModal: undefined, id: "delete-user-modal", className: className },
    React.createElement(ModalHeader, null,
        React.createElement(ModalTitle, null, "Are you sure?"),
        React.createElement(CloseX, { onClick: () => onClose() })),
    React.createElement(ModalBody, null,
        React.createElement(DeleteUserConfirmationForm, { onClose: () => onClose(), user: user }))));
export default {
    DeleteUserModal,
};
