import { useQuery } from "@apollo/client";
import _ from "lodash";
import dollarFormat from "marketing/utils/dollarFormat";
import ExpenseRevenueFilterModal from "profit_and_loss/components/ExpenseRevenueFilterModal";
import ExpenseRevenueListing from "profit_and_loss/components/ExpenseRevenueListing";
import ResponsiveBlankSlate from "profit_and_loss/components/ResponsiveBlankSlate";
import { getFilteredTransactions, getSortedTransactions, getTransactionsSearchResults } from "profit_and_loss/utils";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { GET_FINANCIAL_TRANSACTIONS } from "collection/graphql/profit_and_loss/queries";
import useCurrentCropYear from "hooks/useCurrentCropYear";

import { Button } from "components/fl-ui";
import BulkActions from "components/fl-ui/Buttons/BulkActions";
import Filters from "components/fl-ui/Filters/Filters";
import RefineFilters from "components/fl-ui/Filters/RefineFilters";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import PageSummary, { ResponsiveFigure } from "components/fl-ui/PageSummary";

const copyMap = {
  EXPENSE: (
    <span>
      {"Use expenses to track costs not captured by "}
      <Link to="/activity">activities</Link>, <Link to="/land_costs">land costs</Link>,{" or "}
      <Link to="/products">input purchases</Link>
    </span>
  ),
  REVENUE: "Use revenue accounts to track revenue not associated with your crops",
};

const HeaderSummary = ({ allData, displayedData, type }) => {
  const metrics = [
    { label: "Records", value: `${displayedData?.length} of ${allData?.length}` },
    { label: `Total ${_.capitalize(type)}`, value: dollarFormat(_.sumBy(displayedData, ({ amount }) => +amount)) },
  ];

  return (
    <PageSummary>
      {metrics.map(({ label, value }) => (
        <ResponsiveFigure key={label} label={label} value={value} />
      ))}
    </PageSummary>
  );
};

const ExpenseRevenueDashboard = ({ onBulkDelete, onSelect, selection, setExpensesAndRevenueModalData, type }) => {
  const cropYear = useCurrentCropYear()[0];

  const { data, loading } = useQuery(GET_FINANCIAL_TRANSACTIONS, {
    fetchPolicy: "cache-and-network",
    variables: { accountType: type, cropYear },
  });

  const accountTypeDisplay = type === "EXPENSE" ? `${type.toLowerCase()}s` : type.toLowerCase();
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [sortCriteria, setSortCriteria] = useState("date");
  const [showFilterModal, setShowFilterModal] = useState(false);

  const transactions = data?.transactions || [];
  const filteredTransactions = useMemo(() => getFilteredTransactions(transactions, filters), [transactions, filters]);
  const searchedTransactions = useMemo(
    () => getTransactionsSearchResults(filteredTransactions, search),
    [filteredTransactions, search]
  );
  const sortedTransactions = useMemo(
    () => getSortedTransactions(searchedTransactions, sortCriteria),
    [searchedTransactions, sortCriteria]
  );

  const isCommodityAllocated = ({ allocateType }) => allocateType === "COMMODITY_CROP";
  const isFieldAllocated = ({ allocateType }) => allocateType === "FIELD_CROP";
  const isOverhead = ({ allocateType }) => allocateType === "UNALLOCATED";

  const bulkSelectionOptions = _.compact([
    { label: "All", onClick: () => onSelect(sortedTransactions) },
    { label: "None", onClick: () => onSelect([]) },
    sortedTransactions.some(isOverhead) && {
      label: "Allocated to Overhead",
      onClick: () => onSelect(sortedTransactions.filter(isOverhead)),
    },
    sortedTransactions.some(isCommodityAllocated) && {
      label: "Allocated to Marketing Crops",
      onClick: () => onSelect(sortedTransactions.filter(isCommodityAllocated)),
    },
    sortedTransactions.some(isFieldAllocated) && {
      label: "Allocated to Field Crops",
      onClick: () => onSelect(sortedTransactions.filter(isFieldAllocated)),
    },
  ]);

  useEffect(() => {
    setFilters({});
  }, [type]);

  return (
    <>
      <LoadingWrapper isLoading={loading && !data?.transactions}>
        <Filters
          filters={filters}
          search={search}
          setSearch={setSearch}
          setShowFilterModal={setShowFilterModal}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          sortOptions={[
            { key: 1, label: "Date (New to Old)", value: "date" },
            { key: 2, label: "Amount (High to low)", value: "amountDesc" },
            { key: 3, label: "Amount (Low to high)", value: "amountAsc" },
          ]}
        />

        <HeaderSummary allData={transactions} displayedData={sortedTransactions} type={type} />

        <BulkActions
          danger={{ action: onBulkDelete }}
          options={bulkSelectionOptions}
          selection={selection}
          selectionStatus={`${selection?.size} ${type.toLowerCase()} ${selection?.size === 1 ? "item" : "items"}`}
        />

        {!transactions?.length ? (
          <ResponsiveBlankSlate copy={copyMap[type]} header={`You haven’t logged any ${_.capitalize(type)} yet`}>
            <Button
              color="primary"
              onClick={() => setExpensesAndRevenueModalData({ account: { accountType: type } })}
            >{`Log ${_.capitalize(type)}`}</Button>
          </ResponsiveBlankSlate>
        ) : (
          !sortedTransactions?.length && <RefineFilters filterType={accountTypeDisplay} icon={type.toLowerCase()} />
        )}

        {!!sortedTransactions?.length && (
          <ExpenseRevenueListing
            onSelect={onSelect}
            selection={selection}
            setExpensesAndRevenueModalData={setExpensesAndRevenueModalData}
            transactions={sortedTransactions}
            type={type}
          />
        )}
      </LoadingWrapper>

      {showFilterModal && (
        <ExpenseRevenueFilterModal
          filters={filters}
          onClose={() => setShowFilterModal(false)}
          setFilters={setFilters}
          transactions={transactions}
          type={type}
        />
      )}
    </>
  );
};

export default ExpenseRevenueDashboard;
